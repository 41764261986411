import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../Footer";


const CareerPage = () => {

    const [showModel, setShowModel] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])
    
    return (
        <>
            <main>
                <section class="tm_sec d-block">
                    <div class="container">
                        <div class="row   mt-md-5 justify-content-center">
                            <div class="col-lg-12">
                                <div class="section-title  partner_title_sect text-start ">

                                    <div className="row align-items-center g-4" >
                                        <div className="col-lg-5" >
                                            <h2 class="text-dark  "> Join the Future of Finance at <br />  Crypto India Exchange!   </h2>
                                            <div className="ddd text-start"  >
                                                <p>
                                                    At *Crypto India Exchange*, we're shaping the future of digital finance by providing innovative solutions in cryptocurrency trading, blockchain technology, and financial services. As India’s leading crypto exchange, we are committed to creating a secure, user-friendly, and efficient platform for our growing community of traders, investors, and blockchain enthusiasts.
                                                </p>
                                                <p>If you're passionate about technology, finance, and innovation, and want to be part of the rapidly evolving crypto space, this is your opportunity! We’re looking for dynamic individuals to join our team and drive the future of decentralized finance.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-7" >
                                            <div className="sec_img" >
                                                <img src="assets/images/mm_program.svg" className="img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>


                        </div>

                    </div>
                </section>
                <section className="features_sec feature_bg">
                    <div className="container">
                        <div className="sec_title mb-5">
                            <h2 className="text-dark text-center ">Learn, Grow and Innovate with <br /> Crypto India Exchange </h2>
                            <p>At Crypto India Exchange, we believe that learning is key to innovation. That’s why we provide a work environment where you can grow your skills, explore cutting-edge blockchain technologies, and work on real-world projects that shape the future of finance.</p>
                        </div>
                        <div className="row g-4 justify-content-start">
                            <div className="col-lg-4">
                                <div className="card h-100">
                                    <div className="  p-0  h-100">
                                        <div className="  h-100">
                                            <div>
                                                <h6 className="text  mb-3">
                                                    Innovative Culture:
                                                </h6>
                                                <span></span>
                                                <p>Work with a passionate team that encourages creativity and out-of-the-box thinking</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="card h-100">
                                    <div className="  p-0">
                                        <div className="     h-100">
                                            <div>
                                                <h6 className="text mb-3">
                                                    Growth Opportunities
                                                </h6>
                                                <span></span>
                                                <p>Build your career with us in a thriving, global market</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4">
                                <div className="card h-100">
                                    <div className="  p-0">
                                        <div className="     h-100">
                                            <div>
                                                <h6 className="text mb-3">
                                                    Flexible Work Environment:
                                                </h6>
                                                <span></span>
                                                <p>We offer a collaborative and flexible workspace that adapts to your needs</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="job_opens" >
                    <div className="container mt-5  " >
                        <div className="section-title" >
                            <h2>Current Openings</h2>
                        </div>
                        <div className="grm_row" >

                            {/* step_1 */}
                            <div className="grm_card" >
                                <div className="" >
                                    <h5>  Blockchain Developers </h5>
                                    <div className="grm_con" >
                                        <p>Location: <b>Jaipur (Remote work options available)</b> </p>
                                        <p>Full-Stack Developers: <b>3+ years in blockchain development</b> </p>
                                        <p>Skills Required: <b>Proficiency in Solidity, Ethereum, Hyperledger, smart contract development, and familiarity with cryptocurrency protocols.</b> </p>
                                    </div>
                                    <hr className="my-3" />
                                    <button className="btn btn-warning px-5" onClick={() => setShowModel(true)}>
                                        Apply
                                    </button>
                                </div>
                            </div>
                            {/* step_2 */}
                            <div className="grm_card" >
                                <div className="" >
                                    <h6>Step 2:</h6>
                                    <h5>  Escalation to Nodal Officer (Level 2) </h5>
                                    <p>
                                        If your issue is not resolved satisfactorily by our customer support team, you can escalate the matter to our Principal Nodal Officer (Level 2). The nodal officer will review your case and ensure a proper resolution.
                                    </p>
                                    <p>Principal Nodal Officer Contact Information</p>
                                    <div className="grm_con" >
                                        <p>Name:  Pardeep Kumar Yadav </p>
                                        <p>Email: <a href="mailto:nodal@cryptoindia.in" > nodal@cryptoindia.in </a></p>
                                        <p className="pr_txt mb-0" >Response Time: <b>Time: Within 48 hours</b></p>
                                    </div>

                                </div>
                            </div>

                            {/* step_3 */}
                            <div className="grm_card" >
                                <div className="" >
                                    <h6>Step 3:</h6>
                                    <h5>  Escalation to CEO (Level 3) </h5>
                                    <p>
                                        If you are still not satisfied with the resolution provided by the Nodal Officer, you may escalate the matter to the CEO (Level 3) of Crypto India.
                                    </p>
                                    <p>CEO Contact Information</p>
                                    <div className="grm_con" >
                                        <p>Name:  Pardeep Kumar Yadav </p>
                                        <p>Contact: <a href="ceo@cryptoindia.in" > ceo@cryptoindia.in </a></p>
                                        <p className="pr_txt mb-0" >Response Time: <b>Time: Within 72 hours
                                        </b></p>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </section>

                {
                    showModel &&
                    <div className="modal-open-custom" >
                        <div className="modal show d-block" >
                            <div className="modal-dialog  modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header d-flex justify-content-between  px-md-4">
                                        <h5 className="modal-title" id="modal_otp_Label"> Apply For Job </h5>
                                        <button type="button" className="btn-close" onClick={() => setShowModel(false)}></button>
                                    </div>
                                    <div className="modal-body p-md-4 ">
                                        <div className="row justify-content-center">
                                            <label className="form-label">Upload Your Resume</label>
                                            <input type="file" className="form-control" />
                                        </div>
                                        <div className=" col-lg-12 col-12 mt-1 text-center mt-3">
                                            <button type="button" className="btn  px-5 0 btn-warning" >SUBMIT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                }
            </main>

            <Footer />
        </>

    )
}

export default CareerPage