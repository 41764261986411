import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import AuthService from "../../../Services/ApiServices/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../Services/CustomAlertModel";
import LoaderHelper from "../../../Services/Loading/LoaderHelper";
import DashboardSidebar from "../../CoustamComponent/DashboardSidebar";
import { ProfileContext } from "../../../Services/ProfileProvider";
import { ApiConfig } from "../../../Services/ApiServices/AuthAction/ApiConfig";
import { useNavigate } from "react-router-dom";


const SupportSystem = () => {

    const navigate = useNavigate();
    const { userDetails } = useContext(ProfileContext);
    const [activeTab, setActiveTab] = useState("submitTicket");
    const [supportData, setSupportData] = useState([]);
    const [totalDataLength, setTotalDataLength] = useState([]);
    const [skip, setSkip] = useState(0);
    const limit = 10;

    const [formData, setFormData] = useState(
        {
            subject: "",
            imagePath: "",
            description: "",
        }
    );

    const handleInput = (event) => {
        const { name, value, files } = event.target;
        if (name === 'imagePath' && files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = () => {
                setFormData(prevState => ({
                    ...prevState,
                    imagePath: file
                }));
            };
            reader.readAsDataURL(file);
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSupport = async () => {
        try {
            if (!formData.subject) {
                alertErrorMessage("Subject is required");
                return;
            }
            if (!formData.imagePath) {
                alertErrorMessage("Upload Issue Image.");
                return;
            }
            if (!formData.description) {
                alertErrorMessage("Description is required");
                return;
            }
            const formValue = new FormData();
            formValue.append('email', userDetails?.user?.email);
            formValue.append('phone', userDetails?.user?.phone);
            formValue.append('subject', formData.subject);
            formValue.append('description', formData.description);
            formValue.append('ticket_image', formData.imagePath);
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.submitTicket(formValue);
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                alertSuccessMessage(result.message);
                setFormData({
                    subject: "",
                    imagePath: "",
                    description: "",
                });
                document.querySelector('input[type="file"]').value = null;
                handleSupportList(skip, limit);
                setActiveTab("issueList");
            } else {
                alertErrorMessage(result.message);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage("An error occurred while submitting the ticket.");
            console.error(error);
        }
    };

    const handleSupportList = async (skip, limit) => {
        try {
            const result = await AuthService.supportTicketList(skip, limit);
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                setSupportData(result.data);
                setTotalDataLength(result?.total);
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        handleSupportList(skip, limit);
    }, [skip, limit]);


    const columns = [

        {
            name: '#',
            selector: (row, index) => skip + index + 1
        },
        {
            name: "Date/Time",
            cell: (row) =>
                <div className="c_view justify-content-start" >
                    <small>{moment(row.createdAt).format("DD/MM/YYYY")} <br />
                        {moment(row.createdAt).format("HH:mm:ss")}
                    </small>
                </div>,
            sortable: false,
        },
        {
            name: 'Email',
            selector: row => row.email,
            wrap: true,
            sortable: true,
        },

        {
            name: 'Subject',
            selector: row => row.subject,
            wrap: true,
            sortable: true,
        },
        {
            name: 'Description',
            selector: row => row.description,
            wrap: true,
            sortable: true,
        },
        {
            name: 'Image',
            selector: row => row.ticket_image,
            format: row =>
                <div className="c_view"><img src={ApiConfig?.baseUrl + row?.ticket_image} onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} className="img-fluid" alt="" /></div>,
        },
        {

            name: 'Status',
            selector: row => row.status,
            wrap: true,
            sortable: true,
            format: row =>
                <span className={row.status === 'Resolved' ? " text-success" : "text-warning"}>
                    {row?.status}
                </span>,
        },

        {
            name: 'Action',
            selector: row =>
                <>
                    <button type="button" className="btn btn-link text-decoration-none" onClick={() => handleRowClick(row)}>
                        <i className="ri-chat-2-line"></i>
                    </button>
                    <button type="button" className="btn btn-link text-danger text-decoration-none" onClick={() => handleDeleteTicket(row?._id)}>
                        <i className="ri-delete-bin-6-line"></i>
                    </button>
                </>,
            wrap: true,
        }

    ];

    const handleDeleteTicket = async (ticketId) => {
        try {
            const result = await AuthService.deleteTicket(ticketId);
            if (result?.success) {
                handleSupportList(skip, limit);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    const handlePagination = (action) => {
        if (action === 'prev') {
            if (skip - limit >= 0) {
                setSkip(skip - limit);
            }
        } else if (action === 'next') {
            if (skip + limit < totalDataLength) {
                setSkip(skip + limit);
            }
        } else if (action === 'first') {
            setSkip(0);
        } else if (action === 'last') {
            const lastPageSkip = Math.floor(totalDataLength);
            const data = lastPageSkip - 10
            setSkip(data);
        }
    };


    const handleRowClick = (rowData) => {
        navigate('/support-messages', { state: { rowData } });
    };

    return (
        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper">
                    <section className="d_page history_page">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="pg_header mb-0" >
                                        <div className="hd_innre" >
                                            <h6 className="mb-0"> Help/Support</h6>
                                        </div>
                                    </div>


                                    <div className="spot_tabs my-2 my-md-3 d-flex justify-content-between" >
                                        <ul className="nav custom-tabs">
                                            <li className={`${activeTab === "issueList" ? 'active' : ''}`}
                                                onClick={() => { setActiveTab('issueList'); handleSupportList(skip, limit) }}> <span> Issue List </span></li>
                                            <li className={`tab_item ${activeTab === "submitTicket" ? 'active' : ''}`}
                                                onClick={() => setActiveTab('submitTicket')}><span>Submit Ticket</span></li>
                                        </ul>

                                        {
                                            activeTab === "issueList" && supportData?.length > 0 &&
                                            <button className="btn btn-muted " onClick={() => handleSupportList(skip, limit)}> <i className="ri-restart-line me-2"></i> Reload</button>
                                        }

                                    </div>

                                    {/* issueList */}
                                    <div className={`g_start m_card overflow-hidden p-0 ${activeTab != "issueList" ? 'd-none' : ''}`}>
                                        <div className="m_table">
                                            <div className="table-responsive h-auto">
                                                <DataTable className="table table-hover cursor-pointer"
                                                    columns={columns}
                                                    data={supportData}
                                                    onRowClicked={handleRowClick}
                                                    noDataComponent={
                                                        <p>
                                                            <img src="/assets/images/not_found.svg" width="300" className='img-fluid mt-5' alt='not_found' />
                                                        </p>
                                                    }
                                                />
                                                {
                                                    supportData?.length > 0 &&
                                                    <div className="hVPalX gap-2" >
                                                        <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                                        <div className="sc-eAKtBH gVtWSU">
                                                            <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                                </svg>
                                                            </button>
                                                            <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                </svg>
                                                            </button>

                                                            <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                </svg>
                                                            </button>
                                                            <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>

                                    {/* Submit Ticket */}
                                    <div className={`g_start m_card overflow-hidden p-0 ${activeTab != "submitTicket" ? 'd-none' : ''}`}>
                                        <div className="p_card p-md-5" >
                                            <div className="row">
                                                <div className="col-md-6 mb-4">
                                                    <div className="field-box">
                                                        <label htmlFor="text" className="form-label">Subject</label>
                                                        <div className="field-otp-box">
                                                            <input type="text" className="form-control" name="subject" value={formData.subject} onChange={handleInput} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-4">
                                                    <div className="field-box">
                                                        <label htmlFor="text" className="form-label">Upload Image</label>
                                                        <div className="field-otp-box">
                                                            <input type="file" className="form-control" name="imagePath" onChange={handleInput} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12 mb-4">
                                                    <label className="form-label mb-1">Description</label>
                                                    <div className=" field-otp-box">
                                                        <textarea className="form-control" rows="6" placeholder="Type message here" name="description" value={formData.description} onChange={handleInput}></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-2">
                                                    <button className="btn w-auto px-5 btn-dark" type="button" onClick={handleSupport}>
                                                        <b>Submit</b>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>

    )
}

export default SupportSystem