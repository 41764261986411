import React, { useState,useEffect } from 'react';
import Footer from '../../Footer';
import UserPolicy from '../PolicyComponent/UserPolicy';
import PrivacyPolicy from '../PolicyComponent/PrivacyPolicy';
import AmlCft from '../PolicyComponent/AmlCft';
import KycPmla from '../PolicyComponent/KycPmla';
import { useLocation } from 'react-router-dom';

const Policies = () => {

    const location = useLocation();  
    const [activeSpotOrders, setActiveSpotOrders] = useState(location?.state || "userpolicy");

    useEffect(() => {
        if (location.state) {
            setActiveSpotOrders(location.state);
        } else {
            setActiveSpotOrders("userpolicy");
        }
    }, [location.state]);
    

    return (
        <main>
            <section className="tm_sec" >
                <div className="container" >
                    <div className="row gx- align-items-center mt-5 justify-content-center">
                        <div className="col-lg-10">
                            <div className="section-title mb-0 text-start">
                                <h2 className="text-gradient ">policy</h2>
                                <div className="spot_tabs my-2 my-md-3" >
                                    <ul className="nav custom-tabs">
                                        <li className={`${activeSpotOrders === "userpolicy" ? 'active' : ''}`}
                                            onClick={() => setActiveSpotOrders('userpolicy')}>
                                            <span> User policies </span>
                                        </li>
                                        <li className={`${activeSpotOrders === "privacypolicy" ? 'active' : ''}`}
                                            onClick={() => setActiveSpotOrders('privacypolicy')}>
                                            <span> Privacy policy </span>
                                        </li>
                                        <li className={`${activeSpotOrders === "amlcft" ? 'active' : ''}`}
                                            onClick={() => setActiveSpotOrders('amlcft')}>
                                            <span> AML & CFT </span>
                                        </li>
                                        <li className={`${activeSpotOrders === "kycpmla" ? 'active' : ''}`}
                                            onClick={() => setActiveSpotOrders('kycpmla')}>
                                            <span> KYC & PMLA </span>
                                        </li>
                                    </ul>
                                </div>

                                {activeSpotOrders === "userpolicy" &&
                                    <UserPolicy />
                                }

                                {activeSpotOrders === "privacypolicy" &&
                                    <PrivacyPolicy />
                                }

                                {activeSpotOrders === "amlcft" &&
                                    <AmlCft />
                                }

                                {activeSpotOrders === "kycpmla" &&
                                    <KycPmla />
                                }

                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <Footer />
        </main >
    )
}

export default Policies