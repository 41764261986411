import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import AuthService from "../../../../Services/ApiServices/AuthService";
import LoaderHelper from "../../../../Services/Loading/LoaderHelper";
import { alertErrorMessage, alertSuccessMessage } from "../../../../Services/CustomAlertModel";
import copy from "copy-to-clipboard";
import { ProfileContext } from "../../../../Services/ProfileProvider";

const SecurityModules = () => {

    const [selectedOption, setSelectedOption] = useState();
    const [isActiveOtp, setIsActiveOtp] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [qrData, setQrdata] = useState([]);
    const [verificationCode, setVerificationCode] = useState("");
    const [selectedAuth, setSelectedAuth] = useState("");
    const { userDetails, handleUserProfile } = useContext(ProfileContext);

    useEffect(() => {
        if (userDetails) {
            setSelectedOption(userDetails?.user?.['2fa']);
        }
    }, [userDetails]);

    const handleCheckboxChange = (option) => {
        setSelectedOption(option);
    };



    const saveSettings = () => {
        if (selectedOption === 2 || !userDetails?.user?.['2fa'] === 2) {
            handleGetGoogleQr();
        } else if (selectedOption === 0 && userDetails?.user?.['2fa'] === 2) {
            setIsActiveOtp(true)
        } else {
            const getOtpParams =
                selectedOption === 1 ? userDetails?.user?.email :
                    selectedOption === 3 ? userDetails?.user?.phone :
                        selectedOption === 0 && userDetails?.user?.['2fa'] == 1 ? userDetails?.user?.email :
                            selectedOption === 0 && userDetails?.user?.['2fa'] == 3 ? userDetails?.user?.phone :
                                selectedOption === 0 || userDetails?.user?.['2fa'] == 0 ? alertErrorMessage('Please Select Any Two factor(2FA)') :
                                    null;
            if (selectedOption !== 0 || userDetails?.user?.['2fa'] != 0) {
                setSelectedAuth(getOtpParams);
                handleGetOtp(getOtpParams);
            }
        }
    };

    const handleGetOtp = async (checkedAuth, resend) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.getOtp(checkedAuth, resend);
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                setIsActiveOtp(true)
            } else {
                alertErrorMessage(result?.message);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };

    const handleGetGoogleQr = async () => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.genrateGoogleQr();
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                setQrdata(result?.data);
                setIsActive(true)
            } else {
                alertErrorMessage(result?.message);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };

    const handleUpdate2Fa = async (sselectedOption, verificationCode, selectedAuth) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.changeTwofact(sselectedOption, verificationCode, selectedAuth || userDetails?.user?.email);
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                setIsActiveOtp(false)
                handleUserProfile();
                setVerificationCode("");
            } else {
                alertErrorMessage(result?.message);
                setVerificationCode("");
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };

    return (

        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper">
                    <section className="d_page m_account">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xxl-8 col-xl-10">
                                    <div className="g_start">
                                        <div className="d_u_p align-items-start m-0">
                                            <div className="dup_left">
                                                <h6 className="mb-0"> Security Checkup </h6>
                                            </div>
                                        </div>

                                        <div className="d_u_p align-items-center mt-4" >
                                            <div className="dup_left gap-4" >
                                                <div className="ud" >
                                                    <p className="mb-0 ">
                                                        <i className={`ri-checkbox-circle-fill me-1 ${userDetails?.user?.email ? 'text-success' : "text-muted"}`}></i>
                                                        Email Verification
                                                    </p>
                                                </div>
                                                <div className="ud" >
                                                    <p className="mb-0 ">
                                                        <i className={`ri-checkbox-circle-fill me-1 ${userDetails?.user?.phone ? 'text-success' : "text-muted"}`}></i>
                                                        Phone Verification
                                                    </p>
                                                </div>
                                                <div className="ud" >
                                                    <p className="mb-0 ">
                                                        <i className={`me-1 ${userDetails?.user?.kycVerified == 1 ? 'text-warning ri-error-warning-fill ' : userDetails?.user?.kycVerified == 2 ? 'text-success ri-checkbox-circle-fill ' : userDetails?.user?.kycVerified == 3 ? "text-danger ri-error-warning-fill  " : "text-muted"}`}
                                                        ></i>
                                                        {
                                                            userDetails?.user?.kycVerified === 0 ?
                                                                <Link to="/verify_kyc" className="text-black text-underline" >
                                                                    Identity Verification (KYC)
                                                                </Link>
                                                                : userDetails?.user?.kycVerified === 1 ?
                                                                    "Kyc pending for approval"
                                                                    : userDetails?.user?.kycVerified === 2 ?
                                                                        "Kyc Approved"
                                                                        : userDetails?.user?.kycVerified === 3 ?
                                                                            <Link to="/verify_kyc" className="text-black text-underline" >
                                                                                Kyc Rejected Verify Again
                                                                            </Link>
                                                                            :
                                                                            "Identity Verification (KYC)"
                                                        }
                                                    </p>
                                                </div>
                                                <div className="ud" >
                                                    <p className="mb-0 ">
                                                        <i className={`ri-checkbox-circle-fill me-1 ${userDetails?.user?.['2fa'] === 0 ? 'text-muted' : 'text-success'}`}>
                                                        </i>
                                                        Two-Factor Authentication (2FA)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* change password */}
                                    <div className="g_start " >
                                        <div className="d_u_p align-items-start-md m-0" >
                                            <div className="dup_left d-block " >
                                                <h6 className="mb-0" > Change Password  </h6>
                                                <p className="d-block mb-0 mt-1" > Update your password securely for enhanced account protection. </p>
                                            </div>
                                            <div className="dup_right " >
                                                <Link to="/change_password" className="btn btn-muted"> Change </Link>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Two factor(2FA) */}
                                    <div className="g_start " >
                                        <div className="d_u_p align-items-start-md m-0" >
                                            <div className="dup_left d-block " >
                                                <h6 className="mb-0" > Two factor(2FA)</h6>
                                            </div>
                                        </div>

                                        <div className="mt-4 mt-4 p_card">
                                            <div className="twofa_list d-flex align-items-center justify-space-between">
                                                <h6 className="mb-0 w-100">Authenticator App</h6>
                                                <div className="form-check switch_btns form-switch">
                                                    <input
                                                        className="form-check-input cursor-pointer"
                                                        type="checkbox"
                                                        name="Two-Factor"
                                                        checked={selectedOption === 2}
                                                        onChange={() => handleCheckboxChange(2)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="twofa_list d-flex align-items-center justify-space-between">
                                                <h6 className="mb-0 w-100">Email OTP</h6>
                                                <div className="form-check switch_btns form-switch">
                                                    <input
                                                        className="form-check-input cursor-pointer"
                                                        type="checkbox"
                                                        name="Two-Factor"
                                                        checked={selectedOption === 1}
                                                        onChange={() => handleCheckboxChange(1)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="twofa_list d-flex align-items-center justify-space-between">
                                                <h6 className="mb-0 w-100">Mobile OTP</h6>
                                                <div className="form-check switch_btns form-switch">
                                                    <input
                                                        className="form-check-input cursor-pointer"
                                                        type="checkbox"
                                                        name="Two-Factor"
                                                        checked={selectedOption === 3}
                                                        onChange={() => handleCheckboxChange(3)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="twofa_list d-flex align-items-center justify-space-between">
                                                <h6 className="mb-0 w-100">None</h6>
                                                <div className="form-check switch_btns form-switch">
                                                    <input
                                                        className="form-check-input cursor-pointer"
                                                        type="checkbox"
                                                        name="Two-Factor"
                                                        checked={selectedOption === 0}
                                                        onChange={() => handleCheckboxChange(0)}
                                                    />
                                                </div>
                                            </div>
                                            <hr />

                                            <div className="dup_right text-end">
                                                <button type="button" className="btn btn-dark" onClick={saveSettings}>
                                                    Save Settings
                                                </button>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                {isActiveOtp && (
                    <div className="modal-open-custom" >
                        <div className="modal show d-block" >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header flex-column px-8">
                                        <h5 className="modal-title" id="modal_otp_Label"> OTP Verification</h5>
                                    </div>
                                    <div className="modal-body modal_form">
                                        <form className=" " >
                                            <div className="row">
                                                <div className="col-12 form-group ">
                                                    <input type="text" className="form-control text-center" placeholder="Enter Verification code" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className=" text-center ">
                                                <button type="button" className="btn btn-dark btn-block w-100 mt-3" onClick={() => handleUpdate2Fa(selectedOption, verificationCode, selectedAuth)}>
                                                    <b>Verify</b>
                                                </button>
                                                <button type="button" className="btn btn-secondary px-5 mt-4" onClick={() => setIsActiveOtp(false)}>  Cancel </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                )}

                {isActive && (
                    <div className="modal-open-custom" >
                        <div className="modal show d-block" >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header flex-column px-8 no-border pb-0">
                                        <h5 className="modal-title" id="modal_otp_Label">Authenticator App</h5>

                                    </div>
                                    <div className="modal-body conin_purchase p-md-5">
                                        <div className={`step_1 ${isActive ? '' : 'd-none'}`}>
                                            <div className="col-md-12 m-auto mb-2 text-center">

                                                <img className="img-fluid qr_img w-50" src={qrData?.qr_code} />

                                                <div className="field-box field-otp-box mb-2">

                                                    <input readonly="" name="text" className="form-control cursor-pointer" type="text" value={qrData?.secret?.base32}
                                                        onClick={() => { copy(qrData?.secret?.base32); alertSuccessMessage("copied..!!") }} />

                                                </div>
                                                <small className="d-block text-center w-100 mb-4">Click to copy code</small>

                                                <button type="button" className="btn  btn-dark  btn-block w-100" onClick={() => { setIsActiveOtp(true); setIsActive(false) }}> Next </button>

                                                <button type="button" className="btn btn-secondary px-5 mt-4" onClick={() => setIsActive(false)}>
                                                    Cancel
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                )}
            </div>
        </main>
    )
}

export default SecurityModules