import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../Footer";


const ListingYourCoin = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])


    return (
        <>
        <main>

        <section class="tm_sec d-block pb-5">
                <div class="container">
                    <div class="row   mt-md-5 justify-content-center">
                    <div class="col-lg-12">
                    <div class="section-title  partner_title_sect text-start ">

                        <div className="row align-items-center g-4" >
                            <div className="col-lg-5" >
                                <h2 class="text-dark  "> List Your Coin on Crypto India Exchange  </h2> 
                                <div className="ddd text-start"  > 
                                <p>
                                At Crypto India, we prioritize the safety, security, and trust of our users. That's why our coin listing process is designed to ensure that only high-quality, genuine, and use-case-driven coins are available for trading on our platform.
                                </p> 
                                </div>
                            </div>
                            <div className="col-lg-7" >
                                <div className="sec_img" >
                                    <img src="assets/images/coin_list.svg" className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>     
                    </div> 
                    </div>  
                </div> 
                </div> 
            </section>


            <section className="features_sec feature_bg">
                <div className="container"> 
                    <div className="sec_title mb-5">
                        <h2 className="text-dark text-center "> Why List Your Coin on Crypto India? </h2> 
                    </div>
                    <div className="row g-4 justify-content-start">
                        <div className="col-lg-4">
                            <div className="card h-100">
                                <div className="  p-0  h-100">
                                    <div className=" h-100"> 
                                        <div>
                                            <h6 className="text  mb-3">
                                            Strict Quality Standards
                                            </h6>
                                            <span></span>
                                            <p>We follow rigorous listing criteria to ensure that only coins with a strong development team, clear use case, and proven utility are listed on our exchange. This helps maintain the integrity and quality of the trading experience for all users. </p>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card h-100">
                                <div className="  p-0  h-100">
                                    <div className="  h-100"> 
                                        <div>
                                            <h6 className="text  mb-3">
                                            User Safety & Genuineness
                                            </h6>
                                            <span></span>
                                            <p>We are committed to safeguarding our users by conducting thorough due diligence on every coin. Our process includes technical audits, compliance checks, and background verification to ensure that each coin listed is safe, secure, and transparent.</p> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-lg-4">
                            <div className="card h-100">
                                <div className="  p-0">
                                    <div className="     h-100"> 
                                        <div>
                                            <h6 className="text mb-3">
                                            Comprehensive Use Case Analysis
                                            </h6>
                                            <span></span>
                                            <p>Our listing team evaluates the coin's underlying technology, market potential, and real-world application to ensure it provides true value to the crypto community. Coins with strong use cases and real-world utility are prioritized for listing.</p> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div> 
                </div>
            </section>

             <section className="how_it_sec">
                <div className="container">
                
                    <div className="sec_title mb-5"> 
                        <h2 className="text-dark text-center "> Our Listing Process Includes </h2>
                        <p>
                        Users buy or sell crypto with fiat or other coins on a secure online platform in just simple 3 steps.
                        </p>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row gx-md-5  ">
                                <div className="col-lg-4">
                                    <div className="how_it step_1  ">
                                        <div className="how_card    text-center"> 
                                            <div className=""> 
                                                <h6>
                                                Technical & Security Review
                                                </h6>
                                                <p className="mb-0"> Each coin undergoes an in-depth review by our technical team to ensure it meets the highest standards of security and reliability. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="how_it step_2   ">
                                        <div className="how_card    text-center "> 
                                            <div className=""> 
                                                <h6>
                                                Compliance & Regulatory Checks
                                                </h6>
                                                <p className="mb-0">We ensure that each coin complies with local and international regulations, providing transparency and legal clarity to all users.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="how_it step_3 ">
                                        <div className="how_card  text-center "> 
                                            <div className=""> 
                                                <h6>
                                                Ongoing Monitoring & Updates
                                                </h6>
                                                <p className="mb-0">Even after listing, we continuously monitor listed coins to ensure they maintain their value proposition and meet evolving market standards.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>






            <section class="tm_sec pt-0 mt-0">
                <div class="container">
                    <div class="row   justify-content-center">
                    <div class="col-lg-10"> 

                        <div class="g_start m_card overflow-hidden p-0 ">
                            <div class="p_card p-md-5">
                                

                                {/* step 2*/}
                                <div className="step_2 " >
                                    <div class="row">
                                        <div class="col-md-12 mb-4">

                                            <h6 className="mb-3" >
                                                Contact Information
                                            </h6>   
                                           <div className="row" >
                                            <div class="field-box mb-3 col-lg-6">
                                                    <label for="text" class="form-label">   Full Name  </label>
                                                    <div class="field-otp-box mb-2">
                                                        <input type="text" class="form-control" name="subject"  /> 
                                                    </div> 
                                                </div> 
                                                <div class="field-box mb-3 col-lg-6">
                                                    <label for="text" class="form-label">    Designation    </label>
                                                    <div class="field-otp-box mb-2">
                                                        <input type="text" class="form-control" name="subject"  /> 
                                                    </div> 
                                                </div> 
                                                <div class="field-box mb-3 col-lg-6">
                                                    <label for="text" class="form-label">   Email Address </label>
                                                    <div class="field-otp-box mb-2">
                                                        <input type="text" class="form-control" name="subject"  /> 
                                                    </div> 
                                                </div> 
                                                <div class="field-box mb-3 col-lg-6">
                                                    <label for="text" class="form-label">   Phone Number </label>
                                                    <div class="field-otp-box mb-2">
                                                        <input type="text" class="form-control" name="subject"  /> 
                                                    </div> 
                                                </div> 
                                           </div>




                                            <br/>
                                            <h6 className="mb-3 mt-4" >
                                                Listing Information
                                            </h6>                                    



                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label">    Coin/Token Name  </label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="text" class="form-control" name="subject"  /> 
                                                </div> 
                                            </div> 
                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label">   Symbol (Ticker) </label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="file" class="form-control" name="subject"  /> 
                                                </div> 
                                            </div> 
                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label">    Token/Coin Symbol  </label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="text" class="form-control" name="subject"  /> 
                                                </div> 
                                            </div> 
                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label">    Blockchain/Network  </label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="text" class="form-control" name="subject"  /> 
                                                </div> 
                                            </div>  
                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label">      Smart Contract Address  </label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="text" class="form-control" name="subject"  /> 
                                                </div> 
                                            </div> 
                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label">    Official Website   </label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="text" class="form-control" name="subject"  /> 
                                                </div> 
                                            </div> 
                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label">    Whitepaper Link     </label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="text" class="form-control" name="subject"  /> 
                                                </div> 
                                            </div> 
                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label">    Launch Date   </label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="text" class="form-control" name="subject"  /> 
                                                </div> 
                                            </div> 
                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label">    Project Details    </label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="text" class="form-control" name="subject"  /> 
                                                </div> 
                                            </div> 
                                            <div class="col-lg-12 col-md-12 col-12 mb-4">
                                                <label class="form-label mb-1"> Brief Description of Your Coin/Token 
                                                </label>
                                                <div class=" field-otp-box">
                                                    <textarea class="form-control" rows="4" placeholder="Type message here" name="description"></textarea>
                                                </div>
                                            </div>
                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label">    Use Case and Real-World Application  </label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="text" class="form-control" name="subject"  /> 
                                                </div> 
                                            </div> 
                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label">    Total Supply:      </label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="text" class="form-control" name="subject"  /> 
                                                </div> 
                                            </div> 
                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label">   Are you a registered company?     </label>
                                                <div class="field-otp-box mb-2">
                                                     <select className="form-control form-select" >
                                                        <option> Select Option</option>
                                                        <option> Yes </option>
                                                        <option> No </option>
                                                     </select>
                                                </div> 
                                            </div> 
                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label">  Company  Registration No.    </label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="text" class="form-control" name="subject"  /> 
                                                </div> 
                                            </div>  
                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label"> How does your project ensure security against fraud and hacks?    </label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="text" class="form-control" name="subject"  /> 
                                                </div> 
                                            </div> 
                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label"> Tokenomics & Distribution:   </label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="text" class="form-control" name="subject"  /> 
                                                </div> 
                                            </div> 

                                            <br/>
                                            <h6 className="mb-3 mt-4" >
                                                Market Information:
                                            </h6> 

                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label"> Exchanges where the coin is currently listed  </label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="text" class="form-control" name="subject"  /> 
                                                </div> 
                                            </div> 
                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label"> Average Trading Volume (24hr)</label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="text" class="form-control" name="subject"  /> 
                                                </div> 
                                            </div> 
                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label"> Community Engagement & Size:  </label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="text" class="form-control" name="subject"  /> 
                                                </div> 
                                            </div>  

                                            <br/>
                                            <h6 className="mb-3 mt-4" >
                                                Other Information
                                            </h6> 
                                            
                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label"> Anything else we should know about your project? </label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="text" class="form-control" name="subject"  /> 
                                                </div> 
                                            </div>  
                                            <div class="field-box mb-3">
                                                <label for="text" class="form-label">  Attachments <br/> <small>(Please attach any relevant documents like whitepapers, audit reports, legal statements, etc.</small> </label>
                                                <div class="field-otp-box mb-2">
                                                    <input type="file" multiple class="form-control" name="subject"  /> 
                                                </div> 
                                            </div>
 
                                        </div>
                                         
                                        <div class="col-md-12 mt-2">
                                            <button class="btn w-auto px-5 btn-lg btn-dark" type="button">
                                            Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 

                    </div>
                     
                    </div>
 

                </div>
            </section>



             
        </main>
        
        <Footer />
        </>

    )
}

export default ListingYourCoin