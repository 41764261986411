import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const ComingSoon = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])
    return (

        <main>
            <section className="error_page login_bg login_sec">
                <img src="/assets/images/coming_soon.svg" className="img-fluid" alt="" />
                <Link to="/" className="btn btn-warning custom-btn" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                    <i className="ri-home-2-line me-2"></i> Back to Home
                </Link>
            </section>
        </main>

    )
}

export default ComingSoon