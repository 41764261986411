
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function alertErrorMessage(message) {
  if (!toast.isActive('Toasterror')) {
    toast.error(message, {
      toastId: 'Toasterror',
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
      className: 'single-line-toast',

    });
  }
}

function alertSuccessMessage(message) {
  if (!toast.isActive('Toastsuccess')) {
    toast.success(message, {
      toastId: 'Toastsuccess',
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false
    });
  }
}



function alertWarningMessage(message) {
  if (!toast.isActive('Toastwarning')) {
    toast.warning(message, {
      toastId: 'Toastwarning',
      position: 'bottom-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false
    });
  }
}








export { alertErrorMessage, alertSuccessMessage, alertWarningMessage };