
import { ApiConfig } from "../../ApiServices/AuthAction/ApiConfig";
import AuthService from "../../ApiServices/AuthService";
import { parseFullSymbol } from "./helpers";
const { io } = require("socket.io-client");

const channelToSubscription = new Map();

let socket = io(ApiConfig?.webSocketUrl, { transports: ['websocket'], upgrade: false, rejectUnauthorized: false, reconnectionAttempts: 3, debug: true });

export async function subscribeOnStream(
	symbolInfo,
	resolution,
	onRealtimeCallback,
	subscriberUID,
	onResetCacheNeededCallback,
	lastDailyBar,
) {
	const channelString = symbolInfo.name;
	const handler = {
		id: subscriberUID,
		callback: onRealtimeCallback,
	};
	const parsedSymbol = parseFullSymbol(symbolInfo?.name);
	const allPairs = await AuthService.getPairs()
	const filteredPair = allPairs?.data?.filter((item) => item?.base_currency === parsedSymbol?.fromSymbol && item?.quote_currency === parsedSymbol?.toSymbol)
	let subscriptionItem = channelToSubscription.get(channelString);
	if (subscriptionItem) {
		subscriptionItem.handlers.push(handler);
		return;
	}
	subscriptionItem = {
		subscriberUID,
		resolution,
		lastDailyBar,
		handlers: [handler],
	};
	channelToSubscription.set(channelString, subscriptionItem);
	let payload = {
		'message': 'exchange',
		'base_currency_id': filteredPair?.[0]?.base_currency_id,
		'quote_currency_id': filteredPair?.[0]?.quote_currency_id,
	}
	socket.emit('message', payload);
	setInterval(() => {
		socket.emit('message', payload);
	}, 1000);
	socket.on('message', (data) => {
		let currPair = data?.pairs?.filter((item) => { return item?.base_currency === parsedSymbol?.fromSymbol && item?.quote_currency === parsedSymbol?.toSymbol })
		let tickerData;
		if (!data?.ticker) {
			return;
		}
		tickerData = data?.ticker;
		const tradeTime = tickerData?.time;
		const volume = tickerData?.volume;
		const tradePrice = currPair?.[0]?.buy_price;
		const channelString = symbolInfo?.name;
		const subscriptionItem = channelToSubscription.get(channelString);
		if (subscriptionItem === undefined) {
			return;
		}
		const lastDailyBar = subscriptionItem?.lastDailyBar;
		if (!lastDailyBar) {
			return
		}
		function removeSecondsFromTimestamp(timestamp) {
			const date = new Date(timestamp);
			date.setSeconds(0);
			return date.getTime();
		}
		const updatedTimestamp = removeSecondsFromTimestamp(lastDailyBar?.time);
		const nextDailyBarTime = (updatedTimestamp / 1000) + 65;
		let bar;
		if (tradeTime * 1000 > nextDailyBarTime * 1000) {
			bar = {
				time: tradeTime * 1000,
				open: tradePrice,
				high: tradePrice,
				low: tradePrice,
				close: tradePrice,
				volume: volume,
			};
			subscriptionItem.lastDailyBar = bar
		} else {
			bar = {
				...lastDailyBar,
				high: Math.max(lastDailyBar?.high, tradePrice),
				low: Math.min(lastDailyBar?.low, tradePrice),
				close: tradePrice,
				volume: volume,
			};
		}
		subscriptionItem.lastDailyBar = bar;
		onRealtimeCallback(bar)

	});
}
export function unsubscribeFromStream(subscriberUID) {
	for (const [channelString, subscriptionItem] of channelToSubscription) {
		const handlerIndex = subscriptionItem.handlers.findIndex(handler => handler.id === subscriberUID);

		if (handlerIndex !== -1) {
			subscriptionItem.handlers.splice(handlerIndex, 1);

			if (subscriptionItem.handlers?.length === 0) {
				channelToSubscription.delete(channelString);
				break;
			}
		}
	}
}
