import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../../Services/ApiServices/AuthService";
import LoaderHelper from "../../../Services/Loading/LoaderHelper";
import { alertErrorMessage } from "../../../Services/CustomAlertModel";
import Footer from "../../CoustamComponent/Footer";
import { SocketContext } from "../../../Context/SocketContext";

const MarketPage = () => {

    const [activeTab, setActiveTab] = useState('Spot');
    const [activeTabButton, setActiveTabButton] = useState('ALL');
    const [socketData, setSocketData] = useState([]);
    const [fiterPairData, setFiterPairData] = useState([]);
    const [search, setsearch] = useState("");
    const [favCoins, setfavCoins] = useState([]);
    const Authtoken = localStorage.getItem("token");
    const { socket } = useContext(SocketContext);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    useEffect(() => {
        if (socket) {
            const payload = { message: 'market' };
            socket.emit('message', payload);
            const intervalId = setInterval(() => {
                socket.emit('message', payload);
            }, 1000);
            socket.on('message', (data) => {
                setSocketData(data);
            });
            return () => {
                clearInterval(intervalId);
                socket.off('message');
            };
        }
    }, [socket]);

    const nextPage = (item) => {
        if (item?.quote_currency === "INR") {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            navigate('/buy_sell');
        } else {
            navigate(`/exchange/${item?.base_currency}_${item?.quote_currency}`);
        }
    };

    const handleAddFav = async (pairId) => {
        await AuthService.updateFavoriteCoin(pairId).then((result) => {
            if (result.success) {
                try {
                    handleFavoriteList();
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result?.message)
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };

    const handleFavoriteList = async () => {
        try {
            const result = await AuthService.favoriteList();
            if (result?.success) {
                setfavCoins(result?.data?.pairs);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        let filteredData = socketData?.pairs?.filter((item) => {
            return (
                item?.base_currency?.toLowerCase()?.includes(search?.toLowerCase()) ||
                item?.quote_currency?.toLowerCase()?.includes(search?.toLowerCase())
            );
        });
        setFiterPairData(filteredData);
    }, [search, socketData]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = fiterPairData?.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(fiterPairData?.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        if (Authtoken) {
            handleFavoriteList();
        }
    }, []);

    return (

        <main>
            <div className="market_wrapper" >
                <section className="d_page pt-2">
                    <div className="container-xl" >

                        <div className="Markets mt-0" >
                            <h6> Markets Overview </h6>
                        </div>
                        <div className="hntt" >
                            <div className="row gx-2">
                                <div className="col-lg-3">
                                    <div className="ant-card ant-card-bordered ant-card-small marketRankList_rankItem__L1CvR">
                                        <div className="ant-card-head-wrapper">
                                            <div className="ant-card-head-title"><span className="rankItem_titleWrap__6tzjz"><span> Hot Coins </span></span>
                                            </div>
                                        </div>
                                        {socketData?.hot && socketData?.hot?.length <= 0 ?
                                            <div className=" my-4 " >
                                                <div className="loading-wave-market">
                                                    <div className="loading-bar"></div>
                                                    <div className="loading-bar"></div>
                                                    <div className="loading-bar"></div>
                                                    <div className="loading-bar"></div>
                                                </div>
                                            </div>
                                            :
                                            socketData?.hot?.length > 0 ?
                                                socketData.hot.slice(0, 5).map((item, index) => {
                                                    return (
                                                        <div className="ant-card-body hover-active" key={index}>
                                                            <div className="ant-list row gx-0 cursor-pointer" onClick={() => nextPage(item)}>
                                                                <div className=" col-4" >
                                                                    <div className="spotName">
                                                                        <img alt="" src={item?.icon_path} width="20" height="20" onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} />
                                                                        <div className="symblecurrency">{item?.base_currency + "/" + item?.quote_currency}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 text-end">
                                                                    <span>{item?.buy_price?.toFixed(2)}</span>
                                                                </div>
                                                                <div className="col-3 text-end"><span className={item?.change >= 0 ? "text-success" : "text-danger"}>{item?.change?.toFixed(2)}%</span></div>
                                                            </div>
                                                        </div>
                                                    );
                                                }) :
                                                <div className="favouriteData">
                                                    <img src="/assets/images/no-data.svg" className="img-fluid" width="40" height="40" alt="" />
                                                    <p>No data found</p>
                                                </div>
                                        }

                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="ant-card ant-card-bordered ant-card-small marketRankList_rankItem__L1CvR">
                                        <div className="ant-card-head-wrapper">
                                            <div className="ant-card-head-title"><span className="rankItem_titleWrap__6tzjz"><span>Top Gainers   </span></span>
                                            </div>
                                        </div>
                                        {/* <div className="ant-card-body"> */}

                                        {socketData?.top_gainers && socketData?.top_gainers?.length <= 0 ?
                                            <div className="my-4    " >
                                                <div className="loading-wave-market">
                                                    <div className="loading-bar"></div>
                                                    <div className="loading-bar"></div>
                                                    <div className="loading-bar"></div>
                                                    <div className="loading-bar"></div>
                                                </div>
                                            </div>
                                            :
                                            socketData?.top_gainers?.length > 0 ?
                                                socketData.top_gainers.slice(0, 5).map((item, index) => {
                                                    return (
                                                        <div className="ant-card-body hover-active" key={index}>
                                                            <div className="ant-list row gx-0 cursor-pointer" onClick={() => nextPage(item)}>
                                                                <div className=" col-4">
                                                                    <div className="spotName">
                                                                        <img alt="" src={item?.icon_path} width="20" height="20" onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} />
                                                                        <div className="symblecurrency">{item?.base_currency + "/" + item?.quote_currency}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 text-end"><span>{item?.buy_price?.toFixed(2)}</span></div>
                                                                <div className="col-3 text-end"><span className={item?.change >= 0 ? "text-success" : "text-danger"}>{item?.change?.toFixed(2)}%</span></div>
                                                            </div>
                                                        </div>
                                                    );
                                                }) :
                                                <div className="favouriteData">
                                                    <img src="/assets/images/no-data.svg" className="img-fluid" width="40" height="40" alt="" />
                                                    <p>No data found</p>
                                                </div>
                                        }
                                        {/* </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="ant-card ant-card-bordered ant-card-small marketRankList_rankItem__L1CvR">
                                        <div className="ant-card-head-wrapper">
                                            <div className="ant-card-head-title"><span className="rankItem_titleWrap__6tzjz"><span>New Listing </span></span>
                                            </div>
                                        </div>
                                        {/* <div className="ant-card-body"> */}
                                        {socketData?.new_listed && socketData?.new_listed?.length <= 0 ?
                                            <div className="my-4    " >
                                                <div className="loading-wave-market">
                                                    <div className="loading-bar"></div>
                                                    <div className="loading-bar"></div>
                                                    <div className="loading-bar"></div>
                                                    <div className="loading-bar"></div>
                                                </div>
                                            </div>
                                            :
                                            socketData?.new_listed?.length > 0 ?
                                                socketData.new_listed.slice(0, 5).map((item, index) => {
                                                    return (
                                                        <div className="ant-card-body hover-active" key={index}>
                                                            <div className="ant-list row gx-0 cursor-pointer" onClick={() => nextPage(item)}>
                                                                <div className=" col-4">
                                                                    <div className="spotName">
                                                                        <img alt="" src={item?.icon_path} width="20" height="20" onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} />
                                                                        <div className="symblecurrency">{item?.base_currency + "/" + item?.quote_currency}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 text-end"><span>{item?.buy_price?.toFixed(2)}</span></div>
                                                                <div className="col-3 text-end"><span className={item?.change >= 0 ? "text-success" : "text-danger"}>{item?.change?.toFixed(2)}%</span></div>
                                                            </div>
                                                        </div>
                                                    );
                                                }) :
                                                <div className="favouriteData">
                                                    <img src="/assets/images/no-data.svg" className="img-fluid" width="40" height="40" alt="" />
                                                    <p>No data found</p>
                                                </div>
                                        }
                                        {/* </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="ant-card ant-card-bordered ant-card-small marketRankList_rankItem__L1CvR">
                                        <div className="ant-card-head-wrapper">
                                            <div className="ant-card-head-title"><span className="rankItem_titleWrap__6tzjz"><span>  Top Volume Coin </span></span>
                                            </div>
                                        </div>
                                        {/* <div className="ant-card-body"> */}
                                        {socketData?.new_listed && socketData?.new_listed?.length <= 0 ?
                                            <div className="my-4    " >
                                                <div className="loading-wave-market">
                                                    <div className="loading-bar"></div>
                                                    <div className="loading-bar"></div>
                                                    <div className="loading-bar"></div>
                                                    <div className="loading-bar"></div>
                                                </div>
                                            </div>
                                            :
                                            socketData?.new_listed?.length > 0 ?
                                                socketData.new_listed.slice(0, 5).map((item, index) => {
                                                    return (
                                                        <div className="ant-card-body hover-active" key={index}>
                                                            <div className="ant-list row gx-0 cursor-pointer" onClick={() => nextPage(item)}>
                                                                <div className=" col-4">
                                                                    <div className="spotName">
                                                                        <img alt="" src={item?.icon_path} width="20" height="20" onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} />
                                                                        <div className="symblecurrency">{item?.base_currency + "/" + item?.quote_currency}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 text-end"><span>{item?.buy_price?.toFixed(2)}</span></div>
                                                                <div className="col-3 text-end"><span className={item?.change >= 0 ? "text-success" : "text-danger"}>{item?.change?.toFixed(2)}%</span></div>
                                                            </div>
                                                        </div>
                                                    );
                                                }) :
                                                <div className="favouriteData">
                                                    <img src="/assets/images/no-data.svg" className="img-fluid" width="40" height="40" alt="" />
                                                    <p>No data found</p>
                                                </div>
                                        }
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="container-xl" >
                        <div className="row justify-content-center" >
                            <div className="col-lg-12" >
                                <div className="d-flex-between  custom_dlflex mrk_menu">
                                    <ul className="nav custom-tabs">
                                        <li className={activeTab === 'Favourite' ? 'active' : ""} onClick={() => setActiveTab('Favourite')}> <span>Favorite</span></li>
                                        <li className={activeTab === 'Spot' ? 'active' : ""} onClick={() => setActiveTab('Spot')}>
                                            <span>Spot</span>
                                        </li>
                                    </ul>
                                    {socketData?.pairs?.length > 0 ?
                                        <div className="searchBar custom-tabs">
                                            <input type="search" className="custom_search" placeholder="Search Crypto" value={search} onChange={(e) => { setsearch(e.target.value); }} />
                                            <i className="ri-search-2-line"></i>
                                        </div>
                                        : ""
                                    }
                                </div>
                                <div className="tab-content">
                                    <div className={`tab-pane ${activeTab === 'Favourite' ? 'show active' : ""}`}>
                                        <div className="table-responsive m_table">
                                            {Authtoken ?
                                                (
                                                    fiterPairData && fiterPairData.length > 0 ? (
                                                        <table className="table table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Name</th>
                                                                    <th scope="col" className="text-end">Price</th>
                                                                    <th scope="col" className="text-end">(24h) Change</th>
                                                                    <th scope="col" className="text-end">Volume (24h)</th>
                                                                    <th scope="col" className="text-end">Market Cap</th>
                                                                    <th scope="col" className="text-end">Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {fiterPairData && fiterPairData?.length > 0 && fiterPairData?.some(item => favCoins?.includes(item?._id)) ? (
                                                                    fiterPairData.map((item, index) => {
                                                                        if (!favCoins?.includes(item?._id)) return null;
                                                                        return (
                                                                            <tr className="cursor-pointer" key={index}>
                                                                                <td>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <span className="star_btn btn_icon active">
                                                                                            <i className="ri-star-fill text-warning" onClick={() => handleAddFav(item?._id)}></i>
                                                                                        </span>

                                                                                        <div className="c_view w-100" onClick={() => nextPage(item)}>
                                                                                            <div className="d-flex align-items-center" >
                                                                                                <img src={item?.icon_path} alt="" onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} />
                                                                                                <span >
                                                                                                    {item?.base_currency + "/" + item?.quote_currency} <br />
                                                                                                    <small>{item?.name}</small>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </td>
                                                                                <td className="text-end" onClick={() => nextPage(item)}>
                                                                                    <div className="c_view justify-content-end">
                                                                                        <span>
                                                                                            {item.quote_currency === "INR" ? '₹' : '$'}
                                                                                            {item?.buy_price?.toFixed(2)}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="text-end" onClick={() => nextPage(item)}>
                                                                                    <span className={item?.change >= 0 ? "text-success" : "text-danger"}> {item?.change?.toFixed(2)}% </span>
                                                                                </td>
                                                                                <td className="text-end" onClick={() => nextPage(item)}>
                                                                                    {item?.volume?.toFixed(2)}
                                                                                </td>
                                                                                <td className="text-end" onClick={() => nextPage(item)}>
                                                                                    {item?.base_market_cap || 0}
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    <button className="btn-link text-dark" type="button" onClick={() => nextPage(item)}>{item?.quote_currency === "INR" ? 'Buy-Sell' : 'Trade'}</button>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="6">
                                                                            <div className="favouriteData">
                                                                                <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                )}

                                                            </tbody>
                                                        </table>
                                                    ) : (
                                                        <div className="favouriteData">
                                                            <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                        </div>
                                                    )
                                                ) : (
                                                    <div className="py-5 favouriteData">
                                                        <img src="/assets/images/Signupcuate.svg" className="img-fluid" width="240" alt="" />
                                                        <p className="mt-2">Go to&nbsp;
                                                            <Link className="btn-link" to="/login"><b>&nbsp; Sign in &nbsp;</b></Link>&nbsp;and add your favorite coins from Spot.
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className={`tab-pane ${activeTab === 'Spot' ? 'show active' : ""}`}>
                                        <div className="mrt_row">
                                            <div className="btn-group btn_radio_group" >
                                                <button type="button" className={`btn btn-outline-primary ${activeTabButton === 'ALL' ? 'active' : ""}`} onClick={() => setActiveTabButton('ALL')}>ALL</button>
                                                <button type="button" className={`btn btn-outline-primary ${activeTabButton === 'INR' ? 'active' : ""}`} onClick={() => setActiveTabButton('INR')}>INR</button>
                                                <button type="button" className={`btn btn-outline-primary ${activeTabButton === 'USDT' ? 'active' : ""}`} onClick={() => setActiveTabButton('USDT')}>USDT</button>
                                                {/*   <button type="button" className={`btn btn-outline-primary ${activeTabButton === 'BTC' ? 'active' : ""}`} onClick={() => setActiveTabButton('BTC')}>BTC</button>
                                                <button type="button" className={`btn btn-outline-primary ${activeTabButton === 'ETH' ? 'active' : ""}`} onClick={() => setActiveTabButton('ETH')}>ETH</button>
                                                <button type="button" className={`btn btn-outline-primary ${activeTabButton === 'BNB' ? 'active' : ""}`} onClick={() => setActiveTabButton('BNB')}>BNB</button> */}
                                            </div>
                                        </div>
                                        <div className="table-responsive m_table">
                                            {
                                                currentItems && currentItems.length > 0 ? (
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Name</th>
                                                                <th scope="col" className="text-end">Price</th>
                                                                <th scope="col" className="text-end">(24h) Change</th>
                                                                <th scope="col" className="text-end">Volume (24h)</th>
                                                                <th scope="col" className="text-end">Market Cap</th>
                                                                <th scope="col" className="text-end">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {currentItems.length > 0 && (
                                                                activeTabButton ? (
                                                                    currentItems.some(item => item.quote_currency === activeTabButton || activeTabButton === "ALL") ? (
                                                                        currentItems.map((item, index) => (
                                                                            (activeTabButton === item?.quote_currency || activeTabButton === "ALL") && (
                                                                                <tr className="cursor-pointer" key={index} >
                                                                                    <td>
                                                                                        <div className="d-flex align-items-center">
                                                                                            {Authtoken && (
                                                                                                <span className={"star_btn btn_icon active"}>
                                                                                                    <i className={favCoins?.includes(item?._id) ? "ri ri-star-fill text-warning me-2 " : "ri ri-star-line me-2 "} onClick={() => { handleAddFav(item?._id); }} ></i>
                                                                                                </span>
                                                                                            )}

                                                                                            <div className="c_view w-100" onClick={() => nextPage(item)}>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <img src={item?.icon_path} alt="" onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} />
                                                                                                    <span>{item?.base_currency + "/" + item?.quote_currency}
                                                                                                        <br />
                                                                                                        <small>{item?.name}</small>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </td>
                                                                                    <td className="text-end" onClick={() => nextPage(item)}>
                                                                                        <div className=" justify-content-end">

                                                                                            {item.quote_currency === "INR" ? '₹' : '$'}
                                                                                            {item?.buy_price?.toFixed(2)}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="text-end" onClick={() => nextPage(item)}>
                                                                                        <span className={item?.change >= 0 ? "text-success" : "text-danger"}> {item?.change?.toFixed(2)}% </span>
                                                                                    </td>
                                                                                    <td className="text-end" onClick={() => nextPage(item)}>
                                                                                        {item?.volume?.toFixed(0)}
                                                                                    </td>
                                                                                    <td className="text-end" onClick={() => nextPage(item)}>
                                                                                        {item?.base_market_cap || 0}
                                                                                    </td>
                                                                                    <td className="text-end">
                                                                                        <button className="btn-link text-dark" type="button" onClick={() => nextPage(item)}>{item?.quote_currency === "INR" ? 'Buy-Sell' : 'Trade'}</button>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan="6">
                                                                                <div className="favouriteData">
                                                                                    <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="6">
                                                                            <div className="favouriteData">
                                                                                <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}


                                                        </tbody>
                                                    </table>
                                                ) : (
                                                    <div className="favouriteData">
                                                        <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                    </div>
                                                )
                                            }
                                        </div>

                                        {/* pagenation  */}
                                        <nav className="custom_Page navigation example">
                                            <div className="pagination mt-3">
                                                <button onClick={handlePrevious} disabled={currentPage === 1}>‹</button>
                                                {Array.from({ length: totalPages }, (_, index) => (
                                                    <button
                                                        key={index + 1}
                                                        className={currentPage === index + 1 ? "active" : ""}
                                                        onClick={() => handlePageChange(index + 1)}
                                                    >
                                                        {index + 1}
                                                    </button>
                                                ))}
                                                <button onClick={handleNext} disabled={currentPage === totalPages}>›</button>
                                            </div>
                                        </nav>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </main>

    )
}

export default MarketPage