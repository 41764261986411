import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { alertErrorMessage } from "../../../../Services/CustomAlertModel";
import AuthService from "../../../../Services/ApiServices/AuthService";
import LoaderHelper from "../../../../Services/Loading/LoaderHelper";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import { saveAs } from 'file-saver';


const QuickPayHistory = () => {

    const [activeTab, setActiveTab] = useState("all");
    const [transactionData, setTransactionData] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [totalDataLength, setTotalDataLength] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [activeExport, setActiveExport] = useState(false);
    const [isActiveModel, setIsActiveModel] = useState(false);
    const [rowDetails, setRowDetails] = useState();


    const [skip, setSkip] = useState(0);
    const limit = 10;

    const handleTranactionList = async (skip, limit, activeTab, startDate, endDate, searchKey) => {
        const validStartDate = startDate || "";
        const validEndDate = endDate || "";
        try {
            const result = await AuthService.quickyTranactionHistory(skip, limit, activeTab, validStartDate, validEndDate, searchKey);
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                setTransactionData(result.data?.list);
                setTotalDataLength(result?.data?.totalCount);
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };

    const columns = [
        {
            name: "Date/Time",
            cell: (row) => <div className="justify-content-start" >
                <small>
                    {moment(row.createdAt).format("DD/MM/YYYY")} <br /> {moment(row.createdAt).format("HH:mm:ss")}
                </small>
            </div>,
            sortable: false,
        },

        {
            name: 'Type',
            selector: row => row,
            sortable: true,
            format: row => <small>{row?.transaction_type === "Debit" ? 'Sent' : 'Recived'}</small>,
        },


        {
            name: 'Receiver Name',
            selector: row => row,
            sortable: true,
            format: row => <small>{row?.reciever_name || "------------"}</small>,
        },

        {
            name: 'Sender Name',
            selector: row => row,
            sortable: true,
            format: row => <small>{row?.sender_name || "------------"}</small>,
        },



        {
            name: 'Amount',
            selector: row => row,
            wrap: true,
            sortable: true,
            format: row => (
                <small>
                    {row?.transaction_type === "Debit" ? "-" : "+"} {" "} {row?.amount?.$numberDecimal}
                </small>
            ),
        },


        {
            name: 'Currency',
            selector: row => row,
            wrap: true,
            sortable: true,
            format: row => <small>{row?.currency}</small>,
        },

        {

            name: 'Status',
            selector: row => row,
            wrap: true,
            sortable: true,
            format: row =>
                <span className="text-success">
                    {row?.status}
                </span>,
        },

        {
            name: 'Action',
            selector: row => row,
            format: row => <button onClick={() => { setIsActiveModel(true); setRowDetails(row) }} className="btn btn-dark">Details</button>,
        },

    ];

    const handlePagination = (action) => {
        if (action === 'prev') {
            if (skip - limit >= 0) {
                setSkip(skip - limit);
            }
        } else if (action === 'next') {
            if (skip + limit < totalDataLength) {
                setSkip(skip + limit);
            }
        } else if (action === 'first') {
            setSkip(0);
        } else if (action === 'last') {
            const lastPageSkip = Math.floor(totalDataLength);
            const data = lastPageSkip - limit
            setSkip(data);
        }
    };



    const handleCleartab = () => {
        setSearchKey('');
        setStartDate('');
        setEndDate('');
    };


    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]).filter(key => !['_id', 'user_id', 'order_id', 'updatedAt', '_v'].includes(key));
        let csv = headers.join(',') + '\n';
        data.forEach(item => {
            const row = headers.map(header => {
                if (header === 'createdAt') {
                    return moment(item[header]).format('DD-MM-YYYY HH:mm:ss');
                }
                return item[header];
            });
            csv += row.join(',') + '\n';
        });
        return csv;
    };

    const handleExportData = async () => {
        const csvData = convertToCSV(transactionData);
        saveAs(new Blob([csvData], { type: 'text/csv' }), 'quickpay.csv');
    };

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchKey(searchTerm);
    };

    useEffect(() => {
        handleTranactionList(skip, limit, activeTab, startDate, endDate, searchKey);
    }, [skip, limit, activeTab, startDate, endDate, searchKey]);

    return (
        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper" >
                    <section className="d_page history_page">
                        <div className="container" >
                            <div className="row justify-content-center">
                                <div className="col-lg-12" >
                                    <div className="pg_header" >
                                        <div className="hd_innre" >
                                            <h6 className="mb-0" >Quick Pay History</h6>
                                        </div>
                                    </div>
                                    <div className="spot_tabs my-2 my-md-3" >
                                        <ul className="nav custom-tabs">
                                            <li className={`${activeTab === "all" ? 'active' : ''}`} onClick={() => setActiveTab('all')}>
                                                <span>  All   </span>
                                            </li>
                                            <li className={`tab_item ${activeTab === "sent" ? 'active' : ''}`} onClick={() => setActiveTab('sent')}>
                                                <span>  Sent  </span>
                                            </li>
                                            <li className={`tab_item ${activeTab === "received" ? 'active' : ''}`} onClick={() => setActiveTab('received')}>
                                                <span>  Received  </span>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="filter-card mb-3 mb-md-4">
                                        <div className="row g-2 align-items-end" >
                                            <div className="col-lg-10" >
                                                <div className="row g-2 align-items-end" >
                                                    <div className="col-lg-2" >
                                                        <div className="form-group" >
                                                            <label> Search </label>
                                                            <div className="searchBar custom-tabs mt-0">
                                                                <input type="search" className="custom_search" placeholder="Search Crypto" value={searchKey} onChange={handleSearch} />
                                                                <i className="ri-search-2-line"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6  col-lg-2">
                                                        <div className="form-group">
                                                            <label>Start Date</label>
                                                            <input
                                                                type="date"
                                                                className="form-control cursor-pointer"
                                                                value={startDate}
                                                                onChange={(e) => setStartDate(e.target.value)}
                                                                max={new Date().toISOString().split("T")[0]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6  col-lg-2">
                                                        <div className="form-group">
                                                            <label>End Date</label>
                                                            <input
                                                                type="date"
                                                                className="form-control cursor-pointer"
                                                                value={endDate}
                                                                onChange={(e) => setEndDate(e.target.value)}
                                                                max={new Date().toISOString().split("T")[0]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <div className="d-flex gap-2 mt-2 mt-md-0">
                                                            <button className="btn btn-muted w-50" onClick={handleCleartab} disabled={!startDate || !endDate}>Clear</button>
                                                            <button className="btn btn-dark w-50" onClick={() => { handleTranactionList(skip, limit, activeTab, startDate, endDate, searchKey); setActiveExport(true) }} disabled={!startDate || !endDate}>Apply</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2" >
                                                <div className="ph_right" >
                                                    <button className="btn btn-dark px-4" type="button" onClick={() => handleExportData(startDate, endDate)} disabled={!startDate || !endDate || !activeExport}>
                                                        Export <i className="ms-2 ri-download-2-line"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="g_start m_card overflow-hidden p-0">
                                        <div className="m_table" >
                                            <div className="table-responsive h-auto ">
                                                <DataTable
                                                    className="table table-hover"
                                                    columns={columns}
                                                    data={transactionData}
                                                    noDataComponent={
                                                        <p> <img src="/assets/images/not_found.svg" width="300" className='img-fluid mt-5' alt='' /> </p>
                                                    }
                                                />

                                                {
                                                    transactionData?.length > 0 &&
                                                    <div className="hVPalX gap-2" >
                                                        <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                                        <div className="sc-eAKtBH gVtWSU">
                                                            <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                                </svg>
                                                            </button>
                                                            <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                </svg>
                                                            </button>

                                                            <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                </svg>
                                                            </button>
                                                            <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>



            {
                isActiveModel &&
                <div div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-dialog-centered ">
                            <div className="modal-content">
                                <div className="modal-header" >
                                    <h5 className="modal-title" >Transaction Details</h5>
                                    <button type="button" className="btn-close" onClick={() => setIsActiveModel(false)} ></button>
                                </div>
                                <div class="row justify-content-center ">
                                    <div class="col-xl-12">
                                        <form className="pp_success" >
                                            <svg xmlns="http://www.w3.org/2000/svg" class="svg-success mt-2" viewBox="0 0 24 24">
                                                <g stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10">
                                                    <circle class="success-circle-outline" cx="12" cy="12" r="11.5" />
                                                    <circle class="success-circle-fill" cx="12" cy="12" r="11.5" />
                                                    <polyline class="success-tick" points="17,8.5 9.5,15.5 7,13" />
                                                </g>
                                            </svg>
                                            <p class="mt-3 text-gray-400 dark:text-gray-400" data-id="9">Complete</p>
                                            <h5 data-id="8"> {rowDetails?.transaction_type === "Debit" ? "-" : "+"} {" "}  {rowDetails?.amount?.$numberDecimal} {" "}  {rowDetails?.currency}  </h5>
                                            <div class="tt_data mb-4">
                                                <div class="tt_card  ">
                                                    <div class="tt_item">
                                                        <span class="text-muted"> Reciever Name </span>
                                                        <span class="tt_normal">  {rowDetails?.email || "-----"}  <br /> <small className="fw-normal" > {rowDetails?.reciever_name || "-----"}</small> </span>
                                                    </div>
                                                    <div class="tt_item">
                                                        <span class="text-muted">  Sender Name </span>
                                                        <span class="tt_normal">{rowDetails?.sender_name || "------"}</span>
                                                    </div>

                                                    <div class="tt_item">
                                                        <span class="text-muted"> Note </span>
                                                        <span class="tt_normal">{rowDetails?.note || "------"}</span>
                                                    </div>
                                                    <div class="tt_item  ">
                                                        <span class="text-muted"> Time Created </span>
                                                        <span class="tt_normal">{moment(rowDetails.createdAt).format("DD/MM/YYYY")} <br />
                                                            {moment(rowDetails.createdAt).format("HH:mm:ss")}</span>
                                                    </div>
                                                    <div class="tt_item">
                                                        <span class="text-muted"> Order ID </span>
                                                        <span class="tt_normal"> {rowDetails._id}</span>
                                                    </div>
                                                    <div class="tt_item">
                                                        <span class="text-muted"> Payment Method </span>
                                                        <span class="tt_normal">  {rowDetails.wallet_type} </span>
                                                    </div>
                                                    <div class="tt_item no-border">
                                                        <span class="text-muted"> Paid With </span>
                                                        <span class="tt_normal">{rowDetails?.currency}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"> </div>
                </div >
            }
        </main>

    )
}

export default QuickPayHistory