import React from "react";
const PayLimitModel = ({ isOpenModel, setIsOpenModel, paylimitDetails }) => {    

    return (

        isOpenModel &&
        <div div className="modal-open-custom" >
            <div className="modal show d-block" >
                <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" >  Pay Limit</h5>
                            <button type="button" className="btn-close" onClick={() => setIsOpenModel(false)} ></button>
                        </div>
                        <div className="modal-body py-md-3 p-md-4">
                            <div class="tt_data ">
                                <div class="   ">
                                    <div class="tt_item no-border no-border ">
                                        <span class="tt_normal"> Daily Limit </span>
                                    </div>
                                    <div class="tt_item">
                                        <span class="text-muted"> INR Limit </span>
                                        <span class="tt_normal">₹ {paylimitDetails?.dailyInrLimit?.total}</span>
                                    </div>
                                    <div class="tt_item">
                                        <span class="text-muted"> Remanning INR Limit </span>
                                        <span class="tt_normal"> ₹ {paylimitDetails?.dailyInrLimit?.remaining} </span>
                                    </div>
                                    <div class="tt_item">
                                        <span class="text-muted"> Crypto Limit </span>
                                        <span class="tt_normal">  $ {paylimitDetails?.dailyUsdLimit?.total} </span>
                                    </div>
                                    <div class="tt_item">
                                        <span class="text-muted"> Remanning Crypto Limit</span>
                                        <span class="tt_normal"> $ {paylimitDetails?.dailyUsdLimit?.remaining} </span>
                                    </div>
                                    <div class="tt_item ">
                                        <span class="text-muted"> Total No of Orders Limit </span>
                                        <span class="tt_normal">  {paylimitDetails?.totalOrders?.total} </span>
                                    </div>
                                    <div class="tt_item no-border">
                                        <span class="text-muted"> Remanning Total No of Orders Limit </span>
                                        <span class="tt_normal"> {paylimitDetails?.totalOrders?.remaining} </span>
                                    </div>
                                    <div class="tt_item mt-2 no-border">
                                        <span class="tt_normal"> Monthly Free Limit </span>
                                    </div>
                                    <div class="tt_item">
                                        <span class="text-muted"> INR Limit </span>
                                        <span class="tt_normal">₹ {paylimitDetails?.freeInrLimit?.total} </span>
                                    </div>
                                    <div class="tt_item">
                                        <span class="text-muted"> Remanning INR Limit </span>
                                        <span class="tt_normal"> ₹ {paylimitDetails?.freeInrLimit?.remaining} </span>
                                    </div>
                                    <div class="tt_item">
                                        <span class="text-muted"> Crypto Limit </span>
                                        <span class="tt_normal"> $ {paylimitDetails?.freeUsdLimit?.remaining} </span>
                                    </div>
                                    <div class="tt_item no-border">
                                        <span class="text-muted"> Remanning Crypto Limit</span>
                                        <span class="tt_normal"> $ {paylimitDetails?.freeUsdLimit?.remaining} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"> </div>
        </div >
    )
}

export default PayLimitModel