import React from 'react';
import { Link } from 'react-router-dom';

const Aboutus = () => {

    return (

        <section className="faq_sec">
            <div className="container">               
                <div className="sec_title mb-5"> 
                    <h2 className="text-dark text-center "> Frequently Asked Questions </h2>
                    <p>
                    Get quick answers to most askted questions. Check out our FAQs!
                    </p>
                </div> 
                <div className="row align-items-center ">
                    <div className="col-lg-6">
                        <div className="accordion " id="accordionExample">
                            <div className="card">
                                <h2 className="card-header" id="headingcollapse_1">
                                    <button className="accordion-button  collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse_1" aria-expanded="false" aria-controls="collapse_1">
                                       How do I create an account on Crypto India?
                                    </button>
                                </h2>
                                <div id="collapse_1" className="accordion-collapse collapse" aria-labelledby="headingcollapse_1"
                                    data-bs-parent="#accordionExample">
                                    <div className="card-body card-body-padding-top ">
                                        <p className="mb-0 text-white ">
                                        Sign up by providing your email address, creating a strong password, and completing identity verification.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <h2 className="card-header" id="headingcollapse_2">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse_2" aria-expanded="false" aria-controls="collapse_2">
                                       How can I deposit funds into my Crypto India account?
                                    </button>
                                </h2>
                                <div id="collapse_2" className="accordion-collapse collapse" aria-labelledby="headingcollapse_2"
                                    data-bs-parent="#accordionExample">
                                    <div className="card-body card-body-padding-top">
                                        <p className="mb-0 text-white ">
                                        Deposit funds by selecting your preferred payment method, such as bank transfer, UPI, or crypto transfer.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <h2 className="card-header" id="headingcollapse_3">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse_3" aria-expanded="false" aria-controls="collapse_3">
                                        How long do deposits and withdrawals take?
                                    </button>
                                </h2>
                                <div id="collapse_3" className="accordion-collapse collapse" aria-labelledby="headingcollapse_3"
                                    data-bs-parent="#accordionExample">
                                    <div className="card-body  ">
                                        <p className="mb-0 text-white ">
                                        Deposits and withdrawals are processed instantly, but in some cases, it may take a few minutes depending on network conditions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <h2 className="card-header" id="headingcollapse_4">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse_4" aria-expanded="false" aria-controls="collapse_3">
                                        What are the trading fees on Crypto India?
                                    </button>
                                </h2>
                                <div id="collapse_4" className="accordion-collapse collapse" aria-labelledby="headingcollapse_4"
                                    data-bs-parent="#accordionExample">
                                    <div className="card-body  ">
                                        <p className="mb-0 text-white ">We offer competitive trading fees, which vary based on the transaction type. Check our fee structure for detailed information.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <h2 className="card-header" id="headingcollapse_5">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse_5" aria-expanded="false" aria-controls="collapse_5">
                                        How does Crypto India ensure the security of my funds?
                                    </button>
                                </h2>
                                <div id="collapse_5" className="accordion-collapse collapse" aria-labelledby="headingcollapse_5"
                                    data-bs-parent="#accordionExample">
                                    <div className="card-body  ">
                                        <p className="mb-0 text-white ">
                                        We use advanced encryption, multi-factor authentication, and strict security protocols to protect your assets.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <h2 className="card-header" id="headingcollapse_6">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse_6" aria-expanded="false" aria-controls="collapse_6">
                                       How can I contact Crypto India support?
                                    </button>
                                </h2>
                                <div id="collapse_6" className="accordion-collapse collapse" aria-labelledby="headingcollapse_6"
                                    data-bs-parent="#accordionExample">
                                    <div className="card-body  ">
                                        <p className="mb-0 text-white ">
                                        You can reach our support team 24x7 through live chat, email, or phone for any assistance you need.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <h2 className="card-header" id="headingcollapse_7">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse_7" aria-expanded="false" aria-controls="collapse_7">
                                       Is Crypto India compliant with Indian regulations?
                                    </button>
                                </h2>
                                <div id="collapse_7" className="accordion-collapse collapse" aria-labelledby="headingcollapse_7"
                                    data-bs-parent="#accordionExample">
                                    <div className="card-body  ">
                                        <p className="mb-0 text-white ">
                                        Yes, Crypto India strictly follows Indian regulatory guidelines to ensure a legal and safe trading environment.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <h2 className="card-header" id="headingcollapse_8">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse_8" aria-expanded="false" aria-controls="collapse_8">
                                       Does Crypto India have a mobile app for iOS and Android?
                                    </button>
                                </h2>
                                <div id="collapse_8" className="accordion-collapse collapse" aria-labelledby="headingcollapse_8"
                                    data-bs-parent="#accordionExample">
                                    <div className="card-body  ">
                                        <p className="mb-0 text-white ">
                                        Yes, Crypto India offers a mobile app for both iOS and Android users. Our app allows you to trade, deposit, withdraw, and monitor your cryptocurrency portfolio with ease, anytime and anywhere.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className='globe_sec' >
                            <Link to='/company_management' className='globe_box' >
                                <span>Crypro India</span>
                                <img src="/assets/images/globe_map.svg" className='img-fluid' alt='' />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Aboutus