import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoaderHelper from "../../../Services/Loading/LoaderHelper";
import { alertErrorMessage, alertSuccessMessage } from "../../../Services/CustomAlertModel";
import AuthService from "../../../Services/ApiServices/AuthService";
import Select from 'react-select';
import { ProfileContext } from "../../../Services/ProfileProvider";
import { PasswordModal } from "../../../Services/PasswordModal";
import Swal from "sweetalert2";
import countryName from "../../../Services/countryName.json"


const EntitySignup = () => {
    const ref = window.location.href.split("=")[1];
    const [showPass, setshowPass] = useState(false);
    const [showPass2, setshowPass2] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [isOtpSentMob, setIsOtpSentMob] = useState(false);
    const [countdownMob, setCountdownMob] = useState(0);
    const { setLoginDetails } = useContext(ProfileContext);
    const [isActiveModel, setIsActiveModel] = useState(false);
    const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);
    const [emailError, setEmailError] = useState();
    const [mobileError, setMobileError] = useState();
    const navigate = useNavigate();
    const [showReferInput, setShowReferInput] = useState(false);
    const [passwordError, setPasswordError] = useState();

    const [conditions, setConditions] = useState({
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        hasSpecialChar: false,
        passLength: false
    });

    const [formData, setFormData] = useState(
        {
            entityName: "",
            entityType: "",
            emailId: "",
            emailOtp: "",
            mobileNumber: "",
            mobileOtp: "",
            password: "",
            confirmPassword: "",
            countryCode: "+91",
            referral_code: ref || '',

        }
    );

    const handleInput = (event) => {
        const { name, value } = event.target;

        if ((name === "emailOtp" || name === "mobileOtp") && value.length <= 6) {
            setFormData({ ...formData, [name]: value });
        }
        else if (name === "mobileNumber" && value.length <= 10) {
            setFormData({ ...formData, [name]: value });
            if (/^\d{10}$/.test(value)) {
                handleCheckEmailPhone(value, "Phone");
            } else {
                setCountdownMob(0)
                setMobileError("");
            }
        }

        else if (name === "emailId") {
            setFormData({ ...formData, [name]: value });
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            if (emailPattern.test(value)) {
                handleCheckEmailPhone(value, "email");
            } else {
                setCountdown(0);
                setEmailError("");
            }
        }
        else if (name === "password") {
            const value = event.target.value;
            const hasUpperCase = /[A-Z]/.test(value);
            const hasLowerCase = /[a-z]/.test(value);
            const hasNumber = /\d/.test(value);
            const hasSpecialChar = /[@.#$!%*?&^]/.test(value);
            const passLength = value.length >= 8;

            setFormData({ ...formData, password: value });
            setConditions({ hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar, passLength });
        }
        else if (name !== "emailOtp" && name !== "mobileOtp" && name !== "mobileNumber" && name !== "emailId") {
            setFormData({ ...formData, [name]: value });
        }

    };

    const handleChange = (selectedOption) => {
        handleInput({
            target: {
                name: 'entityType',
                value: selectedOption.value,
            },
        });
    };

    const options = [
        { value: 'Partnership', label: 'Partnership' },
        { value: 'LLP', label: 'LLP' },
        { value: 'Private Limited', label: 'Private Limited' },
        { value: 'Public Limited', label: 'Public Limited' },
        { value: 'Govt Sector', label: 'Govt Sector' },
        { value: 'Govt Agency', label: 'Govt Agency' },
    ];

    const handleCheckEmailPhone = async (signId, type) => {
        try {
            const result = await AuthService.checkEmailPhone(signId, type.toLowerCase());
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                if (type.toLowerCase() === 'email') {
                    setEmailError("");
                } else if (type.toLowerCase() === 'phone') {
                    setMobileError("");
                }
            } else {
                if (type.toLowerCase() === 'email') {
                    setEmailError(result?.message);
                } else if (type.toLowerCase() === 'phone') {
                    setMobileError(result?.message);
                }
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error?.message || "An unexpected error occurred.");
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { entityName, emailId, mobileNumber, password, confirmPassword, entityType } = formData;

        if (!entityName || !emailId || !mobileNumber || !password || !confirmPassword) {
            alertErrorMessage('All Fields are required.');
            return;
        }
        if (!entityType) {
            alertErrorMessage('Select Entity Name');
            return;
        }
        if (mobileNumber?.length < 10) {
            setMobileError("Enter Valid Mobile Number");
            return
        }

        if (emailError || mobileError || password !== confirmPassword) {

            if (emailError) {
                setEmailError(emailError);
                return
            }
            if (mobileError) {
                setEmailError(mobileError);
                return
            }

            if (password !== confirmPassword) {
                setPasswordError("Passwords does not match.");
                return
            }
            return;
        }
        if (!conditions?.hasUpperCase || !conditions?.hasLowerCase || !conditions?.hasNumber || !conditions?.hasSpecialChar || !conditions?.passLength) {
            setPasswordModalOpen(true);
            return
        }

        try {
            await Promise.all([
                handleSendOtp(emailId, "signup", "emailtype"),
                handleSendOtp(mobileNumber, "signup", "mobiletype")
            ]);
        } catch (error) {
            alertErrorMessage('There was an error sending OTPs.');
        }
    };

    const handleSendOtp = async (signId, type, inputType) => {
        try {
            const result = await AuthService.getOtp(signId, type);
            if (result?.success) {
                setIsActiveModel(true);
                if (inputType === "emailtype") {
                    setIsOtpSent(true);
                    setCountdown(60);
                } else if (inputType === "mobiletype") {
                    setIsOtpSentMob(true);
                    setCountdownMob(60);
                }
                alertSuccessMessage("Email & Mobile Otp sent successfully");
            } else {
                alertErrorMessage(result?.message);
            }
        } catch (error) {
            alertErrorMessage(error?.message);
        }
    };

    const handleSignup = async () => {
        const { emailOtp, mobileOtp } = formData;
        if (!emailOtp) {
            alertErrorMessage("Please enter Email otp");
            return
        } if (!mobileOtp) {
            alertErrorMessage("Please enter Mobile otp");
            return
        }
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.antityRegister(formData);
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                setIsActiveModel(false);
                showAlert(result)
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };


    const showAlert = (result) => {
        let newData = result
        Swal.fire({
            title: "Welcome to Crypto India",
            text: "Account Created Successfully",
            icon: "success",
            showCancelButton: true,
            confirmButtonColor: "#11a004",
            cancelButtonColor: "#e7e7e7",
            confirmButtonText: "Complete KYC",
            cancelButtonText: "Skip for now"
        }).then((result) => {
            localStorage.setItem("token", newData.data.token);
            localStorage.setItem("email", newData.data.email);
            localStorage.setItem("user_id", newData.data.user_id);
            setLoginDetails(newData.data);
            handleInput('clearFields');
            if (result.isConfirmed) {
                navigate("/verify_kyc");
            } else if (result.isDismissed) {
                navigate("/dashboard");
            }

        });
    };


    const handleGetOtp = async (signId, type, inputType) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const mobileRegex = /^\d{10}$/;
        if (!signId && inputType === "emailtype") {
            alertErrorMessage('Please Enter Email Address..!!');
            return;
        } if (!signId && inputType === "mobiletype") {
            alertErrorMessage('Please Enter Mobile Number..!!');
            return;
        } if (inputType === "emailtype" && !emailRegex.test(signId)) {
            alertErrorMessage('Email is invalid');
            return;
        } if (inputType === "mobiletype" && !mobileRegex.test(signId)) {
            alertErrorMessage('Mobile number must be 10 digits');
            return;
        }
        try {
            const result = await AuthService.getOtp(signId, type);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                if (inputType === "emailtype") {
                    setIsOtpSent(true);
                    setCountdown(60);
                } else {
                    setIsOtpSentMob(true);
                    setCountdownMob(60);
                }
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    const handleCountryCodeChange = (selectedOption) => {
        if (selectedOption === null) {
            return;
        }
        const { value } = selectedOption;
        setFormData({ ...formData, countryCode: value });
    };

    const countryOptions = countryName.map((country, index) => ({
        value: country?.dial_code,
        label: `${country.flag} ${"  "} ${country.name} (${country.dial_code})`,
        flag: country.flag,
        dial_code: country.dial_code,
    }));

    const formatOptionLabel = ({ flag, dial_code, label }, { context }) => {
        return context === 'menu'
            ? label
            : `${flag} ${dial_code}`;
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? 'black'
                : state.isFocused
                    ? 'gainsboro'
                    : 'white',
            color: state.isSelected
                ? 'white'
                : state.isFocused
                    ? 'dark'
                    : 'black',
            fontSize: '14px',
            borderRadius: '4px',
            margin: '0px',
        }),
    };

    useEffect(() => {
        let timer;
        if (isOtpSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsOtpSent(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSent, countdown]);

    useEffect(() => {
        let timer;
        if (isOtpSentMob && countdownMob > 0) {
            timer = setInterval(() => {
                setCountdownMob((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdownMob === 0) {
            setIsOtpSentMob(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSentMob, countdownMob]);


    return (
        <main>
            <section className="section-padding login_bg login_sec">
                <div className="section-overlay"></div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6  col-12">
                            <div className="login_qr">
                                <h4 className="letter_space"> Sign up Your Entity & <br /> Lighten Your </h4>
                                <h4 className="letter_space text-warning">Cryptocurrencies Journey</h4>
                                <img src="/assets/images/signup_bg.svg" width="380" className="img-fluid mx-auto mt-4 d-none d-md-block"
                                    alt="signupImg" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 mx-auto">
                            <div className="custom-form ticket-form mb-5 mb-lg-0" >
                                <h5 className="text-center mb-4 text-warning" >  Entity Sign up</h5>
                                <div className="tab-content">
                                    <form className="tab-pane show active" onSubmit={handleSubmit}>
                                        <div className="card-body ">
                                            <div className="row gx-2">
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <input type="entityName" name="entityName" id="entityName" className="form-control" placeholder="Enter entity name" required autoComplete="entityName" value={formData.entityName} onChange={handleInput} />
                                                </div>

                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <Select
                                                        name="entityType"
                                                        className="mb-3"
                                                        id="entityType"
                                                        value={options.find(option => option.value === formData.entityType)}
                                                        onChange={handleChange}
                                                        options={options}
                                                        styles={customStyles}

                                                    />
                                                </div>

                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <div className="form-group  mb-3" >
                                                        <input type="email" name="emailId" id="email" pattern="[^ @]*@[^ @]*" className="form-control mb-0" placeholder="Enter email address" required autoComplete="emailId" value={formData.emailId} onChange={handleInput} />
                                                        <div className="in_error" >
                                                            <small>{emailError && emailError}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-12 col-12" >
                                                    <Select
                                                        required
                                                        className="mb-3"
                                                        name="countryCode"
                                                        onChange={handleCountryCodeChange}
                                                        options={countryOptions}
                                                        value={countryOptions.find(option => option.value === formData.countryCode)}
                                                        formatOptionLabel={formatOptionLabel}
                                                        styles={customStyles}
                                                    />
                                                </div>
                                                <div className="col-lg-9 col-md-12 col-12">
                                                    <div className="form-group  mb-3" >
                                                        <input type="number" name="mobileNumber" id="mobileNumber" className="form-control mb-0" placeholder="Enter phone number" required autoComplete="mobileNumber" value={formData.mobileNumber} onChange={handleInput} maxLength="10" />
                                                        <div className="in_error" >
                                                            <small>  {mobileError && mobileError} </small>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <div className=" field-otp-box">
                                                        <input type={showPass ? 'text' : "password"} name="password" id="password" className="form-control mb-2" placeholder="Password" required value={formData.password} onChange={handleInput}
                                                            onFocus={() => setPasswordModalOpen(true)}
                                                            onBlur={() => setPasswordModalOpen(false)}
                                                        />

                                                        <button type="button" className="btn btn-icon btn_view_pass btn-white" onClick={() => { setshowPass(!showPass) }}>
                                                            <i className={`ri-eye${showPass ? "-line" : "-off-line"}`} />
                                                        </button>
                                                        <PasswordModal isOpen={isPasswordModalOpen} conditions={conditions} />
                                                    </div>

                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <div className="form-group mb-3" >
                                                        <div className="field-otp-box">
                                                            <input type={showPass2 ? 'text' : "password"} name="confirmPassword" id="confirmPassword" className="form-control mb-0" placeholder="Confirm password" required value={formData.confirmPassword} onChange={handleInput} />
                                                            <button type="button" className="btn btn-icon btn_view_pass btn-white" onClick={() => { setshowPass2(!showPass2) }}>
                                                                <i className={`ri-eye${showPass2 ? "-line" : "-off-line"}`} />

                                                            </button>
                                                        </div>
                                                        {formData.confirmPassword && formData.password !== formData.confirmPassword &&
                                                            <div className="in_error" ><small>{passwordError || "Passwords does not match."}</small></div>}
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <label className="mb-3 d-flex align-items-center cursor-pointer">
                                                        <small onClick={() => setShowReferInput((prev) => !prev)}>
                                                            Referral Code (Optional)
                                                            <i className={`ri-arrow-${showReferInput || formData?.referral_code ? "up-s-line" : "down-s-line"}`} style={{ fontSize: "20px" }}></i>
                                                        </small>

                                                    </label>
                                                    <input type="text" name="referral_code" id="referral_code" className={`form-control ${showReferInput || formData?.referral_code ? "" : "d-none"}`} placeholder="Referral Code" autoComplete="referral_code" value={formData.referral_code} onChange={handleInput} disabled={ref} />
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-check termsConditions mb-4">
                                                        <input className="form-check-input cursor-pointer" type="checkbox" required />
                                                        <label className="form-check-label pt-1" >
                                                            I agree to Crypto India {" "}
                                                            <Link className="btn-link" target="_blank" to="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/terms_and_conditions.pdf"> Terms and Use</Link>
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-lg-12 col-md-10 col-12 mx-auto mt-1">
                                                <button type="submit" className="form-control">  Sign up </button>
                                            </div>
                                            <div className="registration__info"> <span></span> OR <span></span> </div>
                                            <div className="row g-3" >
                                                <div className="col-lg-6 col-md-12 col-12 text-center text-lg-start mt-4">
                                                    <p className="mb-0" >
                                                        Already have an account? <Link className="fw-bold text-warning text-underline" to="/login" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}> Login </Link>
                                                    </p>
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-12 text-center text-lg-end mt-4">
                                                    <p className="mb-0" >
                                                        New User? <Link className="fw-bold text-warning text-underline" to="/signup"> Sign up  </Link>
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {
                isActiveModel &&
                <div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog  modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title" id="modal_otp_Label"> OTP Verification </h5>
                                    <button type="button" className="btn-close" onClick={() => setIsActiveModel(false)}></button>
                                </div>
                                <div className="modal-body p-md-4 ">
                                    <div className="row justify-content-center">

                                        <div className="col-lg-12 ">
                                            <div className="field-otp-box">
                                                <input type="number" name="emailOtp" id="email_otp" className="form-control" placeholder="Enter Email OTP" required value={formData.emailOtp} onChange={handleInput} maxLength={6} />
                                                <button type="button" className="btn btn-xs custom-btn" onClick={() => { handleGetOtp(formData.emailId, 'signup', 'emailtype') }} disabled={isOtpSent}>
                                                    <span>{isOtpSent ? `Resend in ${countdown}s` : 'GET OTP'}</span>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 mt-3 ">
                                            <div className=" field-otp-box">
                                                <input type="number" name="mobileOtp" id="mobileOtp" className="form-control" placeholder="Enter Mobile OTP" required value={formData.mobileOtp} onChange={handleInput} maxLength={6} />

                                                <button type="button" className="btn btn-xs custom-btn" onClick={() => { handleGetOtp(formData.mobileNumber, 'signup', 'mobiletype') }} disabled={isOtpSentMob}>
                                                    <span>{isOtpSentMob ? `Resend in ${countdownMob}s` : 'GET OTP'}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=" col-lg-12 col-12 mt-1 text-center mt-3">
                                        <p className="text-muted fw-normal" >
                                            <small  >By submitting, I agree to Crypto India Terms of use & Privacy policy </small>
                                        </p>
                                        <button type="button" className="btn  px-5 0 btn-warning" onClick={handleSignup}>SUBMIT</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </div>
            }


        </main>
    )
}

export default EntitySignup