import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { weburl } from "../../../Services/ApiServices/AuthAction/ApiConfig";

const Footer = () => {

    const Authtoken = localStorage.getItem("token");
    const handleupper = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const navigate = useNavigate();

    const handleNavigate = (item) => {
        navigate("/policies", { state: item });
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <footer className="site-footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-12 mb-4 pb-2">
                        <Link className="navbar-brand" to="/" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                            <img src="/logo.svg" />
                        </Link>
                        <p className="mt-2 my-3">At <b className="fw-bold" >Crypto India</b>, we focus on top-notch security and advanced technology to protect your assets. Our platform ensures a simple, efficient trading experience for all users.
                        </p>
                        <div className="fot_soc" >
                            <p>Follow us on:</p>
                            <ul className="social-icon d-block ">
                                <li className="social-icon-item">
                                    <a href="https://www.instagram.com/cryptoindiaexchange/?igsh=MWh3bTZ2Z3RzMmFvMg%3D%3D" title="Follow us on Instagram" className="social-icon-link" target="_blank">
                                        <i className="ri-instagram-line"></i>
                                    </a>
                                </li>
                                <li className="social-icon-item">
                                    <a href="https://t.me/cryptoindiasupport" title="Follow us on Telergam" className="social-icon-link" target="_blank">
                                        <i className="ri-telegram-line"></i>
                                    </a>
                                </li>
                                <li className="social-icon-item">
                                    <a href="https://x.com/cryptoindia_in?s=11&t=g9-804Y9YmuEcyomNZ0b5A" title="Follow us on X" className="social-icon-link" target="_blank">
                                        <i className="ri-twitter-x-line"></i>
                                    </a>
                                </li>
                                <li className="social-icon-item">
                                    <a href="https://whatsapp.com/channel/0029VahMtDlAu3aT8uo21t2L" title="Join us on Whatsapp" className="social-icon-link" target="_blank">
                                        <i className="ri-whatsapp-line"></i>
                                    </a>
                                </li>
                                <li className="social-icon-item">
                                    <a href="https://www.youtube.com/@CryptoIndiaExchange" title="Subscribe our Youtube channel" className="social-icon-link" target="_blank">
                                        <i className="ri-youtube-line"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div className="col-lg-2 col-md-6 col-12 mb-4 mb-lg-0">
                        <h5 className="site-footer-title mb-3">About Us</h5>
                        <p className="text-white d-flex ">
                            <Link to="/company_management" className="site-footer-link" onClick={handleupper}>
                                Company
                            </Link>
                        </p>
                        <p className="text-white d-flex">
                            <Link to="/career" className="site-footer-link" onClick={handleupper}>
                                Career
                            </Link>
                        </p>
                        <p className="text-white d-flex">
                            <a href="https://blogs.cryptoindia.in/" className="site-footer-link" target="_blank">
                                Blog
                            </a>
                        </p>
                        <p className="text-white d-flex">
                            <Link to="/download-app" className="site-footer-link" onClick={handleupper}>
                                Download
                            </Link>
                        </p>
                    </div>


                    <div className="col-lg-2 col-md-6 col-12 mb-4 mb-lg-0">
                        <h5 className="site-footer-title mb-3">Policies</h5>
                        <p className="text-white d-flex ">
                            <a className="site-footer-link cursor-pointer" target="_blank" href="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/kyc_aml_policy.pdf" >
                                KYC & AML Policy
                            </a>
                        </p>
                        <p className="text-white d-flex ">
                            <a className="site-footer-link cursor-pointer" target="_blank" href="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/terms_and_conditions.pdf"  >
                                Terms of Use
                            </a>
                        </p>
                        <p className="text-white d-flex">
                            <a className="site-footer-link cursor-pointer" target="_blank" href="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/data_privacy_policy.pdf"  >
                                Data Privacy
                            </a>
                        </p>
                        <p className="text-white d-flex">
                            <a className="site-footer-link cursor-pointer" target="_blank" href="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/risk_framework_policy.pdf" >
                                Risk Framework
                            </a>
                        </p>
                        <p className="text-white d-flex">
                            <a className="site-footer-link cursor-pointer" target="_blank" href="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/refund_policy.pdf" >
                                Refund Policy
                            </a>
                        </p>
                        <p className="text-white d-flex">
                            <Link className="site-footer-link cursor-pointer" to="/fees_structure" onClick={handleupper}>
                                Fee structure
                            </Link>
                        </p>

                    </div>

                    <div className="col-lg-2 col-md-6 col-12 mb-4 mb-lg-0">
                        <h5 className="site-footer-title mb-3">Business</h5>

                        <p className="text-white d-flex">
                            <Link to="/market_maker_program" className="site-footer-link" onClick={handleupper}>
                                Market maker program
                            </Link>
                        </p>

                        <p className="text-white d-flex">
                            <Link to="/coin_list" className="site-footer-link" onClick={handleupper}>
                                List your coin
                            </Link>
                        </p>
                        <p className="text-white d-flex">
                            <Link to="/partnership_inquiry" className="site-footer-link" onClick={handleupper}>
                                Partnership & Inquiry
                            </Link>
                        </p>

                        <p className="text-white d-flex">
                            <Link to={Authtoken ? '/referral' : '/login'} className="site-footer-link" onClick={handleupper}>
                                Referral program
                            </Link>
                        </p>
                    </div>


                    <div className="col-lg-2 col-md-6 col-12 mb-4 mb-lg-0">
                        <h5 className="site-footer-title mb-3">Contact Us</h5>

                        <p className="text-white d-flex">
                            <Link  to={Authtoken ? "/support" : "/user_support"}  className="site-footer-link"onClick={handleupper}>
                                User Support
                            </Link>
                        </p>
                        <p className="text-white d-flex">
                            <Link to="/grievance_redressal_principal_officer" className="site-footer-link"onClick={handleupper}>
                                Grievance redressal
                            </Link>
                        </p>
                        <p className="text-white d-flex">
                            <Link to="/product_feedback_suggestions" className="site-footer-link"onClick={handleupper}>
                                Product Feedback & Suggestions
                            </Link>
                        </p>
                        <p className="text-white d-flex">
                            <Link to="/lea_legal" className="site-footer-link"onClick={handleupper}>
                                Lea/Legal
                            </Link>
                        </p>

                        <h5 className="site-footer-title mb-3 pt-4">Download App</h5>
                        <div className="dn_app_f" >
                            <Link to={weburl} target="_blank">
                                <img src="assets/images/play_store.png" className="img-fluid" alt="" />
                            </Link>
                            <Link to={weburl}>
                                <img src="assets/images/app_store.png" className="img-fluid" alt="" />
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
            <div className="site-footer-disclamer">
                <div className="container">
                    <p className=" mb-0">
                        <strong>
                            Disclaimer</strong>
                        Crypto products & NFTs are unregulated and can be highly risky. There may be no regulatory recourse for any loss from such transactions.
                        The information and material contained herein are subject to change without prior notice including prices which may fluctuate
                        based on market demand and supply. The material available on the site is proprietary to <b className="fw-bold" >Crypto India</b>, its parent, Licensor and/or
                        its affiliates and is for informational purposes and informed investors only. This material is not: (i) an offer, or solicitation
                        of an offer, to invest in, or to buy or sell, any interests or shares, or to participate in any investment or trading strategy, or
                        (ii) intended to provide accounting, legal, or tax advice, or investment recommendations.
                    </p>
                </div>
            </div>
            <div className="site-footer-bottom">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-12  text-center">
                            <p className="copyright-text mb-0"> Copyright © 2024 <span className=" fw-bold" >Crypto India</span>. All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    )
}

export default Footer