import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { alertErrorMessage } from '../../../Services/CustomAlertModel';
import { ProfileContext } from '../../../Services/ProfileProvider';
import Swal from 'sweetalert2';
import FilterBar from '../FilterBar';
import { SocketContext } from '../../../Context/SocketContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

const Header = () => {

    const [toggled, setToggled] = React.useState(false);

    const Authtoken = localStorage.getItem("token");
    const { setLoginDetails, maskedEmail, userDetails } = useContext(ProfileContext);
    const navigate = useNavigate();
    const [isSticky, setIsSticky] = useState(false);
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const location = useLocation();
    const KycStatus = userDetails?.user?.kycVerified;
    const [showComponent, setShowComponent] = useState(false);
    const [showComponentClose, setShowComponentClose] = useState(false);
    const [socketData, setSocketData] = useState([]);
    const { socket } = useContext(SocketContext);

    const chunkArray = (array, chunkSize) => {
        const result = [];
        for (let i = 0; i < array?.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    };

    const chunkedData = chunkArray(socketData, 5);

    useEffect(() => {
        if (socket) {
            let payload = {
                'message': 'market',
            };
            socket.emit('message', payload);
            socket.on('message', (data) => {
                setSocketData(data?.hot);
            });
        }
    }, [socket, chunkedData]);



    useEffect(() => {
        setShowComponentClose(true)

        const timer = setTimeout(() => {
            setShowComponent(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);



    const handleModalClose = () => {
        setShowComponent(false);
        setShowComponentClose(false);
        setTimeout(() => {
            setShowComponent(true);
            setShowComponentClose(true);
        }, 10000);
    };



    useEffect(() => {
        function handleScroll() {
            setIsSticky(window.scrollY > 88);
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const showAlert = () => {
        Swal.fire({
            title: "Are you sure?",
            text: `You are about to log out`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#e64942",
            cancelButtonColor: "#e7e7e7",
            confirmButtonText: "Yes Logout!"
        }).then((result) => {
            if (result.isConfirmed) {
                tokenExpire()
            }
        });
    };


    const tokenExpire = () => {
        alertErrorMessage('You are logged out..!!');
        localStorage.clear();
        navigate("/");
        setLoginDetails({})
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleIsActive = (e) => {
        e.preventDefault();
        setMobileMenuOpen(prevState => !prevState);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <>

            {socketData && socketData?.length > 5 && (
                <div className="price_bar">

                    <Swiper
                        slidesPerView={'auto'}
                        className="price_swiper"
                        loop={socketData?.length >= 3}
                        speed="2000"
                        autoplay={{
                            delay: 0,
                            pauseOnMouseEnter: true,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                    >
                        {socketData && socketData.map((item, index) => (
                            <SwiperSlide key={index}  >
                                <span className='prica_bar' >
                                    {`${item.base_currency}/${item.quote_currency}`}&nbsp;&nbsp;
                                    <span className={item?.change >= 0 ? "text-success" : "text-danger"}>({item.change >= 0 ? '+' : ''}{item.change.toFixed(2)}%)</span>
                                </span>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
            <div id="sticky-wrapper" className={`sticky-wrapper ${isSticky ? 'is-sticky' : ''}`} style={{ Height: "88px" }}>
                <nav className={`navbar navbar-expand-lg ${Authtoken && 'nav_sticky'}`}>
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                            <img src="/logo.svg" className="img-fluid" alt="Cryto India" />
                        </Link>
                        <div className={`collapse navbar-collapse justify-content-center ${isMobileMenuOpen ? 'show' : ''}`} id="navbarNav">
                            <ul className="navbar-nav align-items-lg-center">
                                <li className="nav-item" onClick={handleIsActive}>
                                    <Link className={`nav-link click-scroll ${location?.pathname === "/" && 'active'}`} to="/" >Home</Link>
                                </li>

                                <li className="nav-item" onClick={handleIsActive}>
                                    <Link className={`nav-link click-scroll ${location?.pathname === "/markets" && 'active'}`} to="/markets"> Markets </Link>
                                </li>

                                <li className="nav-item" onClick={handleIsActive}>
                                    <Link className={`nav-link click-scroll ${location?.pathname === "/buy_sell" && 'active'}`} to="/buy_sell" >INR/USDT</Link>
                                </li>
                                <li className="nav-item dropdown main_dropdown"  >
                                    <Link className={`nav-link click-scroll dropdown-toggle  ${location.pathname.startsWith("/exchange/") && 'active'}`} to="/?" id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown" aria-expanded="false"> Trade </Link>
                                    <ul className="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
                                        <li onClick={handleIsActive}>
                                            <Link className="dropdown-item" to="/exchange/:pairName">
                                                <div className="d-flex  align-items-start">
                                                    <i className="ri-exchange-2-line me-2"></i>
                                                    <div>
                                                        <span> Spot </span>
                                                        <small>Buy and sell on the Spot market</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                        <li onClick={handleIsActive}>
                                            <Link className="dropdown-item disabled" to="/coming_soon">
                                                <div className="d-flex  align-items-center">
                                                    <i className="ri-p2p-line  me-2"></i>
                                                    <div>
                                                        <span>P2P Trading</span>
                                                        <small>Bank transfer and more options</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item dropdown main_dropdown"  >
                                    <Link className="nav-link click-scroll dropdown-toggle" to="/?" id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown" aria-expanded="false"> Business </Link>
                                    <ul className="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
                                        <li onClick={handleIsActive}>
                                            <Link className="dropdown-item" to="/market_maker_program">
                                                <div className="d-flex  align-items-start">
                                                    <div>
                                                        <span> Market Maker Program </span>
                                                        <small> Market Maker Program for our OTC Desk </small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                        <li onClick={handleIsActive}>
                                            <Link className="dropdown-item" to="/coin_list">
                                                <div className="d-flex  align-items-start">
                                                    <div>
                                                        <span> List Your Coin</span>
                                                        <small>List Your Coin on Crypto India Exchange</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                        <li onClick={handleIsActive}>
                                            <Link className="dropdown-item" to="/partnership_inquiry">
                                                <div className="d-flex  align-items-start">
                                                    <div>
                                                        <span>Partnership Inquiry </span>
                                                        <small>Explore new opportunities for collaboration</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                        <li onClick={handleIsActive}>
                                            <Link className="dropdown-item" to={Authtoken ? '/referral' : '/login'}>
                                                <div className="d-flex  align-items-start">
                                                    <div>
                                                        <span> Referral Program </span>
                                                        <small> Refer your friends to Join Crypto India Exchange  </small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown main_dropdown"  >
                                    <Link className="nav-link click-scroll dropdown-toggle" to="/?" id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown" aria-expanded="false"> About Us </Link>
                                    <ul className="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
                                        <li onClick={handleIsActive}>
                                            <Link className="dropdown-item" to="/company_management">
                                                <div className="d-flex  align-items-start">
                                                    <div>
                                                        <span> Our Company </span>
                                                        <small> Know more about Crypto India exchange </small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                        <li onClick={handleIsActive}>
                                            <Link className="dropdown-item" to="/career">
                                                <div className="d-flex  align-items-start">
                                                    <div>
                                                        <span> Career </span>
                                                        <small> Explore career opportunities with us </small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                        <li onClick={handleIsActive}>
                                            <Link className="dropdown-item" to="/coming_soon">
                                                <div className="d-flex  align-items-start">
                                                    <div>
                                                        <span> Blog </span>
                                                        <small> Learn more about crypto world </small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown main_dropdown"  >
                                    <Link className="nav-link click-scroll dropdown-toggle" to="/?" id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown" aria-expanded="false">   Support </Link>
                                    <ul className="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
                                        <li onClick={handleIsActive}>
                                            <Link className="dropdown-item" to="/user_support">
                                                <div className="d-flex  align-items-start">
                                                    <div>
                                                        <span>   User Support </span>
                                                        <small> We available 24x7 for your assistance  </small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                        <li onClick={handleIsActive}>
                                            <Link className="dropdown-item" to="/lea_legal">
                                                <div className="d-flex  align-items-start">
                                                    <div>
                                                        <span>  LEA/Legal </span>
                                                        <small>Government law enforcement request system</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="nav_right">
                            {
                                Authtoken ?
                                    <div className='d-flex align-items-center justify-content-end '>
                                        <FilterBar />
                                        <div className='dropdown headr_drop ' >
                                            <a href='/?' className="avatar-info avatar-hover" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
                                                <i className="ri-user-3-line "></i>
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <div className='us_data mb-1'>
                                                    <p>{maskedEmail}</p>
                                                    <span className={`badge ${userDetails?.user?.kycVerified === 2 ? "badge-success" : userDetails?.user?.kycVerified === 1 ? "badge-warning bg-warning" : userDetails?.user?.kycVerified === 0 ? "badge-warning bg-warning" : "badge-danger"}`} >

                                                        <i className={userDetails?.user?.kycVerified === 2 ? "ri-check-double-line" : userDetails?.user?.kycVerified === 1 ? "ri-error-warning-line me-2" : userDetails?.user?.kycVerified === 0 ? "ri-error-warning-line me-2" : "ri-close-circle-line"} >
                                                        </i>&nbsp;

                                                        <Link to={userDetails?.user?.kycVerified == 2 ? "#" : '/verify_kyc'}>
                                                            {userDetails?.user?.kycVerified == 2 ? "Verified" : userDetails?.user?.kycVerified == 1 ? "Verification Pending" : userDetails?.user?.kycVerified == 0 ? "SUBMIT KYC" : "Verification Failed!"}
                                                        </Link>
                                                    </span>
                                                </div>
                                                <div className='p-1' >
                                                    <li>
                                                        <Link className="dropdown-item" to="/dashboard">
                                                            <i className="ri-home-6-line"></i> Dashboard
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link className="dropdown-item" to="/wallet">
                                                            <i className="ri-wallet-line"></i>Assets
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link className="dropdown-item" to="/spot_order">
                                                            <i className="ri-file-list-3-line"></i> Orders
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link className="dropdown-item" to="/personal_information">
                                                            <i className="ri-user-6-line"></i> Account
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link className="dropdown-item" to="/referral">
                                                            <i className="ri-user-shared-line"></i>  Referral
                                                        </Link>
                                                    </li>
                                                </div>
                                                <li className='border-top' ></li>
                                                <li className='p-1' ><span className="dropdown-item cursor-pointer" onClick={showAlert}>
                                                    <i className="ri-logout-box-r-line "></i> <b>Logout</b></span></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <Link to='/wallet' className="avatar-info avatar-hover">
                                                <i className="ri-wallet-line"></i>
                                            </Link>
                                        </div>
                                        <div className='dropdown headr_drop app_dn-drop' >
                                            <a href='/?' className="avatar-info avatar-hover" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
                                                <i className="ri-mobile-download-line "></i>
                                            </a>
                                            <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton1">
                                                <div className='nofti_header'>
                                                    <p> Download App iOS & Android </p>
                                                </div>
                                                <div className="dn_app_f">
                                                    <a href="#" ><img src="/assets/images/play_store.png" className="img-fluid" alt="play_store" /> </a>
                                                    <a href="#" > <img src="/assets/images/app_store.png" className="img-fluid" alt="app_store" /> </a>
                                                </div>

                                            </ul>
                                        </div>
                                        <button className="navbar-toggler ms-2" onClick={() => setToggled(!toggled)}> <span className="navbar-toggler-icon"></span> </button>
                                    
                                    </div>
                                    :
                                    <div className='d-flex align-items-center justify-content-end'>
                                        <Link to="/login" className="btn custom-btn customsignup customsignup-white ms-2" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                            <i className="ri-user-line me-2"></i>
                                            <span>Login</span>
                                        </Link>
                                        <Link to="/signup" className="btn custom-btn customsignup customsignup-white ms-2" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                            <i className="ri-user-add-line me-2"></i>
                                            <span>Sign up</span>
                                        </Link>
                                        <div className='dropdown headr_drop app_dn-drop' >
                                            <a href='/?' className="avatar-info " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
                                                <i className="ri-mobile-download-line outline-warning"></i>
                                            </a>
                                            <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton1">
                                                <div className='nofti_header'>
                                                    <p> Download App iOS & Android </p>
                                                </div>
                                                <div className="dn_app_f">
                                                    <a href="#" ><img src="/assets/images/play_store.png" className="img-fluid" alt="" /> </a>
                                                    <a href="#" > <img src="/assets/images/app_store.png" className="img-fluid" alt="" /> </a>
                                                </div>

                                            </ul>
                                        </div> 
                                        <button className="navbar-toggler ms-2" onClick={() => setToggled(!toggled)}> <span className="navbar-toggler-icon"></span> </button>
                                    </div>
                            }
                        </div>
                    </div>
                </nav>



            </div>


            <div className='d-flex' >
                {/* new submenu for monile */}
                <Sidebar onBackdropClick={() => setToggled(false)} toggled={toggled} breakPoint="always">

                    <div className='ss_logo' >
<img src="./assets/images/logo.svg" className='img-fluid' alt='' />
                    </div>

                    <Menu>
                        <MenuItem to="www.google.com" >
                            <Link  onClick={() => setToggled(!toggled)} className={`nav-link click-scroll ${location?.pathname === "/" && 'active'}`} to="/" >Home</Link>
                        </MenuItem>
                        <MenuItem>
                            <Link onClick={() => setToggled(!toggled)} className={`nav-link click-scroll ${location?.pathname === "/markets" && 'active'}`} to="/markets"> Markets </Link> </MenuItem>
                        <MenuItem>
                            <Link onClick={() => setToggled(!toggled)} className={`nav-link click-scroll ${location?.pathname === "/buy_sell" && 'active'}`} to="/buy_sell" >INR/USDT</Link>
                        </MenuItem>
                        <SubMenu label="Trade">
                            <MenuItem> <Link onClick={() => setToggled(!toggled)} className={`nav-link click-scroll ${location?.pathname === "/buy_sell" && 'active'}`}  to="/exchange/:pairName"> Spot Trading </Link> </MenuItem>
                            <MenuItem> <Link onClick={() => setToggled(!toggled)} className={`nav-link click-scroll ${location?.pathname === "/buy_sell" && 'active'}`}  to="/exchange/:pairName"> P2P Trading </Link> </MenuItem>
                        </SubMenu>
                        <SubMenu label="Business">
                            <MenuItem>   <Link onClick={() => setToggled(!toggled)} className={`nav-link click-scroll ${location?.pathname === "/buy_sell" && 'active'}`} to="/market_maker_program" >  Market Maker Program </Link> </MenuItem>
                            <MenuItem>   <Link onClick={() => setToggled(!toggled)} className={`nav-link click-scroll ${location?.pathname === "/buy_sell" && 'active'}`} to="/coin_list" >  List Your Coin </Link> </MenuItem>
                            <MenuItem>   <Link onClick={() => setToggled(!toggled)} className={`nav-link click-scroll ${location?.pathname === "/buy_sell" && 'active'}`} to="/partnership_inquiry" > Partnership Inquiry </Link> </MenuItem>
                            <MenuItem>   <Link onClick={() => setToggled(!toggled)} className={`nav-link click-scroll ${location?.pathname === "/buy_sell" && 'active'}`} to={Authtoken ? '/referral' : '/login'} >  Referral Program  </Link> </MenuItem>
                        </SubMenu>
                        <SubMenu label="About Us">
                            <MenuItem>   <Link onClick={() => setToggled(!toggled)} className={`nav-link click-scroll ${location?.pathname === "/buy_sell" && 'active'}`} to="/company_management" >   Our Company   </Link> </MenuItem>
                            <MenuItem>   <Link onClick={() => setToggled(!toggled)} className={`nav-link click-scroll ${location?.pathname === "/buy_sell" && 'active'}`} to="/career" >   Career  </Link> </MenuItem>
                            <MenuItem>   <Link onClick={() => setToggled(!toggled)} className={`nav-link click-scroll ${location?.pathname === "/buy_sell" && 'active'}`} to="/coming_soon" >   Blog  </Link> </MenuItem>
                        </SubMenu>
                        <SubMenu label="Support">
                            <MenuItem>   <Link onClick={() => setToggled(!toggled)} className={`nav-link click-scroll ${location?.pathname === "/buy_sell" && 'active'}`} to="/user_support" > User Support      </Link> </MenuItem>
                            <MenuItem>   <Link onClick={() => setToggled(!toggled)} className={`nav-link click-scroll ${location?.pathname === "/buy_sell" && 'active'}`} to="/lea_legal" >  LEA/Legal    </Link> </MenuItem> 
                        </SubMenu>
                    </Menu>
                </Sidebar>
            </div>




            {/* alert modal */}
            {location?.pathname !== "/login" && location?.pathname !== "/signup" && location?.pathname !== "/verify_kyc" && location?.pathname !== "/buy_sell" && location?.pathname !== "/forgot_password" && location?.pathname !== "/entity-signup" && (
                Authtoken && (KycStatus === 0 || KycStatus === 3) && showComponentClose ? (
                    <div className="ls_md kyc_modal active">


                        <div className="ls_container">
                            <div className="kc_img">
                                <img src="/assets/images/kyc_img.svg" className="img-fluid" alt="KYC" />
                            </div>

                            <div className="kc_row">
                                <div className="kyc_cn">
                                    {KycStatus === 1 ? (
                                        <h4>KYC Verification Pending</h4>
                                    ) : KycStatus === 0 ? (
                                        <h4>Be 1 Step Ahead to Complete Your KYC!</h4>
                                    ) : (
                                        <h4>KYC Rejected, Verify Again</h4>
                                    )}
                                    <p>Complete your KYC first to start trading with Crypto India!</p>
                                </div>
                                <div className="kyc_btn">
                                    {
                                        showComponent &&
                                        <span className="md_close cursor-pointer" onClick={handleModalClose}>
                                            <i className="ri-close-fill"></i>
                                        </span>
                                    }

                                    <Link
                                        to={KycStatus === 1 ? "" : "/verify_kyc"}
                                        className={`btn ${KycStatus === 1 || KycStatus === 0 ? "btn-warning" : "btn-danger"}`}
                                    >
                                        Complete KYC
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    !Authtoken && showComponentClose && (
                        <div className="ls_md active">

                            <div className="ls_container">
                                <div className='d-flex align-items-center justify-content-between' >
                                    <p className='text-start' >Get Started with Crypto India!</p>
                                    {
                                        showComponent &&
                                        <span className="md_close cursor-pointer" onClick={handleModalClose}>
                                            <i className="ri-close-fill"></i>
                                        </span>
                                    }
                                </div>
                                <div className="btn_row">
                                    <Link className="btn custom-btn customsignup customsignup-white me-1" to="/login">
                                        <i className="ri-user-line me-2"></i>
                                        <span>Login</span>
                                    </Link>
                                    <Link className="btn custom-btn ms-1" to="/signup">
                                        <i className="ri-user-line me-2"></i>
                                        <span>Sign up</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )
                )
            )}
        </>
    )
}

export default Header