import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../Footer";


const LeaLegal = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])
    
    return (
        <>
            <main>
                <section class="tm_sec">
                    <div class="container">
                        <div class="row   mt-md-5 justify-content-center">
                            <div class="col-lg-10">

                                <div class="g_start m_card overflow-hidden p-0 ">
                                    <div class="p_card p-md-5">
                                        {/* step1 */}
                                        <div className="step_1" >

                                            <h3>
                                                Government Law Enforcement Request System
                                            </h3>

                                            <p>For Government and Law Enforcement Agencies only</p>

                                            <p>Welcome to the Government Law Enforcement Request System (LERS). Government and law enforcement agencies can use this system to submit information requests. Crypto India will review each case and cooperate on a case-by-case basis to disclose information as legally required, in accordance with our Terms of Use and applicable laws.</p>


                                            <p>Please submit your inquiry on our Law Enforcement Portal. you will need to register on the platform. After registration, you will be able to submit your requests, keep track of your cases, and access all relevant information.</p>

                                            <p> Additionally, be aware that agency verifications submitted with the use of VPNs will not be completed. </p>
                                        </div>
                                        <br />
                                        <br />
                                        {/* step 2*/}
                                        <div className="step_2 " >
                                            <div class="row">
                                                <div class="col-md-12 mb-4">

                                                    <h6 className="mb-3" >
                                                        1. Agency/Department Verification
                                                    </h6>

                                                    <div class="field-box mb-3">
                                                        <label for="text" class="form-label">Emai Address  </label>
                                                        <div class="field-otp-box mb-2">
                                                            <input type="text" class="form-control" name="subject" />
                                                        </div>
                                                        <p className="mb-0" > <small>Please enter your agnecy email address.</small></p>
                                                        <p className="mb-0" > <small>By Clicking an account, you acknowladge and accept out Terms of Services and Privacy Policy.</small></p>
                                                    </div>
                                                </div>
                                                <br />
                                                <div class="col-md-12 mb-4">

                                                    <h6 className="mb-3 mt-4" >
                                                        2. Agency/Department Details
                                                    </h6>

                                                    <div class="field-box mb-3">
                                                        <label for="text" class="form-label"> Agency/Department Name    </label>
                                                        <div class="field-otp-box ">
                                                            <input type="text" class="form-control" name="subject" />
                                                        </div>
                                                    </div>
                                                    <div class="field-box mb-3">
                                                        <label for="text" class="form-label"> Branch Name </label>
                                                        <div class="field-otp-box ">
                                                            <input type="text" class="form-control" name="subject" />
                                                        </div>
                                                    </div>
                                                    <div class="field-box mb-3">
                                                        <label for="text" class="form-label"> Location Branch Address</label>
                                                        <div class="field-otp-box">
                                                            <input type="text" class="form-control" name="subject" />
                                                        </div>
                                                    </div>
                                                    <div class="field-box mb-3">
                                                        <label for="text" class="form-label"> Location/Branch Country </label>
                                                        <div class="field-otp-box ">
                                                            <input type="text" class="form-control" name="subject" />
                                                        </div>
                                                    </div>
                                                    <div class="field-box mb-3">
                                                        <label for="text" class="form-label"> Postel/Zip Code </label>
                                                        <div class="field-otp-box ">
                                                            <input type="text" class="form-control" name="subject" />
                                                        </div>
                                                    </div>

                                                </div>
                                                <br />
                                                <div class="col-md-12 mb-4">

                                                    <h6 className="mb-3 mt-4" >
                                                        3 . Add  Agent Identification Documents
                                                    </h6>
                                                    <div class="field-box mb-3">
                                                        <p className="mb-0" > <small>If you would like to expedite your verification, upload three photos of your badge/ID: </small> </p>
                                                        <ul className="lea_ol mb-3" >
                                                            <li> A close up picture of the front of your badge/ID</li>
                                                            <li> A close up picture of the back of your badge/ID</li>
                                                            <li> A selfie of you holding your badge/ID, ensuring your ID picture is clearly visible.</li>
                                                        </ul>
                                                        <p><small>Please use .jpg, .jpeg, or .png formats. No scans or altered images will be accepted. Documents are stored
                                                            encrypted.</small></p>
                                                        <p className="mb-0" > <small>By Clicking an account, you acknowladge and accept out Terms of Services and Privacy Policy.</small></p>
                                                    </div>
                                                    <div class="field-box mb-3">
                                                        <label for="text" class="form-label"> Documents </label>
                                                        <div class="field-otp-box">
                                                            <input type="file" class="form-control" name="subject" multipl />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-12 mb-4">
                                                        <label class="form-label mb-1">Please provide details about your inquiry
                                                        </label>
                                                        <div class=" field-otp-box">
                                                            <textarea class="form-control" rows="4" placeholder="Type message here" name="description"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <div class="col-md-12 mb-4">

                                                    <h6 className="mb-3 mt-4" >
                                                        4. Personal Information
                                                    </h6>

                                                    <div className="row" >
                                                        <div className="col-lg-12" >
                                                            <div class="field-box mb-3">
                                                                <label for="text" class="form-label">  Designation/Post    </label>
                                                                <div class="field-otp-box ">
                                                                    <input type="text" class="form-control" name="subject" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4" >
                                                            <div class="field-box mb-3">
                                                                <label for="text" class="form-label"> First Name    </label>
                                                                <div class="field-otp-box ">
                                                                    <input type="text" class="form-control" name="subject" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div class="field-box mb-3">
                                                                <label for="text" class="form-label">  Middle Name </label>
                                                                <div class="field-otp-box ">
                                                                    <input type="text" class="form-control" name="subject" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div class="field-box mb-3">
                                                                <label for="text" class="form-label">  Last Name </label>
                                                                <div class="field-otp-box ">
                                                                    <input type="text" class="form-control" name="subject" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field-box mb-3">
                                                        <label for="text" class="form-label"> Email Address </label>
                                                        <div class="field-otp-box ">
                                                            <input type="text" class="form-control" name="subject" />
                                                        </div>
                                                    </div>
                                                    <div class="field-box mb-3">
                                                        <label for="text" class="form-label"> Phone Number </label>
                                                        <div class="field-otp-box ">
                                                            <input type="text" class="form-control" name="subject" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <div class="col-md-12 mb-4">

                                                    <h6 className="mb-3 mt-4" >
                                                        5 . Summary
                                                    </h6>
                                                    <div class="field-box ">
                                                        <p className=" " > <small>If you are a law enforcement agent, member of a carrier partner, a law firm, litigator, or emergency
                                                            responder who is authorized to gather evidence in connection with an official investigation or in
                                                            order to investigate an emergency involving the danger of serious physical injury or death, you may
                                                            request records through this system.</small> </p>

                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label class="form-check-label" for="flexCheckDefault">
                                                                <small>I am an authorized law enforcement agent, carrier partner, a law firm, litigator, or
                                                                    government employee conducting an official investigation, and this is an official request</small>
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault2" />
                                                            <label class="form-check-label" for="flexCheckDefault2">
                                                                <small>I have read and agree to the Crypto India  Terms of Service and Privacy Policy..</small>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <button class="btn w-auto px-5 btn-lg btn-dark" type="button">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>




                        </div>








                    </div>
                </section>
            </main>

            <Footer />
        </>

    )
}

export default LeaLegal