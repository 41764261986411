import React from 'react';



const UserPolicy = () => {
    return (
        <h5>UserPolicy</h5>
    )
}

export default UserPolicy