import React, { useEffect, useState } from "react";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import { alertErrorMessage } from "../../../../Services/CustomAlertModel";
import AuthService from "../../../../Services/ApiServices/AuthService";
import moment from "moment";

const RecentDevices = () => {




    const [devicesData, setDevicesData] = useState([]);
    const [totalDataLength, setTotalDataLength] = useState([]);
    const [skip, setSkip] = useState(0);
    const limit = 10;

    const handleAccountActivity = async (skip, limit) => {
        try {
            const result = await AuthService.accountActivities(skip, limit);
            if (result?.success) {
                setDevicesData(result?.data);
                setTotalDataLength(result?.total)
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        handleAccountActivity(skip, limit);
    }, [skip, limit]);


    const handlePagination = (action) => {
        if (action === 'prev') {
            if (skip - limit >= 0) {
                setSkip(skip - limit);
            }
        } else if (action === 'next') {
            if (skip + limit < totalDataLength) {
                setSkip(skip + limit);
            }
        } else if (action === 'first') {
            setSkip(0);
        } else if (action === 'last') {
            const lastPageSkip = Math.max(0, totalDataLength - limit);
            setSkip(lastPageSkip);
        }
    };

    return (
        <main>
            <div className="page_wrapper">
                <div className="sidebar">
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper">
                    <section className="d_page m_account history_page">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xxl-12 col-xl-12">
                                    <h6>Recent Devices</h6>
                                    <div className="g_start m_card overflow-hidden p-2">
                                        <div className="m_table">
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Date/Time</th>
                                                            <th>Device</th>
                                                            <th>Operating System</th>
                                                            <th>Platform</th>
                                                            <th>Browser</th>
                                                            <th>IP Address</th>
                                                            {/* <th>Actions</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            devicesData?.length > 0 ?
                                                                devicesData.map((item, index) => (

                                                                    <tr key={index}>
                                                                        <td>{moment(item?.device?.createdAt).format("lll")}</td>
                                                                        <td>
                                                                            <div className="c_view">
                                                                                <i className="ri-smartphone-line c_icon"></i>
                                                                                {item.device?.isDesktop ? "Desktop" : "Mobile"}
                                                                            </div>
                                                                        </td>
                                                                        <td>{item.device?.os}</td>
                                                                        <td>{item.device?.platform}</td>
                                                                        <td>{item.device?.browser}</td>
                                                                        <td>{item.device?.version}</td>

                                                                        {/*   <td>
                                                                    <button className="btn btn-dark btn-md">Logout</button>
                                                                </td> */}
                                                                    </tr>
                                                                ))
                                                                :
                                                                <tr>
                                                                    <td colSpan="6">
                                                                        <div className="favouriteData">
                                                                            <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                        }



                                                    </tbody>
                                                </table>
                                                {
                                                    devicesData?.length > 0 &&
                                                    <div className="hVPalX gap-2 " >
                                                        <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                                        <div className="sc-eAKtBH gVtWSU">
                                                            <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                                </svg>
                                                            </button>
                                                            <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                </svg>
                                                            </button>

                                                            <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                </svg>
                                                            </button>
                                                            <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
};

export default RecentDevices;
