import React from 'react';

const RoadMap = () => {

    return (
        <section className="how_it_sec">
        <div className="container">
            {/* <div className="xx_title">
                <h2 className="text-uppercase text-warning">Roadmap</h2>
            </div> */}
            <div className="sec_title mb-5"> 
                <h2 className="text-dark text-center "> How it works </h2>
                <p>
                Users buy or sell crypto with fiat or other coins on a secure online platform in just simple 3 steps.
                </p>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row gx-md-5  ">
                        <div className="col-lg-4">
                            <div className="how_it step_1  ">
                                <div className="how_card hiw_card_bar  text-center">
                                    <div className='thumb m-auto' >
                                        <img src="/assets/images/user_login.svg" className="img-fluid mx-auto" />
                                    </div>
                                    <div className="">
                                        <h4 className="text-uppercase">Step 1</h4>
                                        <h6>
                                            Sign up
                                        </h6>
                                        <p className="mb-0">Sign up your account with Your 
                                            email <br/> id and phone number. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="how_it step_2   ">
                                <div className="how_card hiw_card_bar  text-center ">
                                <div className='thumb m-auto' >
                                        <img src="/assets/images/user_kyc.svg" className="img-fluid  mx-auto" />
                                    </div>
                                    <div className="">
                                        <h4 className="text-uppercase">Step 2</h4>
                                        <h6>
                                            Complete Your KYC
                                        </h6>
                                        <p className="mb-0">Complete the identity verification process to unlock all features of your account and
                                            transactions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="how_it step_3 ">
                                <div className="how_card  text-center ">
                                    <div className='thumb m-auto' >
                                        <img src="/assets/images/start_trading.svg" className="img-fluid   mx-auto" />
                                    </div>
                                    <div className="">
                                        <h4 className="text-uppercase">Step 3</h4>
                                        <h6>
                                            Start Trading
                                        </h6>
                                        <p className="mb-0">You're good to go, Buy/Sell Crypto for your investments
                                            and discover what Crypto India has to Offer.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    )
}

export default RoadMap