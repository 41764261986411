import React from "react";
const SpinLoader = () => {
    return (
        <div class="spinner-line  ">
            <div class="spinner-line-blade"></div>
            <div class="spinner-line-blade"></div>
            <div class="spinner-line-blade"></div>
            <div class="spinner-line-blade"></div>
            <div class="spinner-line-blade"></div>
            <div class="spinner-line-blade"></div>
            <div class="spinner-line-blade"></div>
            <div class="spinner-line-blade"></div>
            <div class="spinner-line-blade"></div>
            <div class="spinner-line-blade"></div>
            <div class="spinner-line-blade"></div>
            <div class="spinner-line-blade"></div>
        </div>
    );
};

export default SpinLoader;
