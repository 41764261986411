import React from "react";
import Routing from "./Services/Routing";
import { ProfileProvider } from "./Services/ProfileProvider";
import Loading from "./Services/Loading";
import LoaderHelper from "./Services/Loading/LoaderHelper";
import { ToastContainer } from 'react-toastify';
import SocketContextProvider from "./Context/SocketContext";

function App() {
  return (
    <ProfileProvider>
      <SocketContextProvider>
        <Routing />
        <Loading ref={ref => LoaderHelper.setLoader(ref)} />
        <ToastContainer />
      </SocketContextProvider>
    </ProfileProvider>
  );
}

export default App;
