
import html2pdf from 'html2pdf.js';
import moment from 'moment/moment';

export const cryptAssetsPdf = async () => {
    
  
    

    try {

        const htmlContent =
            `
             <div> 
           <style type="text/css">
              @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
              body {
                margin: 0;
                padding: 0;
                background: #ffffff;
              } 
              div,
              p,
              a,
              li,
              td {
                -webkit-text-size-adjust: none;
              }
            
              .ReadMsgBody {
                width: 100%;
                background-color: #ffffff;
              }
            
              .ExternalClass {
                width: 100%;
                background-color: #ffffff;
              }
            
              body {
                width: 100%;
                height: 100%;
                background-color: #ffffff;
                margin: 0;
                padding: 0;
                -webkit-font-smoothing: antialiased;
              }
            
              html {
                width: 100%;
              }
            
              p {
                padding: 0 !important;
                margin-top: 0 !important;
                margin-right: 0 !important;
                margin-bottom: 0 !important;
                margin-left: 0 !important;
              }
            
              .visibleMobile {
                display: none;
              }
            
              .hiddenMobile {
                display: block;
              }
              .table-bordered >* {
                border-width: 1px 1px;
                border-color: black;
              }
              .table-bordered  >* {
                  padding: .5rem .5rem; 
              }
            </style>
            
            
            </head><body> 
            
            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable" bgcolor="#ffffff">
              <tbody>
                <tr> 
                  <td>
                    <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" class="fullTable" bgcolor="#ffffff">
                      <tbody>
                        <tr>
                        </tr><tr class="hiddenMobile">
                          <td height="30"></td>
                        </tr>
                        <tr class="visibleMobile">
                          <td height="20"></td>
                        </tr>
                        <tr>
                          <td>
                            <table   width="720" cellpadding="2"  align="center"  >
                              <tbody>  
                                <tr>
                                  <td  colspan="2"  style="font-size: 22px; font-weight: 600; color: #000; font-family: 'Open Sans', sans-serif; line-height: 20px; vertical-align: middle; text-align: center;">
                                    Company Letterhead
                                  </td>
                                </tr> 
                                <tr>
                                  <td height="20" colspan="2"></td>
                                </tr>
                                <tr>
                                  <td height="1" colspan="2" style="border-bottom:1px solid #a8a8a8"></td>
                                </tr>


                                
                                <tr>
                                  <td height="40" colspan="2"  ></td>
                                </tr>

                                
                                <tr>
                                  <td  colspan="2"   style=" text-decoration: underline; font-size: 18px; font-weight: 600; color: #000; font-family: 'Open Sans', sans-serif; line-height: 20px; vertical-align: middle; text-align: center;">
                                    DECLARATION OF SOURCE OF FUNDS
                                  </td>
                                </tr>   

                                <tr>
                                  <td height="20" colspan="2"></td>
                                </tr> 

                                <tr>
                                  <td  height="40" style="  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " class="article">
                                    Date: ………………...
                                  </td>
                                  <td  height="40"> </td>
                                </tr>
                                <tr>
                                  <td colspan="2"    style="  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; line-height: 25px;" class="article">
                                    I, <b>[Director's Name]</b>, in my capacity as the Director of <b>[Company's Name]</b>, hereby declare
                                    and affirm that all funds will be transferred by our company to CRYPTO INDIA (INCRYP
                                    BLOCKCHAIN PRIVATE LIMITED) in INR (Indian Currency) and any other crypto
                                    currency, are obtained from legitimate and legal sources. The funds are fully compliant with
                                    all applicable laws, regulations, and guidelines, including Anti-Money Laundering (AML) and
                                    Counter-Terrorist Financing (CTF) regulation
                                  </td> 
                                </tr>
                                <tr>
                                  <td   colspan="2" height="40"    style="  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " class="article">
                                    We further declare that:
                                  </td> 
                                </tr>

                                <tr>
                                  <td colspan="2" height="40"    style="  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " class="article"> 
                                    1. Legitimate Source: All funds used in our company’s financial operations, including the
                                        acquisition of crypto assets, are free from any association with illegal activities such as money
                                        laundering, tax evasion, or terrorist financing. 
                                  </td>
                                </tr>

                                <tr><td colspan="2" height="10" ></td></tr>

                                <tr>
                                  <td colspan="2" height="40"    style="  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " class="article"> 
                                    2. Compliance with AML Regulations: Our company follows stringent internal controls and
                                    compliance measures, ensuring full adherence to AML, CTF, and Know Your Customer
                                    (KYC) guidelines as stipulated by regulatory authorities. We have systems in place to detect
                                    and prevent suspicious or unlawful financial activities.  
                                  </td>
                                </tr>

                                <tr><td colspan="2" height="10" ></td></tr>

                                <tr>
                                  <td colspan="2" height="40"    style="  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " class="article"> 
                                    3. Due Diligence: Our company undertakes regular due diligence on all counterparties and
                                    customers to verify the legitimacy of funds, consistent with legal requirements, before entering
                                    any transaction involving crypto assets or traditional financial services.
                                  </td>
                                </tr>

                                <tr><td colspan="2" height="10" ></td></tr>

                                <tr>
                                  <td colspan="2" height="40"    style="  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " class="article"> 
                                    4. Ethical Standards: Our company upholds the highest standards of ethical conduct in all
                                    financial dealings, ensuring that funds used in all transactions are derived from legitimate
                                    business activities and are compliant with domestic and international regulations.
                                  </td>
                                </tr>

                                <tr><td colspan="2" height="10" ></td></tr>

                                <tr>
                                  <td colspan="2" height="40"    style="  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " class="article"> 
                                    5. Cooperation with Authorities: In the event of any suspicion, inquiry, or investigation into
                                    the source of our company’s funds, we commit to full cooperation with law enforcement
                                    agencies, financial institutions, and regulatory authorities. We are prepared to provide all
                                    necessary documentation and information to verify the source of funds when required. 
                                  </td>
                                </tr> 

                                

                                <tr>
                                  <td  colspa="1" >
                                    <br>
                                    <table class="table table-bordered" style="border: 0; width=" 400px";"="" border="1" width="400" cellpadding="5" align="left"> 
                                      <tbody>   
                                        <tr> 
                                          <td colspan="2" height="80" width="400" style=" width=" 400px"="" font-size:="" 14px;="" font-weight:500;="" color:="" #000000;="" font-family:="" 'open="" sans',="" sans-serif;="" vertical-align:="" middle;="" "="" class="article">  
                                          </td>  
                                        </tr>   
                                          
                                      </tbody> 
                                    </table>
                                    <br> 
                                        
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="2" style=" text-align: left; font-size: 12px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " class="article">
                                    (Authorised Signatory – Signature & Stamp) 
                                  </td>
                                </tr> 

                                <tr>
                                  <td> 
                                    <table>
                                      <br>
                                      <tbody><tr> 
                                        <td height="30" style=" font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: top; "> 
                                          Name:.................................................................</td>
                                      </tr>
                                      <tr> 
                                        <td height="30" style=" font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: top; ">
                                          Mobile Number:................................................  </td>
                                      </tr>
                                      <tr> 
                                        <td height="30" style=" font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: top; "> 
                                          Date:....................................................................  </td>
                                      </tr>
                                    </tbody></table>
                                  </td>   
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        
                        <tr>
                          <td height="20"></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table> 


          </body></html>
            </div>

           `
        const element = document.createElement("div");
        element.innerHTML = htmlContent;
        await html2pdf()
            .from(element)
            .save(`CryptoAssetsDeclaration.pdf`);
    } catch (error) {
        console.error('Error generating PDF:', error);
    }
};
