import axios from "axios";
import LoaderHelper from "../../Loading/LoaderHelper";

export const ApiCallPost = async (url, parameters, headers) => {
  try {
    const response = await axios.post(url, parameters, { headers });
    return response.data;
  } catch (error) {
    LoaderHelper.loaderStatus(false);
    if (handleTokenExpiration(error)) return;
    return error?.response?.data?.message;
  }
};

export const ApiCallGET = async (url, headers) => {
  try {
    const response = await axios.get(url, { headers });
    return response?.data;
  } catch (error) {
    if (handleTokenExpiration(error)) return;
    return error?.response?.data?.message;
  }
};

export const ApiCallPut = async (url, parameters, headers) => {
  try {
    const response = await axios.put(url, parameters, { headers });
    return response?.data;
  } catch (error) {
    if (handleTokenExpiration(error)) return;
    return error?.response?.data?.message;
  }
};

export const ApiCallDelete = async (url, headers) => {
  try {
    const response = await axios.delete(url, { headers: headers });
    return response.data;
  } catch (error) {
    if (handleTokenExpiration(error)) return;
    return error?.response?.data?.message;
  }
};


const handleTokenExpiration = (error) => {
  const errorMessage = error?.response?.data?.message;
  if (errorMessage === 'Token is expired with message: jwt expired' || errorMessage === 'Token is expired with message: invalid signature') {
    localStorage.clear();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    window.location.href = '/login';
  }
};
