import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";


const FilterBar = () => {

    const [searchDetailData, setSearchDetailData] = useState('');
    const [filteredRoutes, setFilteredRoutes] = useState([]);

    const routes = [
        { name: 'Dashboard', path: '/dashboard' },
        { name: 'Buy Sell', path: '/buy_sell' },
        { name: 'Buy Sell Transactions', path: '/buy_sell_transactions' },
        { name: 'INR/USDT', path: '/buy_sell' },
        { name: 'Spot Order', path: '/spot_order' },
        { name: 'Transaction History', path: '/transaction_history' },
        { name: 'Personal Information', path: '/personal_information' },
        { name: 'Managing Features', path: '/managing_features' },
        { name: 'Verify Kyc', path: '/verify_kyc' },
        { name: 'Payments', path: '/payments' },
        { name: 'Bank', path: '/payments' },
        { name: 'Security', path: '/security' },
        { name: 'Nominee', path: '/add_nominee' },
        { name: 'Currency Preference', path: '/currency_preference' },
        { name: 'Change Password', path: '/change_password' },
        { name: 'Recent Devices', path: '/recent_devices' },
        { name: 'Activities', path: '/activities' },
        { name: 'Fee TDS Summary', path: '/account_summary' },
        { name: 'Account Statement', path: '/account_statement' },
        { name: 'Referral', path: '/referral' },
        { name: 'Referral List', path: '/referral-list' },
        { name: 'Notificatios', path: '/all_notificatios' },
        { name: 'Support', path: '/support' },
        { name: 'Deposit', path: '/deposit_funds' },
        { name: 'Withdrawal', path: '/withdraw_funds' },
        { name: 'Markets', path: '/markets' },
        { name: 'Trade', path: '/exchange/:pairName' },
        { name: 'Company Management', path: '/company_management' },
        { name: 'P2P', path: '/Peer_to_peer' },
    ];



    useEffect(() => {
        if (searchDetailData) {
            setFilteredRoutes(
                routes.filter(route =>
                    route.name.toLowerCase().startsWith(searchDetailData.toLowerCase())
                )
            );
        } else {
            setFilteredRoutes([]);
        }
    }, [searchDetailData]);

    return (
        <div className='dropdown main_search' >
            <a href='/?' className="avatar-info avatar-hover" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
                <i className="ri-search-2-line"></i>
            </a>
            <ul className="dropdown-menu srch_drop" aria-labelledby="dropdownMenuButton1">
                <div className="searchBar custom-tabs">
                    <input type="search" className="custom_search" placeholder="Search Crypto" value={searchDetailData} onChange={(e) => setSearchDetailData(e.target.value)} />
                    <i className="ri-search-2-line"></i>
                </div>
                {filteredRoutes.length <= 0 &&
                    <div className='tp_srchs'>
                        <p>Hot Searches</p>
                        <div className='tps_tgs'>
                            <Link to='/referral'>Refer & Earn</Link>
                            <Link to='/Peer_to_peer'>P2P</Link>
                            <Link to='/dashboard'>Wallet</Link>
                            <Link to='/deposit_funds'>Deposit</Link>
                            <Link to='/withdraw_funds'>Withdrawal</Link>
                            <Link to='/buy_sell'>INR/USDT</Link>
                        </div>
                    </div>
                }
                <div className='tp_srchs'>
                    {filteredRoutes.length > 0 && <p>New Searches</p>}
                    <div className='tps_tgs'>
                        {searchDetailData && filteredRoutes.length > 0 ? (
                            filteredRoutes.map((route) => (
                                <Link
                                    key={route.path}
                                    to={route.path}
                                    onClick={() => setSearchDetailData('')}
                                >
                                    {route.name}
                                </Link>
                            ))
                        ) : searchDetailData ? (
                            <p className='text-center'>No results found</p>
                        ) : null}
                    </div>
                </div>
            </ul>
        </div>
    )
}

export default FilterBar