import React from "react";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";


const P2pPage = () => {


    return (

        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper">
                    <section className="d_page">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xxl-10">
                                    <img src="/assets/images/coming_soon.svg" className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>

    )
}

export default P2pPage