import React, { useContext, useEffect, useState } from "react";
import { alertErrorMessage, alertSuccessMessage } from "../../../Services/CustomAlertModel";
import Select from 'react-select'
import AuthService from "../../../Services/ApiServices/AuthService";
import { Link } from "react-router-dom";
import { SocketContext } from "../../../Context/SocketContext";
import moment from "moment";
import DataTable from "react-data-table-component";
import BuysellSidebar from "../../CoustamComponent/BuysellSidebar";
import { ProfileContext } from "../../../Services/ProfileProvider";
import copy from "copy-to-clipboard";

const BuySell = () => {

    const { userDetails } = useContext(ProfileContext);
    const { socket } = useContext(SocketContext);
    const [transactionList, setTransactionList] = useState([]);
    const [currencyName, setCurrencyName] = useState("");
    const [currencyPrice, setCurrencyPrice] = useState();
    const [currencyImage, setCurrencyImage] = useState('');
    const [payAmount, setPayAmount] = useState('');
    const [getAmount, setGetAmount] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [isActiveAddMoney, setIsActiveAddMoney] = useState(false);
    const [isActiveSell, setIsActiveSell] = useState(false);
    const [buysellDetails, setBuysellDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [totalDataLength, setTotalDataLength] = useState([]);
    const [filterTransaction, setFilterTransaction] = useState([]);
    const [walletBalance, setWalletBalance] = useState('');
    const [selectedTab, setSelectedTab] = useState('Pending');
    const [addAmount, setAddAmount] = useState('');
    const [utrNumber, setUtrNumber] = useState('');
    const [search, setSearch] = useState("");
    const [adminBankData, setAdminBankData] = useState('');
    const [adminBankUpi, setAdminBankUpi] = useState('');
    const [isActiveCopy, setIsActiveCopy] = useState(null);
    const [isActiveCopyupi, setIsActiveCopyupi] = useState(null);
    const [coinDetails, setCoinDetails] = useState();
    const [checkButton, setCheckButton] = useState(false);
    const [coinList, setCoinList] = useState();
    const [selectedTabMenu, setSelectedTabMenu] = useState("Buy");
    const [walletBalanceSellSide, setWalletBalanceSellSide] = useState('');
    const [socketData, setSocketData] = useState([]);
    const [checkButtonInr, setCheckButtonInr] = useState(false);
    const [minimumPrice, setMinimumPrice] = useState();
    const [maximumPrice, setMaximumPrice] = useState();
    const [selectedRowData, setSelectedRowData] = useState();
    const [activeTabInr, setActiveTabInr] = useState("UPI");
    const [selectedTransaction, setSelectedTransaction] = useState({ value: "NEFT" });

    const Authtoken = localStorage.getItem("token");
    const KycStatus = userDetails?.user?.kycVerified;

    const [skip, setSkip] = useState(0);
    const limit = 10;;

    useEffect(() => {
        if (socket) {
            const payload = { message: 'market' };
            socket.emit('message', payload);
            const intervalId = setInterval(() => {
                socket.emit('message', payload);
            }, 1000);
            socket.on('message', (data) => {
                setSocketData(data);
            });
            return () => {
                clearInterval(intervalId);
                socket.off('message');
            };
        }
    }, [socket]);

    const handleCoinList = async () => {
        try {
            const result = await AuthService.coinListDetails();
            if (result?.success) {
                setCoinList(result?.data);
                if (selectedTabMenu === 'Buy') {
                    setCurrencyName(result?.data?.sell[0]?.base_currency);
                    setCurrencyPrice(parseFloat(result?.data?.sell[0]?.price));
                    setCurrencyImage(result?.data?.sell[0]?.icon_path)
                    setCoinDetails(result?.data?.sell[0]);
                    setMaximumPrice(parseFloat(result?.data?.sell[0]?.quote_max || 0));
                    setMinimumPrice(parseFloat(result?.data?.sell[0]?.quote_min || 0));
                } else if (selectedTabMenu === 'Sell') {
                    setCurrencyName(result?.data?.buy[0]?.base_currency);
                    setCurrencyPrice(parseFloat(result?.data?.buy[0]?.price));
                    setCurrencyImage(result?.data?.buy[0]?.icon_path)
                    setCoinDetails(result?.data?.buy[0]);
                    setMaximumPrice(parseFloat(result?.data?.buy[0]?.quote_max || 0));
                    setMinimumPrice(parseFloat(result?.data?.buy[0]?.quote_min || 0));
                }
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        handleCoinList();
    }, []);

    const handleSeletedTab = (side) => {
        setSelectedTabMenu(side);
        if (side === 'Buy') {
            setCurrencyName(coinList?.sell[0]?.base_currency);
            setCurrencyPrice(parseFloat(coinList?.sell[0]?.price));
            setCurrencyImage(coinList?.sell[0]?.icon_path);
            setCoinDetails(coinList?.sell[0]);
            setMaximumPrice(parseFloat(coinList?.sell[0]?.quote_max || 0));
            setMinimumPrice(parseFloat(coinList?.sell[0]?.quote_min || 0));
        } else if (side === 'Sell') {
            setCurrencyName(coinList?.buy[0]?.base_currency);
            setCurrencyPrice(parseFloat(coinList?.buy[0]?.price));
            setCurrencyImage(coinList?.buy[0]?.icon_path);
            setCoinDetails(coinList?.buy[0]);
            setMaximumPrice(parseFloat(coinList?.buy[0]?.quote_max || 0));
            setMinimumPrice(parseFloat(coinList?.buy[0]?.quote_min || 0));
            handleWalletBalanceData(coinList?.buy[0]?.base_currency)
        }
        setPayAmount("");
        setGetAmount("");
    };

    const handleChange = (selectedOption) => {
        if (selectedOption) {
            setCurrencyName(selectedOption.label);
            setCurrencyPrice(parseFloat(selectedOption.price));
            setCurrencyImage(selectedOption.image);
            setCoinDetails(selectedOption?.items);
            handleWalletBalanceData(selectedOption.label);
            setMinimumPrice(selectedOption?.items?.quote_min);
            handleWalletBalanceData(selectedOption?.items?.base_currency)
            setMaximumPrice(parseFloat(selectedOption?.items?.quote_max || 0));
            setMinimumPrice(parseFloat(selectedOption?.items?.quote_min || 0));
            setPayAmount("");
            setGetAmount("");
        }
    };


    const handleWalletBalanceData = async (currency) => {
        if (Authtoken) {
            try {
                const result = await AuthService.walletBalance(currency);
                if (result?.success) {
                    setWalletBalanceSellSide(result?.data);
                } else {
                    alertErrorMessage(result);
                    setWalletBalanceSellSide("");
                }
            } catch (error) {
                alertErrorMessage(error);
            }
        }
    };

    const handlePayAmountChange = (e) => {
        let value;
        if (typeof e === 'object') {
            value = e.target.value;
        } else {
            value = e.toString();
        }
        if (value.startsWith('.')) {
            value = '0' + value;
            return value;
        }
        const regex = /^\d{0,8}(\.\d{0,8})?$/;
        if (regex.test(value)) {
            setPayAmount(value);
            if (!isNaN(value) && value !== '') {
                const convertedValue = (value / currencyPrice)?.toFixed(8);
                const formattedValue = parseFloat(convertedValue).toString();
                if (value >= minimumPrice) {
                    setGetAmount(formattedValue === 'NaN' ? '0.00' : formattedValue);
                }
            } else {
                setGetAmount('');
            }
        }
    };

    const handleGetAmountChange = (e) => {
        let value;
        if (typeof e === 'object') {
            value = e.target.value;
        } else {
            value = e.toString();
        }
        if (value.startsWith('.')) {
            value = '0' + value;
            return value;
        }
        const regex = /^\d{0,8}(\.\d{0,8})?$/;

        if (regex.test(value)) {
            setGetAmount(value);
            if (!isNaN(value) && value !== '') {
                const convertedValue = (value * currencyPrice)?.toFixed(8);
                const formattedValue = parseFloat(convertedValue).toString();
                setPayAmount(formattedValue === 'NaN' ? '0.00' : formattedValue);
            } else {
                setPayAmount('');
            }
        }
    };

    const handlePayAmountChangeSell = (e) => {
        let value;
        if (typeof e === 'object') {
            value = e.target.value;
        } else {
            value = e.toString();
        }
        if (value.startsWith('.')) {
            value = '0' + value;
            return value;
        }
        const regex = /^\d{0,8}(\.\d{0,8})?$/;
        if (regex.test(value)) {
            setPayAmount(value);
            if (!isNaN(value) && value !== '') {
                const convertedValue = (value * currencyPrice)?.toFixed(8);
                const formattedValue = parseFloat(convertedValue).toString();
                setGetAmount(formattedValue === 'NaN' ? '0.00' : formattedValue);
            } else {
                setGetAmount('');
            }
        }
    };

    const handleGetAmountChangeSell = (e) => {
        let value;
        if (typeof e === 'object') {
            value = e.target.value;
        } else {
            value = e.toString();
        }
        if (value.startsWith('.')) {
            value = '0' + value;
            return value;
        }

        const regex = /^\d{0,8}(\.\d{0,8})?$/;
        if (regex.test(value)) {
            setGetAmount(value);
            if (!isNaN(value) && value !== '') {
                const convertedValue = (value / currencyPrice)?.toFixed(8);
                const formattedValue = parseFloat(convertedValue).toString();
                if (value >= 100) {
                    setPayAmount(formattedValue === 'NaN' ? '0.00' : formattedValue);
                }
            } else {
                setPayAmount('');
            }
        }
    };

    const handleTranactionList = async (skip, limit, selectedTab, side) => {
        try {
            const result = await AuthService.buySellTranactionList(skip, limit, selectedTab, side);
            if (result?.success) {
                setTransactionList(result?.data);
                setTotalDataLength(result?.count);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        if (Authtoken) {
            const status = selectedTab === "Pending" ? "Pending" : null;
            const side = selectedTab === "buy" ? "BUY" : selectedTab === "sell" ? "SELL" : null;
            handleTranactionList(skip, limit, selectedTab === "sell" || selectedTab === "buy" ? "Completed" : status, side);
        }
    }, [skip, limit, selectedTab]);

    useEffect(() => {
        Authtoken &&
            handleWalletBalance('INR');
    }, []);

    const columns = [

        {
            name: 'Order No.',
            selector: row => row._id,
            sortable: true,
            wrap: true,

            format: row =>
                <div className="c_view">{row._id} </div>,
        },

        {
            name: "Date",
            cell: (row) => <div className="justify-content-start" > <span>{moment(row.createdAt).format("DD/MM/YYYY  ")} <br /> <small>{moment(row.createdAt).format("HH:mm:ss")}</small> </span> </div>,
            sortable: false,
        },

        {
            name: 'Currency',
            selector: row => row,
            sortable: true,
            format: row => <div className="text-end">{row.base_currency + "/" + row.quote_currency}</div>,
        },
        {

            name: 'Side',
            selector: row => row.side,
            wrap: true,
            sortable: true,
            format: row =>
                <span className={row.side === 'BUY' ? " text-success" : "text-danger"}>
                    {row?.side}
                </span>,
        },
        {
            name: 'Total Paid',
            selector: row => row.total_pay_amount,
            wrap: true,
            sortable: true,
            format: row => <div className="text-end">{row.total_pay_amount?.toFixed(row?.side === "BUY" ? 2 : 5)}
                &nbsp;{row?.side === "BUY" ? row.quote_currency : row.base_currency}</div>,

        },
        {
            name: 'Fee',
            selector: row => row.transaction_fees_amount || '0',
            wrap: true,
            sortable: true,

        },
        {
            name: 'TDS',
            selector: row => row.side === "SELL" && (row.tds_amount)?.toFixed(2) || '0.00',
            wrap: true,
            sortable: true,

        },

        {
            name: 'Total Recived',
            selector: row => row.total_get_amount,
            wrap: true,
            sortable: true,
            format: row => <div className="text-end">{row.total_get_amount?.toFixed(row.side === "BUY" ? 5 : 5)}
                &nbsp;{row?.side === "BUY" ? row.base_currency : row.quote_currency}</div>,

        },

        {

            name: 'Status',
            selector: row => row.status,
            wrap: true,
            sortable: true,
            format: row =>
                <span className={row.status === 'Completed' ? " text-success" : row.status === 'Cancelled' ? "text-danger" : row.status === 'Rejected' ? "text-danger" : !row?.amount_deposited ? "text-warning" : "text-success"}>
                    {row?.status}
                </span>,
        },
        ...(selectedTab === "Pending" ? [{
            name: 'Action',
            selector: row => (
                <div className="table_action d-flex align-items-center justify-content-end gap-1" >
                    {
                        !row?.amount_deposited &&

                        <button className="btn btn-outline-success btn-xxs"
                            onClick={() => {
                                setIsActiveAddMoney(true);
                                setSelectedRowData(row?._id);
                                setAddAmount(row?.inr_deposit_amount);
                            }}

                        >Add UTR</button>

                    }

                    {
                        !row?.amount_deposited ?
                            <button className="btn btn-outline-danger btn-xxs" onClick={() => handleRemoveOrder(row?._id)}>Cancel</button>
                            : "------ -----"
                    }

                </div>
            ),
            wrap: true,
        }] : []),


    ];

    useEffect(() => {
        const filteredData = transactionList.filter(transaction => {
            return (
                transaction?.from?.toLowerCase().includes(search?.toLowerCase()) ||
                transaction?.side?.toLowerCase().includes(search?.toLowerCase()) ||
                transaction?.to?.toLowerCase().includes(search?.toLowerCase())
            );
        });

        setFilterTransaction(filteredData);
    }, [search, transactionList]);

    const handlePagination = (action) => {
        const maxSkip = totalDataLength - limit;

        if (action === 'first') {
            setSkip(0);
        } else if (action === 'prev') {
            setSkip(Math.max(skip - limit, 0));
        } else if (action === 'next') {
            setSkip(Math.min(skip + limit, maxSkip));
        } else if (action === 'last') {
            setSkip(maxSkip > 0 ? maxSkip : 0);
        }
    };


    const handleRemoveOrder = async (id) => {
        try {
            const result = await AuthService.cancelBuySellOrder(id);
            if (result?.success) {
                const status = selectedTab === "Pending" ? "Pending" : null;
                const side = selectedTab === "buy" ? "BUY" : selectedTab === "sell" ? "SELL" : null;
                handleTranactionList(skip, limit, selectedTab === "sell" || selectedTab === "buy" ? "Completed" : status, side);
            } else {
                alertErrorMessage(result?.message);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };


    const handleWalletBalance = async () => {
        try {
            const result = await AuthService.walletBalance('INR');
            if (result?.success) {
                setWalletBalance(result?.data);
            } else {
                alertErrorMessage(result?.message);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    const adminBankDetails = async () => {
        try {
            const result = await AuthService.getAdminBankDetails();
            if (result?.success) {
                setAdminBankData(result?.data?.[0]);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    const adminUpiDetails = async () => {
        try {
            const result = await AuthService.getAdminUpiDetails();
            if (result?.success) {
                setAdminBankUpi(result?.data?.[0]);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        if (Authtoken) {
            adminBankDetails();
            adminUpiDetails();
        }
    }, []);

    const handleIsActiveBuySell = (payAmount, getAmount, side) => {
        if (!userDetails?.permissions?.buy_sell) {
            alertErrorMessage('Disable Buy Sell.');
            return;
        }
        if (!payAmount) {
            alertErrorMessage('Please Enter Amount.');
            return;
        }
        if (side === "BUY") {
            if (payAmount < minimumPrice) {
                alertErrorMessage(`Please Enter Minimum ₹${minimumPrice} Amount`);
                return;
            }
            if (payAmount > maximumPrice) {
                alertErrorMessage(`You can only buy up to ₹${maximumPrice}`);
                return;
            }
            setIsActive(true);
            setBuysellDetails({ payAmount, getAmount, side });
        } else {
            if (getAmount < minimumPrice) {
                alertErrorMessage(`Please Enter Minimum ₹${minimumPrice} Amount`);
                return;
            } if (getAmount > maximumPrice) {
                alertErrorMessage(`You can only Sell up to ₹${maximumPrice}`);
                return;
            }
            setIsActive(false);
            setIsActiveSell(true);
            setBuysellDetails({ payAmount, getAmount, side });
        }
    };

    const handleBuySell = async (payAmount, getAmount, side) => {
        setIsLoading(true);
        await AuthService.buy_sell(payAmount, getAmount, side, coinDetails?.base_currency, "INR", coinDetails?.base_currency_id, coinDetails?.quote_currency_id, checkButton, currencyPrice).then(async (result) => {
            if (result?.success) {
                if (!result?.inr_deposit_amount) {
                    setIsLoading(false);
                    alertSuccessMessage(result.message);
                    setIsActive(false);
                    setIsActiveSell(false);
                    alertSuccessMessage(result?.message);
                    setCheckButton(false);
                    setPayAmount("");
                    setGetAmount("");
                } else {
                    setIsLoading(false);
                    alertErrorMessage(result);
                    setIsActive(false);
                    setIsActiveAddMoney(true);
                    setAddAmount(result?.inr_deposit_amount)
                    alertErrorMessage(result?.message);
                    setPayAmount("");
                    setGetAmount("");
                }
                if (result?.data?.status === "Pending") {
                    handleTranactionList(skip, limit, "Pending", result?.data?.side);
                    setSelectedTab('Pending');
                } else if (result?.data?.side === "SELL") {
                    handleTranactionList(skip, limit, "Completed", "SELL");
                    setSelectedTab('sell');
                } else {
                    handleTranactionList(skip, limit, "Completed", "Buy");
                    setSelectedTab('buy');
                }
                if (selectedTabMenu === "Buy") {
                    handleWalletBalance('INR');
                } else {
                    handleWalletBalanceData(coinDetails?.base_currency);
                }
                setSelectedRowData(result?.data?._id);
            } else {
                alertErrorMessage(result);
                setIsLoading(false);
                setIsActiveSell(false);
                setSelectedTab("Pending");
            }
        });
    };

    const userBankDetil = userDetails?.bank_details?.filter(item => item?.default === true);
    const userUpiDetails = userDetails?.upi_details?.filter(item => item?.default === true);

    const optionTransactions = [
        { value: 'NEFT', label: 'NEFT' },
        { value: 'RTGS', label: 'RTGS' },
        { value: 'IMPS', label: 'IMPS' },
    ];

    const handleTransactionSelected = (selectedOption) => {
        setSelectedTransaction({
            value: selectedOption.value,
        });
    };


    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? 'black'
                : state.isFocused
                    ? 'gainsboro'
                    : 'white',
            color: state.isSelected
                ? 'white'
                : state.isFocused
                    ? 'dark'
                    : 'black',
            fontSize: '14px',
            borderRadius: '4px',
            margin: '0px',
        }),
    };

    const handleSubmitUtrTrans = async (addAmount, utrNumber, selectedRowData, activeTabInr) => {
        setIsLoading(true);
        try {
            const result = await AuthService.addInr(addAmount, utrNumber, selectedRowData, activeTabInr);
            if (result?.success) {
                setIsLoading(false);
                setIsActiveAddMoney(false);
                alertSuccessMessage(result?.message)
                handleTranactionList(skip, limit, "Pending", "BUY");
                setSelectedTab('Pending');
                setUtrNumber("");
            } else {
                alertErrorMessage(result);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            alertErrorMessage(error);
        }
    }

    return (

        <main>
            <div className="page_wrapper bsp_wrapper" >
                {Authtoken ?
                    <BuysellSidebar />
                    : ""
                }
                <div className="af_wrapper  ">
                    <section className=" buy_sell_sec">
                        <div className="container">
                            <div className="row gx-5 align-items-center ">
                                <div className="col-12 col-lg-7  ">
                                    <form className="bc_card" >
                                        <div className="card-header login-header qbs_header">
                                            <ul className="nav nav-pills  " id="pills-tab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active nav_buy" id="pills-Buy-tab"
                                                        data-bs-toggle="pill" data-bs-target="#pills-Buy" type="button"
                                                        role="tab" aria-controls="pills-Buy" aria-selected="true" onClick={() => handleSeletedTab("Buy")}>
                                                        <span>Buy</span>
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link nav_sell" id="pills-Sell-tab"
                                                        data-bs-toggle="pill" data-bs-target="#pills-Sell" type="button"
                                                        role="tab" aria-controls="pills-Sell" aria-selected="false" onClick={() => handleSeletedTab("Sell")}>
                                                        <span>Sell</span>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane show active" id="pills-Buy" role="tabpanel"
                                                aria-labelledby="pills-Buy-tab">
                                                <div className="card-body ">
                                                    <div className="mb-4">
                                                        <div className="swap_form form-group mb-0">
                                                            <label className="" htmlFor="buypayamount"> Pay Amount </label>
                                                            <div className="input_lab">
                                                                <input type="text"
                                                                    className={`form-control ${payAmount && payAmount < minimumPrice ? "error" : payAmount && payAmount > minimumPrice ? "correct" : ""}`}
                                                                    name="amount"
                                                                    id="buypayamount"
                                                                    placeholder={"min ₹" + minimumPrice}
                                                                    value={payAmount}
                                                                    onChange={handlePayAmountChange}
                                                                />
                                                                <span> <img src="/assets/images/icon/inr.png" alt="INR" style={{ width: '20px', marginRight: '10px' }} />INR</span>
                                                            </div>
                                                        </div>
                                                        {
                                                            Authtoken &&
                                                            <div className="input_footer" >
                                                                <div className="avil_bal my-2 mb-1" >
                                                                    <div className="form-check   d-block">
                                                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={checkButton} value={checkButton} onClick={() => setCheckButton((prev) => !prev)} />
                                                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                            Deducted from wallet
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="balance-libra card-success my-2">
                                                                    <div className="token-img-bg_right" id="lc-data"> Available :
                                                                        <b> {walletBalance?.balance?.toFixed(0) || 0} INR</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="mb-2" >
                                                            {payAmount && payAmount < minimumPrice && (
                                                                <div className="bs_error ps-2">Amount must be greater than ₹{minimumPrice}</div>
                                                            )}

                                                            {payAmount && payAmount > maximumPrice && (
                                                                <div className="bs_error ps-2">You can only buy up to ₹{maximumPrice}</div>
                                                            )}
                                                        </div>
                                                        <div className="pr_suggest" >
                                                            <span className="badge bg-muted" onClick={() => handlePayAmountChange("1000")}> 1,000  </span>
                                                            <span className="badge bg-muted" onClick={() => handlePayAmountChange("5000")}> 5,000  </span>
                                                            <span className="badge bg-muted" onClick={() => handlePayAmountChange("10000")}> 10,000  </span>
                                                            <span className="badge bg-muted" onClick={() => handlePayAmountChange("50000")}> 50,000  </span>
                                                        </div>
                                                        <div className="py-3 d-flex justify-contennt-center text-center">
                                                            <i className="ri-arrow-up-down-fill ar_hs  mx-auto cursor-pointer bg-warning text-dark"></i>
                                                        </div>
                                                        <div className="swap_form form-group ">
                                                            <div className="balance-libra card-success my-3 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <div className="token-img-bg-left">
                                                                    <label className="mb-0" >You will Get</label>
                                                                </div>
                                                                <div className="token-img-bg_right" id="lc-data">
                                                                    <label className="mb-0" >Choose Crypto </label>
                                                                </div>
                                                            </div>
                                                            <div className="input_lab ">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    name="amount"
                                                                    id="buycurrency"
                                                                    value={getAmount}
                                                                    placeholder="0.00"
                                                                    onChange={handleGetAmountChange} />
                                                                <span className="sw_img sw_control cursor-pointer">
                                                                    <Select
                                                                        className="sw_select"
                                                                        options={coinList?.sell?.map(item => ({
                                                                            label: item.base_currency,
                                                                            value: item.base_currency,
                                                                            price: item.price,
                                                                            image: item.icon_path,
                                                                            items: item
                                                                        }))}
                                                                        onChange={handleChange}
                                                                        value={{ value: currencyName, label: currencyName, image: currencyImage }}
                                                                        defaultValue={currencyName}

                                                                        getOptionLabel={(optionItem, index) => (
                                                                            <div key={index} className="cc_row">
                                                                                {optionItem.image && <img src={optionItem.image} alt="" onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} />}
                                                                                {optionItem.value}
                                                                            </div>
                                                                        )}
                                                                    />
                                                                </span>
                                                            </div>
                                                            <div className="balance-libra card-success my-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <div className="token-img-bg-left"></div>

                                                                <div className="token-img-bg_right" id="lc-data">
                                                                    <b>1 {currencyName} = {currencyPrice?.toFixed(0)} INR</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-10 col-12 mx-auto mt-3">
                                                        {Authtoken ?
                                                            KycStatus === 0 || KycStatus == 1 || KycStatus == 3 ?
                                                                <div className="order-btns">
                                                                    <Link to={KycStatus == 1 ? "" : '/verify_kyc'} className={`form-control btn ${KycStatus === 1 ? "btn-warning" : KycStatus === 0 ? "btn-warning" : "btn-danger"}`}>
                                                                        {KycStatus == 1 ? "Verification Pending" : KycStatus == 0 ? "Submit Kyc" : "Kyc Rejected Verify Again"}
                                                                    </Link>
                                                                </div> :
                                                                !userBankDetil ?
                                                                    <div className="order-btns">
                                                                        <Link to="/payments" className="form-control btn btn-warning">
                                                                            Verify Bank Details
                                                                        </Link>
                                                                    </div>
                                                                    :
                                                                    !userUpiDetails?.length > 0 ?
                                                                        <div className="order-btns">
                                                                            <Link to="/payments" className="form-control btn btn-warning">
                                                                                Verify UPI Details
                                                                            </Link>
                                                                        </div>
                                                                        :
                                                                        <button type="button" className="form-control btn btn-success" onClick={() => handleIsActiveBuySell(payAmount, getAmount, 'BUY')}>
                                                                            Buy {currencyName}
                                                                        </button>
                                                            :
                                                            <Link to="/login" className="form-control btn btn-warning text-center" >
                                                                Sign In
                                                            </Link>
                                                        }
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-12 text-center mt-4"><p className="mb-0">Missing Deposit? {" "}
                                                        <Link className="fw-bold" to="/support"> Click here! </Link>
                                                    </p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Sell TAb */}
                                            <div className="tab-pane" id="pills-Sell" role="tabpanel"
                                                aria-labelledby="pills-Sell-tab">
                                                <div className="card-body ">
                                                    <div className="mb-4">
                                                        <div className="swap_form form-group mb-0">
                                                            <label className="text-end" htmlFor="currencyget">
                                                                Choose Crypto
                                                            </label>
                                                            <div className="input_lab ">
                                                                <input type="text" className="form-control" placeholder="Enter Amount" name="amount" id="payamount" value={payAmount} onChange={handlePayAmountChangeSell} />
                                                                <span className="sw_img sw_control cursor-pointer">
                                                                    <Select
                                                                        className="sw_select cursor-pointer"
                                                                        options={coinList?.buy?.map(item => ({
                                                                            label: item.base_currency,
                                                                            value: item.base_currency,
                                                                            price: item.price,
                                                                            image: item.icon_path,
                                                                            items: item
                                                                        }))}
                                                                        onChange={handleChange}
                                                                        value={{ value: currencyName, label: currencyName, image: currencyImage }}
                                                                        defaultValue={currencyName}
                                                                        getOptionLabel={(optionItem, index) => (
                                                                            <div key={index} className="cc_row cursor-pointer">
                                                                                {optionItem.image && <img src={optionItem.image} alt="" onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} />}
                                                                                {optionItem.value}
                                                                            </div>
                                                                        )}
                                                                    />
                                                                </span>
                                                            </div>
                                                            {
                                                                Authtoken &&
                                                                <div className="balance-libra card-success my-2">
                                                                    <div className="token-img-bg_right" id="lc-data">
                                                                        <div className="token-img-bg_right" id="lc-data">
                                                                            Available : <b>{walletBalanceSellSide?.balance?.toFixed(2) || "0"} {" "} {walletBalanceSellSide?.short_name}</b>
                                                                            <Link className="actions_deposit__Ydutk" to='/wallet'>
                                                                                <i className="ri-add-circle-fill" style={{ fontSize: "15px" }}></i>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="py-3 d-flex justify-contennt-center text-center">
                                                            <i className="ri-arrow-up-down-fill ar_hs  mx-auto cursor-pointer bg-warning text-dark"></i>
                                                        </div>
                                                        <div className="swap_form form-group mb-0 mt-3">
                                                            <label className="" htmlFor="buypayamount">You will Get </label>
                                                            <div className="input_lab">
                                                                <input type="text" className={`form-control ${getAmount && getAmount < minimumPrice ? "error" : getAmount && getAmount > minimumPrice ? "correct" : ""}`}
                                                                    placeholder={"min ₹" + minimumPrice} name="amount" id="currencyget" value={getAmount} onChange={handleGetAmountChangeSell} />
                                                                <span>
                                                                    <img src="/assets/images/icon/inr.png" alt="INR" style={{ width: '20px', marginRight: '10px' }} />INR</span>
                                                            </div>
                                                        </div>
                                                        <div className="input_footer" >
                                                            <div>
                                                                {getAmount && getAmount < minimumPrice && (
                                                                    <div className="bs_error">Amount must be greater than ₹{minimumPrice}</div>
                                                                )}

                                                                {getAmount && getAmount > maximumPrice && (
                                                                    <div className="bs_error ps-2">You can only Sell up to ₹{maximumPrice}</div>
                                                                )}

                                                            </div>
                                                            <div className="balance-libra card-success my-2">

                                                                <div className="token-img-bg_right" id="lc-data">
                                                                    <b>1 {currencyName} = {" "}
                                                                        {" "} {currencyPrice?.toFixed(0)} INR</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="pr_suggest" >
                                                            <span className="badge bg-muted"
                                                                onClick={() => handleGetAmountChangeSell("1000")}> 1,000  </span>
                                                            <span className="badge bg-muted"
                                                                onClick={() => handleGetAmountChangeSell("5000")}> 5,000  </span>
                                                            <span className="badge bg-muted"
                                                                onClick={() => handleGetAmountChangeSell("10000")}> 10,000  </span>
                                                            <span className="badge bg-muted"
                                                                onClick={() => handleGetAmountChangeSell("50000")}> 50,000  </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-10 col-12 mx-auto  mt-3">
                                                        <div className="col-lg-12 col-md-10 col-12 mx-auto mt-3">

                                                            {Authtoken ?
                                                                KycStatus === 0 || KycStatus == 1 || KycStatus == 3 ?
                                                                    <div className="order-btns">
                                                                        <Link to={KycStatus == 1 ? "" : '/verify_kyc'} className={`form-control btn ${KycStatus === 1 ? "btn-warning" : KycStatus === 0 ? "btn-warning" : "btn-danger"}`}>
                                                                            {KycStatus == 1 ? "Verification Pending" : KycStatus == 0 ? "Submit Kyc" : "Kyc Rejected Verify Again"}
                                                                        </Link>
                                                                    </div> :
                                                                    !userBankDetil ?
                                                                        <div className="order-btns">
                                                                            <Link to="/payments" className="form-control btn btn-warning">
                                                                                Verify Bank Details
                                                                            </Link>
                                                                        </div>
                                                                        :
                                                                        !userUpiDetails?.length > 0 ?
                                                                            <div className="order-btns">
                                                                                <Link to="/payments" className="form-control btn btn-warning">
                                                                                    Verify UPI Details
                                                                                </Link>
                                                                            </div>
                                                                            :
                                                                            currencyName &&
                                                                            <button type="button" className="form-control btn btn-danger" onClick={() => handleIsActiveBuySell(payAmount, getAmount, 'SELL')}>
                                                                                Sell {currencyName}
                                                                            </button>
                                                                :
                                                                <Link to="/login" className="form-control btn btn-warning text-center" >
                                                                    Sign In
                                                                </Link>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-12 text-center mt-4"><p className="mb-0">Missing Deposit? {" "}
                                                        <Link className="fw-bold" to="/support"> Click here! </Link>
                                                    </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>


                                <div className="col-12 col-lg-5  ">
                                    <h3 className=" mt-5 mt-lg-0 mb-3 lg-md-5" > Buy or Sell Your Crypto </h3>
                                    <div className="hero_card bc_h_card">
                                        <div className="hero_title">
                                            <h5>Exchange Recent Orders </h5>
                                        </div>
                                        <div className="c_body" >
                                            <div className="m_table r_table scroll_y bus_scroll">
                                                <div className="table-responsive">
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Date & Time</th>
                                                                <th scope="col">Coin Name</th>
                                                                <th scope="col" >Total Recived</th>
                                                                <th scope="col">Side</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                socketData?.recent_qbs && socketData?.recent_qbs.length > 0 ? (
                                                                    socketData?.recent_qbs.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <div className="c_view justify-content-start">
                                                                                    <span>
                                                                                        {moment(item.createdAt).format("DD/MM/YYYY")}
                                                                                        <small>{moment(item.createdAt).format("HH:mm:ss")}</small>
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td >
                                                                                {item?.base_currency + '/' + item?.quote_currency}
                                                                            </td>
                                                                            <td >
                                                                                {item.total_get_amount?.toFixed(2) || "--"}
                                                                                &nbsp;{item?.side === "BUY" ? item.base_currency : item.quote_currency}
                                                                            </td>

                                                                            <td>
                                                                                <span className={item?.side === "BUY" ? "text-success" : "text-danger"}>{item?.side || "----"}</span>
                                                                            </td>


                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="5">
                                                                            <div className="favouriteData">
                                                                                <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Order history */}
                                <div className="ord_his_sec" >
                                    <div className="col-12 col-lg-12">
                                        <div className="d-flex-between  custom_dlflex mrk_menu no-border mb-0">
                                            <h6 className="mb-0" > Order History </h6>
                                            {transactionList?.length > 0 ?
                                                <div className="searchBar custom-tabs">
                                                    <input type="search" className="custom_search" placeholder="Search Crypto" value={search} onChange={(e) => setSearch(e.target.value)} />
                                                    <i className="ri-search-2-line"></i>
                                                </div>
                                                : ""
                                            }
                                        </div>
                                        <div className="trade_card mb-0">
                                            <div className="treade_card_header d-flex-between trade_tabs w-100 align-items-center buy_sell_cards p-0">
                                                <ul className="nav custom-tabs order_his_tabs ">
                                                    <li className="buysell-tab">
                                                        <a className={selectedTab === 'Pending' ? 'active' : ''} onClick={() => { setSelectedTab('Pending'); setSkip(0) }}> Ongoing Orders </a>
                                                    </li>

                                                    <li className="buysell-tab">
                                                        <a className={selectedTab === 'all' ? 'active' : ''} onClick={() => { setSelectedTab('all'); setSkip(0) }}> All Orders </a>
                                                    </li>
                                                    <li className="buysell-tab">
                                                        <a className={selectedTab === 'buy' ? 'active' : ''} onClick={() => { setSelectedTab('buy'); setSkip(0) }} > Buy Orders </a>
                                                    </li>
                                                    <li className="buysell-tab">
                                                        <a className={selectedTab === 'sell' ? 'active' : ''} onClick={() => { setSelectedTab('sell'); setSkip(0) }} > Sell Orders </a>
                                                    </li>
                                                </ul>
                                                <Link to="/buy_sell_transactions">
                                                    See All <i className=" ri-arrow-right-s-line"></i>
                                                </Link>
                                            </div>

                                            <div className="tab-content">
                                                <div className="tab-pane show active px-0" id="open_orders">
                                                    <div className="table-responsive">
                                                        {Authtoken ? (
                                                            <DataTable
                                                                columns={columns}
                                                                data={filterTransaction}
                                                                noDataComponent={
                                                                    <p> <img src="/assets/images/not_found.svg" width="300" className='img-fluid mt-5' alt='' /> </p>
                                                                }
                                                            />
                                                        ) : (
                                                            <div className="favouriteData mt-3 mb-3">
                                                                <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                <p className="mt-2">
                                                                    Go to&nbsp;
                                                                    <Link className="btn-link" to="/login">
                                                                        <b>&nbsp;Sign in&nbsp;</b>
                                                                    </Link>
                                                                    &nbsp;and check your {selectedTab} Orders.
                                                                </p>
                                                            </div>
                                                        )}
                                                        {
                                                            /*  filterTransaction?.length > 0 && */
                                                            <div className="hVPalX gap-2" >
                                                                <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                                                <div className="sc-eAKtBH gVtWSU">
                                                                    <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first')} disabled={skip === 0}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                            <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                                            <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                                        </svg>
                                                                    </button>
                                                                    <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev')} disabled={skip === 0}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                                                        </svg>
                                                                    </button>

                                                                    <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next')} disabled={skip + limit >= totalDataLength}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                                                        </svg>
                                                                    </button>
                                                                    <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last')} disabled={skip + limit >= totalDataLength}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                            <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div >
            </div >

            {/* buy details model */}

            {isActive && (
                <div div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title" id="modal_otp_Label">{buysellDetails.side} {" "} {coinDetails.base_currency} </h5>
                                    <button type="button" className="btn-close" onClick={() => setIsActive(false)}></button>
                                </div>
                                <div className="modal-body p-md-4">
                                    <div className="tt_data  ">
                                        <div className="tt_card">
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Pay Currency: </span>
                                                <span className="tt_normal">INR</span>
                                            </div>
                                            <div className="tt_item">
                                                <span className="text-muted">Pay Amount :</span>
                                                <span className="tt_normal">{buysellDetails.payAmount}</span>
                                            </div>
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Get Currency: </span>
                                                <span className="tt_normal">{coinDetails.base_currency}</span>
                                            </div>

                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Get Amount : </span>
                                                <span className="tt_normal ">{
                                                    (buysellDetails?.getAmount !== undefined && !isNaN(buysellDetails.getAmount))
                                                        ? parseFloat(buysellDetails?.getAmount)?.toFixed(2)
                                                        : '0.00000000' // Default value in case of NaN or undefined

                                                }</span>
                                            </div>
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Price / {currencyName}  </span>
                                                <span className="tt_normal">{currencyPrice} (INR)</span>
                                            </div>
                                        </div>
                                        <button className="form-control btn btn-success mt-3 w-100 btn-block" onClick={() => handleBuySell(buysellDetails.payAmount, buysellDetails.getAmount, buysellDetails.side)}>
                                            {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                : <span>Proceed to Buy</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"> </div>
                </div >
            )}


            {isActiveAddMoney && (
                <div div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title" id="modal_otp_Label">Add INR</h5>
                                    <button type="button" className="btn-close" onClick={() => setIsActiveAddMoney(false)}></button>
                                </div>
                                <div className="modal-body p-md-4">
                                    <div className="tt_data ">
                                        <div className="bd_card m_account " >
                                            <ul className="nav custom-tabs d-flex justify-content-center">
                                                <li className="w-50" role="presentation">
                                                    <a href="/?" className="w-100 text-center active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" role="tab" aria-controls="profile-tab-pane" aria-selected="false" onClick={() => { setIsActiveCopyupi(false); setIsActiveCopy(false); setActiveTabInr("UPI"); setUtrNumber(""); setCheckButtonInr(false) }}>UPI ID </a>
                                                </li>

                                                <li className="w-50" role="presentation">
                                                    <a href="/?" className="w-100 text-center" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" role="tab" aria-controls="home-tab-pane" aria-selected="true" onClick={() => { setIsActiveCopyupi(false); setIsActiveCopy(false); setActiveTabInr("BANK"); setUtrNumber(""); setCheckButtonInr(false) }}>Bank Account</a>
                                                </li>

                                            </ul>

                                            <div className="p_card">
                                                <div className="tab-content" id="myTabContent">
                                                    <div className="tab-pane show active" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                                                        <div className=" rw_upi_small">
                                                            <div className="wr_col" >
                                                                <img src="/assets/images/qr_code.png" className="img-fluid qr_imgs" />
                                                            </div>
                                                            <div className="id_col" >
                                                                <div className="row gy-2 align-items-center" >
                                                                    <div className="col-md-12">
                                                                        <div className="ud"><span> Name </span>
                                                                            <h5>
                                                                                <small>
                                                                                    {adminBankUpi?.upi_name} &nbsp;
                                                                                    <i className={`text-black cursor-pointer ${isActiveCopy === adminBankUpi?.upi_name ? "ri-check-line" : "ri-file-copy-line"}`} onClick={() => { copy(adminBankUpi?.upi_name); setIsActiveCopy(adminBankUpi?.upi_name) }} ></i>

                                                                                </small>
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="ud"><span> UPI ID </span>
                                                                            <h5>
                                                                                <small>
                                                                                    {adminBankUpi?.upi_vpa_id} &nbsp;
                                                                                    <i className={`text-black cursor-pointer ${isActiveCopyupi === adminBankUpi?.upi_vpa_id ? "ri-check-line" : "ri-file-copy-line"}`} onClick={() => { copy(adminBankUpi?.upi_vpa_id); setIsActiveCopyupi(adminBankUpi?.upi_vpa_id) }} ></i>

                                                                                </small>
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className="my-3 border-dashed" />
                                                        <div className="d-flex align-items-center mt-0 justify-content-between">
                                                            <label className="label my-0 " >   PAY USING  </label>
                                                            <div className="ud  text-end">
                                                                <span className="text-uppercase mb-0" > {userUpiDetails?.[0]?.UPIHolderName} </span>
                                                                <h5 className="mb-0"><small> {userUpiDetails?.[0]?.vpaId} </small>  </h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="tab-pane" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                                        <div className="row g-2">
                                                            <div className="col-md-6">
                                                                <div className="ud"><span>Account Holder Name</span>
                                                                    <h5> <small> {adminBankData?.account_holder_name} </small>  </h5></div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="ud"><span> Account Number </span>
                                                                    <h5>
                                                                        <small>
                                                                            {adminBankData?.account_number} &nbsp;
                                                                            <i className={`text-black cursor-pointer ${isActiveCopy === adminBankData?.account_number ? "ri-check-line" : "ri-file-copy-line"}`} onClick={() => { copy(adminBankData?.account_number); setIsActiveCopy(adminBankData?.account_number) }} ></i>
                                                                        </small>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="ud"><span> IFSC Code  </span>
                                                                    <h5>
                                                                        <small>
                                                                            {adminBankData?.ifsc} &nbsp;

                                                                            <i className={`text-black cursor-pointer ${isActiveCopyupi === adminBankData?.ifsc ? "ri-check-line" : "ri-file-copy-line"}`} onClick={() => { copy(adminBankData?.ifsc); setIsActiveCopyupi(adminBankData?.ifsc) }} ></i>

                                                                        </small>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="ud"><span> Bank Name  </span>
                                                                    <h5> <small> {adminBankData?.bank_name} </small>  </h5></div>
                                                            </div>
                                                            <hr className="my-3 border-dashed" />
                                                            <div className="d-flex align-items-center mt-0 justify-content-between">
                                                                <label className="label my-0 " > PAY USING  </label>
                                                                <div className="ud  text-end">
                                                                    <span className="text-uppercase mb-0" > {userBankDetil?.[0]?.bankName} </span>
                                                                    <h5 className="mb-0"><small> {userBankDetil?.[0]?.accountNumber} </small>  </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <hr className="my-3 border-dashed" />
                                                <div className="row">
                                                    <div className="col-12 form-group ">
                                                        <label className="text-white mb-1 form-label"> Amount </label>
                                                        <input type="text" className="form-control" value={addAmount} placeholder="Enter Amount" disabled />
                                                    </div>
                                                    <div className="col-12 form-group">
                                                        <label className="text-white form-label mb-1"> Enter UTR No. </label>

                                                        <input type="text" className="form-control" value={utrNumber} onChange={(e) =>
                                                            /*  const value = e.target.value;
                                                             const numericValue = value.replace(/[^0-9]/g, '');
                                                             setUtrNumber(numericValue); */
                                                            setUtrNumber(e.target.value)
                                                        }
                                                        />
                                                    </div>
                                                </div>

                                                {
                                                    activeTabInr === "BANK" &&
                                                    <div className="row">
                                                        <div className="col-12 form-group">
                                                            <label className="text-white form-label mb-1">Select Tranaction Type. </label>
                                                            <Select
                                                                name="selectedTransaction"
                                                                id="selectedTransaction"
                                                                value={optionTransactions.find(option => option.value === selectedTransaction?.value)}
                                                                onChange={handleTransactionSelected}
                                                                options={optionTransactions}
                                                                styles={customStyles}
                                                            />
                                                        </div>
                                                    </div>
                                                }

                                                <div className="settings-section-body-list  my-2 mb-3 p-0">
                                                    <span className="dlrLME text-danger">Note:</span>
                                                    <ul className="settings-section-body-list Taxinvoice">
                                                        <li>
                                                            Users are strictly prohibited from making payments through third-party bank accounts or payment methods that are not registered under their profile.
                                                        </li>
                                                        <li>
                                                            If a payment is made using a third-party bank account, a refund will be initiated within 7 days of the payment and account can be suspend.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="form-check d-block cursor-pointer">
                                                    <input className="form-check-input" type="checkbox" id="checkButtonInr" checked={checkButtonInr} value={checkButtonInr} onClick={() => setCheckButtonInr((prev) => !prev)} />
                                                    <label className="form-check-label" htmlFor="checkButtonInr">
                                                        I Agree <small><span className="text-danger">Terms & Conditions</span></small>
                                                    </label>
                                                </div>

                                            </div>

                                        </div>

                                        {
                                            checkButtonInr && utrNumber &&
                                            <button className="form-control btn btn-success mt-3 w-100 btn-block" onClick={() => handleSubmitUtrTrans(addAmount, utrNumber, selectedRowData, activeTabInr === "BANK" ? selectedTransaction?.value : activeTabInr)}>
                                                {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    : <span>Submit</span>}
                                            </button>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"> </div>
                </div >
            )}


            {isActiveSell && (
                <div div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title" id="modal_otp_Label">{buysellDetails.side} {" "} {coinDetails.base_currency} </h5>
                                    <button type="button" className="btn-close" onClick={() => setIsActiveSell(false)}></button>
                                </div>
                                <div className="modal-body p-md-4">
                                    <div className="tt_data ">
                                        <div className="tt_card" >
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Pay Currency: </span>
                                                <span className="tt_normal">{coinDetails.base_currency}</span>
                                            </div>
                                            <div className="tt_item">
                                                <span className="text-muted">Pay Amount :</span>
                                                <span className="tt_normal">{buysellDetails.payAmount}</span>
                                            </div>
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Get Currency: </span>
                                                <span className="tt_normal">INR</span>
                                            </div>

                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Get Amount : </span>
                                                <span className="tt_normal ">{buysellDetails.getAmount}</span>
                                            </div>
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Price / {currencyName}  </span>
                                                <span className="tt_normal">{currencyPrice} (INR)</span>
                                            </div>
                                        </div>

                                        <button className="form-control btn btn-danger mt-3 w-100 btn-block" onClick={() => handleBuySell(buysellDetails.payAmount, buysellDetails.getAmount, buysellDetails.side)}>
                                            {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                : <span>Quick Sell</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"> </div>
                </div >
            )}
        </main >
    )
}

export default BuySell