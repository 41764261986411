import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../Footer";


const GrievanceRedressalPrincipalOfficer = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])
    return (
        <>
        <main>
            <section class="tm_sec d-block">
                <div class="container">
                    <div class="row   mt-md-5 justify-content-center">
                            <div class="col-lg-12">
                            <div class="section-title  partner_title_sect text-start ">

                                <div className="row align-items-center g-4" >
                                    <div className="col-lg-6" >
                                        <h2 class="text-warning"> Grievance Redressal <br/> Mechanism  </h2> 
                                        <div className="ddd text-start"  > 
                                        <p>
                                        At Crypto India, we prioritize the satisfaction of our users and are committed to resolving any issues or concerns as quickly and efficiently as possible. Our multi-tier grievance redressal process ensures that your queries are addressed in a timely manner, with escalating levels of support available if needed.
                                        </p> 
                                        </div>
                                    </div>
                                    <div className="col-lg-6" >
                                        <div className="sec_img" >
                                            <img src="assets/images/grievance_redressal_mechanism.svg" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </div>     
                            </div>
                            
                            
                        </div>
    

                    </div>

                </div> 


                
                <div className="container mt-5  " >
                    <div className="section-title" >
                        <h2>How to Raise Your Concern</h2>
                        <p>If you have a complaint or feedback, please choose one of the following options:</p>
                    </div>
                     <div className="grm_row" >

                        {/* step_1 */}
                        <div className="grm_card" >
                            <div className="" >
                                <h6>Step 1:</h6>
                                <h5>Customer Support</h5>
                                <p>
                                If you have a query, issue, or concern, please contact our Customer Support Team first.
                                </p>
                                <div className="grm_con" >
                                    <p>Email: <a href="mailto:support@cryptoindia.in" > support@cryptoindia.in </a></p>
                                    <p>Contact: <a href="tel:014119166489" > 0141-19166489 </a></p>
                                    <p className="pr_txt  " >Response Time: <b>Within 24 hours</b></p> 
                                </div>
                                <hr className="my-1" />
                                <p className="mb-0 text-dark" >
                                    Our customer support team will do their best to resolve your issue at this initial level. 
                                </p>                                
                            </div>
                        </div>
                        {/* step_2 */}
                        <div className="grm_card" >
                            <div className="" >
                                <h6>Step 2:</h6>
                                <h5>  Escalation to Nodal Officer (Level 2) </h5>
                                <p>
                                If your issue is not resolved satisfactorily by our customer support team, you can escalate the matter to our Principal Nodal Officer (Level 2). The nodal officer will review your case and ensure a proper resolution.
                                </p>
                                <p>Principal Nodal Officer Contact Information</p>
                                <div className="grm_con" >
                                    <p>Name:  Pardeep Kumar Yadav </p>
                                    <p>Email: <a href="mailto:nodal@cryptoindia.in" > nodal@cryptoindia.in </a></p>
                                    <p className="pr_txt mb-0" >Response Time: <b>Time: Within 48 hours</b></p>
                                </div>
                                
                            </div>
                        </div>
                        
                        {/* step_3 */}
                        <div className="grm_card" >
                            <div className="" >
                                <h6>Step 3:</h6>
                                <h5>  Escalation to CEO (Level 3) </h5>
                                <p>
                                If you are still not satisfied with the resolution provided by the Nodal Officer, you may escalate the matter to the CEO (Level 3) of Crypto India.
                                </p>
                                <p>CEO Contact Information</p>
                                <div className="grm_con" >
                                <p>Name:  Pardeep Kumar Yadav </p>
                                    <p>Contact: <a href="ceo@cryptoindia.in" > ceo@cryptoindia.in </a></p>
                                    <p className="pr_txt mb-0" >Response Time: <b>Time: Within 72 hours
                                    </b></p>
                                </div>
                                
                            </div>
                        </div>

                        
                     </div>
                </div> 

                <section className="comp_sec" >
                    <div className="container" >
                        <div className="other_info" >
                            <h6>How to File a Complaint</h6>
                            <p>Contact our Customer Support Team via email or phone to raise your concern.
                            If unresolved, escalate your issue to our Nodal Officer by providing a reference to your original query or ticket number.</p>
                            <p>If necessary, escalate to the CEO for a final resolution.
                            We aim to resolve all issues within the specified timelines at each level. Your satisfaction and trust are important to us, and we are here to assist you at every step.</p>
                        </div>
                    </div>
                </section>


            </section>
            
        </main>
        
        <Footer />
        </>

    )
}

export default GrievanceRedressalPrincipalOfficer