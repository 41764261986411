import React, { useContext, useEffect, useState } from "react";
import { ProfileContext } from "../../../../Services/ProfileProvider";
import LoaderHelper from "../../../../Services/Loading/LoaderHelper";
import { alertErrorMessage, alertSuccessMessage } from "../../../../Services/CustomAlertModel";
import AuthService from "../../../../Services/ApiServices/AuthService";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";


const CurrencyManagement = () => {

    const { userDetails, handleUserProfile } = useContext(ProfileContext);

    const [currencyType, setCurrencyType] = useState(userDetails?.user?.currency_prefrence);


    const handleCurrencyTypeChange = (e) => {
        setCurrencyType(e.target.value);
    };

    useEffect(() => {
        setCurrencyType(userDetails?.user?.currency_prefrence);
    }, [userDetails]);


    const handleCurrency = async (currencyType) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.updateCurrency(currencyType);
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                alertSuccessMessage(result.message);
                handleUserProfile();
            } else {
                alertErrorMessage(result.message);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };


    return (
        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper">
                    <section className="d_page m_account  history_page">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xxl-8 col-xl-12">
                                    <div className="g_start">
                                        <h6 className="mb-0">Currency Preference</h6>
                                        <p>Select your preferred display currency for all markets</p>
                                        <div className=" mt-4  p_card currency_card">
                                            <div className="row mb-4 mb-md-5">
                                                <div className="col-md-6">
                                                    <label className="card-radio-btn mb-3 label">
                                                        <input
                                                            type="radio"
                                                            className="card-input-element d-none"
                                                            name="currencyType"
                                                            value="USDT"
                                                            checked={currencyType === "USDT"}
                                                            onChange={handleCurrencyTypeChange}
                                                        />
                                                        <div className="card card-body">
                                                            <img src="/assets/images/icon/tether.png" className="img-fluid check_img" />
                                                            <div className="content_head mx-3">Tether USD (USDT)</div>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="card-radio-btn mb-3 label ">
                                                        <input
                                                            type="radio"
                                                            className="card-input-element d-none"
                                                            name="currencyType"
                                                            value="BTC"
                                                            checked={currencyType === "BTC"}
                                                            onChange={handleCurrencyTypeChange}
                                                        />
                                                        <div className="card card-body">
                                                            <img src="/assets/images/icon/btc.png" className="img-fluid check_img" />
                                                            <div className="content_head mx-3">Bitcoin (BTC)</div>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="card-radio-btn mb-3 label">
                                                        <input
                                                            type="radio"
                                                            className="card-input-element d-none"
                                                            name="currencyType"
                                                            value="BNB"
                                                            checked={currencyType === "BNB"}
                                                            onChange={handleCurrencyTypeChange}
                                                        />
                                                        <div className="card card-body">
                                                            <img src="/assets/images/icon/bnb.png" className="img-fluid check_img" />
                                                            <div className="content_head mx-3">Binance coin (BNB)</div>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="card-radio-btn mb-3 label">
                                                        <input
                                                            type="radio"
                                                            className="card-input-element d-none"
                                                            name="currencyType"
                                                            value="INR"
                                                            checked={currencyType === "INR"}
                                                            onChange={handleCurrencyTypeChange}
                                                        />
                                                        <div className="card card-body">
                                                            <img src="/assets/images/icon/rupee.png" className="img-fluid check_img" />
                                                            <div className="content_head mx-3">Rupee (INR)</div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="dup_right text-end">
                                                <button className="btn btn-dark" onClick={() => handleCurrency(currencyType)}>Save Settings</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
};

export default CurrencyManagement;
