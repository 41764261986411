import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import MenuItems from "../../../Context/MenuItems";

const BuysellSidebar = () => {
    const location = useLocation();
    const [isActive, setIsActive] = useState(location.pathname);
    const [activeMenu, setActiveMenu] = useState(false);
    const [menuName, setMenuName] = useState('Dashboard');
    const [menuIcon, setMenuIcon] = useState('ri-home-6-line');

    useEffect(() => {
        const currentMenu = MenuItems[location.pathname] || MenuItems["/"];
        setMenuName(currentMenu.name);
        setMenuIcon(currentMenu.icon);
    }, [location.pathname]);

    const handleSetActive = (path) => {
        setIsActive(path);
    };

    const handleMenuToggle = () => {
        setActiveMenu(prevState => !prevState);
    };


    return (
        <div className="sidebar bs_sidebar">
            <div className="sub_header d_sub_header">
                <div className="sub_header_row">
                    <div className="sub_header_l">
                        <span className="side_dropdown-item main_menu cursor-pointer" onClick={handleMenuToggle}>
                            <span><i className={menuIcon}></i> {menuName}</span>
                        </span>

                        <nav className={`navbar-nav navbar side_nav ${activeMenu && 'active'}`} id="sub_nav">

                            <li className={`side_dropdown ${activeMenu && 'active'}`}>
                                <Link className={`side_dropdown-toggle nav-link ${isActive === "/wallet" ? 'active' : ''}`}
                                    to="/wallet"
                                    onClick={() => handleSetActive('/wallet')}>
                                    <span>
                                        <i className="ri-wallet-line"></i> Wallet
                                    </span>
                                </Link>
                            </li>

                            <li className={`side_dropdown ${activeMenu && 'active'}`}>
                                <Link className={`side_dropdown-toggle nav-link ${isActive === "/buy_sell_transactions" ? 'active' : ''}`}
                                    to="/buy_sell_transactions"
                                    onClick={() => handleSetActive('/buy_sell_transactions')}>
                                    <span>
                                        <i className="ri-file-list-3-line"></i> History
                                    </span>
                                </Link>
                            </li>

                            <li className={`side_dropdown ${activeMenu && 'active'}`}>
                                <Link className={`side_dropdown-toggle nav-link ${isActive === "/payments" ? 'active' : ''}`}
                                    to="/payments"
                                    onClick={() => handleSetActive('/payments')}>
                                    <span>
                                        <i className="ri-bank-card-line"></i> Payments
                                    </span>
                                </Link>
                            </li>

                            <li className={`side_dropdown ${activeMenu && 'active'}`}>
                                <Link className={`side_dropdown-toggle nav-link ${isActive === "/all_notificatios" ? 'active' : ''}`}
                                    to="/all_notificatios"
                                    onClick={() => handleSetActive('/all_notificatios')}>
                                    <span>
                                        <i className="ri-notification-line"></i> Notifications
                                    </span>
                                </Link>
                            </li>

                            <li className={`side_dropdown ${activeMenu && 'active'}`}>
                                <Link className={`side_dropdown-toggle nav-link ${isActive === "/support" ? 'active' : ''}`}
                                    to="/support"
                                    onClick={() => handleSetActive('/support')}>
                                    <span>
                                        <i className="ri-message-2-line"></i> Support
                                    </span>
                                </Link>
                            </li>

                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuysellSidebar;
