import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DashboardModule from "../../DashboardModule";
import { ProfileContext } from "../../../../Services/ProfileProvider";
const ReciveCrypto = () => {

    const { userDetails } = useContext(ProfileContext);


    return (
        <main className=" qp_wrapper qp_wrapper_send" >
            <div className="page_wrapper d-block" >
                <div className="qp_body" >
                    <div className="container" >
                        <div className="qp_sub_head" >
                            <div className="row align-items-center" >
                                <div className="col-lg-6" >
                                    <div className="sub_title" >
                                        <h3>
                                            <Link to="/quick_pay" className="current-color" >
                                                <small className="cursor-pointer" ><i class="ri-arrow-left-long-line me-2"></i></small>
                                            </Link>
                                            Receive
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-lg-6" >
                                    <div className="cl_action" >
                                        <div className="sub_info" >
                                            <Link to="/pay_history" className="text_link current-color">Transaction History</Link>
                                            <Link to="/send_quick_pay" className="btn btn-muted">Send</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sub_body" >
                            <div class="row justify-content-center">
                                <div class="col-xl-8 multistep_form ">
                                    {/* step 1 */}
                                    <fieldset class="current  ">
                                        <div class="row justify-content-center ">
                                            <div class="col-xl-6">
                                                <div className="form-card" >
                                                    <div class="st_tab">
                                                        <div class="rc_profile">
                                                            <i class=" ">
                                                                <img src="assets/images/user_icon.svg" class="img-fluid" alt="" />
                                                            </i>
                                                            <div class="ud">
                                                                <h5>AJAY KUMAR JANGID</h5>
                                                                <span> aj**@appinop.com</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="qr_body qr_recive" >
                                                        <div className="" >
                                                            <p>Scan this QR to send</p>
                                                        </div>
                                                        <canvas height="176" width="176" class="img-fluid qr_img" style={{ height: "176", width: "176" }}></canvas>
                                                    </div>
                                                    <div className="c_view  c_view_xl" >
                                                        <div class="d-flex align-items-center">
                                                            <img src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" alt="" />
                                                            <span>USDT </span>
                                                        </div>
                                                        <h5 className="mb-0" >1,222</h5>
                                                    </div>
                                                    <div className="qr_link" >
                                                        <a href="#" className="btn btn-link p-0" > Set  Amount </a>
                                                        <a href="#" className="btn btn-link p-0" > Save QR Code </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* set amount_modal */}
            <div div className="modal-open-custom   d-none" >
                <div className="modal show d-block" >
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className="modal-header" >
                                <h5 className="modal-title" >Select Currency</h5>
                                <button type="button" className="btn-close"  ></button>
                            </div>
                            <div className="modal-body p-md-4">
                                <div className="row align-items-center" >
                                    <div className="col-lg-12 mb-3" >
                                        <label className="form-label" > Currency </label>
                                        <select className="form-control form-select  " >
                                            <option>   BNB </option>
                                            <option>   BTC </option>
                                        </select>
                                    </div>
                                    <div className="col-lg-12 mb-3" >
                                        <label className="form-label d-flex align-items-center justify-content-between"> Amount (optional)    </label>
                                        <input type="text" className="form-control" placeholder="Enter Amount" />
                                    </div>
                                    <div className="col-lg-12 mb-4" >
                                        <label className="form-label d-flex align-items-center justify-content-between"> Note (optional)    </label>
                                        <input type="text" className="form-control" placeholder="Type a message " />
                                    </div>
                                    <div className="col-lg-12  " >
                                        <button className="form-control btn-warning" >
                                            Confirm
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"> </div>
            </div >
        </main>
    )
}

export default ReciveCrypto