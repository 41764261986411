import React from 'react';
import { Link } from 'react-router-dom';


const Whyus = () => {

    return (
        <section className="features_sec feature_bg">
            <div className="container">
                {/* <div className="xx_title">
                    <h2 className="text-uppercase text-warning text-end"> wHY US ? </h2>
                </div> */}
                <div className="sec_title mb-5">
                    <h2 className="text-dark text-center "> Why Choose Us? </h2>
                    <p>
                        Maximize Your Crypto Trading Potential with Spot and Futures Markets
                    </p>
                </div>
                <div className="row g-3 justify-content-center">
                    <div className="col-lg-5">
                        <div className="card h-100">
                            <div className="card-body p-0  h-100">
                                <div className="how_card  card_why border-warning  h-100">
                                    <img src="/assets/images/platform.svg" className="img-fluid me-4" />
                                    <div>
                                        <h6 className="text">
                                        User-Friendly Platform
                                        </h6>
                                        <span></span>
                                        <p>Whether you're new to crypto or a seasoned trader, our platform is designed for simplicity and ease of use, making your trading journey effortless. </p>
                                        {/* <Link className="btn btn-warning btn-icon" to="/coming_soon"><i className="ri-arrow-right-line"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="card h-100">
                            <div className="card-body p-0  h-100">
                                <div className="how_card card_why border-warning  h-100">
                                    <img src="/assets/images/inr_wallet.svg" className="img-fluid me-4" />
                                    <div>
                                        <h6 className="text">
                                        INR Deposits & Withdrawals
                                        </h6>
                                        <span></span>
                                        <p>Fast and seamless transactions, allowing you to manage your funds without delays</p>
                                        {/* <Link className="btn btn-warning btn-icon" to="/coming_soon"><i className="ri-arrow-right-line"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-3 mt-2 mt-md-2 justify-content-center">
                    <div className="col-lg-5">
                        <div className="card h-100">
                            <div className="card-body p-0">
                                <div className="how_card card_why border-warning  h-100">
                                    <img src="/assets/images/fund_security.svg" className="img-fluid me-4" />
                                    <div>
                                        <h6 className="text">
                                        Top-Tier Fund Security
                                        </h6>
                                        <span></span>
                                        <p>At Crypto India, your security is our top priority. We use advanced encryption and strict protocols to safeguard your assets and data at all times. </p>
                                        {/* <Link className="btn btn-warning btn-icon" to="/coming_soon"><i className="ri-arrow-right-line"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="card h-100">
                            <div className="card-body p-0">
                                <div className="how_card card_why border-warning  h-100">
                                    <img src="/assets/images/user_support.svg" className="img-fluid me-4" />
                                    <div>
                                        <h6 className="text">
                                        24x7 Support
                                        </h6>
                                        <span></span>
                                        <p>The Crypto India support team is available around the clock to help with any questions or concerns, ensuring a seamless trading experience.</p>
                                        {/* <Link className="btn btn-warning btn-icon" to="/coming_soon"><i className="ri-arrow-right-line"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Whyus