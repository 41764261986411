import { ApiConfig } from "../../ApiServices/AuthAction/ApiConfig";
import { ApiCallDelete, ApiCallGET, ApiCallPost, ApiCallPut } from "../../ApiServices/AuthAction/Apicall";

const AuthService = {

  register: async (formData) => {
    const { baseUser, register } = ApiConfig;
    const url = baseUser + register;
    const params = {
      email: formData.emailId?.toLowerCase(),
      eotp: formData.emailOtp,
      phone: formData.mobileNumber,
      potp: formData.mobileOtp,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
      countryCode: formData.countryCode,
      referral_code: formData.referral_code,

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  checkEmailPhone: async (signId, type) => {
    const { baseUser, check_attempt } = ApiConfig;
    const url = baseUser + check_attempt;
    const params = {
      email_or_phone: signId,
      type: type,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  antityRegister: async (formData) => {
    const { baseUser, register } = ApiConfig;
    const url = baseUser + register;
    const params = {
      entity_name: formData.entityName,
      entity_type: formData.entityType,
      email: formData.emailId?.toLowerCase(),
      eotp: formData.emailOtp,
      phone: formData.mobileNumber,
      potp: formData.mobileOtp,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
      countryCode: formData.countryCode,
      referral_code: formData.referral_code,
      user_type: "Entity",

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getOtp: async (signId, type) => {
    const { baseUser, getOtp } = ApiConfig;
    const url = baseUser + getOtp;
    const params = {
      email_or_phone: signId?.toLowerCase(),
      type: type,
      "resend": true
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  login: async (formData) => {
    const { baseUser, signIn } = ApiConfig;
    const url = baseUser + signIn;
    const params = {
      email_or_phone: formData.signId?.toLowerCase(),
      password: formData.password,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  codeVerification: async (signId, verificationcode) => {
    const { baseUser, verify_otp } = ApiConfig;
    const url = baseUser + verify_otp;
    const params = {
      email_or_phone: signId,
      verification_code: +verificationcode
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  forgotPassword: async (formData) => {
    const { baseUser, forgotPassword } = ApiConfig;
    const url = baseUser + forgotPassword;
    const params = {
      email: formData.emailId,
      eotp: +formData.email_otp,
      phone: +formData.mobileNumber,
      potp: +formData.mob_otp,
      newPassword: formData.password,
      confirmPassword: formData.confirmPassword,
      countryCode: formData.countryCode,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  getDetails: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, userDetails } = ApiConfig;
    const url = baseUser + userDetails;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  addNomini: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, add_nominee } = ApiConfig;
    const url = baseUser + add_nominee;
    const params = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      relation: formData.relation,
      adhaar_number: formData.aadhar_number,
      dob: formData.date,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  addBankAccount: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    let formattedName = formData?.accountHolderName?.trim().replace(/\s+/g, ' ');
    const { baseUser, add_bank } = ApiConfig;
    const url = baseUser + add_bank;
    const params = {
      email: formData.emailId,
      verification_code: formData.emailOtp,
      account_holder_name: formattedName,
      accountNumber: formData.accountNumber,
      ifsc: formData.ifscCode,
      account_type: formData.accountType,
      bank_name: formData.bank_name,
      branch_name: formData.branch_name,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  uploadBankDocument: async (formData) => {
    const token = localStorage.getItem("token");
    const { baseUser, upload_bank_document } = ApiConfig;
    const url = baseUser + upload_bank_document;
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': token
    };
    return ApiCallPost(url, formData, headers);
  },

  // editBankAccount: async (formData, isDefault) => {
  //   const Authtoken = localStorage.getItem("token");
  //   const { baseUser, edit_bank } = ApiConfig;
  //   const url = `${baseUser}${edit_bank}/${formData?.userbankId}`;
  //   const params = {
  //     email: formData.emailId,
  //     verification_code: formData.emailOtp,
  //     account_holder_name: formData.accountHolderName,
  //     account_number: formData.reEnterAccountNumber,
  //     ifsc: formData.ifscCode,
  //     account_type: formData.accountType,
  //     bank_name: formData.bank_name,
  //     branch_name: formData.branch_name,
  //     is_default: isDefault,
  //   };
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: Authtoken,
  //   };
  //   return ApiCallPut(url, params, headers);
  // },

  updateDefaultBank: async (accountNumber) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, default_bank } = ApiConfig;
    const url = baseUser + default_bank;
    const params = {
      accountNumber: accountNumber,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPut(url, params, headers);
  },

  addUpiDetails: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    let formattedName = formData?.accountHolderName?.trim().replace(/\s+/g, ' ');
    const { baseUser, verify_Upi } = ApiConfig;
    const url = baseUser + verify_Upi;
    const params = {
      email: formData.emailId,
      otp: formData.emailOtp,
      feName: formattedName,
      vpaId: formData.upiId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  // editUpiDetails: async (formData) => {
  //   const Authtoken = localStorage.getItem("token");
  //   const { baseUser, edit_upi } = ApiConfig;
  //   const url = `${baseUser}${edit_upi}/${formData?.userUpiId}`;
  //   const params = {
  //     email: formData.emailId,
  //     verification_code: formData.emailOtp,
  //     upi_name: formData.userName,
  //     upi_vpa_id: formData.upiId,
  //   };
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: Authtoken,
  //   };
  //   return ApiCallPut(url, params, headers);
  // },

  updateDefaultUpi: async (vpaId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, default_upi } = ApiConfig;
    const url = baseUser + default_upi;
    const params = {
      vpaId: vpaId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPut(url, params, headers);
  },

  deletetUpi: async (vpaId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, delete_upi } = ApiConfig;
    const url = `${baseUser}${delete_upi}/${vpaId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallDelete(url, headers);
  },

  deletetBank: async (accountNumber) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, delete_bank } = ApiConfig;
    const url = `${baseUser}${delete_bank}/${accountNumber}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallDelete(url, headers);
  },

  updateProfile: async (formData) => {
    const token = localStorage.getItem("token");
    const { baseUser, editProfile } = ApiConfig;
    const url = baseUser + editProfile;
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': token
    };
    return ApiCallPut(url, formData, headers);
  },

  changePassword: async (formData) => {
    const token = localStorage.getItem("token");
    const { baseUser, change_password } = ApiConfig;
    const url = baseUser + change_password;
    const params = {
      email_or_phone: formData.signId,
      verification_code: formData.verificationCode,
      newPassword: formData.newPassword,
      confirmPassword: formData.confirmPassword,
      currentPassword: formData.currentPassword,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,

    };
    return ApiCallPost(url, params, headers);
  },

  updateCurrency: async (currencyType) => {
    const token = localStorage.getItem("token");
    const { baseUser, edit_currency } = ApiConfig;
    const url = baseUser + edit_currency;
    const params = {
      currency: currencyType,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  genrateGoogleQr: async () => {
    const token = localStorage.getItem("token");
    const { baseUser, genrate_google_qr } = ApiConfig;
    const url = baseUser + genrate_google_qr;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  buy_sell: async (payAmount, getAmount, side, base_currency, quote_currency, base_currency_id, quote_currency_id, checkButton, currencyPrice) => {
    const Authtoken = localStorage.getItem("token");
    const { baseqbs, buySell } = ApiConfig;
    const url = baseqbs + buySell;
    const params = {
      pay_amount: +payAmount,
      get_amount: +getAmount,
      side: side,
      base_currency: base_currency,
      quote_currency: quote_currency,
      base_currency_id: base_currency_id,
      quote_currency_id: quote_currency_id,
      use_available: checkButton,
      price: currencyPrice
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  cancelBuySellOrder: async (id) => {
    const Authtoken = localStorage.getItem("token");
    const { baseqbs, cancel_qbs_order } = ApiConfig;
    const url = baseqbs + cancel_qbs_order + `/${id}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  walletBalance: async (currencyName) => {
    const Authtoken = localStorage.getItem("token");
    const { baseWallet, walletby_coinName } = ApiConfig;
    const url = baseWallet + walletby_coinName + `?short_name=${currencyName}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  getCoinList: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseWallet, getWallet } = ApiConfig;
    const url = baseWallet + getWallet;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  submitKyc: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, identityKyc } = ApiConfig;
    const url = baseUser + identityKyc;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, formData, headers);
  },


  getCoinDetails: async (coinId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseCoin, coin_details } = ApiConfig;
    const url = baseCoin + coin_details + `?_id=${coinId}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': Authtoken
    };
    return ApiCallGET(url, headers);
  },

  favoriteList: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, favoriteList } = ApiConfig;
    const url = baseUser + favoriteList;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  getPairs: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseCoin, getpairs } = ApiConfig;
    const url = baseCoin + getpairs;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  placeOrder: async (infoPlaceOrder, buyprice, buyamount, base_currency_id, quote_currency_id, side) => {
    const Authtoken = localStorage.getItem("token");
    const { baseExchange, placeOrder } = ApiConfig;
    const url = baseExchange + placeOrder;
    const params = {
      "order_type": infoPlaceOrder,
      "base_currency_id": base_currency_id,
      "quote_currency_id": quote_currency_id,
      "side": side,
      "price": +buyprice,
      "quantity": +buyamount
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },


  pastOrder: async (base_currency_id, quote_currency_id) => {
    const Authtoken = localStorage.getItem("token");
    const { baseExchange, pastOrder } = ApiConfig;
    const url = baseExchange + pastOrder;
    const params = {
      "base_currency_id": base_currency_id,
      "quote_currency_id": quote_currency_id,
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  cancelOrder: async (orderId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseExchange, cancelOrder } = ApiConfig;
    const url = baseExchange + cancelOrder;
    const params = {
      "order_id": orderId
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  cryptoCompareApi: async (base, quote, to, resolution) => {
    const Authtoken = localStorage.getItem("token");
    const url = `https://min-api.cryptocompare.com/data/v2/${resolution}?fsym=${base}&tsym=${quote}&to=${to}&limit=2000&api_key="0ab764c3c79b69db81f0971ce7a7e809531013afbed9a6893a9e7a0ce547b6c4"`
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  getHistoricalData: async (base, quote, from, to) => {
    const Authtoken = localStorage.getItem("token");
    const { baseExchange, getHistoricalData } = ApiConfig;
    const url = baseExchange + getHistoricalData;
    const params = {
      base_currency: base,
      quote_currency: quote,
      from: from,
      to: to,
      limit: 2000,
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  updateFavoriteCoin: async (pairId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, favoriteCoin } = ApiConfig;
    const url = baseUser + favoriteCoin;
    const params = {
      "pair_id": pairId
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  generateDepositAddress: async (chain) => {
    const Authtoken = localStorage.getItem("token");
    const { baseAddress, depositAddress } = ApiConfig;
    const url = baseAddress + depositAddress;
    const params = {
      "chain": chain
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPut(url, params, headers);
  },




  buySellTranactionList: async (skip, limit, selectedTab, side, startDate, endDate) => {
    const validStartDate = startDate || "";
    const validEndDate = endDate || "";
    const validSide = side || "";
    const valiStatus = selectedTab || "";
    const Authtoken = localStorage.getItem("token");
    const { baseqbs } = ApiConfig;
    const url = baseqbs + `quick-buy-sell-history?skip=${skip}&limit=${limit}&status=${valiStatus}&side=${validSide}&start_date=${validStartDate}&end_date=${validEndDate}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  spotList: async (skip, limit, startDate, endDate) => {
    const token = localStorage.getItem("token");
    const { baseExchange } = ApiConfig;
    const url = baseExchange + `trade-history?skip=${skip}&limit=${limit}&start_date=${startDate}&end_date=${endDate}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  openOrderList: async (skip, limit, startDate, endDate) => {
    const token = localStorage.getItem("token");
    const { baseExchange } = ApiConfig;
    const url = baseExchange + `open-orders?skip=${skip}&limit=${limit}&start_date=${startDate}&end_date=${endDate}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  depositDatawithdrawalData: async (skip, limit, startDate, endDate, type) => {
    const Authtoken = localStorage.getItem("token");
    const { baseWallet } = ApiConfig;
    const url = baseWallet + `user-deposit-withdrawal-history?type=${type}&skip=${skip}&limit=${limit}&start_date=${startDate}&end_date=${endDate}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  exportDataSpot: async (startDate, endDate) => {
    const token = localStorage.getItem("token");
    const { baseExchange } = ApiConfig;
    const url = baseExchange + `export-trade-history?start_date=${startDate}&end_date=${endDate}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  exportDataBuySell: async (startDate, endDate) => {
    const token = localStorage.getItem("token");
    const { baseqbs } = ApiConfig;
    const url = baseqbs + `export-quick-buy-sell-history?start_date=${startDate}&end_date=${endDate}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  exportDataDepositWithdrawal: async (startDate, endDate, status) => {
    const token = localStorage.getItem("token");
    const { baseWallet } = ApiConfig;
    const url = baseWallet + `export-user-deposit-withdrawal-history?start_date=${startDate}&end_date=${endDate}&status=${status}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  verifyDeposit: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseAddress, verify_deposit } = ApiConfig;
    const url = baseAddress + verify_deposit;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  cryptoWithdrawal: async (walletAddress, amountValue, otp, chainName, email, currency_id) => {
    const token = localStorage.getItem("token");
    const { baseWallet, crypto_ithdrawal } = ApiConfig;
    const url = baseWallet + crypto_ithdrawal;
    const params = {
      address: walletAddress,
      quantity: amountValue,
      verification_code: otp,
      chain: chainName,
      email_or_phone: email,
      currency_id: currency_id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,

    };
    return ApiCallPost(url, params, headers);
  },

  changeTwofact: async (sselectedOption, verificationCode, selectedAuth) => {
    const token = localStorage.getItem("token");
    const { baseUser, update2fa } = ApiConfig;
    const url = baseUser + update2fa;
    const params = {
      type: sselectedOption,
      verification_code: verificationCode,
      email_or_phone: selectedAuth
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  supportTicketList: async (skip, limit) => {
    const token = localStorage.getItem("token");
    const { baseUser } = ApiConfig;
    const url = baseUser + `user-ticket-list?skip=${skip}&limit=${limit}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  submitTicket: async (formData) => {
    const token = localStorage.getItem("token");
    const { baseUser, update_ticket } = ApiConfig;
    const url = baseUser + update_ticket;
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': token
    };

    return ApiCallPost(url, formData, headers);
  },

  cancelAllOrder: async () => {
    const token = localStorage.getItem("token");
    const { baseExchange, cancel_all_order } = ApiConfig;
    const url = baseExchange + cancel_all_order;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallDelete(url, headers);
  },


  manageFeature: async (featureName, isEnabled) => {
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("user_id");
    const { baseUser, update_permission } = ApiConfig;
    const url = `${baseUser}${update_permission}/${user_id}`;
    const params = {
      [featureName]: isEnabled
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallPut(url, params, headers);
  },

  deleteTicket: async (ticketId) => {
    const token = localStorage.getItem("token");
    const { baseUser, delete_ticket } = ApiConfig;
    const url = `${baseUser}${delete_ticket}/${ticketId}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallDelete(url, headers);
  },

  notificationList: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, notifications } = ApiConfig;
    const url = baseUser + notifications;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  accountActivities: async (skip, limit) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser } = ApiConfig;
    const url = baseUser + `activity-logs?skip=${skip}&limit=${limit}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  referList: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, user_referral_list } = ApiConfig;
    const url = baseUser + user_referral_list;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': Authtoken
    };
    return ApiCallGET(url, headers);
  },

  sendUserMessage: async (ticket_id, sendMessage) => {
    const token = localStorage.getItem("token");
    const { baseUser, ticket_user_reply } = ApiConfig;
    const url = baseUser + ticket_user_reply;
    const params = {
      ticket_id: ticket_id,
      message: sendMessage,
      message_by: 1,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,

    };
    return ApiCallPost(url, params, headers);
  },

  getMessageById: async (ticketId) => {
    const token = localStorage.getItem("token");
    const { baseUser, single_ticket } = ApiConfig;
    const url = `${baseUser}${single_ticket}/${ticketId}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallGET(url, headers);
  },


  getAdminBankDetails: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, admin_bank_details } = ApiConfig;
    const url = baseUser + admin_bank_details;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  getAdminUpiDetails: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, admin_upi_details } = ApiConfig;
    const url = baseUser + admin_upi_details;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  coinListDetails: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, qbs_list } = ApiConfig;
    const url = baseUser + qbs_list;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  addInr: async (addAmount, utrNumber, selectedRowData, activeTabInr) => {
    const Authtoken = localStorage.getItem("token");
    const { baseqbs, utr_trans } = ApiConfig;
    const url = baseqbs + utr_trans;
    const params = {
      addAmount: addAmount,
      utr_number: utrNumber,
      id: selectedRowData,
      type: activeTabInr,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPut(url, params, headers);
  },

  /* /******Persnol Details/ */


  getuserKycStep: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, fetch_kyc_step } = ApiConfig;
    const url = baseUser + fetch_kyc_step;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  updateKycStepStatus: async (step) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, update_kyc_step } = ApiConfig;
    const url = baseUser + update_kyc_step;
    const params = {
      step: step,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPut(url, params, headers);
  },

  verifyPancard: async (itemName) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, verify_pan } = ApiConfig;
    const url = baseUser + verify_pan;
    const params = {
      pan: itemName,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  getAmlCheckStatus: async (pan, name, dateOfBirth) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, user_aml_check } = ApiConfig;
    const url = baseUser + user_aml_check;
    const params = {
      name: name,
      dob: dateOfBirth,
      idNumber: pan,
      idType: "PAN"
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },



  verifyAadharDocument: async (aadharNumber, referenceId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, generate_adhaar_otp } = ApiConfig;
    const url = baseUser + generate_adhaar_otp;
    const params = {
      source: aadharNumber,
      reference_id: String(referenceId)
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  getAadharDetails: async (aadharOtp, transaction_id, reference_id, panNumber) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, adhaar_details } = ApiConfig;
    const url = baseUser + adhaar_details;
    const params = {
      otp: aadharOtp,
      reference_id: String(reference_id),
      transaction_id: String(transaction_id),
      pan: panNumber
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  // verifyDrivingLicance: async (drivingNumber, dob) => {
  //   const Authtoken = localStorage.getItem("token");
  //   const { baseUser, dl_details } = ApiConfig;
  //   const url = baseUser + dl_details;
  //   const params = {
  //     dlNumber: drivingNumber,
  //     dob: dob
  //   };
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: Authtoken,
  //   };
  //   return ApiCallPost(url, params, headers);
  // },

/*   verifyPassport: async (passportFileNumber, passportDob, passportIssueDate, passportNumber, passportUserName) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, passport_details } = ApiConfig;
    const url = baseUser + passport_details;
    const params = {
      fileNo: passportFileNumber,
      dob: passportDob,
      doi: passportIssueDate,
      passportNo: passportNumber,
      name: passportUserName,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  }, */

  livenessPicture: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, check_user_liveness } = ApiConfig;
    const url = baseUser + check_user_liveness;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, formData, headers);
  },

  getFaceMatchData: async (liveface_image, adhaar_image) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, user_face_match } = ApiConfig;
    const url = baseUser + user_face_match;
    const params = {
      imageUrl1: adhaar_image,
      imageUrl2: liveface_image
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  submitEducationOccupation: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, other_kyc_details } = ApiConfig;
    const url = baseUser + other_kyc_details;
    const params = {
      education: formData?.edituaction,
      marital_status: formData?.maritalStatus,
      occupation: formData?.occupation,
      annual_income: +formData?.annualIncome,
      annual_turnover: +formData?.tradingTurnover,
      pep: formData?.politically,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  submitOtherImages: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, upload_documents } = ApiConfig;
    const url = baseUser + upload_documents;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, formData, headers);
  },

  declaration: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, kyc_declaration } = ApiConfig;
    const url = baseUser + kyc_declaration;
    const params = {
      declaration_submitted: true
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  updateKycStatus: async (status) => {
    const Authtoken = localStorage.getItem("token");
    const userId = localStorage.getItem("user_id");
    const { baseUser, update_kyc_status } = ApiConfig;
    const url = baseUser + update_kyc_status;
    const params = {
      userId: userId,
      status: status,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPut(url, params, headers);
  },


  getKycDetails: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, kyc_details } = ApiConfig;
    const url = baseUser + kyc_details;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  /* /******Entity Details/ */

  getKycDetailsEntity: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseEntity, kyc_details } = ApiConfig;
    const url = baseEntity + kyc_details;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  getEntityPanDetails: async (itemName, name) => {
    const Authtoken = localStorage.getItem("token");
    const { baseEntity, entity_pan_details } = ApiConfig;
    const url = baseEntity + entity_pan_details;
    const params = {
      user_type: "Entity",
      pan: itemName,
      directorName: name
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  getAmlCheckStatusEntity: async (panNumber, name) => {
    const Authtoken = localStorage.getItem("token");
    const { baseEntity, entityAmlData } = ApiConfig;
    const url = baseEntity + entityAmlData;
    const params = {
      idNumber: panNumber,
      idType: panNumber,
      name: name
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  getEntityCinDetails: async (itemName) => {
    const Authtoken = localStorage.getItem("token");
    const { baseEntity, cinDetails } = ApiConfig;
    const url = baseEntity + cinDetails;
    const params = {
      cin: itemName,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  submitEntityDetails: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    const { baseEntity, entity_details } = ApiConfig;
    const url = baseEntity + entity_details;
    const params = {

      entity_name: formData?.entityName,
      entity_type: formData?.entityType,
      registered_country: formData?.countryName,
      registration_number: formData?.registrationNo,
      date: formData?.IncorporationDate,
      website: formData?.website,
      registered_address: formData?.registerdAddress,
      registered_address_city: formData?.registerdCity,
      registered_address_state: formData?.registerdState,
      registered_address_pincode: formData?.registerdPinCode,
      operational_address: formData?.opertationalAddress,
      operational_address_city: formData?.opertationalCity,
      operational_address_state: formData?.opertationalState,
      operational_address_pincode: formData?.opertationalPinCode,
      email_id: formData?.emailId,
      mobile_number: formData?.mobileNumber,
      purpose: formData?.purposeApplication,
      annual_turnover: formData?.annualTrunover,
      source_of_capital: formData?.sourceCapital,
      total_assets: +formData?.totalAssets,
      gst_number: formData?.gstNumber,
      fiu_number: formData?.fiuNumber,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  verifyDirectorPancard: async (itemName, director, Entity) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, verify_pan } = ApiConfig;
    const url = baseUser + verify_pan;
    const params = {
      pan: itemName,
      director_id: director,
      user_type: Entity
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  verifyEntityAadharDocument: async (documentNumber, captchaNumber, director, sessionId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, generate_adhaar_otp } = ApiConfig;
    const url = baseUser + generate_adhaar_otp;
    const params = {
      sessionId: sessionId,
      aadhaar: documentNumber,
      securityCode: captchaNumber,
      director_id: director,
      user_type: "Director"
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  getEntityAadharDetails: async (aadharOtp, directorId, sessionId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, adhaar_details } = ApiConfig;
    const url = baseUser + adhaar_details;
    const params = {
      otp: aadharOtp,
      sessionId: sessionId,
      directorId: String(directorId),
      user_type: "Director"
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  verifyUboMore: async (uboData) => {
    const Authtoken = localStorage.getItem("token");
    const { baseEntity, saveUboData } = ApiConfig;
    const url = baseEntity + saveUboData;
    const params = { uboData };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  verifyMainTrader: async (mainTraderName, aadharImage) => {
    const Authtoken = localStorage.getItem("token");
    const { baseEntity, main_trader } = ApiConfig;
    const url = baseEntity + main_trader;
    const params = { mainTraderName, aadharImage };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  EntitylivenessPicture: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, check_user_liveness } = ApiConfig;
    const url = baseUser + check_user_liveness;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, formData, headers);
  },

  getEntityFaceMatchData: async (liveface_image, adhaar_image) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, user_face_match } = ApiConfig;
    const url = baseUser + user_face_match;
    const params = {
      imageUrl1: adhaar_image,
      imageUrl2: liveface_image,
      user_type: "MainTrader"
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },


  submitEntityDocument: async (formData, directorLength) => {
    const Authtoken = localStorage.getItem("token");
    const { baseEntity, verificationdocuments } = ApiConfig;
    const url = `${baseEntity}${verificationdocuments}?total_Directors=${directorLength}`;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, formData, headers);
  },

  getDownloadReport: async (fromDate, toDate) => {
    const Authtoken = localStorage.getItem("token");
    const { baseqbs, download_report } = ApiConfig;
    const url = baseqbs + download_report;
    const params = {
      fromDate: fromDate,
      toDate: toDate
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  getDownloadReportSpot: async (fromDate, toDate) => {
    const Authtoken = localStorage.getItem("token");
    const { baseExchange, download_report } = ApiConfig;
    const url = baseExchange + download_report;
    const params = {
      fromDate: fromDate,
      toDate: toDate
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },


  getAccountStatement: async (fromDate, toDate) => {
    const Authtoken = localStorage.getItem("token");
    const { baseWallet, getWalletDetails } = ApiConfig;
    const url = baseWallet + getWalletDetails;
    const params = {
      fromDate: fromDate,
      toDate: toDate
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },


  authDataSdk: async (appId, appKey) => {
    const Authtoken = localStorage.getItem("token");
    const url = "https://auth.hyperverge.co/login";
    const params = {
      appId,
      appKey,
      "expiry": 86400
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  getLinkKycResult: async (appId,appKey) => {
    const transactionId = localStorage.getItem("user_id");

    
    const url = `https://ind.idv.hyperverge.co/v1/link-kyc/results/`

    const params = {transactionId};
    const headers = {
      "Content-Type": "application/json",
      appId: appId,
      appKey: appKey,
    };
    return ApiCallPost(url, params, headers);
  },
  

};

export default AuthService;
