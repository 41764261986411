import React, { useEffect, useState } from "react";
import DashboardSidebar from "../../CoustamComponent/DashboardSidebar";
import { Link } from "react-router-dom";
import AuthService from "../../../Services/ApiServices/AuthService";
import { alertErrorMessage } from "../../../Services/CustomAlertModel";
import LoaderHelper from "../../../Services/Loading/LoaderHelper";
import moment from "moment";
import DataTable from "react-data-table-component";

const ReferrallistPage = () => {

  const [referralData, setReferralData] = useState([]);

  const handleReferList = async () => {
    try {
      const result = await AuthService.referList();
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        setReferralData(result.data);
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result);
      }
    } catch (error) {
      LoaderHelper.loaderStatus(false);
      alertErrorMessage(error);
    }
  };

  useEffect(() => {
    handleReferList();
  }, []);


  const columns = [

    {
      name: 'Serial Number',
      selector: (row, index) => index + 1,
    },

    {
      name: "Date/Time",
      cell: (row) =>
        <div className="c_view justify-content-start" >
          <span>{moment(row.createdAt).format("DD/MM/YYYY  ")} <br />
            <small>{moment(row.createdAt).format("HH:mm:ss")}</small>
          </span>
        </div>,
      sortable: false,
    },
    {
      name: 'user ID',
      selector: row => row._id,
      wrap: true,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email.slice(0, 2) + "******" + row.email.slice(-9),
      wrap: true,
      sortable: true,
    },
    {
      name: 'Mobile Number',
      selector: row => "+" + row.countryCode + " " + row.phone.slice(0, 2) + "******" + row.phone.slice(-2),
      wrap: true,
      sortable: true,
    },
    {
      name: 'Referral Code',
      selector: row => row.reffered_by,
      wrap: true,
      sortable: true,
    },

    {
      name: 'Kyc Status',
      selector: row => <span className={row.kycVerified === 0 ? "text-warning" : row.kycVerified === 1 ? "text-warning" : row.kycVerified === 2 ? "text-success" : "text-danger"}>{
        row.kycVerified === 0 ? "Kyc Pending" : row.kycVerified === 1 ? "Verification Pending" : row.kycVerified === 2 ? "Veriffied" : "Rejected"
      }</span>,
      wrap: true,
      sortable: true,
    },


  ];


  return (
    <main>
      <div className="page_wrapper" >
        <div className="sidebar" >
          <DashboardSidebar />
        </div>
        <div className="af_wrapper">
          <section className="d_page m_account history_page">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xxl-12 col-xl-12">

                  <div className="pg_header">
                    <div className="hd_innre">
                      <h6 className="mb-0">
                        <Link to="/referral" className="text-dark me-2 me-md-3" >  <i className="ri-arrow-left-line"></i> </Link> Referal List  </h6>
                    </div>
                  </div>
                  <div className="g_start p-0">
                    <div className="m_table">
                      <div className="table-responsive">
                        <table className="table wallet_table">
                          <DataTable className="table table-hover"
                            columns={columns}
                            data={referralData}
                            noDataComponent={
                              <tbody>
                                <tr rowspan="4">
                                  <td colspan="6">
                                    <p className="text-center my-5" >
                                      <div className="favouriteData"><img src="/assets/images/no-data.png" className="img-fluid" width="96" height="96" alt="" />
                                        <p className="mb-0" >No Data Available</p>
                                      </div>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            }
                          />
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>

  )
}

export default ReferrallistPage