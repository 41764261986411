import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { alertErrorMessage } from "../../../../Services/CustomAlertModel";
import AuthService from "../../../../Services/ApiServices/AuthService";
import LoaderHelper from "../../../../Services/Loading/LoaderHelper";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import { ProfileContext } from "../../../../Services/ProfileProvider";
import { saveAs } from 'file-saver';
import { generatePdfBuySell } from "../../../../Context/Utility/generatePdfBuySell";

const BuySellTranstions = () => {

    const [activeOrders, setActiveOrders] = useState("allorders");
    const [transactionList, setTransactionList] = useState([]);
    const [orderSide, setOrderSide] = useState('');
    const [orderStatus, setOrderStatus] = useState('');
    const [search, setSearch] = useState("");
    const [filterTransaction, setFilterTransaction] = useState([]);
    const [totalDataLength, setTotalDataLength] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [activeExport, setActiveExport] = useState(false);
    const { userDetails } = useContext(ProfileContext);
    const pdfRef = useRef();
    const [skip, setSkip] = useState(0);
    const limit = 20;
    const [adminBankData, setAdminBankData] = useState('');
    const [adminBankUpi, setAdminBankUpi] = useState('');
    const Authtoken = localStorage.getItem("token");
    const [userKycDetails, setUserKycDetails] = useState([]);

    const userBankDetil = userDetails?.bank_details?.filter(item => item?.default === true);
    const userUpiDetails = userDetails?.upi_details?.filter(item => item?.default === true);
    
    const handleTranactionList = async (skip, limit, orderStatus, orderSide, startDate, endDate) => {
        try {
            const result = await AuthService.buySellTranactionList(skip, limit, orderStatus, orderSide, startDate, endDate);
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                setTransactionList(result.data);
                setTotalDataLength(result?.count);
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        handleTranactionList(skip, limit, orderStatus, orderSide);
    }, [skip, limit, orderStatus, orderSide]);

    useEffect(() => {
        const filteredData = transactionList.filter(item => {
            return (
                item?.from?.toLowerCase().includes(search?.toLowerCase()) ||
                item?.side?.toLowerCase().includes(search?.toLowerCase()) ||
                item?.status?.toLowerCase().includes(search?.toLowerCase()) ||
                item?._id?.toLowerCase().includes(search?.toLowerCase())
            );
        });

        setFilterTransaction(filteredData);
    }, [search, transactionList]);


    const handleGetKycDetails = async () => {
        try {
            const result = await AuthService.getKycDetails();
            if (result?.success) {
                setUserKycDetails(result.data);
            } else {
                // alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };


    useEffect(() => {
        handleGetKycDetails();
    }, []);

    const adminBankDetails = async () => {
        try {
            const result = await AuthService.getAdminBankDetails();
            if (result?.success) {
                setAdminBankData(result?.data?.[0]);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    const adminUpiDetails = async () => {
        try {
            const result = await AuthService.getAdminUpiDetails();
            if (result?.success) {
                setAdminBankUpi(result?.data?.[0]);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        if (Authtoken) {
            adminBankDetails();
            adminUpiDetails();
        }
    }, []);

    const columns = [

        {
            name: 'Order No.',
            selector: row => row._id,
            sortable: true,
            wrap: true,

            format: row =>
                <div className="c_view">{row._id} </div>,
        },

        {
            name: "Date",
            cell: (row) => <div className="justify-content-start" > <span>{moment(row.createdAt).format("DD/MM/YYYY  ")} <br /> <small>{moment(row.createdAt).format("HH:mm:ss")}</small> </span> </div>,
            sortable: false,
        },

        {
            name: 'Currency',
            selector: row => row,
            sortable: true,
            format: row => <span>{row.base_currency + "/" + row.quote_currency}</span>,
        },
        {

            name: 'Side',
            selector: row => row.side,
            wrap: true,
            sortable: true,
            format: row =>
                <span className={row.side === 'BUY' ? " text-success" : "text-danger"}>
                    {row?.side}
                </span>,
        },

        {
            name: 'Total Paid',
            selector: row => row.total_pay_amount,
            wrap: true,
            sortable: true,
            format: row => <div className="text-end">{row.total_pay_amount?.toFixed(2)}
                &nbsp;{row?.side === "BUY" ? row.quote_currency : row.base_currency}</div>,

        },
        {
            name: 'Fee',
            selector: row => row.transaction_fees_amount || '0',
            wrap: true,
            sortable: true,

        },
        {
            name: 'TDS',
            selector: row => row.side === "SELL" && row.tds_amount || '0.00',
            wrap: true,
            sortable: true,

        },

        {
            name: 'Total Recived',
            selector: row => row.total_get_amount,
            wrap: true,
            sortable: true,
            format: row => <div className="text-end">{row.total_get_amount?.toFixed(2)}
                &nbsp;{row?.side === "BUY" ? row.base_currency : row.quote_currency}</div>,

        },

        {

            name: 'Status',
            selector: row => row.status,
            wrap: true,
            sortable: true,
            format: row =>
                <span className={row.status === 'Completed' ? " text-success" : row.status === 'Cancelled' ? "text-danger" : row.status === 'Rejected' ? "text-danger" : "text-warning"}>
                    {row?.status}
                </span>,
        },

        /*  {
             name: 'Remaining Time',
             selector: row => '----',
             format: row => row.side === 'SELL' &&
                 <>
                      {time === 0 ? <button onClick={startCountdown} type="button">Remind</button> :
                         formatTime(time)
                     }
                 </>
         }, */

        {
            name: 'Invoice',
            selector: row => row.status === 'Completed' && <div className="ac_row cursor-pointer" onClick={() => generatePdfBuySell(row, userKycDetails, userBankDetil?.[0]?.account_number, userUpiDetails?.[0]?.vpaId, adminBankData?.account_number, adminBankUpi?.upi_vpa_id)}>  <i className="ri-download-2-line"></i></div>,
            wrap: true,
        }
    ];

    const handlePagination = (action) => {
        if (action === 'prev') {
            if (skip - limit >= 0) {
                setSkip(skip - limit);
            }
        } else if (action === 'next') {
            if (skip + limit < totalDataLength) {
                setSkip(skip + limit);
            }
        } else if (action === 'first') {
            setSkip(0);
        } else if (action === 'last') {
            const lastPageSkip = Math.floor(totalDataLength);
            const data = lastPageSkip - 10
            setSkip(data);
        }
    };

    const handleClear = () => {
        setSearch('');
        setStartDate('');
        setEndDate('');
        handleTranactionList(skip, limit, orderStatus, orderSide);
    };

    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]).filter(key => !['_id', 'user_id', 'order_id', 'updatedAt', '_v'].includes(key));
        let csv = headers.join(',') + '\n';
        data.forEach(item => {
            const row = headers.map(header => {
                if (header === 'createdAt') {
                    return moment(item[header]).format('DD-MM-YYYY HH:mm:ss');
                }
                return item[header];
            });
            csv += row.join(',') + '\n';
        });

        return csv;
    };


    const handleExportData = async (startDate, endDate) => {
        try {
            const result = await AuthService.exportDataBuySell(startDate, endDate);
            if (result?.success) {
                if (result.data && result.data.length > 0) {
                    const csvData = convertToCSV(result.data);
                    saveAs(new Blob([csvData], { type: 'text/csv' }), `Buy_sell ${userDetails?.user?.email}.csv`);
                } else {
                    alertErrorMessage('No Data Available...!')
                }
                LoaderHelper.loaderStatus(false);
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };

    return (
        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper" >
                    <section className="d_page history_page">
                        <div className="container" >
                            <div className="row justify-content-center">
                                <div className="col-lg-12" >
                                    <div className="pg_header" >
                                        <div className="hd_innre" >
                                            <h6 className="mb-0" >Buy/Sell Orders</h6>
                                        </div>
                                    </div>
                                    <div className="spot_tabs my-2 my-md-3" >
                                        <ul className="nav custom-tabs">
                                            <li className={`${activeOrders === "ongoinorder" ? 'active' : ''}`}
                                                onClick={() => { setActiveOrders('ongoinorder'); setOrderStatus('Pending'); setSkip(0) }}> <span> Ongoing Orders </span></li>
                                            <li className={`tab_item ${activeOrders === "allorders" ? 'active' : ''}`}
                                                onClick={() => { setActiveOrders('allorders'); setOrderStatus(''); setSkip(0) }}><span>All Orders</span></li>
                                        </ul>

                                    </div>

                                    <div className="filter-card mb-3 mb-md-4">
                                        <div className="row g-2 align-items-end" >
                                            <div className="col-lg-10" >
                                                <div className="row g-2 align-items-end" >
                                                    <div className="col-lg-2" >
                                                        <div className="form-group" >
                                                            <label> Search </label>
                                                            <div className="searchBar custom-tabs mt-0">
                                                                <input type="search" className="custom_search" placeholder="Search Crypto" value={search} onChange={(e) => { setSearch(e.target.value); }} />
                                                                <i className="ri-search-2-line"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-2">
                                                        <div className="form-group">
                                                            <label> Order Side </label>
                                                            <select className="form-control form-select cursor-pointer" onChange={(e) => { setOrderSide(e.target.value); setSkip(0) }}>
                                                                <option value="">All Orders</option>
                                                                <option value="BUY">Buy Orders</option>
                                                                <option value="SELL">Sell Orders</option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                    {activeOrders === "allorders" &&
                                                        <div className="col-12 col-lg-2">
                                                            <div className="form-group">
                                                                <label> Order Status </label>
                                                                <select className="form-control form-select cursor-pointer" onChange={(e) => { setOrderStatus(e.target.value); setSkip(0) }}>
                                                                    <option value="">All Status</option>
                                                                    <option value="Completed">Completed </option>
                                                                    <option value="Pending">Pending</option>
                                                                    <option value="Rejected">Rejected</option>

                                                                </select>

                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-6  col-lg-2">
                                                        <div className="form-group">
                                                            <label>Start Date</label>
                                                            <input
                                                                type="date"
                                                                className="form-control cursor-pointer"
                                                                value={startDate}
                                                                onChange={(e) => setStartDate(e.target.value)}
                                                                max={new Date().toISOString().split("T")[0]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6  col-lg-2">
                                                        <div className="form-group">
                                                            <label>End Date</label>
                                                            <input
                                                                type="date"
                                                                className="form-control cursor-pointer"
                                                                value={endDate}
                                                                onChange={(e) => setEndDate(e.target.value)}
                                                                max={new Date().toISOString().split("T")[0]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <div className="d-flex gap-2 mt-2 mt-md-0">
                                                            <button className="btn btn-muted w-50" onClick={handleClear} disabled={!startDate || !endDate}>Clear</button>
                                                            <button className="btn btn-dark w-50" onClick={() => { handleTranactionList(skip, limit, orderStatus, orderSide, startDate, endDate); setActiveExport(true) }} disabled={!startDate || !endDate}>Apply</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {activeOrders === "allorders" &&
                                                <div className="col-lg-2" >
                                                    <div className="ph_right" >
                                                        <button className="btn btn-dark px-4" type="button" onClick={() => handleExportData(startDate, endDate)} disabled={!startDate || !endDate || !activeExport}>
                                                            Export <i className="ms-2 ri-download-2-line"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className={`g_start m_card overflow-hidden p-0 ${activeOrders != "ongoinorder" ? 'd-none' : ''}`}>
                                        <div className="m_table" >
                                            <div className="table-responsive h-auto ">
                                                <DataTable
                                                    columns={columns}
                                                    data={filterTransaction}
                                                    noDataComponent={
                                                        <p> <img src="/assets/images/not_found.svg" width="300" className='img-fluid mt-5' alt='' /> </p>
                                                    }
                                                />

                                                {
                                                    filterTransaction?.length > 0 &&
                                                    <div className="hVPalX gap-2" >
                                                        <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                                        <div className="sc-eAKtBH gVtWSU">
                                                            <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                                </svg>
                                                            </button>
                                                            <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                </svg>
                                                            </button>

                                                            <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                </svg>
                                                            </button>
                                                            <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                    <div className={`g_start m_card overflow-hidden p-0 ${activeOrders != "allorders" ? 'd-none' : ''}`}>

                                        <div className="m_table" >
                                            <div className="table-responsive h-auto ">
                                                <DataTable
                                                    columns={columns}
                                                    data={filterTransaction}
                                                    noDataComponent={
                                                        <p> <img src="/assets/images/not_found.svg" width="300" className='img-fluid mt-5' alt='' /> </p>
                                                    }
                                                />

                                                {
                                                    filterTransaction?.length > 0 &&
                                                    <div className="hVPalX gap-2" >
                                                        <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                                        <div className="sc-eAKtBH gVtWSU">
                                                            <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                                </svg>
                                                            </button>
                                                            <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                </svg>
                                                            </button>

                                                            <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                </svg>
                                                            </button>
                                                            <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                {/*  <div id="divToDownload">

                    YOUR OTHER COMPONENT CODE GOES HERE

                </div> */}
            </div>
        </main>

    )
}

export default BuySellTranstions