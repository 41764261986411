import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ProfileContext } from "../../../Services/ProfileProvider";
import AuthService from "../../../Services/ApiServices/AuthService";
import SpinLoader from "../../CoustamComponent/SpinLoader";

const Login = () => {

    const [formData, setFormData] = useState({ signId: "", password: "" });
    const [showPass, setshowPass] = useState(false);
    const navigate = useNavigate();
    const { setLoginDetails } = useContext(ProfileContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingOtp, setIsLoadingOtp] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const [isActiveOtp, setIsActiveOtp] = useState(false);
    const [authType, setauthType] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [responseResult, setResponseResult] = useState("");
    const [signidError, setSignIdError] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const handleInput = (event) => {
        const { name, value } = event.target;

        setFormData({ ...formData, [name]: value });
        setResponseResult("");
        
        if (name === "signId") {
            if (value.length > 8) {
                handleCheckEmailPhone(value);
                setShowErrorMessage(false);
            }
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        const { signId, password } = formData;
        if (!signId || !password) {
            setResponseResult('Email and password are required.');
            return;
        }
        if (signidError) {
            setResponseResult(`User doesn't exist.`);
            return;
        }
        setIsLoading(true);
        try {
            const result = await AuthService.login(formData);
            if (result?.success) {
                if (result?.data?.['2fa'] === 0) {
                    setIsLoading(false);
                    localStorage.setItem("token", result.data.token);
                    localStorage.setItem("email", result.data.email);
                    localStorage.setItem("user_id", result.data.user_id);
                    setLoginDetails(result.data);
                    navigate("/dashboard");
                } else {
                    if (result?.data?.['2fa'] != 2) {
                        handleGetOtp(formData.signId, 'verification')
                        setauthType(result?.data?.['2fa']);
                        setIsLoading(false);
                    } else {
                        setauthType(result?.data?.['2fa']);
                        setIsLoading(false);
                        setIsActiveOtp(true)
                    }
                }
            } else {
                setIsLoading(false);
                setResponseResult(result);
            }
        } catch (error) {
            setIsLoading(false);
            setResponseResult(error);
        }
    };

    const handleGetOtp = async (signId, type) => {
        setIsLoadingOtp(true);
        try {
            const result = await AuthService.getOtp(signId, type);
            if (result?.success) {
                setIsLoadingOtp(false);
                setIsActiveOtp(true)
                setIsOtpSent(true);
                setCountdown(60);
                setResponseResult("");
                setIsLoadingOtp(false);
            } else {
                setResponseResult(result);
                setIsLoadingOtp(false);
            }
        } catch (error) {
            setResponseResult(error);
            setIsLoadingOtp(false);
        }
    };


    const handleVerifyOtp = async (event) => {
        event.preventDefault();
        const { signId } = formData;
        if (!verificationCode) {
            setResponseResult('Please Enter Verification Code.');
            return;
        }
        setIsLoading(true);
        try {
            const result = await AuthService.codeVerification(signId, verificationCode);
            if (result?.success) {
                localStorage.setItem("token", result.data.token);
                localStorage.setItem("email", result.data.email);
                localStorage.setItem("user_id", result.data.user_id);
                setLoginDetails(result.data);
                navigate("/dashboard");
            } else {
                setResponseResult(result);
                setIsLoading(false);
            }
        } catch (error) {
            setResponseResult(error);
            setIsLoading(false);
        }
    };

    const handleOtpChange = (event) => {
        const value = event.target.value;
        if (!isOtpSent) {
            setResponseResult("Send OTP First");
            setVerificationCode("")
            return
        } else {
            if (value.length <= 6) {
                setVerificationCode(value);
            }
            setResponseResult("");
        }

    };

    useEffect(() => {
        let timer;
        if (isOtpSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsOtpSent(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSent, countdown]);



    const handleCheckEmailPhone = async (signId) => {
        const result = await AuthService.checkEmailPhoneLogin(signId.toLowerCase());
        if (result?.success) {
            setSignIdError(""); // Clear error if valid
        } else {
            setSignIdError(result?.message);
        }
    };



    return (
        <main>
            <section className="section-padding login_bg login_sec">
                <div className="section-overlay"></div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-5 col-lg-6 col-12 mx-auto">
                            <form className={`custom-form ticket-form mb-5 mb-lg-0 ${isActiveOtp ? 'd-none' : ''}`} onSubmit={handleSubmit} >
                                {/* box_shadow */}
                                <div border-radius="50% 200% 40% 80%" class="css-1ueqzyg">
                                    <div border-radius="50% 200% 40% 80%" class="css-1ueqzyg"></div>
                                </div>
                                <div border-radius="50% 200% 40% 80%" class="css-10gqqxt"></div>
                                {/* box shadow end */}
                                <div className="card-body">
                                    <div className="page_title">
                                        <h5 className="text-center mb-4 text-warning "> Login to Crypto India </h5>
                                    </div>

                                    {responseResult && (
                                        <div className="fl_card mb-3 mtm_error mt-3 " >
                                            <div className="fl_row gap-2" >
                                                <i class="ri-error-warning-fill"></i>
                                                <p className="mt-0">
                                                    {responseResult}
                                                </p>
                                            </div>
                                        </div>
                                    )}


                                    <div className="row gx-2 gy-2">
                                        <div className="col-12">
                                            <label className="form-label" htmlFor="signId"> Email/Mobile</label>
                                            <input type="text" name="signId" id="signId" className={`form-control mb-2 ${signidError && showErrorMessage  ? "is-invalid" : "form-active"}`} placeholder="Email address or mobile number" value={formData.signId} onChange={handleInput} onClick={() => setShowErrorMessage(false)} required />
                                            <div className="in_error" >
                                                {signidError && showErrorMessage && (
                                                    <small> {signidError} </small>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <label className="form-label" htmlFor="password"> Password</label>
                                            <div className=" field-otp-box">
                                                <input type={showPass ? 'text' : "password"} name="password" id="password" className="form-control   mb-1 " placeholder="Password" required value={formData.password} onChange={handleInput} onFocus={() => setShowErrorMessage(true)} />
                                                <button type="button" className="btn btn-icon btn_view_pass btn-white" onClick={() => { setshowPass(!showPass) }}>
                                                    <i className={`ri-eye${showPass ? "-line" : "-off-line"}`} />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12 text-end mb-4">
                                            <Link className="text-white" to="/forgot_password" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Forgot Password?</Link>
                                        </div>
                                    </div>
                                    <div className="col-12 mx-auto">
                                        <button type="submit" className="form-control position-relative btn-donate">
                                            {isLoading ? <SpinLoader /> : <span>Login</span>}
                                        </button>
                                    </div>
                                    <div className="col-12 text-center mt-4">
                                        <p>Create a New Crypto India Account.? <Link className="fw-bold" to="/signup" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Sign Up</Link></p>
                                    </div>

                                </div>

                            </form>

                            {/*  Authenticator */}
                            <form className={`custom-form ticket-form mb-5 mb-lg-0 ${!isActiveOtp ? 'd-none' : ''}`} onSubmit={handleVerifyOtp} >
                                <div border-radius="50% 200% 40% 80%" class="css-1ueqzyg"><div border-radius="50% 200% 40% 80%" class="css-1ueqzyg"></div></div>
                                <div border-radius="50% 200% 40% 80%" class="css-10gqqxt"></div>

                                <div className="card-body">
                                    <div className="page_title">
                                        <h3 className="d-block text-center  text-warning letter_space"> OTP Verification </h3>
                                        <small className="text-center d-block mb-4" >{authType != 2 && "Verification code sent to"}  <b> {authType == 2 ? "Check Google Authenticator App Code" : formData.signId} </b> </small>
                                    </div>
                                    {responseResult && (
                                        <div className="fl_card mb-4 mtm_error mt-0 " >
                                            <div className="fl_row gap-2" >
                                                <i class="ri-error-warning-fill"></i>
                                                <p className="mt-0">
                                                    {responseResult}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="field-otp-box">
                                            <input type="number" className="text-start form-control" placeholder="Enter Verification code" value={verificationCode} onChange={handleOtpChange} required /* disabled={authType !== 2 ? !isOtpSent : false} */
                                            />
                                            {
                                                authType != 2 &&
                                                <button type="button" className="btn btn-xs custom-btn" onClick={() => handleGetOtp(formData.signId, 'verification')} disabled={isOtpSent}>
                                                    {isLoadingOtp ?
                                                        <SpinLoader />
                                                        : <span>{isOtpSent ? `Resend in ${countdown}s` : 'GET OTP'}</span>
                                                    }
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 mx-auto">
                                        <button type="submit" className="form-control">
                                            {isLoading ? <SpinLoader /> : <span>Verify</span>}
                                        </button>
                                    </div>
                                    <div className="col-12 text-center mt-4">
                                        <button type="button" className="btn btn-secondary px-5 mt-2 " onClick={() => setIsActiveOtp(false)}>  Cancel </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12">
                            <div className="login_qr">
                                <div className="l_qr">
                                    <img src="/assets/images/qr_code.png" width="200" className="img-fluid" alt="" />
                                </div>
                                <h5>Login with QR code</h5>
                                <p>Scan this code with <span className="text-warning">Crypto India Mobile App</span> to login instantly</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Login;
