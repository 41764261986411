import React, { useContext, useEffect, useState } from "react";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import { alertErrorMessage, alertSuccessMessage } from "../../../../Services/CustomAlertModel";
import AuthService from "../../../../Services/ApiServices/AuthService";
import { ProfileContext } from "../../../../Services/ProfileProvider";
import { Link } from "react-router-dom/dist";

const PaymentModules = () => {


    const [isOtpSent, setIsOtpSent] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [isActiveAddBank, setIsActiveAddBank] = useState(false);
    const [isActiveAddUpi, setIsActiveAddUpi] = useState(false);
    const { userDetails, handleUserProfile } = useContext(ProfileContext);
    const [userKycDetails, setUserKycDetails] = useState([]);
    const [isUploadDocument, setIsUploadDocument] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        emailId: "",
        emailOtp: '',
        bank_name: '',
        branch_name: '',
        accountHolderName: userKycDetails?.pandetails?.name,
        accountNumber: '',
        reEnterAccountNumber: '',
        ifscCode: '',
        accountType: 'Saving',
        upiId: '',

    });

    useEffect(() => {
        setFormData({
            emailId: userDetails?.user?.email || "",
            accountHolderName: userKycDetails?.pandetails?.name,
            accountType: 'Saving',
        });
    }, [userKycDetails, userDetails]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async () => {
        const ifscPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;
        if (!formData?.bank_name) {
            alertErrorMessage('Please Enter Bank Name..!!');
            return;
        } if (!formData?.branch_name) {
            alertErrorMessage('Please Enter Branch Name..!!');
            return;
        } if (!formData?.accountNumber) {
            alertErrorMessage('Please Enter Account Number..!!');
            return;
        } if (!formData?.reEnterAccountNumber) {
            alertErrorMessage('Please Re-Enter Account Number..!!');
            return;
        } if (formData?.accountNumber != formData?.reEnterAccountNumber) {
            alertErrorMessage('Please Enter Valid Account Number..!!');
            return;
        } if (!formData?.ifscCode) {
            alertErrorMessage('Please Enter IFSC Code..!!');
            return;
        } if (!ifscPattern.test(formData.ifscCode)) {
            alertErrorMessage('Invalid IFSC Code format. Please enter a valid IFSC Code.');
            return;
        } if (!formData?.emailOtp) {
            alertErrorMessage('Please Enter Email Otp..!!');
            return;
        } if (formData?.emailOtp.length > 6) {
            alertErrorMessage('Please Enter Valid Email Otp..!!');
            return;
        } if (!isUploadDocument) {
            alertErrorMessage('Please Upload Document..!!');
            return;
        }
        setIsLoading(true);
        try {
            const result = await AuthService.addBankAccount(formData);
            if (result?.success) {
                handleUserProfile()
                setIsActiveAddBank(false);
                setIsOtpSent(false);
                setCountdown(0);
                setIsLoading(false);
            } else {
                alertErrorMessage(result);
                setIsLoading(false);
            }
        } catch (error) {
            alertErrorMessage(error);
            setIsLoading(false);
        }
    };

    const handleSubmitAddUpi = async () => {

        if (!formData?.emailOtp) {
            alertErrorMessage('Please Enter Email Otp..!!');
            return;
        } if (formData?.emailOtp.length > 6) {
            alertErrorMessage('Please Enter Valid Email Otp..!!');
            return;
        } if (!formData?.upiId) {
            alertErrorMessage('Please Enter Upi ID..!!');
            return;
        }
        try {
            const result = await AuthService.addUpiDetails(formData);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserProfile()
                setIsActiveAddUpi(false);
                setIsOtpSent(false);
                setCountdown(0);
                setFormData({
                    emailId: "",
                    emailOtp: '',
                    upiId: '',
                });
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }

    };


    const handleAddDefaultBank = async (accountNumber) => {
        try {
            const result = await AuthService.updateDefaultBank(accountNumber);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserProfile()
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    }

    const handleAddDefaultUpi = async (vpaId) => {
        try {
            const result = await AuthService.updateDefaultUpi(vpaId);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserProfile()
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    }

    const handleDeleteUpi = async (vpaId) => {
        try {
            const result = await AuthService.deletetUpi(vpaId);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserProfile()
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    }

    const deleteBankDetails = async (accountNumber) => {
        try {
            const result = await AuthService.deletetBank(accountNumber);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserProfile()
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    }

    const handleGetOtp = async (emailId, type) => {
        try {
            const result = await AuthService.getOtp(emailId, type);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                setIsOtpSent(true);
                setCountdown(60);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        let timer;
        if (isOtpSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsOtpSent(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSent, countdown]);

    const ResetCount = () => {
        setIsOtpSent(false);
        setCountdown(0);
        setFormData({
            emailId: '',
            emailOtp: '',
        });
    }

    const handleGetKycDetails = async () => {
        const result = await AuthService.getKycDetails();
        if (result?.success) {
            setUserKycDetails(result.data);
        }
    };


    useEffect(() => {
        handleGetKycDetails();
    }, []);


    const handleSubmitEntityDocument = async (file) => {
        const formValue = new FormData();
        formValue.append("bankDocument", file);
        await AuthService.uploadBankDocument(formValue).then(async (result) => {
            if (result?.success) {
                alertSuccessMessage(result?.message);
                setIsUploadDocument(true);
            } else {
                alertErrorMessage(result);
            }
        });
    };

    return (

        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper" >
                    <section className="d_page history_page p_page  m_account  ">
                        <div className="container" >
                            <div className="row justify-content-center" >
                                <div className="col-xxl-8 col-xl-10" >

                                    <div className="d_u_p align-items-center m-0" >
                                        <div className="dup_left" >
                                            <h6 className="mb-0" >  Payment Method   </h6>
                                        </div>
                                        <div className="up_right text-end" id="myTab" role="tablist" >
                                            <ul className="nav custom-tabs">
                                                <li className="" role="presentation">
                                                    <a href="/?" className="active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                                                        role="tab" aria-controls="home-tab-pane" aria-selected="true" onClick={handleGetKycDetails}>Bank Account</a>
                                                </li>
                                                <li className="" role="presentation">
                                                    <a href="/?" className="" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
                                                        role="tab" aria-controls="profile-tab-pane" aria-selected="false" onClick={handleGetKycDetails}>UPI ID </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <div className="tab-content" id="myTabContent">
                                        <>
                                            <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                                <div className="g_start  mt-4">
                                                    <div className="d_u_p align-items-center m-0" >
                                                        <div className="dup_left" >
                                                            <h6 className="mb-0"   > Bank Account   </h6>
                                                        </div>
                                                        <div className="up_right" >
                                                            <div className="btn_row">
                                                                <button className="btn btn-muted" onClick={() => setIsActiveAddBank(true)}> Add  New </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {userDetails?.bank_details ? userDetails?.bank_details?.map((item, index) => {
                                                        return (
                                                            <div className=" mt-4  p_card" key={index}>
                                                                <div className="bb_head" >
                                                                    <div className="row align-items-center" >
                                                                        <div className="col-6" >
                                                                            <h6 className="mb-0" > <small>{item?.account_holder_name}</small> </h6>
                                                                        </div>
                                                                        <div className="col-6 text-end" >
                                                                            <span className={`badge mx-1 text-dark 
                                                                                    ${item?.status === "Success" ? 'badge-success' :
                                                                                    item?.status === "Pending" ? 'bg-warning' :
                                                                                        item?.status === "Rejected" ? 'badge-danger' : 'bg-secondary'}`}>
                                                                                {item?.status === "Success" ? 'Verified' :
                                                                                    item?.status === "Pending" ? 'Pending' :
                                                                                        item?.status === "Rejected" ? 'Rejected' : 'Unknown'}
                                                                            </span>
                                                                            <span className={`badge mx-1 ${item?.default === true ? 'bg-primary' : ''}`}>
                                                                                {item?.default === true ? 'Default' : ''}
                                                                            </span>
                                                                            {
                                                                                item?.default === false &&
                                                                                <span className="dropdown more_menu">
                                                                                    <button className="btn no-border" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                        <i className="ri-more-2-fill"></i>
                                                                                    </button>
                                                                                    <ul className="dropdown-menu p-2" aria-labelledby="dropdownMenuLink">
                                                                                        <li>
                                                                                            <div className="dropdown-item cursor-pointer" onClick={() => handleAddDefaultBank(item?.accountNumber)}>
                                                                                                Set As Default
                                                                                            </div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="dropdown-item  cursor-pointer text-danger" onClick={() => deleteBankDetails(item?.accountNumber)}>
                                                                                                Delete Account
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row g-3" >
                                                                    <div className="col-md-4" >
                                                                        <div className="ud" >
                                                                            <span>Bank Name </span>
                                                                            <h5> <small> {item?.bankName} </small>  </h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6" >
                                                                        <div className="ud" >
                                                                            <span> Account Number</span>
                                                                            <h5> <small>{item?.accountNumber}</small>  </h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4" >
                                                                        <div className="ud" >
                                                                            <span> Branch Name </span>
                                                                            <h5> <small> {item?.branchName} </small>  </h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4" >
                                                                        <div className="ud" >
                                                                            <span> IFSC Code </span>
                                                                            <h5> <small> {item?.ifsc} </small>  </h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4" >
                                                                        <div className="ud" >
                                                                            <span> Account Holder Name </span>
                                                                            <h5> <small> {item?.userNameInBank} </small>  </h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) : null}
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                                                <div className="g_start  mt-4    " >
                                                    <div className="d_u_p align-items-center m-0" >
                                                        <div className="dup_left" >
                                                            <h6 className="mb-0" > UPI ID   </h6>
                                                        </div>

                                                        <div className="up_right">
                                                            <div className="btn_row">
                                                                {!userDetails?.bank_details ? (
                                                                    <h5> Verify Your Bank First  </h5>
                                                                ) : (
                                                                    <button className="btn btn-muted" onClick={() => setIsActiveAddUpi(true)}> Add UPI </button>
                                                                )}
                                                            </div>
                                                        </div>



                                                        {/* <div className="up_right" >
                                                            <div className="btn_row">
                                                                <button type="button" className="btn btn-muted" onClick={() => setIsActiveAddUpi(true)}> Add New  </button>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                    {userDetails?.upi_details ? userDetails?.upi_details?.map((item, index) => {
                                                        return (
                                                            <div className=" mt-4  p_card" key={index}>
                                                                <div className="bb_head" >
                                                                    <div className="row align-items-center" >
                                                                        <div className="col-6" >
                                                                            <h6 className="mb-0" > <small>{item?.vpaId}</small> </h6>
                                                                        </div>
                                                                        <div className="col-6 text-end" >

                                                                            <span className={`badge mx-1 text-dark ${item?.nameMatch ? 'badge-success' : 'bg-warning'}`}
                                                                            > {item?.nameMatch ? 'Verified' : 'Pending'}</span>

                                                                            <span className={`badge mx-1 ${item?.default === true ? 'bg-primary' : ''}`}>
                                                                                {item?.default === true ? 'Default' : ''}
                                                                            </span>
                                                                            {
                                                                                item?.default === false &&
                                                                                <span className="dropdown more_menu">
                                                                                    <button className="btn no-border" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                        <i className="ri-more-2-fill"></i>
                                                                                    </button>
                                                                                    <ul className="dropdown-menu p-2" aria-labelledby="dropdownMenuLink">
                                                                                        <li>
                                                                                            <div className="dropdown-item cursor-pointer" onClick={() => handleAddDefaultUpi(item?.vpaId)}> Set As Default </div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="dropdown-item  cursor-pointer text-danger" onClick={() => handleDeleteUpi(item?.vpaId)}> Delete UPI </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row g-3" >
                                                                    <div className="col-md-4" >
                                                                        <div className="ud" >
                                                                            <span> Name  </span>
                                                                            <h5> <small>{item?.UPIHolderName}</small> </h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6" >
                                                                        <div className="ud" >
                                                                            <span> UPI ID </span>
                                                                            <h5> <small> {item?.vpaId} </small>  </h5>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        )
                                                    }) : null}
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>



                {/* <!-- Add account modal --> */}
                {isActiveAddBank && (
                    <div div className="modal-open-custom" >
                        <div className="modal show d-block" >
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header flex-column px-8">
                                        <button type="button" className="btn-close" onClick={() => { setIsActiveAddBank(false); ResetCount() }}></button>
                                        <h5 className="modal-title" id="modal_add_bank_Label"> Add New Bank Account </h5>
                                    </div>
                                    <div className="modal-body modal_form">
                                        {
                                            userDetails?.user?.kycVerified != 2 ?

                                                <div className=" text-center" >
                                                    <img src="/assets/images/user_kyc.svg" className="img-fluid  m-auto" />
                                                    <span className={`m-auto mt-3 badge ${userDetails?.user?.kycVerified === 1 ? "badge-warning bg-warning" : userDetails?.user?.kycVerified === 0 ? "badge-warning bg-warning" : "badge-danger"}`} >
                                                        <Link className="text-dark" to={userDetails?.user?.kycVerified == 2 ? "#" : '/verify_kyc'}>
                                                            {userDetails?.user?.kycVerified == 1 ? "Kyc is Under Review" : userDetails?.user?.kycVerified == 0 ? "SUBMIT KYC" : "Verification Failed!"}
                                                        </Link>
                                                    </span>
                                                </div>
                                                :
                                                <form>
                                                    <div className="row">

                                                        <div className="col-6 form-group">
                                                            <label className="text-white mb-1" htmlFor="accountType">Account Type</label>
                                                            <select name="accountType" className="form-select form-control" value={formData.accountType} onChange={handleChange}>
                                                                <option value="Saving">Saving Account</option>
                                                                <option value="Current">Current Account</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-6 form-group">
                                                            <label className="text-white mb-1" htmlFor="bank_name">Bank Name</label>
                                                            <input type="text" name="bank_name" id="bank_name" className="form-control" value={formData.bank_name} onChange={handleChange} />
                                                        </div>
                                                        <div className="col-6 form-group">
                                                            <label className="text-white mb-1" htmlFor="branch_name">Branch Name</label>
                                                            <input type="text" name="branch_name" id="branch_name" className="form-control" value={formData.branch_name} onChange={handleChange} />
                                                        </div>
                                                        <div className="col-6 form-group">
                                                            <label className="text-white mb-1" htmlFor="accountHolderName">Account Holder Name</label>
                                                            <input type="text" name="accountHolderName" id="accountHolderName" className="form-control" value={formData.accountHolderName} disabled />
                                                        </div>
                                                        <div className="col-6 form-group">
                                                            <label className="text-white mb-1" htmlFor="accountNumber">Account Number</label>
                                                            <input type="text" name="accountNumber" id="accountNumber" className="form-control" value={formData.accountNumber} onChange={handleChange} />
                                                        </div>
                                                        <div className="col-6 form-group">
                                                            <label className="text-white mb-1" htmlFor="reEnterAccountNumber">Re-Enter Account Number</label>
                                                            <input type="text" name="reEnterAccountNumber" id="reEnterAccountNumber" className="form-control" value={formData.reEnterAccountNumber} onChange={handleChange} />
                                                        </div>
                                                        <div className="col-6 form-group">
                                                            <label className="text-white mb-1" htmlFor="ifscCode">IFSC Code</label>
                                                            <input type="text" name="ifscCode" id="ifscCode" className="form-control" value={formData.ifscCode} onChange={handleChange} />
                                                        </div>

                                                        <div className="col-6 form-group">
                                                            <label className="text-white mb-1" htmlFor="email_otp">Email OTP</label>
                                                            <div className="field-otp-box">
                                                                <input type="number" name="emailOtp" id="email_otp" className="form-control" placeholder="Email verification Code" required value={formData.emailOtp} onChange={handleChange} />

                                                                <button type="button" className="btn btn-sm btn-warning" onClick={() => { handleGetOtp(formData?.emailId, 'addbank') }} disabled={isOtpSent || !formData?.bank_name || !formData?.branch_name || !formData?.accountNumber || !formData?.reEnterAccountNumber || !formData?.ifscCode}>
                                                                    <span>{isOtpSent ? `Resend in ${countdown}s` : 'GET OTP'}</span>
                                                                </button>

                                                            </div>
                                                        </div>

                                                        <div className="dov_card" >
                                                            <div className="row g-3">
                                                                <div className="col-lg-6" >
                                                                    <div className="doc_row mt-1" >
                                                                        <label htmlFor="assetsFile"> <small> Cancelled Cheque | Passbook Photo <br /> Bank Statement</small> </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6" >
                                                                    <input type="file" className="form-control" name="documentImage" data-multiple-caption="{count} files selected" id="assetsFile" multiple onChange={(event) => handleSubmitEntityDocument(event.target.files[0])} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="text-center">
                                                        <button type="button" className="btn btn-dark w-50 mt-3" onClick={handleSubmit} disabled={isLoading}>
                                                            {
                                                                isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>Verify</span>
                                                            }
                                                        </button>
                                                    </div>
                                                </form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                )}


                {/* <!-- Add UPI modal --> */}
                {isActiveAddUpi && (
                    <div className="modal-open-custom">
                        <div className="modal show d-block">
                            <div className="modal-dialog modal-md">
                                <div className="modal-content">
                                    <div className="modal-header flex-column px-8">
                                        <button type="button" className="btn-close" onClick={() => { setIsActiveAddUpi(false); ResetCount() }}></button>
                                        <h5 className="modal-title" id="modal_add_upi_Label">
                                            Add New UPI Id
                                        </h5>
                                    </div>
                                    <div className="modal-body modal_form">


                                        {
                                            userDetails?.user?.kycVerified != 2 ?

                                                <div className=" text-center" >
                                                    <img src="/assets/images/user_kyc.svg" className="img-fluid  m-auto" />
                                                    <span className={`m-auto mt-3 badge ${userDetails?.user?.kycVerified === 1 ? "badge-warning bg-warning" : userDetails?.user?.kycVerified === 0 ? "badge-warning bg-warning" : "badge-danger"}`} >
                                                        <Link className="text-dark" to={userDetails?.user?.kycVerified == 2 ? "#" : '/verify_kyc'}>
                                                            {userDetails?.user?.kycVerified == 1 ? "Kyc is Under Review" : userDetails?.user?.kycVerified == 0 ? "SUBMIT KYC" : "Verification Failed!"}
                                                        </Link>
                                                    </span>
                                                </div>
                                                :
                                                <form>
                                                    <div className="row">
                                                        <div className="col-12 form-group ">
                                                            <label className="text-white mb-1" htmlFor="upiHolder"> Name  </label>
                                                            <input type="text" name="upiHolder" id="upiHolder" className="form-control" value={formData.accountHolderName} disabled />
                                                        </div>
                                                        <div className="col-12 form-group">
                                                            <label className="text-white mb-1" htmlFor="upiId"> UPI VPA Id    </label>
                                                            <input type="text" name="upiId" id="upiId" className="form-control" value={formData.upiId} onChange={handleChange} />
                                                        </div>

                                                        <div className="col-12 form-group">
                                                            <label className="text-white mb-1" htmlFor="email_otp">Email OTP</label>
                                                            <div className="field-otp-box">
                                                                <input type="number" name="emailOtp" id="email_otp" className="form-control" placeholder="Email verification Code" required value={formData.emailOtp} onChange={handleChange} />

                                                                <button type="button" className="btn btn-sm btn-warning" onClick={() => { handleGetOtp(formData?.emailId, 'addbank') }} disabled={isOtpSent || !formData.upiId}>
                                                                    <span>{isOtpSent ? `Resend in ${countdown}s` : 'GET OTP'}</span>
                                                                </button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className=" text-center ">
                                                        <button type="button" className="btn btn-dark w-50 mt-3" onClick={handleSubmitAddUpi}>
                                                            Verify UPI
                                                        </button>
                                                    </div>
                                                </form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                )}


                {/* <!-- Edit account modal --> */}
                <div className="modal fade" id="modal_edit_upi" tabIndex="-1" aria-labelledby="modal_edit_upi_Label" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header flex-column px-8">
                                <h5 className="modal-title" id="modal_edit_upi_Label"> Edit UPI Id </h5>
                            </div>
                            <div className="modal-body modal_form">
                                <form className=" " >
                                    <div className="row">
                                        <div className="col-12 form-group ">
                                            <label className="text-white mb-1" htmlFor="signId">   Name </label>
                                            <input type="text" name="" id="" className="form-control" />
                                        </div>
                                        <div className="col-12 form-group">
                                            <label className="text-white mb-1" htmlFor="signId"> UPI Id    </label>
                                            <input type="text" name="" id="" className="form-control" />
                                        </div>
                                    </div>
                                    <div className=" text-center ">
                                        <button type="button" className="btn btn-dark btn-block w-100 mt-3 ">
                                            <div className="spinner-border text-light spinner-border-sm" role="status"> </div>
                                        </button>
                                        <button type="button" className="btn btn-secondary px-5 mt-3" data-bs-dismiss="modal" aria-label="Close" >  Cancel </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </main>

    )
}

export default PaymentModules