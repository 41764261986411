import React, { useRef, useState } from "react";
import Footer from "../../../Footer";
import AuthService from "../../../../../Services/ApiServices/AuthService";
import Swal from "sweetalert2";
import SpinLoader from "../../../SpinLoader";

const ListingYourCoin = () => {

    const [formData, setFormData] = useState({
        fullName: "",
        designation: "",
        email: "",
        phoneNumber: "",
        coinName: "",
        symbolImage: null,
        symbol: "",
        blockchain: "",
        contractAddress: "",
        website: "",
        whitepaper: "",
        launchDate: "",
        projectDetails: "",
        description: "",
        useCase: "",
        totalSupply: "",
        registerdCompany: "",
        registrationNo: "",
        securityMeasures: "",
        tokenomics: "",
        exchanges: "",
        tradingVolume: "",
        communitySize: "",
        additionalInfo: "",
        price: ""
    });

    const [formErrors, setFormErrors] = useState({});
    const [responseResult, setResponseResult] = useState("");
    const [loading, setLoading] = useState(false);
    const fieldRefs = useRef({});
    const [selectedImages, setSelectedImages] = useState([]);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^\d{10}$/;

    const handleInputChange = (e) => {
        const { name, type, files, value } = e.target;

        if (name === "phoneNumber" && !/^\d*$/.test(value)) {
            return;
        }

        setFormData({
            ...formData,
            [name]: type === "file" ? files[0] : value,
        });

        if (formErrors && name === getFirstInvalidField()) {
            setFormErrors("");
        }
    };

    const getFirstInvalidField = () => {
        if (!formData.fullName) return "fullName";
        if (!formData.designation) return "designation";
        if (!formData?.email || !emailRegex.test(formData.email)) return 'email';
        if (!formData?.phoneNumber || !phoneRegex.test(formData.phoneNumber)) return 'phoneNumber';
        if (!formData.coinName) return "coinName";
        if (!formData.symbolImage) return "symbolImage";
        if (!formData.symbol) return "symbol";
        if (!formData.price) return "price";
        if (!formData.blockchain) return "blockchain";
        if (!formData.contractAddress) return "contractAddress";
        if (!formData.website) return "website";
        if (!formData.whitepaper) return "whitepaper";
        if (!formData.launchDate) return "launchDate";
        if (!formData.projectDetails) return "projectDetails";
        if (!formData.description) return "description";
        if (!formData.useCase) return "useCase";
        if (!formData.totalSupply) return "totalSupply";
        if (!formData.registerdCompany) return "registerdCompany";
        if (!formData.registrationNo) return "registrationNo";
        if (!formData.securityMeasures) return "securityMeasures";
        if (!formData.tokenomics) return "tokenomics";
        if (!formData.exchanges) return "exchanges";
        if (!formData.tradingVolume) return "tradingVolume";
        if (!formData.communitySize) return "communitySize";
        if (!formData.additionalInfo) return "additionalInfo";
        return null;
    };

    const validateForm = (name, value) => {
        switch (name) {
            case "fullName":
                return value ? "" : "Full name is required.";
            case "designation":
                return value ? "" : "Designation is required.";
            case "email":
                return emailRegex.test(value) ? "" : "Enter Valid Email Address.";
            case "phoneNumber":
                return phoneRegex.test(value) ? "" : "Enter Valid 10-digit Phone Number is required.";
            case "coinName":
                return value ? "" : "coin Name is required.";
            case "symbolImage":
                return value ? "" : "Symbol Image is required.";
            case "symbol":
                return value ? "" : "Symbol is required.";
            case "price":
                return value ? "" : "Price is required.";
            case "blockchain":
                return value ? "" : "Blockchain/Network is required.";
            case "contractAddress":
                return value ? "" : "Contract Address is required.";
            case "website":
                return value ? "" : "Website is required.";
            case "whitepaper":
                return value ? "" : "Whitepaper is required.";
            case "launchDate":
                return value ? "" : "Launch Date is required.";
            case "projectDetails":
                return value ? "" : "Project Details is required.";
            case "description":
                return value ? "" : "Description is required.";
            case "useCase":
                return value ? "" : "Use Case is required.";
            case "totalSupply":
                return value ? "" : "Total Supply is required.";
            case "registerdCompany":
                return value ? "" : "Registerd Company is required.";
            case "registrationNo":
                return value ? "" : "Registration Number is required.";
            case "securityMeasures":
                return value ? "" : "Security Measures is required.";
            case "tokenomics":
                return value ? "" : "Tokenomics is required.";
            case "exchanges":
                return value ? "" : "Exchanges is required.";
            case "tradingVolume":
                return value ? "" : "Trading Volume is required.";
            case "communitySize":
                return value ? "" : "Community Size is required.";
            case "additionalInfo":
                return value ? "" : "Additional Info is required.";
            default:
                return "";
        }
    };

    const showAlert = (jobTitle) => {
        Swal.fire({
            title: `Thank You ${formData?.name}`,
            text: "Your Request Submited Successfully",
            icon: "success",
        })
    };

    const handleSubmit = async () => {
        const firstInvalidField = getFirstInvalidField();
        if (firstInvalidField) {
            const error = validateForm(firstInvalidField, formData[firstInvalidField]);
            setFormErrors({ [firstInvalidField]: error });
            if (fieldRefs.current[firstInvalidField]) {
                fieldRefs.current[firstInvalidField].scrollIntoView({ behavior: "smooth", block: "center" });
                fieldRefs.current[firstInvalidField].focus();
            }
            return;
        }
        if (selectedImages?.length <= 0) {
            setResponseResult("Please attach any relevant documents")
            return;
        }
        setFormErrors({});
        const formValue = new FormData();
        formValue.append("full_name", formData.fullName);
        formValue.append("designation", formData.designation);
        formValue.append("email", formData.email);
        formValue.append("phone", formData.phoneNumber);
        formValue.append("token_name", formData.coinName);
        formValue.append("token_image", formData.symbolImage);
        formValue.append("token_symbol", formData.symbol);
        formValue.append("blockchain[0].chain_name", formData.blockchain);
        formValue.append("blockchain[0].contract_address", formData.contractAddress);
        formValue.append("website", formData.website);
        formValue.append("whitepaper_link", formData.whitepaper);
        formValue.append("launch_date", formData.launchDate);
        formValue.append("project_details", formData.projectDetails);
        formValue.append("description", formData.description);
        formValue.append("use_case", formData.useCase);
        formValue.append("total_supply", formData.totalSupply);
        formValue.append("registered_company", formData.registerdCompany);
        formValue.append("company_reg_no", formData.registrationNo);
        formValue.append("security_measures", formData.securityMeasures);
        formValue.append("tokenomics", formData.tokenomics);
        formValue.append("listed_exchanges", formData.exchanges);
        formValue.append("avg_trading_volume_24hr", formData.tradingVolume);
        formValue.append("commmunity_engagement", formData.communitySize);
        formValue.append("other_details", formData.additionalInfo);
        selectedImages.map((item) => {
            formValue.append("other_images", item);
        })
        formValue.append("price_per_usdt", formData.price);
        setLoading(true);
        try {
            const result = await AuthService.coinListing(formValue);
            if (result?.success) {
                showAlert(result?.message);
                setResponseResult("")
                setLoading(false);
                setFormData({
                    fullName: "",
                    designation: "",
                    email: "",
                    phoneNumber: "",
                    coinName: "",
                    symbolImage: null,
                    symbol: "",
                    blockchain: "",
                    contractAddress: "",
                    website: "",
                    whitepaper: "",
                    launchDate: "",
                    projectDetails: "",
                    description: "",
                    useCase: "",
                    totalSupply: "",
                    registrationNo: "",
                    securityMeasures: "",
                    tokenomics: "",
                    exchanges: "",
                    tradingVolume: "",
                    communitySize: "",
                    additionalInfo: "",
                    registerdCompany: "",
                    price: "",
                });
                setSelectedImages([])
            } else {
                setLoading(false);
                setResponseResult(result || "An error occurred.");
            }
        } catch (error) {
            setLoading(false);
            setResponseResult(error?.message || "An unexpected error occurred.");
        }
    };

    const handleImageChanges = (e) => {
        const files = Array.from(e.target.files);
        const totalSelected = selectedImages.length + files.length;
        if (totalSelected > 10) {
            setResponseResult("You can upload a maximum of 10 files.");
            return;
        }
        const validFiles = files.filter((file) => file && file.name); // Filter out any invalid files

        setSelectedImages((prevFiles) => {
            const combinedFiles = [...prevFiles, ...validFiles];
            return combinedFiles.slice(0, 10); // Ensure only 10 files are kept
        });

        setResponseResult("")
    };

    const handleRemoveImage = (index) => {
        setSelectedImages((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };


    const [chains, setChains] = useState([
        { blockchain: "", contractAddress: "" },
    ]);
    
    const handleChainInputChange = (index, field, value) => {
        const updatedChains = chains.map((chain, idx) =>
            idx === index ? { ...chain, [field]: value } : chain
        );
        setChains(updatedChains);
    };

    const handleAddChain = () => {
        setChains([...chains, { blockchain: "", contractAddress: "" }]);
    };

    const handleRemoveChain = (index) => {
        setChains(chains.filter((_, idx) => idx !== index));
    };

    return (
        <>
            <main>
                <section className="tm_sec d-block pb-5">
                    <div className="container">
                        <div className="row mt-md-5 justify-content-center">
                            <div className="col-lg-12">
                                <div className="section-title  partner_title_sect text-start">
                                    <div className="row align-items-center g-4" >
                                        <div className="col-lg-5">
                                            <h2 className="text-dark"> List Your Coin on Crypto India Exchange  </h2>
                                            <div className="ddd text-start"  >
                                                <p>
                                                    At Crypto India, we prioritize the safety, security, and trust of our users. That's why our coin listing process is designed to ensure that only high-quality, genuine, and use-case-driven coins are available for trading on our platform.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-7" >
                                            <div className="sec_img" >
                                                <img src="assets/images/coin_list.svg" className="img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="features_sec feature_bg">
                    <div className="container">
                        <div className="sec_title mb-5">
                            <h2 className="text-dark text-center"> Why List Your Coin on Crypto India? </h2>
                        </div>
                        <div className="row g-4 justify-content-start">
                            <div className="col-lg-4">
                                <div className="card h-100">
                                    <div className="p-0  h-100">
                                        <div className=" h-100">
                                            <div>
                                                <h6 className="text mb-3">
                                                    Strict Quality Standards
                                                </h6>
                                                <span></span>
                                                <p>We follow rigorous listing criteria to ensure that only coins with a strong development team, clear use case, and proven utility are listed on our exchange. This helps maintain the integrity and quality of the trading experience for all users. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card h-100">
                                    <div className="p-0  h-100">
                                        <div className="h-100">
                                            <div>
                                                <h6 className="text mb-3">
                                                    User Safety & Genuineness
                                                </h6>
                                                <span>
                                                </span>
                                                <p>We are committed to safeguarding our users by conducting thorough due diligence on every coin. Our process includes technical audits, compliance checks, and background verification to ensure that each coin listed is safe, secure, and transparent.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="card h-100">
                                    <div className="  p-0">
                                        <div className="     h-100">
                                            <div>
                                                <h6 className="text mb-3">
                                                    Comprehensive Use Case Analysis
                                                </h6>
                                                <span></span>
                                                <p>Our listing team evaluates the coin's underlying technology, market potential, and real-world application to ensure it provides true value to the crypto community. Coins with strong use cases and real-world utility are prioritized for listing.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="how_it_sec">
                    <div className="container">
                        <div className="sec_title mb-5">
                            <h2 className="text-dark text-center "> Our Listing Process Includes </h2>
                            <p>
                                Users buy or sell crypto with fiat or other coins on a secure online platform in just simple 3 steps.
                            </p>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row gx-md-5  ">
                                    <div className="col-lg-4">
                                        <div className="how_it step_1  ">
                                            <div className="how_card    text-center">
                                                <div className="">
                                                    <h6>
                                                        Technical & Security Review
                                                    </h6>
                                                    <p className="mb-0"> Each coin undergoes an in-depth review by our technical team to ensure it meets the highest standards of security and reliability. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="how_it step_2   ">
                                            <div className="how_card    text-center ">
                                                <div className="">
                                                    <h6>
                                                        Compliance & Regulatory Checks
                                                    </h6>
                                                    <p className="mb-0">We ensure that each coin complies with local and international regulations, providing transparency and legal clarity to all users.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="how_it step_3 ">
                                            <div className="how_card  text-center ">
                                                <div className="">
                                                    <h6>
                                                        Ongoing Monitoring & Updates
                                                    </h6>
                                                    <p className="mb-0">Even after listing, we continuously monitor listed coins to ensure they maintain their value proposition and meet evolving market standards.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="tm_sec pt-0 mt-0">
                    <div className="container">
                        <div className="row   justify-content-center">
                            <div className="col-lg-10">
                                <div className="g_start m_card overflow-hidden p-0 ">
                                    <div className="p_card p-md-5">

                                        <div className="step_2 " >
                                            <div className="row">
                                                <div className="col-md-12 mb-2">
                                                    <h6 className="mb-3" >
                                                        Contact Information
                                                    </h6>
                                                    <div className="row">
                                                        <div className="field-box mb-3 col-lg-6">
                                                            <label className="form-label">Full Name</label>
                                                            <div className="field-otp-box mb-2">
                                                                <input
                                                                    type="text"
                                                                    ref={(el) => (fieldRefs.current.fullName = el)}
                                                                    className={`form-control ${formErrors.fullName ? "is-invalid" : ""}`}
                                                                    name="fullName"
                                                                    value={formData.fullName}
                                                                    onChange={handleInputChange}
                                                                />
                                                                {formErrors.fullName && (
                                                                    <div className="invalid-feedback">{formErrors.fullName}</div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="field-box mb-3 col-lg-6">
                                                            <label className="form-label">Designation</label>
                                                            <div className="field-otp-box mb-2">
                                                                <input
                                                                    type="text"
                                                                    ref={(el) => (fieldRefs.current.designation = el)}
                                                                    className={`form-control ${formErrors.designation ? "is-invalid" : ""}`}
                                                                    name="designation"
                                                                    value={formData.designation}
                                                                    onChange={handleInputChange}
                                                                />
                                                                {formErrors.designation && (
                                                                    <div className="invalid-feedback">{formErrors.designation}</div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="field-box mb-3 col-lg-6">
                                                            <label className="form-label">Email Address</label>
                                                            <div className="field-otp-box mb-2">
                                                                <input
                                                                    type="email"
                                                                    ref={(el) => (fieldRefs.current.email = el)}
                                                                    className={`form-control ${formErrors.email ? "is-invalid" : ""}`}
                                                                    name="email"
                                                                    value={formData.email}
                                                                    onChange={handleInputChange}
                                                                />
                                                                {formErrors.email && (
                                                                    <div className="invalid-feedback">{formErrors.email}</div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="field-box mb-3 col-lg-6">
                                                            <label className="form-label">Phone Number</label>
                                                            <div className="field-otp-box mb-2">
                                                                <input
                                                                    type="tel"
                                                                    ref={(el) => (fieldRefs.current.phoneNumber = el)}
                                                                    className={`form-control ${formErrors.phoneNumber ? "is-invalid" : ""}`}
                                                                    name="phoneNumber"
                                                                    value={formData.phoneNumber}
                                                                    onChange={handleInputChange}
                                                                    maxLength={10}
                                                                />
                                                                {formErrors.phoneNumber && (
                                                                    <div className="invalid-feedback">{formErrors.phoneNumber}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <br />
                                                    <h6 className="mb-3 mt-2">
                                                        Listing Information
                                                    </h6>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Coin/Token Name  </label>
                                                        <div className="field-otp-box mb-2">

                                                            <input
                                                                type="text"
                                                                ref={(el) => (fieldRefs.current.coinName = el)}
                                                                className={`form-control ${formErrors.coinName ? "is-invalid" : ""}`}
                                                                name="coinName"
                                                                value={formData.coinName}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.coinName && (
                                                                <div className="invalid-feedback">{formErrors.coinName}</div>
                                                            )}

                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label">   Symbol (Ticker) </label>
                                                        <div className="field-otp-box mb-2">
                                                            <input
                                                                type="file"
                                                                ref={(el) => (fieldRefs.current.symbolImage = el)}
                                                                className={`form-control ${formErrors.symbolImage ? "is-invalid" : ""}`}
                                                                name="symbolImage"
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.symbolImage && (
                                                                <div className="invalid-feedback">{formErrors.symbolImage}</div>
                                                            )}

                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Token/Coin Symbol  </label>
                                                        <div className="field-otp-box mb-2">
                                                            <input
                                                                type="text"
                                                                ref={(el) => (fieldRefs.current.symbol = el)}
                                                                className={`form-control ${formErrors.symbol ? "is-invalid" : ""}`}
                                                                name="symbol"
                                                                value={formData.symbol}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.symbol && (
                                                                <div className="invalid-feedback">{formErrors.symbol}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Price in USDT  </label>
                                                        <div className="field-otp-box mb-2">
                                                            <input
                                                                type="number"
                                                                ref={(el) => (fieldRefs.current.price = el)}
                                                                className={`form-control ${formErrors.price ? "is-invalid" : ""}`}
                                                                name="price"
                                                                value={formData.price}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.price && (
                                                                <div className="invalid-feedback">{formErrors.price}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Blockchain/Network  </label>
                                                        <div className="field-otp-box mb-2">
                                                            <input
                                                                type="text"
                                                                ref={(el) => (fieldRefs.current.blockchain = el)}
                                                                className={`form-control ${formErrors.blockchain ? "is-invalid" : ""}`}
                                                                name="blockchain"
                                                                value={formData.blockchain}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.blockchain && (
                                                                <div className="invalid-feedback">{formErrors.blockchain}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label">Smart Contract Address  </label>
                                                        <div className="field-otp-box mb-2">
                                                            <input
                                                                type="text"
                                                                ref={(el) => (fieldRefs.current.contractAddress = el)}
                                                                className={`form-control ${formErrors.contractAddress ? "is-invalid" : ""}`}
                                                                name="contractAddress"
                                                                value={formData.contractAddress}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.contractAddress && (
                                                                <div className="invalid-feedback">{formErrors.contractAddress}</div>
                                                            )}
                                                        </div>
                                                    </div>


                                                    {/* {chains.map((chain, index) => (
                                                        <div key={index} className="mb-4">
                                                            <div className="field-box mb-3">
                                                                <label className="form-label">Blockchain/Network</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={chain.blockchain}
                                                                    onChange={(e) =>
                                                                        handleChainInputChange(index, "blockchain", e.target.value)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="field-box mb-3">
                                                                <label className="form-label">Smart Contract Address</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={chain.contractAddress}
                                                                    onChange={(e) =>
                                                                        handleChainInputChange(index, "contractAddress", e.target.value)
                                                                    }
                                                                />
                                                            </div>
                                                            {chains.length > 1 && (
                                                                <button
                                                                    type="button"
                                                                    className="btn w-auto px-5 btn-lg btn-danger"
                                                                    onClick={() => handleRemoveChain(index)}
                                                                >
                                                                    Remove
                                                                </button>
                                                            )}
                                                        </div>
                                                    ))}

                                                    <button
                                                        type="button"
                                                        className="btn w-auto px-5 btn-lg btn-dark"
                                                        onClick={handleAddChain}
                                                    >
                                                        Add More
                                                    </button>

                                                    <hr /> */}
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Official Website   </label>
                                                        <div className="field-otp-box mb-2">
                                                            <input
                                                                type="text"
                                                                ref={(el) => (fieldRefs.current.website = el)}
                                                                className={`form-control ${formErrors.website ? "is-invalid" : ""}`}
                                                                name="website"
                                                                value={formData.website}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.website && (
                                                                <div className="invalid-feedback">{formErrors.website}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Whitepaper Link     </label>
                                                        <div className="field-otp-box mb-2">
                                                            <input
                                                                type="text"
                                                                ref={(el) => (fieldRefs.current.whitepaper = el)}
                                                                className={`form-control ${formErrors.whitepaper ? "is-invalid" : ""}`}
                                                                name="whitepaper"
                                                                value={formData.whitepaper}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.whitepaper && (
                                                                <div className="invalid-feedback">{formErrors.whitepaper}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Launch Date   </label>
                                                        <div className="field-otp-box mb-2">
                                                            <input
                                                                type="date"
                                                                ref={(el) => (fieldRefs.current.launchDate = el)}
                                                                className={`form-control ${formErrors.launchDate ? "is-invalid" : ""}`}
                                                                name="launchDate"
                                                                value={formData.launchDate}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.launchDate && (
                                                                <div className="invalid-feedback">{formErrors.launchDate}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Project Details    </label>
                                                        <div className="field-otp-box mb-2">
                                                            <input
                                                                type="text"
                                                                ref={(el) => (fieldRefs.current.projectDetails = el)}
                                                                className={`form-control ${formErrors.projectDetails ? "is-invalid" : ""}`}
                                                                name="projectDetails"
                                                                value={formData.projectDetails}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.projectDetails && (
                                                                <div className="invalid-feedback">{formErrors.projectDetails}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-12 mb-4">
                                                        <label className="form-label mb-1"> Brief Description of Your Coin/Token
                                                        </label>
                                                        <div className=" field-otp-box">

                                                            <textarea className={`form-control ${formErrors.description ? "is-invalid" : ""}`} rows="4" placeholder="Type message here" name="description" value={formData.description}
                                                                onChange={handleInputChange}
                                                                ref={(el) => (fieldRefs.current.description = el)}
                                                            ></textarea>
                                                            {formErrors.description && (
                                                                <div className="invalid-feedback">{formErrors.description}</div>
                                                            )}

                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Use Case and Real-World Application  </label>
                                                        <div className="field-otp-box mb-2">
                                                            <input
                                                                type="text"
                                                                ref={(el) => (fieldRefs.current.useCase = el)}
                                                                className={`form-control ${formErrors.useCase ? "is-invalid" : ""}`}
                                                                name="useCase"
                                                                value={formData.useCase}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.useCase && (
                                                                <div className="invalid-feedback">{formErrors.useCase}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Total Supply: </label>
                                                        <div className="field-otp-box mb-2">
                                                            <input
                                                                type="number"
                                                                ref={(el) => (fieldRefs.current.totalSupply = el)}
                                                                className={`form-control ${formErrors.totalSupply ? "is-invalid" : ""}`}
                                                                name="totalSupply"
                                                                value={formData.totalSupply}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.totalSupply && (
                                                                <div className="invalid-feedback">{formErrors.totalSupply}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Are you a registered company? </label>
                                                        <div className="field-otp-box mb-2">
                                                            <select
                                                                className={`form-control form-select ${formErrors.registerdCompany ? "is-invalid" : ""}`}
                                                                name="registerdCompany"
                                                                ref={(el) => (fieldRefs.current.registerdCompany = el)}
                                                                onChange={handleInputChange}
                                                            >
                                                                <option> Select Option</option>
                                                                <option value="Yes"> Yes </option>
                                                                <option value="No"> No </option>
                                                            </select>
                                                            {formErrors.registerdCompany && (
                                                                <div className="invalid-feedback">{formErrors.registerdCompany}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label">  Company Registration No.    </label>
                                                        <div className="field-otp-box mb-2">
                                                            <input
                                                                type="number"
                                                                ref={(el) => (fieldRefs.current.registrationNo = el)}
                                                                className={`form-control ${formErrors.registrationNo ? "is-invalid" : ""}`}
                                                                name="registrationNo"
                                                                value={formData.registrationNo}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.registrationNo && (
                                                                <div className="invalid-feedback">{formErrors.registrationNo}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> How does your project ensure security against fraud and hacks?    </label>
                                                        <div className="field-otp-box mb-2">
                                                            <input
                                                                type="text"
                                                                ref={(el) => (fieldRefs.current.securityMeasures = el)}
                                                                className={`form-control ${formErrors.securityMeasures ? "is-invalid" : ""}`}
                                                                name="securityMeasures"
                                                                value={formData.securityMeasures}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.securityMeasures && (
                                                                <div className="invalid-feedback">{formErrors.securityMeasures}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Tokenomics & Distribution:   </label>
                                                        <div className="field-otp-box mb-2">
                                                            <input
                                                                type="text"
                                                                ref={(el) => (fieldRefs.current.tokenomics = el)}
                                                                className={`form-control ${formErrors.tokenomics ? "is-invalid" : ""}`}
                                                                name="tokenomics"
                                                                value={formData.tokenomics}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.tokenomics && (
                                                                <div className="invalid-feedback">{formErrors.tokenomics}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <br />
                                                    <h6 className="mb-3 mt-2" >
                                                        Market Information:
                                                    </h6> 
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Exchanges where the coin is currently listed  </label>
                                                        <div className="field-otp-box mb-2">
                                                            <input
                                                                type="text"
                                                                ref={(el) => (fieldRefs.current.exchanges = el)}
                                                                className={`form-control ${formErrors.exchanges ? "is-invalid" : ""}`}
                                                                name="exchanges"
                                                                value={formData.exchanges}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.exchanges && (
                                                                <div className="invalid-feedback">{formErrors.exchanges}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Average Trading Volume (24hr)</label>
                                                        <div className="field-otp-box mb-2">
                                                            <input
                                                                type="number"
                                                                ref={(el) => (fieldRefs.current.tradingVolume = el)}
                                                                className={`form-control ${formErrors.tradingVolume ? "is-invalid" : ""}`}
                                                                name="tradingVolume"
                                                                value={formData.tradingVolume}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.tradingVolume && (
                                                                <div className="invalid-feedback">{formErrors.tradingVolume}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Community Engagement & Size:  </label>
                                                        <div className="field-otp-box mb-2">
                                                            <input
                                                                type="text"
                                                                ref={(el) => (fieldRefs.current.communitySize = el)}
                                                                className={`form-control ${formErrors.communitySize ? "is-invalid" : ""}`}
                                                                name="communitySize"
                                                                value={formData.communitySize}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.communitySize && (
                                                                <div className="invalid-feedback">{formErrors.communitySize}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <br />
                                                    <h6 className="mb-3 mt-2" >
                                                        Other Information
                                                    </h6>

                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label">Any additional details we should know about your project? </label>
                                                        <div className="field-otp-box mb-2">
                                                            <input
                                                                type="text"
                                                                ref={(el) => (fieldRefs.current.additionalInfo = el)}
                                                                className={`form-control ${formErrors.additionalInfo ? "is-invalid" : ""}`}
                                                                name="additionalInfo"
                                                                value={formData.additionalInfo}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.additionalInfo && (
                                                                <div className="invalid-feedback">{formErrors.additionalInfo}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label">  Attachments <br /> <small>(Please attach any relevant documents like whitepapers, audit reports, legal statements, etc.)</small> </label>
                                                        <div className="accordion-body">
                                                            <div className="dov_card mt-2">
                                                                <label className="form-label">Upload Assets ( <small>Total Files: {selectedImages.length} / 10</small>
                                                                    )</label>
                                                                <input
                                                                    className="form-control"
                                                                    type="file"
                                                                    name="selectedImages"
                                                                    multiple
                                                                    onChange={handleImageChanges}
                                                                />
                                                                <div className="upd_images">

                                                                    {selectedImages.map((file, index) => (
                                                                        <div className="upd_box" key={index}>
                                                                            <div className="upd_card">
                                                                                {file.type.startsWith("image/") ? (
                                                                                    <img
                                                                                        src={URL.createObjectURL(file)}
                                                                                        alt="uploaded"
                                                                                        style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                                                                    />
                                                                                ) : (
                                                                                    <p>{file.name}</p>
                                                                                )}
                                                                                <button
                                                                                    className="btn btn-sm btn-outline-danger"
                                                                                    onClick={() => handleRemoveImage(index)}
                                                                                    type="button"
                                                                                >
                                                                                    Remove
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                {responseResult && (
                                                    <div className="col-md-12">
                                                        <div className="alert alert-danger">{responseResult}</div>
                                                    </div>
                                                )}
                                                <div className="col-md-12 ">
                                                    <button className="btn w-auto px-5 form-control btn-lg btn-dark" type="button" onClick={handleSubmit}>
                                                        {loading ? <SpinLoader /> : <span>Submit</span>}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>

    )
}

export default ListingYourCoin