import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../Footer";


const FeeStructure = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])
    return (
        <>
        <main>
            <section class="tm_sec">
                <div class="container">
                    <div class="row   mt-md-5 justify-content-center">
                    <div class="col-lg-10">
                        <div class="section-title text-center">
                        <h2 class="text-gradient mb-0 "> Fee structure </h2>
                            <nav class="mt-2">
                                <ol class="breadcrumb justify-content-center">
                                    <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page"> Fee structure </li>
                                </ol>
                            </nav>
                        </div> 

                        {/* <div class="d-flex-between  custom_dlflex mrk_menu">
                            <ul class="nav custom-tabs">
                                <li class="active"> <span> Trading Fee </span></li>
                                <li class=""><span> Withdrawal Fee </span></li>
                            </ul>
                        </div> */}


                        <div className="fees_wrapper" >
                            {/* tab_1 */}


                            <div className="trading_fee_tab" >

                                <h6> Quick Buy Sell</h6>
                                <div  class="crypto-group ng-star-inserted" >
                                    <table className="mt-3 mb-5" >
                                        <thead >
                                        <tr  class="inactive-level">
                                            <th  class="token-head">Crypto/Fiat</th>
                                            <th  class="head-custom">  Trading Fee  </th>
                                            <th  class="head-custom">Minimum Deposit Limit</th>
                                            <th  class="head-custom">Minimum Withdrawal Limit</th>
                                            <th  class="head-custom">Deposit Fee</th>
                                            <th  class="head-custom">Withdrawal Fee</th>
                                        </tr>
                                        </thead>
                                        <tbody >
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Indian Rupee (INR) </td>
                                            <td > 0.00 </td>
                                            <td > 1000.00 INR </td>
                                            <td > 1000.00 INR </td>
                                            <td > 0.00 INR </td>
                                            <td > 0.00 INR </td>
                                        </tr> 
                                        </tbody>
                                    </table>  

                                    <h6> Spot Trading </h6>

                                    <table className="mt-3" >
                                        <thead >
                                        <tr  class="inactive-level">
                                            <th  class="token-head">Crypto</th>
                                            <th  class="head"> Trading Fee </th>
                                            <th  class="head">Minimum Withdrawal Limit</th>
                                            <th  class="head">Withdrawal Fee</th>
                                        </tr>
                                        </thead>
                                        <tbody >
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Cosmos (ATOM) </td>
                                            <td> 0.20% </td>
                                            <td > 0.2000 ATOM </td>
                                            <td > 0.0800 ATOM </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Avalanche (AVAX) </td>
                                            <td> 0.20% </td>
                                            <td > 0.1000 AVAX </td>
                                            <td > 0.0200 AVAX </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > BakeryToken (BAKE) </td>
                                            <td> 0.20% </td>
                                            <td > 16.0000 BAKE </td>
                                            <td > 2.0000 BAKE </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Basic Attention Token (BAT) </td>
                                            <td> 0.20% </td>
                                            <td > 68.4100 BAT </td>
                                            <td > 34.2000 BAT </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Binance Coin (BNB) </td>
                                            <td> 0.20% </td>
                                            <td > 0.0150 BNB </td>
                                            <td > 0.0020 BNB </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Chromia (CHR) </td>
                                            <td> 0.20% </td>
                                            <td > 25.0000 CHR </td>
                                            <td > 3.0000 CHR </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Chiliz (CHZ) </td>
                                            <td> 0.20% </td>
                                            <td > 211.3100 CHZ </td>
                                            <td > 105.6600 CHZ </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > The Sandbox (SAND) </td>
                                            <td> 0.20% </td>
                                            <td > 33.4000 SAND </td>
                                            <td > 16.7000 SAND </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Cartesi (CTSI) </td>
                                            <td> 0.20% </td>
                                            <td > 25.0000 CTSI </td>
                                            <td > 3.0000 CTSI </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Convex Finance (CVX) </td>
                                            <td> 0.20% </td>
                                            <td > 5.1600 CVX </td>
                                            <td > 2.5800 CVX </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Doge (DOGE) </td>
                                            <td> 0.20% </td>
                                            <td > 20.0000 DOGE </td>
                                            <td > 8.0000 DOGE </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Elrond eGold (EGLD) </td>
                                            <td> 0.20% </td>
                                            <td > 0.0130 EGLD </td>
                                            <td > 0.0066 EGLD </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Ethereum (ETH) </td>
                                            <td> 0.20% </td>
                                            <td > 0.0100 ETH </td>
                                            <td > 0.0000 ETH </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Filecoin (FIL) </td>
                                            <td> 0.20% </td>
                                            <td > 0.1200 FIL </td>
                                            <td > 0.0600 FIL </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Fantom (FTM) </td>
                                            <td> 0.20% </td>
                                            <td > 41.7900 FTM </td>
                                            <td > 20.9000 FTM </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Gnosis (GNO) </td>
                                            <td> 0.20% </td>
                                            <td > 0.0800 GNO </td>
                                            <td > 0.0400 GNO </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > IoTeX (IOTX) </td>
                                            <td> 0.20% </td>
                                            <td > 100.0000 IOTX </td>
                                            <td > 8.0000 IOTX </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Kyber Network (KNC) </td>
                                            <td> 0.20% </td>
                                            <td > 5.0000 KNC </td>
                                            <td > 1.0000 KNC </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > SHIBA INU (SHIB) </td>
                                            <td> 0.20% </td>
                                            <td > 1718439.7400 SHIB </td>
                                            <td > 859219.8700 SHIB </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Synthetix Network Token (SNX) </td>
                                            <td> 0.20% </td>
                                            <td > 4.7699 SNX </td>
                                            <td > 2.3900 SNX </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Sushi (SUSHI) </td>
                                            <td> 0.20% </td>
                                            <td > 5.0000 SUSHI </td>
                                            <td > 0.6000 SUSHI </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Tron (TRX) </td>
                                            <td> 0.20% </td>
                                            <td > 10.0000 TRX </td>
                                            <td > 1.0000 TRX </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Tether (USDT) </td>
                                            <td> 0.20% </td>
                                            <td > 5.0000 USDT </td>
                                            <td > 0.6000 USDT </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > VeChain (VET) </td>
                                            <td> 0.20% </td>
                                            <td > 250.0000 VET </td>
                                            <td > 24.0000 VET </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Lido DAO (LDO) </td>
                                            <td> 0.20% </td>
                                            <td > 5.2699 LDO </td>
                                            <td > 2.6400 LDO </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > NEAR Protocol (NEAR) </td>
                                            <td> 0.20% </td>
                                            <td > 0.2200 NEAR </td>
                                            <td > 0.1100 NEAR </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > PAX Gold (PAXG) </td>
                                            <td> 0.20% </td>
                                            <td > 0.0100 PAXG </td>
                                            <td > 0.0000 PAXG </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Pendle (PENDLE) </td>
                                            <td> 0.20% </td>
                                            <td > 2.0000 PENDLE </td>
                                            <td > 0.7500 PENDLE </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Phoenix Global (PHB) </td>
                                            <td> 0.20% </td>
                                            <td > 5.0000 PHB </td>
                                            <td > 0.7000 PHB </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Quant (QNT) </td>
                                            <td> 0.20% </td>
                                            <td > 0.1400 QNT </td>
                                            <td > 0.0700 QNT </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Rocket Pool (RPL) </td>
                                            <td> 0.20% </td>
                                            <td > 0.0060 RPL </td>
                                            <td > 0.0011 RPL </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Radiant Capital (RDNT) </td>
                                            <td> 0.20% </td>
                                            <td > 20.0000 RDNT </td>
                                            <td > 2.2000 RDNT </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > yearn.finance (YFI) </td>
                                            <td> 0.20% </td>
                                            <td > 0.0006 YFI </td>
                                            <td > 0.0003 YFI </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Highstreet (HIGH) </td>
                                            <td> 0.20% </td>
                                            <td > 11.9200 HIGH </td>
                                            <td > 5.9600 HIGH </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Selfkey (KEY) </td>
                                            <td> 0.20% </td>
                                            <td > 3205.3900 KEY </td>
                                            <td > 1602.6900 KEY </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Mask Network (MASK) </td>
                                            <td> 0.20% </td>
                                            <td > 4.1800 MASK </td>
                                            <td > 2.0900 MASK </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Pepe (PEPE) </td>
                                            <td> 0.20% </td>
                                            <td > 14074702.5000 PEPE </td>
                                            <td > 7037351.2500 PEPE </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > TrueFi (TRU) </td>
                                            <td> 0.20% </td>
                                            <td > 331.1400 TRU </td>
                                            <td > 165.5700 TRU </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > WINkLink (WIN) </td>
                                            <td> 0.20% </td>
                                            <td > 8388.0000 WIN </td>
                                            <td > 4194.0000 WIN </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Chainlink (LINK) </td>
                                            <td> 0.20% </td>
                                            <td > 1.1700 LINK </td>
                                            <td > 0.5800 LINK </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Aave (AAVE) </td>
                                            <td> 0.20% </td>
                                            <td > 0.1700 AAVE </td>
                                            <td > 0.0800 AAVE </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Decentraland (MANA) </td>
                                            <td> 0.20% </td>
                                            <td > 36.8400 MANA </td>
                                            <td > 18.4200 MANA </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > The Graph (GRT) </td>
                                            <td> 0.20% </td>
                                            <td > 97.7300 GRT </td>
                                            <td > 48.8600 GRT </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Ambire AdEx (ADX) </td>
                                            <td> 0.20% </td>
                                            <td > 3.7800 ADX </td>
                                            <td > 1.8900 ADX </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Curve (CRV) </td>
                                            <td> 0.20% </td>
                                            <td > 29.9500 CRV </td>
                                            <td > 14.9700 CRV </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Frax Share (FXS) </td>
                                            <td> 0.20% </td>
                                            <td > 2.0400 FXS </td>
                                            <td > 1.0200 FXS </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Gala (GALA) </td>
                                            <td> 0.20% </td>
                                            <td > 648.3000 GALA </td>
                                            <td > 324.1500 GALA </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Axie Infinity (AXS) </td>
                                            <td> 0.20% </td>
                                            <td > 2.1500 AXS </td>
                                            <td > 1.0700 AXS </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > PancakeSwap (CAKE) </td>
                                            <td> 0.20% </td>
                                            <td > 2.0000 CAKE </td>
                                            <td > 0.3000 CAKE </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Celer (CELR) </td>
                                            <td> 0.20% </td>
                                            <td > 36.0000 CELR </td>
                                            <td > 18.0000 CELR </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Dai (DAI) </td>
                                            <td> 0.20% </td>
                                            <td > 15.0000 DAI </td>
                                            <td > 7.5000 DAI </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Maker (MKR) </td>
                                            <td> 0.20% </td>
                                            <td > 0.0100 MKR </td>
                                            <td > 0.0000 MKR </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > STEPN (GMT) </td>
                                            <td> 0.20% </td>
                                            <td > 2.5600 GMT </td>
                                            <td > 1.2800 GMT </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Linear Finance (LINA) </td>
                                            <td> 0.20% </td>
                                            <td > 2000.0000 LINA </td>
                                            <td > 1000.0000 LINA </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Solana (SOL) </td>
                                            <td> 0.20% </td>
                                            <td > 0.0500 SOL </td>
                                            <td > 0.0060 SOL </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > USD Coin (USDC) </td>
                                            <td> 0.20% </td>
                                            <td > 15.0000 USDC </td>
                                            <td > 7.5000 USDC </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Polkadot (DOT) </td>
                                            <td> 0.20% </td>
                                            <td > 1.0000 DOT </td>
                                            <td > 0.1000 DOT </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Compound (COMP) </td>
                                            <td> 0.20% </td>
                                            <td > 0.1000 COMP </td>
                                            <td > 0.0120 COMP </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Bitcoin Cash (BCH) </td>
                                            <td> 0.20% </td>
                                            <td > 0.0020 BCH </td>
                                            <td > 0.0015 BCH </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > BinaryX (BNX) </td>
                                            <td> 0.20% </td>
                                            <td > 2.5400 BNX </td>
                                            <td > 1.2700 BNX </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Bitcoin (BTC) </td>
                                            <td> 0.20% </td>
                                            <td > 0.0010 BTC </td>
                                            <td > 0.0004 BTC </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > ARPA (ARPA) </td>
                                            <td> 0.20% </td>
                                            <td > 265.2100 ARPA </td> 
                                            <td > 132.6000 ARPA </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Adappter Token (ADP) </td>
                                            <td> 0.20% </td>
                                            <td > 0.1000 ADP </td>
                                            <td > 0.0000 ADP </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Alpaca Finance (ALPACA) </td>
                                            <td> 0.20% </td>
                                            <td > 4.0000 ALPACA </td>
                                            <td > 2.0000 ALPACA </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > True USD (TUSD) </td>
                                            <td> 0.20% </td>
                                            <td > 15.0200 TUSD </td>
                                            <td > 7.5100 TUSD </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Uniswap (UNI) </td>
                                            <td> 0.20% </td>
                                            <td > 2.5900 UNI </td>
                                            <td > 1.3000 UNI </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Ankr (ANKR) </td>
                                            <td> 0.20% </td>
                                            <td > 150.0000 ANKR </td>
                                            <td > 24.0000 ANKR </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Zilliqa (ZIL) </td>
                                            <td> 0.20% </td>
                                            <td > 200.0000 ZIL </td>
                                            <td > 16.0000 ZIL </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Alchemy Pay (ACH) </td>
                                            <td> 0.20% </td>
                                            <td > 877.9800 ACH </td>
                                            <td > 438.9900 ACH </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > ApeCoin (APE) </td>
                                            <td> 0.20% </td>
                                            <td > 11.5600 APE </td>
                                            <td > 5.7800 APE </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > Loopring (LRC) </td>
                                            <td> 0.20% </td>
                                            <td > 66.5000 LRC </td>
                                            <td > 33.2500 LRC </td>
                                        </tr>
                                        <tr  class="inactive-level ng-star-inserted">
                                            <td > WOO Network (WOO) </td>
                                            <td> 0.20% </td>
                                            <td > 2.0000 WOO </td>
                                            <td > 0.9200 WOO </td>
                                        </tr>
                                    
                                        </tbody>
                                    </table>
                                </div> 


                                <div className="note" >

                                

                                <p>
                                    Please read the <a targt="_blank" href="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/terms_and_conditions.pdf" >Terms of Use</a> and  Risk Disclosure as applicable to the website.
                                </p>

                                <p>
                               <b>Disclaimer</b> Crypto products & NFTs are unregulated and can be highly risky. There may be no regulatory recourse for any loss from such transactions. The information and material contained herein are subject to change without prior notice including prices which may fluctuate based on market demand and supply. The material available on the site is proprietary to Crypto India, its parent, Licensor and/or its affiliates and is for informational purposes and informed investors only. This material is not: (i) an offer, or solicitation of an offer, to invest in, or to buy or sell, any interests or shares, or to participate in any investment or trading strategy, or (ii) intended to provide accounting, legal, or tax advice, or investment recommendations.
                                </p>

                                <h5 ><strong >Please Note: </strong></h5>

                                <ul >
                                        <li >Certain pairs allow the fee to be calculated based on the trade's base currency, which can be specified when ordering using the fee currency option.</li>
                                    <li >
                                        Fees on the Platform for INR trading pairs are updated daily.
                                        </li>
                                        <li >Taxes will be levied as per applicable law. </li><li >Fees are charged on a per-trade basis and calculated as a percentage of the trade's quoted currency volume by default.</li>
                                        <li >Fees are adjusted according to the blockchain conditions regularly.</li>
                                </ul>

                                </div>








                                               
                            </div>




                        </div>



                         



                    </div>
                    



                    </div>


                    





                </div>
            </section>
        </main>
        
        <Footer />
        </>

    )
}

export default FeeStructure