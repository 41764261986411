import React, { useEffect, useState } from "react";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import moment from "moment";
import LoaderHelper from "../../../../Services/Loading/LoaderHelper";
import { alertErrorMessage } from "../../../../Services/CustomAlertModel";
import AuthService from "../../../../Services/ApiServices/AuthService";
import DataTable from "react-data-table-component";
import { saveAs } from 'file-saver';


const SpotOrders = () => {

    const [activeSpotOrders, setActiveSpotOrders] = useState("tradeHistory");
    const [selectedTab, setSelectedTab] = useState('all');
    const [spotOrders, setSpotOrders] = useState([]);
    const [search, setSearch] = useState("");
    const [filterDataSpot, setFilterDataSpot] = useState([]);
    const [totalDataLength, setTotalDataLength] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [activeExport, setActiveExport] = useState(false);
    const [totalOpenOrder, setTotalOpenOrder] = useState([]);
    const [openOrder, setOpenOrder] = useState([]);
    const [filterDataOpenOrder, setFilterDataOpenOrder] = useState([]);

    const [skip, setSkip] = useState(0);
    const limit = 20;
    const [skipOpenOrder, setSkipOpenOrder] = useState(0);
    const limitOpenOrder = 20;

    const handleSpotOrders = async (skip, limit, startDate, endDate) => {
        const validStartDate = startDate || "";
        const validEndDate = endDate || "";
        try {

            const result = await AuthService.spotList(skip, limit, validStartDate, validEndDate);
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                setSpotOrders(result.data);
                setTotalDataLength(result?.count);
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };


    const handleOpenOrder = async (skipOpenOrder, limitOpenOrder, startDate, endDate) => {
        const validStartDate = startDate || "";
        const validEndDate = endDate || "";
        try {

            const result = await AuthService.openOrderList(skipOpenOrder, limitOpenOrder, validStartDate, validEndDate);
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                setOpenOrder(result.data);
                setTotalOpenOrder(result?.count);
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        handleSpotOrders(skip, limit);
        handleOpenOrder(skipOpenOrder, limitOpenOrder);
    }, [skip, limit, skipOpenOrder, limitOpenOrder]);


    useEffect(() => {
        const filteredData = spotOrders.filter(item => {
            if (selectedTab !== 'all') {
                if (selectedTab === 'buy' && item.side !== 'BUY') return false;
                if (selectedTab === 'sell' && item.side !== 'SELL') return false;
            }

            return (
                item?.currency?.toLowerCase().includes(search?.toLowerCase()) ||
                item?.side?.toLowerCase().includes(search?.toLowerCase()) ||
                item?.transaction_type?.toLowerCase().includes(search?.toLowerCase()) ||
                item?.order_id?.toLowerCase().includes(search?.toLowerCase())
            );
        });

        setFilterDataSpot(filteredData);
    }, [selectedTab, search, spotOrders]);


    useEffect(() => {
        const filteredData = openOrder.filter(item => {
            if (selectedTab !== 'all') {
                if (selectedTab === 'buy' && item.side !== 'BUY') return false;
                if (selectedTab === 'sell' && item.side !== 'SELL') return false;
                if (selectedTab === 'pending' && item.status !== 'PENDING') return false;
                if (selectedTab === 'complete' && item.status !== 'COMPLETED') return false;
                if (selectedTab === 'cancel' && item.status !== 'CANCELLED') return false;
            }

            return (
                item?.currency?.toLowerCase().includes(search?.toLowerCase()) ||
                item?.side?.toLowerCase().includes(search?.toLowerCase()) ||
                item?.transaction_type?.toLowerCase().includes(search?.toLowerCase()) ||
                item?.order_id?.toLowerCase().includes(search?.toLowerCase()) ||
                item?._id?.toLowerCase().includes(search?.toLowerCase())
            );
        });

        setFilterDataOpenOrder(filteredData);
    }, [selectedTab, search, openOrder]);


    const columns = [
        {
            name: 'Order No.',
            selector: row => row.order_id || row._id,
            wrap: true,
            sortable: true,
        },
        {
            name: "Date/Time",
            cell: (row) =>
                <small>
                    {moment(row.createdAt).format("DD/MM/YYYY")}
                    {moment(row.createdAt).format("HH:mm:ss")}
                </small>,
            sortable: false,
        },

        {
            name: 'Trading Pair',
            selector: row => row.base_currency + '/' + row.quote_currency,
            wrap: true,
            sortable: true,
        },

        {
            name: 'Order Type',
            selector: row => row.order_type,
            wrap: true,
            sortable: true,
        },

        {

            name: 'Order Side',
            selector: row => row.side,
            wrap: true,
            sortable: true,
            format: row =>
                <span className={row.side === 'BUY' ? " text-success" : "text-danger"}>
                    {row?.side}
                </span>,
        },

        {
            name: 'Currency Price',
            selector: row => row.price,
            wrap: true,
            sortable: true,
        },
        {
            name: 'Order quantity',
            selector: row => (row.amount)?.toFixed(2),
            wrap: true,
            sortable: true,
        },

        {
            name: 'Executed',
            selector: row => row.quantity + " - " + (row.price * row.quantity)?.toFixed(2),
            wrap: true,
            sortable: true,

        },

        ...(activeSpotOrders === 'tradeHistory' ? [{
            name: 'Fee Type',
            selector: row => row.fees_type,
            wrap: true,
            sortable: true,
            format: row =>
                <div className="text-end">
                    {row.fees_type}
                </div>,
        }] : []),

        {
            name: 'Fee',
            selector: row => row.transaction_fees_amount || row.transaction_fee_amount,
            wrap: true,
            sortable: true,
            format: row =>
                <div className="text-end">
                    {row.transaction_fees_amount?.toFixed(2) || row.transaction_fee_amount?.toFixed(2)}
                </div>,
        },


        {
            name: 'TDS',
            selector: row => row.tds_amount,
            wrap: true,
            sortable: true,
            format: row =>
                <div className="text-end">
                    {row.tds_amount?.toFixed(2)}
                </div>,
        },

        ...(activeSpotOrders === 'tradeHistory' ? [{
            name: 'Avg Price',
            selector: row => row.avgPrice || '-----',
            wrap: true,
            sortable: true,
        }] : []),


        {
            name: 'Total',
            selector: row => (row.total_amount)?.toFixed(5),
            wrap: true,
            sortable: true,

        },

        ...(activeSpotOrders === 'openOrders' ? [{
            name: 'Status',
            selector: row => row.status,
            wrap: true,
            sortable: true,
            format: row =>
                <span className={row.status === 'SUCCESS' || row.status === 'FILLED' ? " text-success" : row.status === 'PENDING' ? " text-warning" : "text-danger"}>
                    {row.status === 'FILLED' ? "EXECUTED" : row?.status}
                </span>,
        }] : []),

    ];


    const handlePagination = (action) => {
        if (action === 'prev') {
            if (skip - limit >= 0) {
                setSkip(skip - limit);
            }
        } else if (action === 'next') {
            if (skip + limit < totalDataLength) {
                setSkip(skip + limit);
            }
        } else if (action === 'first') {
            setSkip(0);
        } else if (action === 'last') {
            const lastPageSkip = Math.floor(totalDataLength);
            const data = lastPageSkip - 10
            setSkip(data);
        }
    };

    const handlePaginationOpenOrder = (action) => {
        if (action === 'prev') {
            if (skipOpenOrder - limitOpenOrder >= 0) {
                setSkipOpenOrder(skipOpenOrder - limitOpenOrder);
            }
        } else if (action === 'next') {
            if (skipOpenOrder + limitOpenOrder < totalOpenOrder) {
                setSkipOpenOrder(skipOpenOrder + limitOpenOrder);
            }
        } else if (action === 'first') {
            setSkipOpenOrder(0);
        } else if (action === 'last') {
            const lastPageSkip = Math.floor(totalOpenOrder);
            const data = lastPageSkip - 10
            setSkipOpenOrder(data);
        }
    };

    const handleClear = () => {
        setSearch('');
        setStartDate('');
        setEndDate('');
        handleSpotOrders(skip, limit);
        handleOpenOrder(skipOpenOrder, limitOpenOrder);
    };

    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]).filter(key => !['_id', 'user_id', 'order_id', 'updatedAt', '_v'].includes(key));
        let csv = headers.join(',') + '\n';
        data.forEach(item => {
            const row = headers.map(header => {
                if (header === 'createdAt') {
                    return moment(item[header]).format('DD-MM-YYYY HH:mm:ss');
                }
                return item[header];
            });
            csv += row.join(',') + '\n';
        });

        return csv;
    };


    const handleExportData = async (startDate, endDate) => {
        try {
            const result = await AuthService.exportDataSpot(startDate, endDate);
            if (result?.success) {
                if (result.data && result.data.length > 0) {
                    const csvData = convertToCSV(result.data);
                    saveAs(new Blob([csvData], { type: 'text/csv' }), 'Trade_history.csv');
                    setStartDate("")
                    setEndDate("")
                } else {
                    alertErrorMessage('No Data Available...!')
                }
                LoaderHelper.loaderStatus(false);
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };

    const handleTabChange = (event) => {
        setSelectedTab(event.target.value);
    };


    return (
        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper">
                    <section className="d_page history_page">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="pg_header mb-0" >
                                        <div className="hd_innre" >
                                            <h6 className="mb-0" >Spot Orders</h6>
                                        </div>
                                    </div>

                                    <div className="spot_tabs my-2 my-md-3" >
                                        <ul className="nav custom-tabs">

                                            <li className={`${activeSpotOrders === "openOrders" ? 'active' : ''}`}
                                                onClick={() => setActiveSpotOrders('openOrders')}> <span> Open Orders </span>
                                            </li>
                                            <li className={`tab_item ${activeSpotOrders === "orderHistory" ? 'active' : ''}`}
                                                onClick={() => setActiveSpotOrders('orderHistory')}><span>Order History</span>
                                            </li>
                                            <li className={`tab_item ${activeSpotOrders === "tradeHistory" ? 'active' : ''}`}
                                                onClick={() => setActiveSpotOrders('tradeHistory')}><span>Trade History</span>
                                            </li>
                                        </ul>
                                    </div>


                                    <div className="filter-card mb-3 mb-md-4">
                                        <div className="row g-2 align-items-end" >
                                            <div className="col-lg-10" >
                                                <div className="row g-2 align-items-end" >
                                                    <div className="col-lg-2" >
                                                        <div className="form-group" >
                                                            <label> Search </label>
                                                            <div className="searchBar custom-tabs mt-0">
                                                                <input type="search" className="custom_search" placeholder="Search Crypto" value={search} onChange={(e) => { setSearch(e.target.value); }} />
                                                                <i className="ri-search-2-line"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-2 ">
                                                        <div className="form-group">
                                                            <label> Order Side </label>
                                                            <select className="form-control form-select cursor-pointer" onChange={handleTabChange}>
                                                                <option className="" value="all">All Orders</option>
                                                                <option value="buy">Buy Orders</option>
                                                                <option value="sell">Sell Orders</option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                    {activeSpotOrders === "openOrders" &&
                                                        <div className="col-12 col-lg-2">
                                                            <div className="form-group">
                                                                <label> Order Status </label>
                                                                <select className="form-control form-select cursor-pointer" onChange={handleTabChange}>
                                                                    <option value="all">All Status</option>
                                                                    <option value="pending">Pending </option>
                                                                    <option value="complete">Completed </option>
                                                                    <option value="cancel">Cancelled</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-6  col-lg-2">
                                                        <div className="form-group">
                                                            <label>Start Date</label>
                                                            <input
                                                                type="date"
                                                                className="form-control cursor-pointer"
                                                                value={startDate}
                                                                onChange={(e) => setStartDate(e.target.value)}
                                                                max={new Date().toISOString().split("T")[0]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6  col-lg-2">
                                                        <div className="form-group">
                                                            <label>End Date</label>
                                                            <input
                                                                type="date"
                                                                className="form-control cursor-pointer"
                                                                value={endDate}
                                                                onChange={(e) => setEndDate(e.target.value)}
                                                                max={new Date().toISOString().split("T")[0]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <div className="d-flex gap-2 mt-2 mt-md-0">
                                                            <button className="btn btn-muted w-50" onClick={handleClear} disabled={!startDate || !endDate}>Clear</button>
                                                            <button className="btn btn-dark w-50" onClick={() => { handleSpotOrders(skip, limit, startDate, endDate); setActiveExport(true) }} disabled={!startDate || !endDate}>Apply</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2" >
                                                <div className="ph_right" >
                                                    <button className="btn btn-dark px-4" type="button" onClick={() => handleExportData(startDate, endDate)} disabled={!startDate || !endDate || !activeExport}>
                                                        Export <i className="ms-2 ri-download-2-line"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* openOrders */}
                                    <div className={`g_start m_card overflow-hidden p-0 ${activeSpotOrders != "openOrders" ? 'd-none' : ''}`}>
                                        <div className="m_table">
                                            <div className="table-responsive h-auto">
                                                <div className="table-responsive">
                                                    <DataTable className="table table-hover"
                                                        columns={columns}
                                                        data={filterDataOpenOrder}
                                                        noDataComponent={
                                                            <p>
                                                                <img src="/assets/images/not_found.svg" width="300" className='img-fluid mt-5' alt='not_found' />
                                                            </p>
                                                        }
                                                    />
                                                    {
                                                        filterDataOpenOrder?.length > 0 &&
                                                        <div className="hVPalX gap-2" >
                                                            <span className="" >{skipOpenOrder + 1}-{Math.min(skipOpenOrder + limitOpenOrder, totalOpenOrder)} of {totalOpenOrder}</span>
                                                            <div className="sc-eAKtBH gVtWSU">
                                                                <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePaginationOpenOrder('first')}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                        <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                                        <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                                    </svg>
                                                                </button>
                                                                <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePaginationOpenOrder('prev')}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                                    </svg>
                                                                </button>

                                                                <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePaginationOpenOrder('next')}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                                    </svg>
                                                                </button>
                                                                <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePaginationOpenOrder('last')}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                        <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                                        <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Orders History */}
                                    <div className={`${activeSpotOrders != "tradeHistory" ? 'd-none' : ''}`}>
                                        <div className="g_start m_card overflow-hidden p-0 " >
                                            <div className="m_table">
                                                <div className="table-responsive">
                                                    <DataTable className="table table-hover"
                                                        columns={columns}
                                                        data={filterDataSpot}
                                                        noDataComponent={
                                                            <p>
                                                                <img src="/assets/images/not_found.svg" width="300" className='img-fluid mt-5' alt='not_found' />
                                                            </p>
                                                        }
                                                    />
                                                    {
                                                        filterDataSpot?.length > 0 &&
                                                        <div className="hVPalX gap-2" >
                                                            <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                                            <div className="sc-eAKtBH gVtWSU">
                                                                <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first')}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                        <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                                        <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                                    </svg>
                                                                </button>
                                                                <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev')}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                                    </svg>
                                                                </button>

                                                                <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next')}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                                    </svg>
                                                                </button>
                                                                <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last')}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                        <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                                        <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>

    )
}

export default SpotOrders