import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import QRCode from 'qrcode.react';
import copy from 'copy-to-clipboard';
import AuthService from "../../../../Services/ApiServices/AuthService";
import { fetchCoinDetails, fetchCoinList, fetchVerifyDeposit } from "../../../../Context/Utility/apiutils";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import { alertErrorMessage, alertSuccessMessage } from "../../../../Services/CustomAlertModel";
import { ApiConfig } from "../../../../Services/ApiServices/AuthAction/ApiConfig";
import { ProfileContext } from "../../../../Services/ProfileProvider";
const DepositFunds = () => {

    const location = useLocation();
    const { state } = location;
    const [stateData, setStateData] = useState(state || null);



    const [isActive, setIsActive] = useState('step1')
    const [coinList, setCoinList] = useState([]);
    const [genrateDepositqr, setGenrateDepositqr] = useState("");
    const [search, setsearch] = useState("");
    const [fiterPairData, setFiterPairData] = useState([]);
    const [coinDetails, setCoinDetails] = useState('');
    const [chainName, setChainName] = useState('');
    const [activeDepostInr, setActiveDepostInr] = useState('step1');
    const { userDetails } = useContext(ProfileContext);


    const getDepostAddress = async (chain) => {
        await AuthService.generateDepositAddress(chain).then((result) => {
            if (result?.success) {
                try {
                    setGenrateDepositqr(result?.data);
                } catch (error) {
                    alertErrorMessage(error)
                }
            }
            else {
                alertErrorMessage(result?.message)
                setIsActive('step1')
            }
        });
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchVerifyDeposit();
        }, 60000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        let filteredData = coinList?.filter((item) => {
            return (
                item?.short_name?.toLowerCase().includes(search?.toLowerCase())
            );
        });
        setFiterPairData(filteredData);
    }, [search, coinList]);




    const handleDepositDetails = (item, step) => {

        if (item?.short_name === 'INR') {
            /*   setIsActive('step4')
              setChainName("") */
        } else {
            if (!userDetails?.permissions?.deposit) {
                alertErrorMessage('Disable Deposit..');
                return;
            }
            setIsActive(step)
            fetchCoinDetails(setCoinDetails, item?.currency_id)
            getDepostAddress(item?.chain[0])
            setChainName(item?.chain[0])
        }
    }

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setChainName(selectedValue);
        getDepostAddress(selectedValue, 'step2');
    };


    useEffect(() => {
        fetchCoinList(setCoinList);
    }, []);


    useEffect(() => {
        if (stateData) {
            handleDepositDetailsAuto(stateData, "step2");
        }
    }, [stateData]);


    const handleDepositDetailsAuto = (item, step) => {
        if (!userDetails?.permissions?.deposit) {
            alertErrorMessage('Disable Deposit..');
            return;
        }
        setIsActive(step);
        fetchCoinDetails(setCoinDetails, item?.currency_id);
        getDepostAddress(item?.chain[0]);
        setChainName(item?.chain[0]);
    };

    const handleBack = () => {
        setStateData(null);
        setIsActive('step1');
    };

    return (
        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper">
                    <section className="d_page history_page p_page deposit_wrapper">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xxl-10 col-xl-12">
                                    <div className="d_u_p align-items-center m-0">
                                        <div className="dup_left">
                                            <h6 className="mb-0">Desposit Funds </h6>
                                        </div>
                                        <div className="up_right text-end">
                                            <Link to="/transaction_history" className="btn btn-muted"> Recent Transactions </Link>
                                        </div>
                                    </div>
                                    <div className="g_start d_card mt-3">

                                        <div className={`step-1 ${isActive === 'step1' ? 'active' : 'd-none'}`}>
                                            <div className="nc_header">
                                                <div className="row align-items-center">
                                                    <div className="col-md-6  col-lg-7">
                                                        <span className="nc_icon justify-content-start">
                                                            Select Coin
                                                        </span>
                                                    </div>
                                                    {coinList?.length > 0 ?
                                                        <div className="col-md-6 col-lg-5">
                                                            <div className="searchBar custom-tabs">
                                                                <i className="ri-search-2-line"></i>
                                                                <input type="search" className="custom_search w-100" placeholder="Search Crypto Coin" value={search} onChange={(e) => setsearch(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            </div>
                                            <div className="nc_body scroll_y">
                                                <div className="ng_table">
                                                    <div className="container">
                                                        <div className="row  t_head_chain">
                                                            <div className="col-3 text-start">
                                                                <p>Coin Name</p>
                                                            </div>
                                                            <div className="col-3 text-center">
                                                                <p>Available Balance </p>
                                                            </div>
                                                            <div className="col-3 text-center">
                                                                <p>Locked Balance </p>
                                                            </div>
                                                            <div className="col-3 text-end">
                                                                <p>Total Balance</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="t_body_row">
                                                        {fiterPairData?.length > 0 ? (
                                                            fiterPairData?.map((item, index) => (
                                                                <span className="row align-items-center t_body_chain cursor_pointer" key={index} onClick={() => handleDepositDetails(item, 'step2')}>
                                                                    <div className="col-3 text-start">
                                                                        <div className="td_div">
                                                                            <img src={item.icon_path} width="25" className="img-fluid icon_img coinimg_max me-2 " alt=""
                                                                                onError={(e) => {
                                                                                    e.target.onerror = null;
                                                                                    e.target.src = "/assets/images/icon/nocoin.png";
                                                                                }} />
                                                                            <b>{item.short_name}</b>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3 text-center">
                                                                        <p>{item.balance?.toFixed(5)}</p>
                                                                    </div>
                                                                    <div className="col-3 text-center">
                                                                        <p>{item.locked_balance?.toFixed(5)}</p>
                                                                    </div>
                                                                    <div className="col-3 text-end">
                                                                        <p>{(item.balance + item.locked_balance)?.toFixed(5)}</p>
                                                                    </div>
                                                                </span>
                                                            ))
                                                        ) : (
                                                            <div className="favouriteData">
                                                                <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Step 3 */}
                                        <div className={`step-3 ${isActive === 'step2' ? 'active' : 'd-none'}`}>
                                            <div className="nc_header">
                                                <div className="row align-items-center">
                                                    <div className="col-md-6  col-lg-7">
                                                        <span className="nc_icon justify-content-start ">
                                                            <span className="btn-icon cursor_pointer" onClick={() => { setIsActive('step1'); handleBack() }}><i className="ri-arrow-left-s-line"></i></span>
                                                            Deposit {coinDetails?.short_name}
                                                            <img src={coinDetails.icon_path} width="25" className="img-fluid icon_img coinimg_max me-2 " alt=""
                                                                onError={(e) => {
                                                                    e.target.onerror = null;
                                                                    e.target.src = "/assets/images/icon/nocoin.png";
                                                                }} />
                                                        </span>
                                                    </div>
                                                    <div className="col-md-6 col-lg-5 text-end">
                                                        <p className="text_ifo">
                                                            <small className="text-muted d-block">Minimum deposit</small>
                                                            <small>
                                                                <b className="text-dark">More than {coinDetails?.min_deposit} {coinDetails?.short_name}
                                                                </b>
                                                            </small>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="nc_body nc_qr">
                                                <div className="form-group mb-3" >
                                                    <div className="spot_tabs mb-2 mb-md-3">
                                                        <label htmlFor="deposit address">Select Chain</label>
                                                        {coinDetails?.chain?.length > 0 ? (
                                                            <select
                                                                className="form-select form-control width-50"
                                                                value={chainName}
                                                                onChange={handleSelectChange}
                                                            >
                                                                <option disabled>
                                                                    Select a network
                                                                </option>
                                                                {coinDetails.chain.map((item, index) => (
                                                                    <option key={index} value={item} className="form-control">
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            null
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="qr_body">
                                                    <QRCode value={genrateDepositqr} className="img-fluid" />
                                                    <div className="qr_add">
                                                        <div>
                                                            <span className="text-muted">Address</span>
                                                            <p> {genrateDepositqr} </p>
                                                        </div>
                                                        <span className="px-2 cursor-pointer btn-copy" onClick={() => { copy(genrateDepositqr); alertSuccessMessage("Deposit Address copied..!!") }}> <i className="ri-file-copy-fill "></i> </span>
                                                    </div>
                                                </div>

                                                <div className="p_card mt-2 p-3">
                                                    <ul className="desc mb-0">
                                                        <li>
                                                            <i className="ri-error-warning-line me-2"></i>
                                                            Minimum deposit of {coinDetails?.min_deposit} {coinDetails?.short_name}, deposit below that cannot be recovered.
                                                        </li>
                                                        <li>
                                                            <i className="ri-error-warning-line me-2"></i>
                                                            Please deposit only {coinDetails?.short_name} on this address. If you deposit any other coin, it will be lost forever.
                                                        </li>
                                                        <li>
                                                            <i className="ri-error-warning-line me-2"></i>
                                                            This is {chainName} deposit address type. Transferring to an unsupported network could result in loss of deposit.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>


                                        {/* Step INR */}
                                        <div className={`step-4 ${isActive === 'step4' ? 'active' : 'd-none'}`}>
                                            <div className="nc_header">
                                                <div className="row align-items-center">
                                                    <div className="col-md-12  col-lg-12">
                                                        <span className="nc_icon justify-content-start ">
                                                            <span className="btn-icon cursor_pointer" onClick={() => { setIsActive('step1'); setActiveDepostInr('step1') }}><i className="ri-arrow-left-s-line"></i></span>
                                                            INR
                                                        </span>
                                                    </div>
                                                    <div className="ng_table">
                                                        {/* step 1 */}
                                                        <div className={`t_body_row ${activeDepostInr === 'step1' ? '' : 'd-none'}`}>
                                                            <div className="up_right text-center my-3" id="myTab" role="tablist" >
                                                                <ul className="nav custom-tabs">
                                                                    <li className="" role="presentation">
                                                                        <a href="/?" className="active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                                                                            role="tab" aria-controls="home-tab-pane" aria-selected="true">Bank Account</a>
                                                                    </li>
                                                                    <li className="" role="presentation">
                                                                        <a href="/?" className="" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
                                                                            role="tab" aria-controls="profile-tab-pane" aria-selected="false">UPI ID </a>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <button className="btn custom-btn mt-3 w-100 btn-block" onClick={() => setActiveDepostInr('step2')}> Deposit INR </button>
                                                        </div>

                                                        {/* step 2 */}
                                                        <div className={`t_body_row modal_form p_card mt-3 ${activeDepostInr === 'step2' ? '' : 'd-none'}`}>
                                                            <div className="row">
                                                                <div className="col-12 text-center" >
                                                                    <h6> Verify Deposit </h6>
                                                                </div>
                                                                <div className="col-12 form-group ">
                                                                    <label className="text-white mb-1" htmlFor="signId"> Amount </label>
                                                                    <input type="text" name="" id="" className="form-control" />
                                                                </div>
                                                                <div className="col-12 form-group">
                                                                    <label className="text-white mb-1" htmlFor="signId"> Enter UTR No.</label>
                                                                    <input type="text" name="" id="" className="form-control" />
                                                                </div>
                                                                <div className="col-12" >
                                                                    <button className="btn custom-btn mt-3 w-100 btn-block" > Verify Deposit   </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>

    )
}

export default DepositFunds