import React, { useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Scrollbar } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import { SocketContext } from '../../../../Context/SocketContext';


const MarketUpdates = () => {

    const [socketData, setSocketData] = useState([]);
    const [selectedPairs, setSelectedPairs] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const { socket } = useContext(SocketContext);

    const navigate = useNavigate();
    useEffect(() => {
        if (socket) {
            let payload = {
                'message': 'market',
            };
            socket.emit('message', payload);
            socket.on('message', (data) => {
                setSocketData(data?.pairs);
            });
        }
    }, [socket]);

    useEffect(() => {
        if (socketData?.length > 0) {
            const currentPairExists = socketData.some(item => item?._id === selectedPairs?._id);            
            if (!selectedPairs || !currentPairExists) {
                setSelectedPairs(socketData[0]);
            }
        }
    }, [socketData]);
    
    const arrowClass = selectedPairs?.change_percentage > 0 ? 'ri-arrow-up-s-fill' : 'ri-arrow-down-s-fill';
    const arrowClassSuccess = selectedPairs?.change_percentage > 0 ? 'pr_data text-success' : 'pr_data text-danger';

    const nextPage = (item) => {
        if (item?.quote_currency === "INR") {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            navigate('/buy_sell');
        } else {
            navigate(`/exchange/${item?.base_currency}_${item?.quote_currency}`, { replace: true });
        }
    };

    return (
        <section className="market_update_sec market_update_table pt-0">
            <div className="container">
                <div className="section-title xxl_title  text-center">
                    <h2 className="text-gradient text-uppercase"> market <span className="text-warning">updates</span> </h2>
                </div>
                <div className="tu_main selection">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            {
                                selectedPairs?.base_currency ?

                                    <div className="mu_main">
                                        <div className="td_div">

                                            <img src={selectedPairs?.icon_path || "/assets/images/icon/nocoin.png"} className="img-fluid icon_img coinimg_max me-2 " onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} />
                                            <div className="headline_etfDisplay__P4Hdv">
                                                <span>{selectedPairs?.base_currency_full_name || '----'}</span>
                                            </div>
                                            <b>
                                                {
                                                    !selectedPairs?.base_currency ? "--------------"
                                                        :
                                                        selectedPairs?.base_currency + "/" + selectedPairs?.quote_currency

                                                }
                                            </b>

                                        </div>

                                        <h3 className="mu_pr">
                                            {selectedPairs?.quote_currency === "INR" ? '₹' : '$'} {(selectedPairs?.buy_price?.toFixed(2)) || '0.000'}
                                            <div className={arrowClassSuccess}><i className={`${arrowClass} me-1`}></i>{(selectedPairs?.change_percentage || 0).toString()}% </div>
                                        </h3>
                                    </div>
                                    :
                                    <img src="/assets/images/coin_list.svg" className="img-fluid " />
                            }

                        </div>
                        <div className="col-md-8">
                            <div className={`card_mr ${isActive && 'active'}`}>
                                <div className="row g-md-4">
                                    <div className="col-6">
                                        <div className="mr_data">
                                            <span>Market cap  </span>
                                            <h5>
                                                {selectedPairs && typeof selectedPairs.buy_price === 'number' && typeof selectedPairs.volume === 'number' ?

                                                    (selectedPairs.buy_price * selectedPairs.volume)?.toFixed(2) :
                                                    '0.00'
                                                }
                                            </h5>
                                            <div className={arrowClassSuccess}><i className={`${arrowClass} me-1`}></i>
                                                {(selectedPairs?.change_percentage || 0).toFixed(2)}%
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="mr_data">
                                            <span>Buy Price  </span>
                                            <h5>{(selectedPairs?.buy_price || 0).toFixed(2)}</h5>
                                            <div className={arrowClassSuccess}><i className={`${arrowClass} me-1`}></i>{(selectedPairs?.change_percentage || 0).toString()}% </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="mr_data">
                                            <span>Volume  </span>
                                            <h5>{(selectedPairs?.volume || 0).toFixed(2)}</h5>
                                            <div className={arrowClassSuccess}>
                                                <i className={`${arrowClass} me-1`}></i>
                                                {(selectedPairs?.change_percentage || 0).toFixed(2)}%
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="mr_data">
                                            <span> Trading Bot  </span>
                                            <h5>${(selectedPairs?.trading_bot_gap || 0).toFixed(2)}</h5>
                                            <div className={arrowClassSuccess}><i className={`${arrowClass} me-1`}></i>
                                                {(selectedPairs?.change_percentage || 0).toFixed(2)}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pc_card">
                        <div className="swiper mySwiper coin_slide">
                            <Swiper scrollbar={{ hide: true }} slidesPerView="auto" modules={[Scrollbar]} className="swiper-wrapper">
                                {socketData && socketData.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="swiper-slide cursor_pointer"
                                            onMouseEnter={() => { setSelectedPairs(item); setIsActive(true); }}
                                            onMouseLeave={() => setIsActive(false)}
                                            onClick={() => nextPage(item)}
                                        >
                                            <div className="pc_item">
                                                <div className="td_div flex-column align-items-start">

                                                    <div className='d-flex align-items-center gap-3' >
                                                        <img src={item?.icon_path} width="35" className="img-fluid icon_img coinimg_xxl" onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} />
                                                        <span className="  ">
                                                            <b className="me-2">{item.base_currency_full_name?.length > 15
                                                                ? item.base_currency_full_name.substring(0, 15) + ".."
                                                                : item.base_currency_full_name || '----'}
                                                            </b></span>
                                                    </div>
                                                    <span>{item?.base_currency + "/" + item?.quote_currency}</span>
                                                    <h4> {item.quote_currency === "INR" ? '₹' : '$'} {(item?.buy_price?.toFixed(2))} </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        <div className="swiper-scrollbar"></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MarketUpdates