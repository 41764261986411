
import html2pdf from 'html2pdf.js';
import moment from 'moment/moment';

export const SanctionsPdf = async () => {




  try {

    const htmlContent =
      ` 
             <style type="text/css">
  @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

  body {
    margin: 0;
    padding: 0;
    background: #ffffff;
  }

  div,
  p,
  a,
  li,
  td {
    -webkit-text-size-adjust: none;
  }

  .ReadMsgBody {
    width: 100%;
    background-color: #ffffff;
  }

  .ExternalClass {
    width: 100%;
    background-color: #ffffff;
  }

  body {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
  }

  html {
    width: 100%;
  }

  p {
    padding: 0 !important;
    margin-top: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
  }

  .visibleMobile {
    display: none;
  }

  .hiddenMobile {
    display: block;
  }
  .table-bordered >* {
    border-width: .5px;
    border-color: black;
  }  


</style>


</head><body> 

<table width="600px" border="0" cellpadding="0" cellspacing="0" align="center" className="fullTable" bgcolor="#ffffff">
  <tbody>
    <tr> 
      <td>
        <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" className="fullTable" bgcolor="#ffffff">
          <tbody>
            <tr>
            </tr><tr className="hiddenMobile">
              <td height="30"></td>
            </tr>
            <tr className="visibleMobile">
              <td height="20"></td>
            </tr>
            <tr>
              <td>

                <table   width="680" cellpadding="2"  align="center"  >
                  <tbody>
                    <tr>
                      <td  colspan="2"  style="font-size: 18px; font-weight: 600; color: #000; font-family: 'Open Sans', sans-serif; line-height: 20px; vertical-align: middle; text-align: center;">
                        Company Letterhead
                      </td>
                    </tr> 
                    <tr>
                      <td height="10" colspan="2"></td>
                    </tr> 
                     <tr>
                      <td height="10" colspan="2"></td>
                    </tr>
                  </tbody>

                </table> 

              </td>
            </tr>   
            <tr>
              <td >
                <table className="table table-bordered" style="border: 0;" border="1" width="680" cellpadding="5"  align="center"  > 
                    <tr>
                      <td  colspan="2"  style="font-size: 16px; font-weight: 600; color: #000; font-family: 'Open Sans', sans-serif; line-height: 20px; vertical-align: top; text-align: center;">
                        SANCTIONS QUESTIONNAIRE
                      </td>
                    </tr> 
                    <tr>
                      <td  height="20"   style="  font-size: 12px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                        Legal Entity Name
                      </td>
                      <td  height="20"   style="  font-size: 12px;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                       
                      </td>
                    </tr>
                    <tr>
                      <td  height="20"   style="  font-size: 12px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                        CIN / Registration Number    
                      </td>
                      <td  height="20"   style="  font-size: 12px;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                       
                      </td>
                    </tr>
                    <tr>
                      <td  height="20"   style="  font-size: 12px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                        Registered Address
                      </td>
                      <td  height="20"   style="  font-size: 12px;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                       
                      </td>
                    </tr>
                    <tr>
                      <td  height="20"   style="  font-size: 12px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                        Company's Operations Address    
                      </td>
                      <td  height="20"   style="  font-size: 12px;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                       
                      </td>
                    </tr>
                    <tr>
                      <td  height="20"  style=" width: 50%;  font-size: 12px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                        Does your Company or any Director directly or  
                        indirectly engage in or plan to engage in activity with
                        or in any of the following Sanctioned Countries  
                        Regions?     
                      </td>
                      <td  height="20"  style=" width: 50%;  font-size: 12px; font-weight:500;  color: #ffffff; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                        Does your Company or any Director directly or  
                        indirectly engage in or plan to engage in activity with
                        or in any of the following Sanctioned Countries  
                        Regions?     
                      </td>
                    </tr>
                    <tr>
                      <td  height="20" colspan="2"   style="  font-size: 12px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                        Please tick whichever region is applicable:    
                      </td> 
                    </tr> 
                    <tr>
                      <td  height="40" style=" max-width: 50%;  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: top; " className="article"> 

                        <h4 style="margin-top: 0;  font-size: 14px;" >Grey Listed Countries:</h4>

                        <ul style="list-style: none; " >
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style="height: 10px; width: 10px; margin-right: 10px; margin-top:15px;  border: 1px solid #000000;" ></span> 
                             Albania
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                             Barbados
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                             Burkina Faso
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                            Cameroon
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                             Cayman Islands
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                             Democratic Republic of the Congo
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                             Gibraltar
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                             Haiti
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                             Jamaica
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                             Jordan
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                             Mali
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                             Mozambique
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                               Nigeria
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                              Panama
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                              Philippines
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                             Senegal
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                             Mozambique
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                              South Africa
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                              South Sudan
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                             Mozambique
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                              Syria
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                              Tanzania
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                              Turkey
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                              Uganda
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                              United Arab Emirates (UAE)
                          </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                             <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                              Yemen
                          </li>
                        </ul>





                      </td>
                      <td  height="40"   style="    font-size: 14px;  color: #000; font-family: 'Open Sans', sans-serif;  vertical-align: top; " className="article">

                        <table width="100%" cellpadding="0" cellspacing="0" align="left" className="fullPadding">
                          <tbody> 
                            
                             


                            <tr>
                              <td style=" font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: top; " >
                                <h4 style="margin-top: 0;  font-size: 14px;" >Blacklisted Countries:</h4>

                                <ul style="list-style: none;" >
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                                     <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px;  display: inline-block; border: 1px solid #000000;" ></span> 
                                     North Korea
                                  </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                                     <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                                     Iran
                                  </li>
                          <li style=" max-width: 100%;  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  display:flex; align-items:center; " >
                                     <span style=" margin-top:15px; height: 10px; width: 10px; margin-right: 10px; display: inline-block; border: 1px solid #000000;" ></span> 
                                     No One from both list
                                  </li>
                                </ul>
                              </td>   
                            </tr>
                            <tr>
                              <td height="60" colspan="1" ></td>
                            </tr>
        
                            <tr>
                              <td height="1" colspan="1" style="border-bottom:1px solid #a8a8a8"></td>
                            </tr>
                            <tr>
                              <td height="60" colspan="1" ></td>
                            </tr>

                            
                            <tr>
                              <td > 
                                <table >
                                  <tr  > 
                                    <td   height="30" style=" font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: top; "  > 
                                      Name:.................................................................</td>
                                  </tr>
                                  <tr> 
                                     <td  height="30" style=" font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: top; "  >
                                      Mobile Number:................................................  </td>
                                  </tr>
                                  <tr> 
                                    <td   height="30" style=" font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: top; " > 
                                      Date:....................................................................  </td>
                                  </tr>
                                </table>
                              </td>   
                            </tr>

                            
                            <tr>
                              <td height="60" colspan="1" ></td>
                            </tr>

                            <tr>
                              <td height="1" colspan="1" style="border-bottom:1px solid #a8a8a8"></td>
                            </tr>
                            <tr>
                              <td height="130" colspan="1" ></td>
                            </tr>

                            <td   height="30" style=" font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: bottom; " > 
                              Company Stamp & Signature of Authorized <br/>
                              Signatory </td>






                          </tbody>
                        </table> 

                         
                       
                      </td>
                    </tr>

                    
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td height="20"></td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table> 


</body></html> 


           `
    const element = document.createElement("div");
    element.innerHTML = htmlContent;
    await html2pdf()
      .from(element)
      .save(`SanctionsQuestionnaires.pdf`);
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};
