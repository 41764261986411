import React, { useContext, useEffect, useState } from "react";
import DashboardSidebar from "../../../../CoustamComponent/DashboardSidebar";
import { ProfileContext } from "../../../../../Services/ProfileProvider";
import Select from 'react-select';
import AuthService from "../../../../../Services/ApiServices/AuthService";
import { format } from 'date-fns';
import moment from "moment";
import SpinLoader from "../../../../CoustamComponent/SpinLoader";
import { Link } from "react-router-dom";

const PersonalKyc = () => {

    const { userDetails, handleUserProfile, userKycDetails, handleGetKycDetails, loading, setLoading } = useContext(ProfileContext);
    const [kycStatus, setKycStatus] = useState("");
    const [isPanValid, setIsPanValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPan, setIsLoadingPan] = useState(false);
    const [aadharOtpDetails, setAadharOtpDetails] = useState("");
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [checkButton, setCheckButton] = useState(false);
    const [kycFormStep, setKycFormStep] = useState('');
    const [isActivePanAadhar, setIsActivePanAadhar] = useState(false);
    const [isActiveLiveness, setIsActiveLiveness] = useState(false);
    const [isActiveEducation, setIsActiveEducation] = useState(false);
    const [activeModeKycReject, setActiveModeKycReject] = useState(false);
    const [isActiveAadharOtpField, setIsActiveAadharOtpField] = useState(false);
    const transactionId = localStorage.getItem("user_id");
    const [stepRejectedRemark, setStepRejectedRemark] = useState([]);
    const [progressBar, setProgressBar] = useState([]);
    const [responseResult, setResponseResult] = useState("");
    const [formError, setFormError] = useState({});
    const [decelaration, setDecelaration] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [userLatestPan, setUserLatestPan] = useState();
    const [successMessage, setSuccessMessage] = useState("");

    const kycLevelStatus = userDetails?.user?.user_upgrade
    const kycLevelStatusType = userDetails?.user?.upgrade_status

    const maskName = (name) => {
        if (!name) return '';
        const words = name.trim().split(' ');
        const maskedWords = words.map((word) => {
            if (word.length <= 2) return word;
            const firstChar = word[0];
            const lastChar = word[word.length - 1];
            const maskedPart = '*'.repeat(word.length - 2);
            return `${firstChar}${maskedPart}${lastChar}`;
        });
        return maskedWords.join(' ');
    };

    const [formData, setFormData] = useState({
        panNumber: '',
        aadharNumber: '',
        aadharOtp: '',
        edituaction: '',
        maritalStatus: '',
        occupation: '',
        annualIncome: '',
        tradingTurnover: '',
    });

    useEffect(() => {
        if (userDetails) {
            setFormData(prevState => ({
                ...prevState,
                panNumber: userKycDetails?.pandetails?.pan || userLatestPan || '',
                edituaction: userKycDetails?.otherDetails?.education || '',
                maritalStatus: userKycDetails?.otherDetails?.marital_status || '',
                occupation: userKycDetails?.otherDetails?.occupation || '',
                annualIncome: userKycDetails?.otherDetails?.annual_income || '',
                tradingTurnover: userKycDetails?.otherDetails?.annual_turnover || '',
            }));
        }
        setKycStatus(userDetails?.user?.kycVerified);
    }, [userDetails, userKycDetails]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        let formattedValue = value.toUpperCase().trim();
        if (name === "panNumber") {
            const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
            if (formattedValue === "") {
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: formattedValue,
                }));
                setIsPanValid(false);
                setErrorMessage("");
                return;
            }
            if (/^[A-Z]{0,5}$/.test(formattedValue) || /^[A-Z]{5}[0-9]{0,4}$/.test(formattedValue) || /^[A-Z]{5}[0-9]{4}[A-Z]{0,1}$/.test(formattedValue)) {
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: formattedValue,
                }));
                if (formattedValue.length === 10) {
                    if (panRegex.test(formattedValue)) {
                        setIsPanValid(true);
                        setErrorMessage("");
                    } else {
                        setIsPanValid(false);
                    }
                } else {
                    setIsPanValid(false);
                    setErrorMessage("");
                }
            } else {
                setIsPanValid(false);
            }
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: formattedValue,
            }));
            setErrorMessage("");
        }
        setResponseResult("");
        setSuccessMessage("");
    };

    const handleAadharChange = (e) => {
        let input = e.target.value.replace(/\D/g, '');
        const formattedInput = input.replace(/(\d{4})(?=\d)/g, '$1 ');
        setFormData({
            ...formData,
            aadharNumber: formattedInput,
        });
        setIsOtpSent(false);
        setCountdown(0);
        setIsActiveAadharOtpField(false);
        setResponseResult("");
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return format(date, 'dd-MM-yyyy');
    };

    useEffect(() => {
        let timer;
        if (isOtpSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsOtpSent(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSent, countdown]);


    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? 'black'
                : state.isFocused
                    ? 'gainsboro'
                    : 'white',
            color: state.isSelected
                ? 'white'
                : state.isFocused
                    ? 'dark'
                    : 'black',
            fontSize: '14px',
            borderRadius: '4px',
            margin: '0px',
        }),
    };

    const is18OrAbove = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);
        let age = today.getFullYear() - birth.getFullYear();
        const monthDifference = today.getMonth() - birth.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
            age--;
        }
        return age >= 18;
    };

    const handleuserStep = async () => {
        const result = await AuthService.getuserKycStep();
        if (result?.success) {
            setKycFormStep(result.data?.step || "step1");
            handleGetKycDetails();
            handleProgressbar();
        }
    };

    const handleUpdateKycStep = async (step) => {
        if (!userKycDetails?.pandetails?.aadhaarLinked) {
            setResponseResult("Your PAN is not linked with Aadhaar")
            return;
        } if (!is18OrAbove(userKycDetails?.pandetails?.dateOfBirth)) {
            setResponseResult("your Age is below 18 Year")
            return;
        }
        setIsLoading(true);
        try {
            const result = await AuthService.updateKycStepStatus(step);
            if (result?.success) {
                setIsLoading(false);
                handleGetKycDetails();
                handleuserStep();
                handleProgressbar();
            } else {
                setIsLoading(false);
                setResponseResult(result);
            }
        } catch (error) {
            setIsLoading(false);
            setResponseResult(error);
        }
    };

    const handleProgressbar = async () => {
        await AuthService.getCurrentKycStatus().then(async (result) => {
            if (result?.success) {
                setProgressBar(result?.data)
            }
        }
        );
    };
    useEffect(() => {
        if (userDetails?.user?.kycVerified !== 2) {
            handleuserStep();
        }
    }, [])

    const handleSubmitData = async (item) => {
        setIsLoadingPan(true);
        setResponseResult("");
        try {
            const result = await AuthService.verifyPancard(item);
            if (result?.success) {
                setIsLoadingPan(false);
                setIsPanValid(false);
                handleAmlCheck(result?.data?.pan, result?.data?.name, result?.data?.dateOfBirth);
                handleProgressbar();
            } else {
                setIsLoadingPan(false);
                setResponseResult(result);

            }
        } catch (error) {
            setIsLoadingPan(false);
        }
    };

    const handleAmlCheck = async (pan, name, dateOfBirth) => {
        try {
            await AuthService.getAmlCheckStatus(pan, name, formatDate(dateOfBirth)).then(async (result) => {
                if (result?.success) {
                    handleGetKycDetails();
                } else {
                    setResponseResult(result);
                }
            });
        } catch (error) {
            setResponseResult(error);
        }
    };

    const handlegetOtpAAdhar = async (aadharNumber) => {
        setIsLoadingPan(true);
        setResponseResult("");
        try {
            const referenceId = Math.floor(100000 + Math.random() * 900000);
            const result = await AuthService.verifyAadharDocument(aadharNumber, referenceId);
            if (result?.success) {
                setAadharOtpDetails(result.data);
                setIsActiveAadharOtpField(true);
                setIsOtpSent(true);
                setCountdown(60);
                setIsLoadingPan(false);
                setSuccessMessage(result?.message);
            } else {
                setResponseResult(result);
                setIsOtpSent(false);
                setCountdown(0);
                setIsLoadingPan(false);
                setSuccessMessage("");
            }
        } catch (error) {
            setResponseResult(error);
            setSuccessMessage("");
            setIsLoadingPan(false);
        }
    };

    const handleVerifyAadhar = async (aadharOtp) => {
        setResponseResult("");
        setIsLoading(true);
        try {
            const result = await AuthService.getAadharDetails(aadharOtp, aadharOtpDetails?.transaction_id, aadharOtpDetails?.reference_id, formData?.panNumber);
            if (result?.success) {
                setIsLoading(false);
                handleGetKycDetails();
                handleProgressbar();
            } else {
                setIsLoading(false);
                setResponseResult(result?.message || JSON.stringify(result));
            }
        } catch (error) {
            setIsLoading(false);
            setResponseResult(error);
        }
    };

    const options = [
        { value: '', label: 'Please select one' },
        { value: 'Self Employed', label: 'Self Employed' },
        { value: 'Salaried Employed', label: 'Salaried Employed' },
        { value: 'House Wife', label: 'House Wife' },
        { value: 'Retired Person', label: 'Retired Person' },
    ];

    const optionsEducation = [
        { value: '', label: 'Please select one' },
        { value: 'Matriculation', label: 'Matriculation' },
        { value: 'Higher Secondary', label: 'Higher Secondary' },
        { value: 'Graduation', label: 'Graduation' },
        { value: 'Post Graduation', label: 'Post Graduation' },
        { value: 'Doctorate', label: 'Doctorate' },
    ];

    const optionsMaritalStatus = [
        { value: '', label: 'Please select one' },
        { value: 'Unmarried', label: 'Unmarried' },
        { value: 'Married', label: 'Married' },
    ];

    const optionsAnnualIncome = [
        { value: '', label: 'Please select one' },
        { value: '300000', label: '0-300000' },
        { value: '700000', label: '300001 - 700000' },
        { value: '1000000', label: '700001 - 1000000' },
        { value: '1200000', label: '1000001 - 1200000' },
        { value: '1500000', label: '1200001 - 1500000' },
        { value: '1500001', label: 'Above 15 Lakh' },
    ];

    const tradingTrunOver = [
        { value: '', label: 'Please select one' },
        { value: '100000000', label: '0-10 Crore' },
        { value: '500000000', label: '10 Crore - 50 Crore' },
        { value: '1000000000', label: '50 Crore - 100 Crore' },
        { value: '1000000001', label: 'Above 100 Crore' },
    ];

    const handleChange = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'occupation',
                value: selectedOption.value,
            },
        });
        if (selectedOption.value) {
            setFormError({});
        }
    };

    const handleChangeEducation = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'edituaction',
                value: selectedOption.value,
            },
        });
        if (selectedOption.value) {
            setFormError({});
        }
    };

    const handleChangeMarital = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'maritalStatus',
                value: selectedOption.value,
            },
        });
        if (selectedOption.value) {
            setFormError({});
        }
    };

    const handleChangeAnualIncome = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'annualIncome',
                value: selectedOption.value,
            },
        });
        if (selectedOption.value) {
            setFormError({});
        }
    };

    const handleChangeTradingTurnover = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'tradingTurnover',
                value: selectedOption.value,
            },
        });
        if (selectedOption.value) {
            setFormError({});
        }
    };

    const handleSubmitEducation = () => {
        setResponseResult("")
        if (!formData.edituaction) {
            setFormError({ edituaction: "Education is required." });
            return;
        }
        if (!formData.maritalStatus) {
            setFormError({ maritalStatus: "Marital status is required." });
            return;
        }
        if (!formData.occupation) {
            setFormError({ occupation: "Occupation is required." });
            return;
        }
        if (!formData.annualIncome) {
            setFormError({ annualIncome: "Annual income is required." });
            return;
        }
        if (!formData.tradingTurnover) {
            setFormError({ tradingTurnover: "Trading turnover is required." });
            return;
        }
        setFormError({});
        handleEducationOccupation();
    };


    const handleEducationOccupation = async () => {
        setResponseResult("")
        try {
            await AuthService.submitEducationOccupation(formData).then(async (result) => {
                if (result?.success) {
                    handleGetKycDetails();
                    handleProgressbar();
                    handleUpdateKycStep("step4");
                } else {
                    setResponseResult(result);
                }
            });
        } catch (error) {
            setResponseResult(error);
        }
    };

    const handleDeclarationSubmission = async () => {
        if (!checkButton) {
            setDecelaration(true);
            return;
        }
        try {
            const result = await AuthService.declaration();
            if (result?.success) {
                handleUserProfile();
                handleGetKycDetails();
            } else {
                setResponseResult(result);
            }
        } catch (error) {
            setResponseResult(error);
        }
    }

    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const formattedDateTime = format(currentDateTime, "do MMMM yyyy, h:mm:ss a");

    // Step2 Functions Start

    const handleLivenessResult = async () => {
        setLoading(true);
        try {
            const resultData = await AuthService.getLinkKycResult();
            if (resultData?.success) {
                handleFaceMatch(resultData?.data?.imageUrl, resultData?.data?._id);
            } else {
                setResponseResult(resultData);
                setLoading(false);
            }
        } catch (error) {
            setResponseResult(error);
            setLoading(false);
        }
    };

    const handleAuthLivenessSdk = async () => {
        setLoading(true);
        setResponseResult("");
        try {
            const resultData = await AuthService.authDataSdk();
            if (resultData?.success) {
                startOnboarding(resultData?.data?.result?.token);
            } else {
                setResponseResult("Failed to authenticate liveness SDK.");
            }
        } catch (error) {
            setResponseResult(error);
        }
    };

    const workflowId = "liveness";
    const startOnboarding = (sdkToken) => {
        if (window.HyperKycConfig && window.HyperKYCModule) {
            const hyperKycConfig = new window.HyperKycConfig(
                sdkToken,
                workflowId,
                transactionId,
            );
            window.HyperKYCModule.launch(hyperKycConfig, (sdkResult) => {
                handleLivenessResult(sdkResult);
            });
        } else {
            setResponseResult("HyperKYCModule or HyperKycConfig is not loaded.");
        }
    };

    const handleFaceMatch = async (imageLive, id) => {
        setLoading(true);
        await AuthService.getFaceMatchData(imageLive, userKycDetails?.adhaardetails?.adhaar_image, id).then(async (result) => {
            if (result?.success) {
                handleGetKycDetails();
                handleProgressbar();
            } else {
                setResponseResult(result)
                handleGetKycDetails();
            }
        });
    };

    const handleStepRejectedRemark = async () => {
        await AuthService.getStepRejectedRemark(!userKycDetails?.pandetails ? "all" : kycFormStep).then(async (result) => {
            if (result?.success && Array.isArray(result?.data)) {
                setStepRejectedRemark(result.data);
                setActiveModeKycReject(true);
            } else {
                setStepRejectedRemark([]);
                setActiveModeKycReject(false);
            }
        });
    };


    const handleUserLatestPan = async () => {
        await AuthService.getlatestPan().then(async (result) => {
            if (result?.success) {
                setUserLatestPan(result.data);
                setIsPanValid(true);
            }
        });
    };

    useEffect(() => {
        if (kycStatus === 3 || kycStatus === 4) {
            handleStepRejectedRemark()
        }
    }, [kycStatus]);

    useEffect(() => {
        if (kycStatus === 4) {
            handleUserLatestPan()
        }
    }, [kycStatus]);

    const filteredRemarks = stepRejectedRemark?.filter(item => item?.step === "reject_all")[0];
    const filteredRemarksRekyc = stepRejectedRemark?.filter(item => item?.step === "re_kyc")[0];
    const step1Remarks = stepRejectedRemark?.filter(item => item?.step === "step1")[0];
    const step2Remarks = stepRejectedRemark?.filter(item => item?.step === "step2")[0];
    const step3Remarks = stepRejectedRemark?.filter(item => item?.step === "step3")[0];


    return (
        <main>
            {
                kycStatus == 0 || kycStatus == 3 || kycStatus == 4 ?
                    <div className="pager_wrapper feature_bg">
                        <div className="kycp_wrapper">
                            <section className="d_page ">
                                <div className="container">
                                    <div className="dd_title d-block text-center">
                                        <h3 className="text-warning">KYC Verification</h3>
                                        <p className="mb-0" >{kycStatus == 0 ? 'Complete your KYC in just 4 steps' : ""}</p>
                                    </div>
                                    <div className="mt_form">
                                        <ul id="progressbar">
                                            <ul id="progressbar">
                                                <li
                                                    className={`cursor-pointer ${kycFormStep === "step1" ? "active" : ""}  ${progressBar?.[0]?.status === "complete" ? "success" : progressBar?.[0]?.status === "pending" ? "warning" : progressBar?.[0]?.status === "rejected" ? "danger" : progressBar?.[0]?.status === "not-submitted" ? "current" : ""}`}
                                                    id="step1"
                                                    onClick={() => setIsActivePanAadhar(!!userKycDetails?.pandetails)}>
                                                    <strong>Proof of Identification</strong>
                                                </li>
                                                <li
                                                    className={`cursor-pointer ${kycFormStep === "step2" ? "active" : ""}  ${progressBar?.[1]?.status === "complete" ? "success" : progressBar?.[1]?.status === "pending" ? "warning" : progressBar?.[1]?.status === "rejected" ? "danger" : progressBar?.[1]?.status === "not-submitted" ? "current" : ""}`}
                                                    id="step2"
                                                    onClick={() => setIsActiveLiveness(!!userKycDetails?.facematchdetails && !!userKycDetails?.livenessdetails)}>
                                                    <strong>Check Liveness</strong>
                                                </li>
                                                <li
                                                    className={`cursor-pointer ${kycFormStep === "step3" ? "active" : ""}  ${progressBar?.[2]?.status === "complete" ? "success" : progressBar?.[2]?.status === "pending" ? "warning" : progressBar?.[2]?.status === "rejected" ? "danger" : progressBar?.[2]?.status === "not-submitted" ? "current" : ""}`}
                                                    id="step3"
                                                    onClick={() => setIsActiveEducation(!!userKycDetails?.otherDetails)}>
                                                    <strong>Education & Occupation</strong>
                                                </li>
                                                <li
                                                    className={`cursor-pointer ${kycFormStep === "step4" ? "active" : ""}  ${progressBar?.[3]?.status === "complete" ? "success" : progressBar?.[3]?.status === "pending" ? "warning" : progressBar?.[3]?.status === "rejected" ? "danger" : progressBar?.[3]?.status === "not-submitted" ? "current" : ""}`}
                                                    id="step4">
                                                    <strong>Declaration and Submission</strong>
                                                </li>
                                            </ul>
                                        </ul>
                                        <div className="row justify-content-center" >
                                            <div className="col-xl-8 multistep_form ">
                                                {
                                                    kycFormStep === "step1" &&
                                                    <fieldset className="current">
                                                        {
                                                            step1Remarks && !userKycDetails?.pandetails &&
                                                            <div className="fl_card" >
                                                                <div className="fl_row" >
                                                                    <i class="ri-close-circle-fill"></i>
                                                                    <div>
                                                                        <h6> Verification Failed </h6>
                                                                        <p className=" ">
                                                                            {step1Remarks?.remarks}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        }
                                                        <div className="form-card mt-0">
                                                            <div className="row justify-content-center ">
                                                                <div className="col-xl-8 ">
                                                                    <div className="step_title" >
                                                                        <h4>
                                                                            Verify your proof of identity
                                                                        </h4>
                                                                        <p>
                                                                            Crypto India Required some valid government issued ID (PAN card, Aadhaar card)
                                                                        </p>
                                                                    </div>
                                                                    {responseResult && (
                                                                        <div className="fl_card mb-2 mtm_error " >
                                                                            <div className="fl_row gap-2" >
                                                                                <i class="ri-error-warning-fill"></i>
                                                                                <p className="mt-0">
                                                                                    {responseResult}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                    <div className="field-box mb-3">
                                                                        <form
                                                                            onSubmit={(e) => {
                                                                                e.preventDefault();
                                                                                handleSubmitData(formData.panNumber);
                                                                            }}
                                                                        >
                                                                            <label htmlFor="panNumber" className="form-label">Pan Number  </label>
                                                                            <div className="field-otp-box">
                                                                                <div className={`form-control border-0 p-0 ${userKycDetails?.pandetails?.name && formData?.panNumber === userKycDetails?.pandetails?.pan ? "form-active" : ""}`}>
                                                                                    <input
                                                                                        id="panNumber"
                                                                                        name="panNumber"
                                                                                        type="text"
                                                                                        className={`form-control ${errorMessage ? "is-invalid" : ""}`}
                                                                                        value={formData.panNumber}
                                                                                        onChange={handleInputChange}
                                                                                        placeholder="Enter Your Pan Number"
                                                                                        maxLength={10}
                                                                                        disabled={userKycDetails?.pandetails?.verified || userLatestPan}
                                                                                    />
                                                                                </div>


                                                                                {formData?.panNumber !== userKycDetails?.pandetails?.pan ? (
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="btn btn-xs custom-btn"
                                                                                        disabled={isLoadingPan || formData.panNumber?.length !== 10 || !isPanValid}
                                                                                    >
                                                                                        {isLoadingPan ? <SpinLoader /> : <span>Verify</span>}
                                                                                    </button>
                                                                                )
                                                                                    :
                                                                                    userKycDetails?.pandetails?.name && formData?.panNumber === userKycDetails?.pandetails?.pan ?
                                                                                        <button type="button" className="btn btn-xs success-btn pe-none pn_check">
                                                                                            {maskName(userKycDetails?.pandetails?.name)} &nbsp;
                                                                                            <i className="ri-verified-badge-fill text-success"></i>
                                                                                        </button>
                                                                                        :
                                                                                        ""
                                                                                }


                                                                            </div>
                                                                            {
                                                                                errorMessage && <div className="invalid-feedback d-block">{errorMessage}</div>
                                                                            }
                                                                        </form>
                                                                    </div>


                                                                    <>
                                                                        {!userKycDetails?.adhaardetails && userKycDetails?.pandetails && formData?.panNumber === userKycDetails?.pandetails?.pan ? (
                                                                            <>
                                                                                <form
                                                                                    onSubmit={(e) => {
                                                                                        e.preventDefault();
                                                                                        handlegetOtpAAdhar(formData.aadharNumber.replace(/\D/g, '') + userKycDetails?.pandetails?.maskedAadhaarNumber?.slice(-4));
                                                                                    }}
                                                                                >
                                                                                    <div className="field-box mb-3">
                                                                                        <label htmlFor="aadharNumber" className="form-label">Aadhaar Number   </label>
                                                                                        <div className={`field-otp-box ${userKycDetails?.pandetails?.name && formData?.aadharNumber === userKycDetails?.aadhardetails?.pan ? "form-active" : ""}`}>
                                                                                            <input
                                                                                                id="aadharNumber"
                                                                                                name="aadharNumber"
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                maxLength="9"
                                                                                                value={formData.aadharNumber}
                                                                                                onChange={handleAadharChange}
                                                                                                autoComplete="off"
                                                                                                placeholder="Enter Aadhaar Number"
                                                                                            />

                                                                                            {
                                                                                                formData.aadharNumber?.length === 9 &&
                                                                                                <span
                                                                                                    style={{ borderLeft: "0px" }}
                                                                                                    className=" fw-bold last-four-input opacity-50  "
                                                                                                    value={userKycDetails?.pandetails?.maskedAadhaarNumber?.slice(-4)}
                                                                                                >
                                                                                                    {userKycDetails?.pandetails?.maskedAadhaarNumber?.slice(-4)}
                                                                                                </span>
                                                                                            }

                                                                                            <button
                                                                                                type="submit"
                                                                                                className="btn btn-xs custom-btn"
                                                                                                disabled={isLoadingPan || isOtpSent || formData.aadharNumber?.length !== 9}
                                                                                            >
                                                                                                {
                                                                                                    isLoadingPan ? <SpinLoader /> :
                                                                                                        <span>
                                                                                                            {isOtpSent ? `Resend in ${countdown}s` : 'Send OTP'}
                                                                                                        </span>
                                                                                                }

                                                                                            </button>
                                                                                        </div>

                                                                                        {successMessage && (
                                                                                            <div className="fl_row gap-2 " >
                                                                                                <p className="mt-2 text-success">
                                                                                                    {successMessage}
                                                                                                </p>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </form>

                                                                                {isActiveAadharOtpField && formData.aadharNumber.length == 9 && (
                                                                                    <form
                                                                                        onSubmit={(e) => {
                                                                                            e.preventDefault();
                                                                                            handleVerifyAadhar(formData.aadharOtp);
                                                                                        }}
                                                                                    >
                                                                                        <div className="field-box mb-3">
                                                                                            <label htmlFor="aadharOtp" className="form-label">Enter Aadhaar OTP</label>
                                                                                            <div className="field-otp-box">
                                                                                                <div className="form-control border-0 p-0">
                                                                                                    <input
                                                                                                        id="aadharOtp"
                                                                                                        name="aadharOtp"
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        value={formData.aadharOtp}
                                                                                                        onChange={(e) => {
                                                                                                            if (/^\d{0,6}$/.test(e.target.value)) {
                                                                                                                handleInputChange(e);
                                                                                                            }
                                                                                                        }}
                                                                                                        required
                                                                                                        maxLength={6}
                                                                                                    />
                                                                                                </div>

                                                                                                <button
                                                                                                    type="submit"
                                                                                                    className="btn btn-xs custom-btn"
                                                                                                    disabled={isLoading || formData.aadharOtp.length !== 6}
                                                                                                >
                                                                                                    {isLoading ? <SpinLoader /> : <span>Verify</span>}
                                                                                                </button>

                                                                                            </div>
                                                                                        </div>
                                                                                    </form>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            userKycDetails?.pandetails?.verified ?
                                                                                <>
                                                                                    <label className="form-label">Aadhaar Name</label>
                                                                                    <div className="vn_card mt-0">
                                                                                        {userKycDetails?.adhaardetails?.full_name}
                                                                                        <i className="ri-verified-badge-fill"></i>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                ""
                                                                        )}

                                                                        {userKycDetails?.adhaardetails && userKycDetails?.pandetails?.verified && (
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <div className="form-group mt-4">
                                                                                        <button className="form-control btn-dark  btn-block w-100" type="button" onClick={() => handleUpdateKycStep("step2")}>
                                                                                            Continue to the Next Step
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </fieldset>
                                                }
                                                {
                                                    kycFormStep === "step2" &&
                                                    <fieldset className="current">
                                                        {
                                                            step2Remarks && !userKycDetails?.livenessdetails &&
                                                            <div className="fl_card" >
                                                                <div className="fl_row" >
                                                                    <i class="ri-close-circle-fill"></i>
                                                                    <div>
                                                                        <h6> Verification Failed </h6>
                                                                        <p className=" ">
                                                                            {step2Remarks?.remarks}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        }
                                                        <div className="form-card mt-0">
                                                            <div className="row justify-content-center ">



                                                                <div className="col-xl-6">
                                                                    {responseResult && (
                                                                        <div className="fl_card mb-3 mt-0 mtm_error " >
                                                                            <div className="fl_row gap-2" >
                                                                                <i class="ri-error-warning-fill"></i>
                                                                                <p className="mt-0">
                                                                                    {responseResult}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}


                                                                    {loading ? (
                                                                        <div className="loader-overlay card_loader py-5 mt-4">
                                                                            <SpinLoader />
                                                                            <p>Processing... Please wait.</p>
                                                                        </div>
                                                                    ) :
                                                                        userKycDetails?.livenessdetails?.liveFace?.confidence === "high" || userKycDetails?.livenessdetails?.liveFace?.value === "yes" ? (
                                                                            userKycDetails?.facematchdetails?.facematch_value === "yes" &&
                                                                                userKycDetails?.facematchdetails?.facematch_confidence === "high" ? (
                                                                                <>
                                                                                    <form
                                                                                        onSubmit={(e) => {
                                                                                            e.preventDefault();
                                                                                            handleUpdateKycStep("step3");
                                                                                        }}
                                                                                    >
                                                                                        <div className="web_cam_row">
                                                                                            <div className="web_cam_box success">
                                                                                                {
                                                                                                    !userKycDetails?.livenessdetails?.imageUrl ? (
                                                                                                        <div className="spinner-line">
                                                                                                            {[...Array(12)].map((_, index) => (
                                                                                                                <div key={index} className="spinner-line-blade"></div>
                                                                                                            ))}
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <img
                                                                                                            src={userKycDetails?.livenessdetails?.imageUrl}
                                                                                                            alt="Liveness Check"
                                                                                                        />
                                                                                                    )
                                                                                                }

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="step_title mt-4" >
                                                                                            <h4 className="text-success" >
                                                                                                <small> Liveness Check Successful <i class="ri-verified-badge-fill ms-2 fw-normal"></i> </small>
                                                                                            </h4>
                                                                                        </div>
                                                                                        <button
                                                                                            className="btn btn-dark  btn-block w-100  mt-3"
                                                                                            type="submit"
                                                                                        >
                                                                                            Continue to the Next Step
                                                                                        </button>
                                                                                    </form>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <form
                                                                                        onSubmit={(e) => {
                                                                                            e.preventDefault();
                                                                                            handleAuthLivenessSdk();
                                                                                        }}
                                                                                    >
                                                                                        <div className="web_cam_row">
                                                                                            <div className="web_cam_box failed">
                                                                                                {
                                                                                                    !userKycDetails?.livenessdetails?.imageUrl ? (
                                                                                                        <div className="spinner-line">
                                                                                                            {[...Array(12)].map((_, index) => (
                                                                                                                <div key={index} className="spinner-line-blade"></div>
                                                                                                            ))}
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <img
                                                                                                            src={userKycDetails?.livenessdetails?.imageUrl}
                                                                                                            alt="Liveness Check"
                                                                                                        />
                                                                                                    )
                                                                                                }

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="step_title mt-4" >
                                                                                            <h4 className="text-danger" >
                                                                                                <small> Liveness Check Failed <i class="ri-error-warning-fill ri-xl ms-2 fw-normal"></i> </small>
                                                                                            </h4>
                                                                                        </div>
                                                                                        <button
                                                                                            className="btn btn-dark  btn-block w-100  mt-3"
                                                                                            type="submit"
                                                                                        >
                                                                                            Verify Again
                                                                                        </button>
                                                                                    </form>
                                                                                </>
                                                                            )
                                                                        ) : userKycDetails?.livenessdetails?.liveFace?.confidence === "low" || userKycDetails?.livenessdetails?.liveFace?.value === "no" ? (
                                                                            <>
                                                                                <form
                                                                                    onSubmit={(e) => {
                                                                                        e.preventDefault();
                                                                                        handleAuthLivenessSdk();
                                                                                    }}
                                                                                >
                                                                                    <div className="web_cam_row">
                                                                                        <div className="web_cam_box failed">
                                                                                            {
                                                                                                userKycDetails?.livenessdetails?.imageUrl ? (
                                                                                                    <div className="spinner-line">
                                                                                                        {[...Array(12)].map((_, index) => (
                                                                                                            <div key={index} className="spinner-line-blade"></div>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <img
                                                                                                        src={userKycDetails?.livenessdetails?.imageUrl || "/assets/images/liveness_thumb.svg"}
                                                                                                        alt="Liveness Check"
                                                                                                    />
                                                                                                )
                                                                                            }

                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="step_title mt-4" >
                                                                                        <h4 className="text-danger" >
                                                                                            <small> Liveness Check Failed <i class="ri-error-warning-fill  ri-xl ms-2 fw-normal"></i> </small>
                                                                                        </h4>
                                                                                    </div>
                                                                                    <button
                                                                                        className="btn btn-dark  btn-block w-100  mt-3"
                                                                                        type="submit"
                                                                                    >
                                                                                        Verify Again
                                                                                    </button>
                                                                                </form>
                                                                            </>
                                                                        ) : (
                                                                            <form
                                                                                onSubmit={(e) => {
                                                                                    e.preventDefault();
                                                                                    handleAuthLivenessSdk();
                                                                                }}
                                                                            >
                                                                                <div className="field-box text-center">
                                                                                    <div className="mb-3" >
                                                                                        <h6>
                                                                                            Liveness Check
                                                                                        </h6>
                                                                                    </div>
                                                                                    <img src="/assets/images/liveness_mockup.svg" alt="Liveness Check" className="img-fluid box-shadow" />
                                                                                    <div className="tip_ul">
                                                                                        <h6>Tips</h6>
                                                                                        <ul>
                                                                                            <li>
                                                                                                <i class="ri-checkbox-circle-fill text-success"></i>
                                                                                                Find a well lit place
                                                                                            </li>
                                                                                            <li>
                                                                                                <i class="ri-checkbox-circle-fill text-success"></i>
                                                                                                Ensure your face is within the frame
                                                                                            </li>
                                                                                            <li>
                                                                                                <i class="ri-close-circle-fill text-danger"></i>
                                                                                                Don't wear hats, glasses & masks
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <button
                                                                                        className="btn btn-dark  btn-block w-100  mt-3"
                                                                                        type="submit"
                                                                                    >
                                                                                        Start Liveness
                                                                                    </button>
                                                                                </div>
                                                                            </form>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                }

                                                {
                                                    kycFormStep === "step3" &&
                                                    <fieldset className="current">

                                                        {
                                                            step3Remarks &&
                                                            <div className="fl_card" >
                                                                <div className="fl_row" >
                                                                    <i class="ri-close-circle-fill"></i>
                                                                    <div>
                                                                        <h6> Verification Failed </h6>
                                                                        <p className=" ">
                                                                            {step3Remarks?.remarks}
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        }

                                                        <div className="form-card mt-0">
                                                            <div className="step_title" >
                                                                <h4>
                                                                    Education & Occupation
                                                                </h4>
                                                                <p>
                                                                    Please provide your educational background, current occupation , marital status, annual income range, and employment status.
                                                                </p>


                                                            </div>
                                                            {responseResult && (
                                                                <div className="fl_card mb-4 mtm_error " >
                                                                    <div className="fl_row gap-2" >
                                                                        <i class="ri-error-warning-fill"></i>
                                                                        <p className="mt-0">
                                                                            {responseResult}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <form
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    handleSubmitEducation();
                                                                }}
                                                            >
                                                                <div className="row">
                                                                    <div className="col-md-6" >
                                                                        <div className={`form-group mb-4 ${formError.edituaction ? "error-border" : ""}`}>
                                                                            <label htmlFor="edituaction"> Education </label>
                                                                            <Select
                                                                                name="edituaction"
                                                                                id="edituaction"
                                                                                value={optionsEducation.find(option => option.value === formData.edituaction)}
                                                                                onChange={handleChangeEducation}
                                                                                options={optionsEducation}
                                                                                styles={customStyles}
                                                                                isDisabled={userKycDetails?.otherDetails}
                                                                            />
                                                                            {formError.edituaction && <p className="invalid-feedback">{formError.edituaction}</p>}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-6" >
                                                                        <div className={`form-group mb-4 ${formError.maritalStatus ? "error-border" : ""}`} >
                                                                            <label htmlFor="maritalStatus"> Marital Status </label>
                                                                            <Select
                                                                                name="maritalStatus"
                                                                                id="maritalStatus"
                                                                                value={optionsMaritalStatus.find(option => option.value === formData.maritalStatus)}
                                                                                onChange={handleChangeMarital}
                                                                                options={optionsMaritalStatus}
                                                                                styles={customStyles}
                                                                                isDisabled={userKycDetails?.otherDetails}
                                                                            />
                                                                            {formError.maritalStatus && <p className="invalid-feedback">{formError.maritalStatus}</p>}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-4" >
                                                                        <div className={`form-group mb-4 ${formError.occupation ? "error-border" : ""}`} >
                                                                            <label htmlFor="occupation"> Occupation </label>
                                                                            <Select
                                                                                name="occupation"
                                                                                id="occupation"
                                                                                value={options.find(option => option.value === formData.occupation)}
                                                                                onChange={handleChange}
                                                                                options={options}
                                                                                styles={customStyles}
                                                                                isDisabled={userKycDetails?.otherDetails}
                                                                            />
                                                                            {formError.occupation && <p className="invalid-feedback">{formError.occupation}</p>}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-4 mb-4">
                                                                        <div className={`form-group mb-4 ${formError.annualIncome ? "error-border" : ""}`}>
                                                                            <label htmlFor="annualIncome" className="form-label">Annual Income In Rupees</label>
                                                                            <Select
                                                                                name="annualIncome"
                                                                                id="annualIncome"
                                                                                value={optionsAnnualIncome.find(option => option.value == formData.annualIncome)}
                                                                                onChange={handleChangeAnualIncome}
                                                                                options={optionsAnnualIncome}
                                                                                styles={customStyles}
                                                                                isDisabled={userKycDetails?.otherDetails}
                                                                            />
                                                                            {formError.annualIncome && <p className="invalid-feedback">{formError.annualIncome}</p>}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-4 mb-4">
                                                                        <div className={`form-group mb-4 ${formError.tradingTurnover ? "error-border" : ""}`}>
                                                                            <label htmlFor="tradingTurnover" className="form-label">
                                                                                Estimated Annual Trading Turnover
                                                                            </label>
                                                                            <Select
                                                                                name="tradingTurnover"
                                                                                id="tradingTurnover"
                                                                                value={tradingTrunOver.find(option => option.value == formData.tradingTurnover)}
                                                                                onChange={handleChangeTradingTurnover}
                                                                                options={tradingTrunOver}
                                                                                styles={customStyles}
                                                                                isDisabled={userKycDetails?.otherDetails}
                                                                            />
                                                                            {formError.tradingTurnover && <p className="invalid-feedback">{formError.tradingTurnover}</p>}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-6 col-md-10 col-6 mx-auto" >
                                                                        <div className="form-group" >
                                                                            <button type="submit" className="form-control btn-warning  btn-block w-100" disabled={isLoading}>
                                                                                {
                                                                                    isLoading ? <SpinLoader /> : <span>Continue to the Next Step</span>
                                                                                }
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>

                                                    </fieldset>
                                                }

                                                {
                                                    kycFormStep === "step4" &&
                                                    <fieldset className="current">
                                                        <form className="form-card"
                                                            onSubmit={(e) => {
                                                                e.preventDefault();
                                                                handleDeclarationSubmission();
                                                            }}
                                                        >
                                                            <div className="row">
                                                                <div className="p_card mt-2 p-3">
                                                                    <h5>DECLARATION OF SOURCE OF FUNDS</h5>
                                                                    <ul className="desc mb-0">
                                                                        <p>Date: &nbsp; <b> {formattedDateTime} </b></p>
                                                                        <li className="mb-2 d-block">
                                                                            <b>[{userKycDetails?.pandetails?.name}]</b>, a citizen of India, holding Aadhaar Number <b>[{userKycDetails?.pandetails?.maskedAadhaarNumber}]</b> and PAN Card number <b>
                                                                                [{userKycDetails?.pandetails?.pan}]</b>, hereby declare and affirm that all funds used in my financial transactions, with CRYPTO INDIA (Incryp Blockchain Private Limited) in INR and any other crypto currency, are obtained from legitimate and legal sources. The funds are fully compliant with all applicable laws, regulations, and guidelines, including Anti-Money Laundering (AML) and Counter-Terrorist Financing (CTF) regulations.
                                                                        </li>
                                                                        <li className="mb-2">We further declare that:</li>

                                                                        <li className="mb-2 d-block">
                                                                            1. Legitimate Source: All funds used in our company’s financial operations, including the acquisition of crypto assets, are free from any association with illegal activities such as money laundering, tax evasion, or terrorist financing.
                                                                        </li>

                                                                        <li className="mb-2 d-block">
                                                                            2. Compliance with AML Regulations: Our company follows stringent internal controls and compliance measures, ensuring full adherence to AML, CTF, and Know Your Customer (KYC) guidelines as stipulated by regulatory authorities. We have systems in place to detect and prevent suspicious or unlawful financial activities
                                                                        </li>

                                                                        <li className="mb-2 d-block">
                                                                            3. Due Diligence: Our company undertakes regular due diligence on all counterparties and customers to verify the legitimacy of funds, consistent with legal requirements, before entering any transaction involving crypto assets or traditional financial services.

                                                                        </li>

                                                                        <li className="mb-2 d-block">
                                                                            4. Ethical Standards: Our company upholds the highest standards of ethical conduct in all financial dealings, ensuring that funds used in all transactions are derived from legitimate business activities and are compliant with domestic and international regulations.
                                                                        </li>

                                                                        <li className="mb-2 d-block">
                                                                            5. Cooperation with Authorities: In the event of any suspicion, inquiry, or investigation into the source of our company’s funds, we commit to full cooperation with law enforcement agencies, financial institutions, and regulatory authorities. We are prepared to provide all necessary documentation and information to verify the source of funds when required.
                                                                        </li>
                                                                    </ul>
                                                                    <div className="avil_bal my-2 mb-1 " >
                                                                        <div className="form-check cursor-pointer  d-block" >
                                                                            <input className={`form-check-input cursor-pointer ${decelaration ? "is-invalid" : ""}`} type="checkbox" id="flexCheckDefault" defaultChecked={checkButton} value={checkButton} onChange={() => {
                                                                                setCheckButton((prev) => !prev);
                                                                                setDecelaration(false);
                                                                            }} required />

                                                                            <label className="form-check-label" label="flexCheckDefault">
                                                                                <b>   I understand and agree to the above declaration,   <a href="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/terms_and_conditions.pdf" className="text-dark text-underline" target="_blank"> terms of use  </a>
                                                                                    &nbsp;  and <a href="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/refund_policy.pdf" className="text-dark text-underline" target="_blank"> refund policy.  </a>
                                                                                    &nbsp;  </b>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row" >
                                                                <div className="col-md-6" >
                                                                    <div className="form-group mt-4 " >
                                                                        <button className="btn form-control btn-warning  btn-block w-100 " type="submit" > Submit </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        {responseResult && (
                                                            <div className="fl_card " >
                                                                <div className="fl_row" >
                                                                    <i class="ri-close-circle-fill"></i>
                                                                    <p className="mt-0">
                                                                        {responseResult}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </fieldset>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>


                        </div>
                    </div>
                    :
                    <div className="page_wrapper">
                        <div className="sidebar">
                            <DashboardSidebar />
                        </div>
                        <div className="af_wrapper">
                            <section className="d_page ">
                                <div className="container">
                                    <div className="dd_title d-block text-center">
                                        <h3 className="text-warning">KYC Verification</h3>
                                    </div>
                                    <fieldset className="current row gy-4 justify-content-center">
                                        {
                                            kycLevelStatus && kycLevelStatusType == 3 &&
                                            <div className="col-xl-10 " >
                                                <div className="upgrr_card overflow-hidden ">
                                                    <div class="points_wrapper points_wrapper_2">
                                                        <i class="point"></i>
                                                        <i class="point"></i>
                                                        <i class="point"></i>
                                                        <i class="point"></i>
                                                        <i class="point"></i>
                                                        <i class="point"></i>
                                                        <i class="point"></i>
                                                        <i class="point"></i>
                                                        <i class="point"></i>
                                                        <i class="point"></i>
                                                    </div>
                                                    <div className="row align-items-end" >
                                                        <div className="col-xl-8" >
                                                            <div className="upd_title" >
                                                                <h3>
                                                                    Account Upgrade Available
                                                                </h3>
                                                                <p>
                                                                    Based on your recent account activity and good behavior, you are now eligible
                                                                    to upgrade your account.
                                                                </p>
                                                                <ul>
                                                                    <li> <i class="ri-check-fill"></i> Higher fiat (INR) account withdrawal limit</li>
                                                                    <li> <i class="ri-check-fill"></i> Higher fiat (INR) daily withdrawal limit</li>
                                                                    <li> <i class="ri-check-fill"></i> Higher fiat (INR) daily buy limit </li>
                                                                    <li> <i class="ri-check-fill"></i> Benefits of lower trade fee</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4" >
                                                            <Link to="/upgrade_kyc" class="custom_btn">
                                                                <div class="points_wrapper">
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                </div>
                                                                <span class="inner"  >
                                                                    Upgrade Account
                                                                    <em>
                                                                        <svg
                                                                            class="icon"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            viewBox="0 0 24 24"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-width="2.5"   >
                                                                            <polyline points="13.18 1.37 13.18 9.64 21.45 9.64 10.82 22.63 10.82 14.36 2.55 14.36 13.18 1.37"  ></polyline>
                                                                        </svg>
                                                                    </em>
                                                                </span >
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {/* userKyc Step Verifcation */}
                                        <div className=" col-xl-10 " >
                                            <div className="upgrr_card overflow-hidden uprrr_kyc ">
                                                <div class="points_wrapper points_wrapper_2">
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                </div>
                                                <div className="upd_title" >
                                                    {kycStatus == 3 || kycStatus === 4 ?
                                                        <div className=" " >
                                                            <div className="d-flex align-items-center justify-content-between" >
                                                                <h3 className="text-danger" >
                                                                    <i class="ri-close-circle-fill me-2 fw-normal text-danger"></i>  Account verification Rejected
                                                                </h3>
                                                            </div>
                                                            <p>
                                                                Your account verification has been rejected. Please Check submitted information for accuracy and follow guidelines for resubmission or appeal.
                                                            </p>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {kycStatus == 1 &&
                                                        <div className=" " >
                                                            <div className="d-flex align-items-center justify-content-between" >
                                                                <h3 className="" >
                                                                    <i class="ri-error-warning-line me-2 fw-normal text-warning"></i>  Account verification Pending
                                                                </h3>
                                                            </div>
                                                            <p>
                                                                Your account verification is currently under review. Our team is carefully processing your information to ensure everything is in order. You will be notified via mail once the review is complete.
                                                            </p>
                                                        </div>
                                                    }
                                                    {kycStatus == 2 &&
                                                        <>
                                                            <div className="d-flex align-items-center justify-content-between" >
                                                                <h3>
                                                                    <i class="ri-verified-badge-fill me-2 fw-normal text-success"></i>  Kyc Successfully Approved
                                                                </h3>
                                                                <span> <small> Approved on:  </small>
                                                                    {
                                                                        userKycDetails?.kycdeclaration?.updatedAt ?
                                                                            moment(userKycDetails?.kycdeclaration?.updatedAt).format('Do MMMM YYYY, h:mm:ss a')
                                                                            : "--------"
                                                                    }
                                                                </span>
                                                            </div>
                                                            <p>
                                                                Your verification has been successfully approved! You now have access to unlimited crypto deposits and withdrawals. Deposit your funds and start trading with ease today!
                                                            </p>

                                                        </>
                                                    }
                                                </div>
                                                <div className="row align-items-end" >
                                                    <div className="col-xl-8" >
                                                        <ul className="ur_pp ur_vefy" >
                                                            <li className="d-flex align-items-center justify-content-between">
                                                                Proof of Identification
                                                                <span className={`ky_ badge ${kycStatus === 1 ? "badge-warning" : kycStatus === 2 ? "badge-success" : kycStatus === 3 ? "badge-danger" : kycStatus === 4 ? "badge-danger" : userKycDetails?.pandetails ? "badge-success" : !userKycDetails?.pandetails ? "badge-danger" : ""}`}>

                                                                    <i className={kycStatus === 1 ? "ri-error-warning-line me-1" : kycStatus === 2 ? "ri-check-double-line me-1" : kycStatus === 3 ? "ri-close-circle-line me-1" : kycStatus === 4 ? "ri-close-circle-line me-1" : userKycDetails?.pandetails ? "ri-check-double-line me-1" : !userKycDetails?.pandetails ? "ri-close-circle-line me-1" : ""} ></i>

                                                                    {kycStatus === 1 ? "Verification Pending" : kycStatus === 2 ? "Verified" : kycStatus === 3 ? "Verification Failed" : kycStatus === 4 ? "Verification Failed" : userKycDetails?.pandetails ? "Verified" : !userKycDetails?.pandetails ? "Verification Failed!" : ""}
                                                                </span>
                                                            </li>
                                                            <li className="d-flex align-items-center justify-content-between">
                                                                Check Liveness
                                                                <span className={`ky_ badge ${kycStatus === 1 ? "badge-warning" : kycStatus === 2 ? "badge-success" : kycStatus === 3 ? "badge-danger" : kycStatus === 4 ? "badge-danger" : userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "badge-success" : kycStatus === 1 ? "badge-warning bg-warning" : kycStatus === 3 || kycStatus === 4 ? "badge-danger" : ""}`} >

                                                                    <i className={kycStatus === 1 ? "ri-error-warning-line me-1" : kycStatus === 2 ? "ri-check-double-line me-1" : kycStatus === 3 ? "ri-close-circle-line me-1" : kycStatus === 4 ? "ri-close-circle-line me-1" : userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "ri-check-double-line  me-1" : kycStatus === 1 ? "ri-error-warning-line  me-1" : kycStatus === 3 || kycStatus === 4 ? "ri-close-circle-line  me-1" : ""} ></i>


                                                                    {kycStatus === 1 ? "Verification Pending" : kycStatus === 2 ? "Verified" : kycStatus === 3 ? "Verification Failed" : kycStatus === 4 ? "Verification Failed" : userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "Verified" : kycStatus == 1 ? "Verification Pending" : ""}
                                                                </span>
                                                            </li>
                                                            <li className="d-flex align-items-center justify-content-between">
                                                                Education & Occupation
                                                                <span className={`ky_ badge ${kycStatus === 1 ? "badge-warning" : kycStatus === 2 ? "badge-success" : kycStatus === 3 ? "badge-danger" : kycStatus === 4 ? "badge-danger" : userKycDetails?.otherDetails ? "badge-success" : !userKycDetails?.otherDetails ? "badge-warning bg-warning" : ""}`} >

                                                                    <i className={kycStatus === 1 ? "ri-error-warning-line me-1" : kycStatus === 2 ? "ri-check-double-line me-1" : kycStatus === 3 ? "ri-close-circle-line me-1" : kycStatus === 4 ? "ri-close-circle-line me-1" : userKycDetails?.otherDetails ? "ri-check-double-line  me-1" : !userKycDetails?.otherDetails ? "ri-error-warning-line" : kycStatus === 3 || kycStatus === 4 ? "ri-close-circle-line" : ""} ></i>

                                                                    {kycStatus === 1 ? "Verification Pending" : kycStatus === 2 ? "Verified" : kycStatus === 3 ? "Verification Failed" : kycStatus === 4 ? "Verification Failed" : userKycDetails?.otherDetails ? "Verified" : !userKycDetails?.otherDetails ? "Verification Pending" : ""}
                                                                </span>
                                                            </li>
                                                            <li className="d-flex align-items-center justify-content-between">
                                                                Declaration and Submission
                                                                <span className={`ky_ badge ${kycStatus === 1 ? "badge-warning" : kycStatus === 2 ? "badge-success" : kycStatus === 3 ? "badge-danger" : kycStatus === 4 ? "badge-danger" : userKycDetails?.kycdeclaration ? "badge-success" : !userKycDetails?.kycdeclaration ? "badge-danger" : ""}`} >

                                                                    <i className={kycStatus === 1 ? "ri-error-warning-line me-1" : kycStatus === 2 ? "ri-check-double-line me-1" : kycStatus === 3 ? "ri-close-circle-line me-1" : kycStatus === 4 ? "ri-close-circle-line me-1" : userKycDetails?.kycdeclaration ? "ri-check-double-line  me-1" : !userKycDetails?.kycdeclaration ? "ri-close-circle-line  me-1" : ""} ></i>

                                                                    {kycStatus === 1 ? "Verification Pending" : kycStatus === 2 ? "Verified" : kycStatus === 3 ? "Verification Failed" : kycStatus === 4 ? "Verification Failed" : userKycDetails?.kycdeclaration ? "Verified" : !userKycDetails?.kycdeclaration ? "Verification Failed!" : ""}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    {kycStatus == 2 &&
                                                        <div className="col-xl-4" >
                                                            <Link to="/exchange/:pairName" class=" custom_btn  ">
                                                                <div class="points_wrapper">
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                </div>
                                                                <span class="inner"  >
                                                                    Start Trading
                                                                    <em>
                                                                        <div className="arrow" ></div>
                                                                    </em>
                                                                </span >
                                                            </Link>

                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className=" col-xl-10 " >
                                            {/* level 2 verification pending */}

                                            {
                                                kycLevelStatus && kycLevelStatusType == 0 &&
                                                <div className="upgrr_card overflow-hidden upgrr_card_ur">
                                                    <div className="row align-items-end" >
                                                        <div className="col-xl-12" >
                                                            <div className="upd_title" >
                                                                <div className="d-flex align-items-center justify-content-between" >
                                                                    <h3>
                                                                        <i class="ri-error-warning-fill me-2 fw-normal text-warning"></i> Account upgrade Pending
                                                                    </h3>
                                                                    <span> <small> Submitted on:  </small> 11 Jan 2025, 12:11:00 PM </span>
                                                                </div>
                                                                <p>
                                                                    Your request to upgrade your account is still being processed. Please wait for confirmation or check for any required actions to complete the upgrade
                                                                </p>
                                                                <ul className="ur_pp" >
                                                                    <li>  <span className="d-flex align-items-center justify-content-between" > <i class="ri-error-warning-line  text-warning  fw-normal  me-2" ></i>    Higher fiat (INR) account withdrawal limit</span> <b className="" > Pending </b>  </li>
                                                                    <li> <span className="d-flex align-items-center justify-content-between"> <i class="ri-error-warning-line  text-warning   fw-normal me-2"></i>     Higher fiat (INR) daily withdrawal limit</span> <b className="" > Pending </b> </li>
                                                                    <li> <span className="d-flex align-items-center justify-content-between"> <i class="ri-error-warning-line  text-warning  fw-normal me-2"></i>    Higher fiat (INR) daily buy limit </span><b className="" > Pending </b> </li>
                                                                    <li className="no-border" > <span className="d-flex align-items-center justify-content-between"> <i class="ri-error-warning-line fw-normal text-warning  me-2"></i>  New trade fee </span><b className="" > Pending </b> </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {/* level 2 kyc verification success  */}

                                            {
                                                kycLevelStatus && kycLevelStatusType == 1 &&
                                                <div className="upgrr_card overflow-hidden   ">
                                                    <div className="row align-items-end" >
                                                        <div className="col-xl-12" >
                                                            <div className="upd_title" >
                                                                <div className="d-flex align-items-center justify-content-between" >
                                                                    <h3>
                                                                        <i class="ri-verified-badge-fill me-2 fw-normal text-success"></i> Account Upgraded Successfully
                                                                    </h3>
                                                                    <span> <small> Approved on:  </small> 11 Jan 2025, 12:11:00 PM </span>
                                                                </div>
                                                                <p>
                                                                    Your account has been successfully upgraded to the desired tier or plan. Enjoy the new features and benefits that come with your upgraded account
                                                                </p>
                                                                <ul className="ur_pp" >
                                                                    <li>  <span className="d-flex align-items-center justify-content-between" > <i class="ri-check-fill   fw-normal  me-2" ></i>    New fiat (INR) account withdrawal limit</span> <b className="" >  000.000 </b>  </li>
                                                                    <li> <span className="d-flex align-items-center justify-content-between"> <i class="ri-check-fill    fw-normal me-2"></i>     New fiat (INR) daily withdrawal limit</span> <b className="" >  000.000 </b> </li>
                                                                    <li> <span className="d-flex align-items-center justify-content-between"> <i class="ri-check-fill   fw-normal me-2"></i>    New fiat (INR) daily buy limit </span><b className="" > 000.000 </b> </li>
                                                                    <li className="no-border" > <span className="d-flex align-items-center justify-content-between"> <i class="ri-check-fill me-2"></i>  New trade fee </span><b className="" > 00 </b> </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {/* level 2 Rejected pending */}
                                            {
                                                kycLevelStatus && kycLevelStatusType == 2 &&
                                                <div className="upgrr_card overflow-hidden upgrr_card_ur upgrr_card_danger">
                                                    <div className="upd_title" >
                                                        <div className="d-flex align-items-center justify-content-between" >
                                                            <h3>
                                                                <i class="ri-close-circle-line me-2 fw-normal text-danger"></i>
                                                                Account upgrade Rejected
                                                            </h3>
                                                            <span> <small> Rejected on:  </small> 11 Jan 2025, 12:11:00 PM </span>
                                                        </div>
                                                        <p>
                                                            Your account upgrade request has been rejected due to unmet requirements. Please review the remark ontop and try again.
                                                        </p>
                                                    </div>
                                                    <div className="row align-items-end" >
                                                        <div className=" col-xl-8 " >
                                                            <div className="upd_title" >
                                                                <ul className="ur_pp" >
                                                                    <li>
                                                                        <span className="d-flex align-items-center justify-content-between" >
                                                                            <i class="ri-close-circle-line text-danger fw-normal  me-2" ></i>    Higher fiat (INR) account withdrawal limit
                                                                        </span>
                                                                        <b> Rejected </b>
                                                                    </li>
                                                                    <li>
                                                                        <span className="d-flex align-items-center justify-content-between">
                                                                            <i class="ri-close-circle-line text-danger  fw-normal me-2"></i>     Higher fiat (INR) daily withdrawal limit
                                                                        </span>
                                                                        <b> Rejected </b>
                                                                    </li>
                                                                    <li>
                                                                        <span className="d-flex align-items-center justify-content-between">
                                                                            <i class="ri-close-circle-line text-danger fw-normal me-2"></i>
                                                                            Higher fiat (INR) daily buy limit </span>
                                                                        <b> Rejected </b>
                                                                    </li>
                                                                    <li className="no-border" >
                                                                        <span className="d-flex align-items-center justify-content-between">
                                                                            <i class="ri-close-circle-line fw-normal text-danger me-2"></i>
                                                                            New trade fee
                                                                        </span>
                                                                        <b> Rejected </b>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4" >
                                                            <Link to="/upgrade_kyc" class="custom_btn custom_warning">
                                                                <div class="points_wrapper">
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                </div>
                                                                <span class="inner">
                                                                    Upgrade Again
                                                                    <em>
                                                                        <svg
                                                                            class="icon"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            viewBox="0 0 24 24"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-width="2.5"   >
                                                                            <polyline points="13.18 1.37 13.18 9.64 21.45 9.64 10.82 22.63 10.82 14.36 2.55 14.36 13.18 1.37"  ></polyline>
                                                                        </svg>
                                                                    </em>
                                                                </span >
                                                            </Link>

                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </fieldset>
                                </div>
                            </section>
                        </div>
                    </div>
            }


            {activeModeKycReject && !userKycDetails?.pandetails && (
                <div div className="modal-open-custom fadeInUp" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-body fl_modal p-lg-5">
                                    <div className="row g-5 align-items-center" >
                                        <div className="col-lg-7" >
                                            <h5 className="text-danger" >KYC Verification Failed!</h5>
                                            <p>{filteredRemarks?.remarks}</p>
                                            <p>{filteredRemarksRekyc?.remarks}</p>
                                            <button className="btn btn-danger btn-md px-4 " onClick={() => setActiveModeKycReject(false)} > Submit KYC Again  </button>
                                            <button className="btn btn-link" onClick={() => setActiveModeKycReject(false)} > Skip For Now </button>
                                        </div>
                                        <div className="col-lg-5" >
                                            <img src="assets/images/kyc_failed.svg" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"> </div>
                </div >
            )}

            {isActivePanAadhar && (
                <div div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title" id="modal_otp_Label">Pan Card & Aadhaar Details</h5>
                                    <button type="button" className="btn-close" onClick={() => setIsActivePanAadhar(false)}></button>
                                </div>
                                <div className="modal-body p-md-4">
                                    <div className="tt_data  ">
                                        <div className="tt_card outline-success">
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> User Name: </span>
                                                <span className="tt_normal">
                                                    {maskName(userKycDetails?.pandetails?.firstName)} {maskName(userKycDetails?.pandetails?.middleName)} {maskName(userKycDetails?.pandetails?.lastName)} &nbsp;
                                                    <i className="ri-verified-badge-fill text-success"></i>
                                                </span>
                                            </div>
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Pan Number: </span>
                                                <span className="tt_normal">
                                                    {userKycDetails?.pandetails?.pan} &nbsp;
                                                    <i className="ri-verified-badge-fill text-success"></i>
                                                </span>
                                            </div>

                                            {
                                                userKycDetails?.pandetails?.verified &&
                                                <>
                                                    <div className="tt_item no-border">
                                                        <span className="text-muted"> Aadhaar Name: </span>
                                                        <span className="tt_normal">
                                                            {userKycDetails?.adhaardetails?.full_name} &nbsp;
                                                            <i className="ri-verified-badge-fill text-success"></i>
                                                        </span>
                                                    </div>
                                                    <div className="tt_item no-border">
                                                        <span className="text-muted"> Aadhaar Number: </span>
                                                        <span className="tt_normal">
                                                            {userKycDetails?.pandetails?.maskedAadhaarNumber} &nbsp;
                                                            <i className="ri-verified-badge-fill text-success"></i>
                                                        </span>
                                                    </div>
                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"> </div>
                </div >
            )}

            {isActiveLiveness && (
                <div div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title" id="modal_otp_Label">Liveness Image</h5>
                                    <button type="button" className="btn-close" onClick={() => setIsActiveLiveness(false)}></button>
                                </div>
                                <div className="modal-body p-md-4">
                                    <div className="tt_data  ">
                                        <div className="tt_card outline-success">
                                            <div className="tt_item no-border">
                                                <span className="text-muted"></span>
                                                <span className="tt_normal">
                                                    {userKycDetails?.pandetails?.firstName} {userKycDetails?.pandetails?.middleName} {userKycDetails?.pandetails?.lastName} &nbsp;
                                                    <i className="ri-verified-badge-fill text-success"></i>
                                                </span>
                                            </div>
                                            <div className="web_cam_row"  >
                                                <div className={`web_cam_box ${userKycDetails?.facematchdetails?.facematch_value === "yes" &&
                                                    userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "success" : "failed"}`} >
                                                    <img src={userKycDetails?.livenessdetails?.imageUrl} alt="Liveness Check" className="img-fliud" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"> </div>
                </div >
            )}

            {isActiveEducation && (
                <div div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title" id="modal_otp_Label">Education & Occupation Details</h5>
                                    <button type="button" className="btn-close" onClick={() => setIsActiveEducation(false)}></button>
                                </div>
                                <div className="modal-body p-md-4">
                                    <div className="tt_data  ">
                                        <div className="tt_card outline-success">
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Education: </span>
                                                <span className="tt_normal">
                                                    {userKycDetails?.otherDetails?.education}
                                                </span>
                                            </div>
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Occupation: </span>
                                                <span className="tt_normal">
                                                    {userKycDetails?.otherDetails?.occupation}
                                                </span>
                                            </div>
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Marital Status: </span>
                                                <span className="tt_normal">
                                                    {userKycDetails?.otherDetails?.marital_status}
                                                </span>
                                            </div>
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Annual Income: </span>
                                                <span className="tt_normal">
                                                    {userKycDetails?.otherDetails?.annual_income}
                                                </span>
                                            </div>
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Annual Turnover: </span>
                                                <span className="tt_normal">
                                                    {userKycDetails?.otherDetails?.annual_turnover}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"> </div>
                </div >
            )}



        </main >
    );
};

export default PersonalKyc;
