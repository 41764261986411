import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../Footer";


const ProductFeedbackSuggestions = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])
    return (
        <>
        <main>
            <section class="tm_sec  features_sec feature_bg ">
                <div class="container">
                    <div class="row   mt-md-5 justify-content-center">
                    <div class="col-lg-10">
                        <div class="section-title   text-center">
                        <h2 class="text-warning  "> Product Feedback & Suggestions   </h2>

                         <div className="ddd"  >
                            <h4>
                            We Value Your Feedback!
                            </h4>
                            <p>
                              Thank you for choosing <b>Crypto India</b>. Your opinion matters to us, and we’d love to hear your feedback about our products and services. Please take a moment to let us know how we’re doing so we can continue to improve your experience. 
                            </p>
                            
                            <p>
                            We take every piece of feedback seriously, and our team is committed to reviewing your suggestions. If your feedback highlights areas for improvement, we will strive to implement changes to enhance our products and services.
                             
                            </p>

                             <p> Your input helps us continuously improve and provide you with the best experience possible at <b>Crypto India</b>.</p>  

                            <p><b className="text-warning" >Thank you for helping us grow!</b></p>




                         </div>
                             
                        </div>



                        <div class="g_start m_card overflow-hidden p-0 ">
                            <div class="p_card p-md-5">
                                <div class="row">
                                    <div class="col-md-6 mb-4">
                                        <div class="field-box">
                                        <label for="text" class="form-label">Full Name</label>
                                        <div class="field-otp-box">
                                            <input type="text" class="form-control" name="subject"  />
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                        <div class="field-box">
                                        <label for="text" class="form-label">Email Address</label>
                                        <div class="field-otp-box">
                                            <input type="text" class="form-control" name="subject"  />
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                        <div class="field-box">
                                        <label for="text" class="form-label"> Mobile Number </label>
                                        <div class="field-otp-box">
                                            <input type="text" class="form-control" name="subject"  />
                                        </div>
                                        </div>
                                    </div> 
                                    <div class="col-md-6 mb-4">
                                        <div class="field-box">
                                        <label for="text" class="form-label"> Attachments (optional)  </label>
                                        <div class="field-otp-box">
                                            <input type="file" class="form-control" name="imagePath" multiple />
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12 mb-4">
                                        <label class="form-label mb-1">Description</label>
                                        <div class=" field-otp-box">
                                        <textarea class="form-control" rows="4" placeholder="Type message here" name="description"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <button class="btn w-auto btn-xl px-5 btn-dark" type="button">
                                        <b>Submit</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                    



                    </div>


                    





                </div>
            </section>
        </main>
        
        <Footer />
        </>

    )
}

export default ProductFeedbackSuggestions