import React, { useState } from 'react';



const PrivacyPolicy = () => {

    return (
        <div className=''>

            <h5 className='mt-5'>(1) Introduction</h5>
            <p>Crypto India values your privacy and is committed to protecting your personal information. This
                Privacy Policy outlines the types of personal data we collect, how we use it, and your rights
                regarding your personal data. By accessing or using Crypto India's services, including our website,
                mobile application, and other services, you agree to the terms outlined in this policy.
            </p>
            <h5 className='mt-5'>(2) Scope</h5>
            <b> This Privacy Policy applies to:</b>
            <ul>

                <li>
                    -All users of Crypto India's platform, including website visitors, mobile app users, and individuals
                    using our cryptocurrency exchange services.
                </li>
                <li>
                    -All personal information collected during the course of your interaction with Crypto India, whether
                    directly or through third-party services.
                </li>
            </ul>


            <h5 className='mt-5'>(3) Information We Collect </h5>
            <b> (3.1) Personal Information</b>
            <p>We collect personal information that you provide when you</p>
            <ul>

                <li>
                    - Create an account with Crypto India.
                </li>
                <li>
                    - Complete the Know Your Customer (KYC) verification process
                </li>
                <li>
                    - Use our services, such as trading, deposits, or withdrawals.
                </li>
                <li>
                    - Contact our customer support team.
                </li>
            </ul>
            <p>The personal information we collect may include:</p>
            <ul>
                <li>
                    - Identity Information: Full name, date of birth, government-issued identification (e.g., Aadhaar, PAN
                    card, passport), photographs.
                </li>
                <li>
                    - Contact Information: Email address, phone number, and postal address.
                </li>
                <li>
                    - Financial Information: Bank account details, transaction history, payment method details.
                </li>
                <li>
                    - Verification Information: Proof of address, KYC documents, source of funds, and other relevant
                    information.
                </li>
            </ul>
            <b> (3.2) Non-Personal Information </b>
            <p>We may also collect non-personal information automatically when you use our services. This may
                include:</p>
            <ul>
                <li>- Device Information: IP address, browser type, operating system, and device identifiers.</li>
                <li>- Usage Information: Pages visited, duration of visit, transaction history, and other activity on our
                    platform.</li>
                <li>- Location Information: With your consent, we may collect your approximate location through GPS or
                    other geolocation data.</li>
            </ul>

            <b> (3.3) Third-Party Information </b>
            <p>We may collect information about you from third-party services, such as:</p>
            <ul>
                <li>- Verification services, to validate your identity during the KYC process</li>
                <li>- Financial institutions, for transaction monitoring and compliance purposes.</li>
                <li>- Public databases, sanctions lists, and watchlists as part of our AML/CFT compliance.</li>

            </ul>

            <h5 className='mt-5'>(4) How We Use Your Information </h5>
            <b>Crypto India uses your personal information for the following purposes</b>
            <ul>

                <li>
                    - Account Registration and Verification: To create and maintain your account, verify your identity,
                    and comply with legal obligations under KYC/AML/CFT regulations
                </li>
                <li>
                    - Providing Services: To process transactions, manage your digital assets, and provide you with
                    access to our exchange and wallet services
                </li>
                <li>
                    - Customer Support: To respond to inquiries, resolve issues, and provide customer service.
                </li>
                <li>
                    - Compliance and Legal Requirements: To comply with our legal and regulatory obligations,
                    including reporting to regulatory authorities under the Prevention of Money Laundering Act (PMLA)
                    and other applicable laws.
                </li>
                <li>- Security and Fraud Prevention: To protect our platform from fraud, unauthorized access, and other
                    security threats</li>
                <li>- Improving Our Services: To analyze usage patterns, improve our platform, and provide a better
                    user experience.</li>
            </ul>

            <h5 className='mt-5'>(5) Sharing of Information</h5>
            <b>Crypto India does not sell your personal information to third parties. However, we may share your
                personal information with:</b>
            <ul>

                <li>
                    - Regulatory Authorities: As required by law, such as with the Financial Intelligence Unit (FIU-IND)
                    under the PMLA.
                </li>
                <li>
                    - Service Providers: Third-party vendors and partners that provide services to us, such as KYC/AML
                    verification services, payment processors, and IT service providers.
                </li>
                <li>
                    - Legal Compliance: Law enforcement agencies, courts, or other authorities when we are legally
                    obligated to do so or to protect the rights and safety of our users and platform.
                </li>
                <li>
                    - Corporate Transactions: In the event of a merger, acquisition, sale, or other corporate transaction,
                    your information may be transferred as part of that transaction.
                </li>

            </ul>

            <h5 className='mt-5'>(6) Security of Your Information</h5>
            <b>We implement industry-standard security measures to protect your personal information from
                unauthorized access, loss, or misuse. This includes:</b>
            <ul>

                <li>
                    - Encryption: We use encryption protocols to secure sensitive information during transmission and
                    storage.
                </li>
                <li>
                    - Access Controls: Access to personal data is restricted to authorized personnel only.
                </li>
                <li>
                    - Regular Audits: We conduct regular audits and assessments to identify potential vulnerabilities and
                    improve our security measures Despite our efforts, no security system is completely foolproof, and we cannot guarantee the
                    absolute security of your information. You are responsible for safeguarding your account credentials
                    and ensuring that your account is protected from unauthorized access.
                </li>
            </ul>

            <h5 className='mt-5'>(7)  Data Retention</h5>
            <b>Crypto India retains your personal information only for as long as necessary to fulfill the purposes
                outlined in this Privacy Policy or to comply with legal obligations. This includes:</b>
            <ul>

                <li>
                    - Transaction Records: Retained for at least five years after the transaction date or account closure,
                    in accordance with PMLA and other applicable regulations.
                </li>
                <li>
                    - KYC Information: Retained for a minimum of five years after the cessation of the relationship with
                    the customer, as required by law.
                </li>
            </ul>

            <h5 className='mt-5'>(8)  Your Rights </h5>
            <b>You have the following rights regarding your personal information:</b>
            <ul>

                <li>
                    - Access: You can request access to the personal information we hold about you.
                </li>
                <li>
                    - Correction: You can request that we correct any inaccurate or incomplete personal information.
                </li>
                <li>
                    - Deletion: You can request the deletion of your personal information under certain conditions (e.g.,
                    when it is no longer needed for legal or operational purposes).
                </li>
                <li>
                    - Restriction: You can request that we limit the processing of your personal information in certain
                    circumstances.
                </li>
                <li>
                    - Portability: You can request a copy of your personal information in a structured, machine-readable
                    format.
                    To exercise any of these rights, please contact us at [Insert Contact Email].
                </li>

            </ul>

            <h5 className='mt-5'>(9) Cookies and Tracking Technologies</h5>
            <p>Crypto India uses cookies and similar tracking technologies to enhance your experience on our
                platform. Cookies are small data files stored on your device that help us remember your
                preferences, provide personalized services, and analyze usage patterns.</p>
            <p>You can manage your cookie preferences through your browser settings. However, disabling
                cookies may affect your ability to use certain features of our platform.</p>

            <h5 className='mt-5'>(10) International Data Transfers</h5>
            <p>If you are accessing our platform from outside India, please note that your information may be
                transferred to and processed in India or other jurisdictions that may not have the same level of data
                protection as your home country. By using our services, you consent to the transfer of your
                information to India.</p>




            <h5 className='mt-5'> (11) Changes to This Privacy Policy</h5>
            <p>We may update this Privacy Policy from time to time to reflect changes in our practices, legal
                obligations, or service offerings. When we make changes, we will notify you by posting the updated
                policy on our platform and updating the "Effective Date" at the top of this document.</p>
            <p>Your continued use of our services after the changes become effective will signify your acceptance
                of the updated policy.</p>



            <h5 className='mt-5'> (12) Contact Us </h5>
            <p>If you have any questions or concerns about this Privacy Policy or our data handling practices,
                please contact us at:</p>
            <p>Crypto India</p>
            <p>Email: support@cryptoindia.in </p>
            <p>Address: Jaipur, Rajasthan, India</p>
            <p>Acknowledgment</p>
            <p>By using Crypto India's platform, you acknowledge that you have read, understood, and agree to the
                terms of this Privacy Policy.</p>
        </div>
    )
}

export default PrivacyPolicy