import React, { useContext } from "react";
import DashboardSidebar from "../../CoustamComponent/DashboardSidebar";
import { Link } from "react-router-dom";
import copy from 'copy-to-clipboard';
import { alertSuccessMessage } from "../../../Services/CustomAlertModel";
import { ProfileContext } from "../../../Services/ProfileProvider";

const ReferralPage = () => {

  const { userDetails } = useContext(ProfileContext);

  const location = window.location.host;
  const referralLink = `${location}/signup?reffcode=${userDetails?.user?.referral_code}`;
  function handleShareFacebook() {
    const shareText = `Check out my code: ${userDetails?.user?.referral_code}!`;
    const shareUrl = `http://103.175.163.162:4017/share?code=${encodeURIComponent(shareText)}`;
    const shareWindow = window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareText)}`,
      '_blank'
    );
    if (!shareWindow) {
      alert('Please disable your popup blocker to share the page.');
    }
  }

  function handleShareWhatsApp() {
    const shareUrl = `${location}/signup?reffcode=${userDetails?.user?.referral_code}`;
    const shareLink = `https://web.whatsapp.com/send?text=${shareUrl}`;
    const shareWindow = window.open(shareLink, '_blank');
    if (!shareWindow) {
      alert('Please disable your popup blocker to share the page.');
    }
  }

  function handleShareTwitter() {
    const shareText = `Check out my code: ${userDetails?.user?.referral_code}!`;
    const shareUrl = `${location}/signup?reffcode=${userDetails?.user?.referral_code}`;
    if (navigator.share) {
      navigator.share({
        title: document.title,
        text: shareText,
        url: shareUrl,
      });
    } else {
      const shareWindow = window.open(
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          shareText
        )}&url=${encodeURIComponent(shareUrl)}`,
        "_blank"
      );
      if (!shareWindow) {
        alert("Please disable your popup blocker to share the page.");
      }
    }
  }

  function handleShareTelegram() {
    const shareUrl = `${location}/signup?reffcode=${userDetails?.user?.referral_code}`;
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(shareUrl)}`;
    window.open(telegramUrl, '_blank');
  }


  return (
    <main>
      <div className="page_wrapper" >
        <div className="sidebar" >
          <DashboardSidebar />
        </div>
        <div className="af_wrapper">
          <section className="d_page m_account history_page">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xxl-9 col-xl-12">

                  <div className="pg_header">
                    <div className="hd_innre">
                      <h6 className="mb-0">Refer & Earn  </h6>
                      <div className="ph_right">
                        <Link to="/referral-list" className="btn custom-btn btn-sm" > Referal List </Link>
                      </div>
                    </div>
                  </div>
                  <div className="g_start">
                    <div className="twofa_card">
                      <div>
                        <div className="card-header">
                          <p className="mb-0 text-muted">Total Referrals </p>
                          <h2 className="ref_title">0</h2>
                        </div>
                        <div className="card-body_inner">
                          <div className="referrals__wrap">
                            <div className="referrals__title">
                              <small>Invite your friends to join <b>Crypto India Exchange</b> and get existing gifts</small>
                            </div>
                            <div className="row mb-4">
                              <div className="col-12">
                                <div className="field">
                                  <div className="field__label">Referral codes</div>
                                  <div className="field__wrap  field-otp-box">
                                    <input className="field__input form-control" type="text" name="referral-code" value={referralLink} readOnly />
                                    <button type="button" className="btn btn-xs custom-btn" onClick={() => { copy(referralLink); alertSuccessMessage("Refferal Link Copied") }}><span>Copy</span></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <label>
                              <small>OR share via</small></label>
                            <div className="d-flex  joc_social_row mt-2">
                              <span className="joc_social cursor-pointer" title="Facebook" onClick={handleShareFacebook}>
                                <i className="ri-facebook-line  ri-xl"></i>
                              </span>
                              <span className="joc_social cursor-pointer" title="Whatsapp" onClick={handleShareWhatsApp}>
                                <i className="ri-whatsapp-fill  ri-xl"></i>
                              </span>
                              <span className="joc_social cursor-pointer" title="Twitter" onClick={handleShareTwitter}>
                                <i className="ri-twitter-line  ri-xl"></i>
                              </span>
                              <span className="joc_social cursor-pointer" title="Instagram" onClick={handleShareTelegram}>
                                <i className="ri-telegram-fill  ri-xl"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>

  )
}

export default ReferralPage