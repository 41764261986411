import React from 'react';

const AmlCft = () => {

    return (
        <h5>AmlCft</h5>

    )
}

export default AmlCft