import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import AuthService from "../../../../Services/ApiServices/AuthService";
import SpinLoader from "../../../CoustamComponent/SpinLoader";
import moment from "moment";
import PayLimitModel from "./PayLimitModel";
import { handlePayLimit } from "../../../../Context/Utility/apiutils";
const SendCrypto = () => {

    const [step, setStep] = useState("step1")
    const [currencyList, setCurrencryList] = useState([]);
    const [currency, setCurrency] = useState('');
    const [walletBalance, setWalletBalance] = useState({});
    const [loading, setLoading] = useState(false);
    const [responseResult, setResponseResult] = useState("");
    const [reciverDetails, setReciverDetails] = useState();
    const [quickPayDetails, setQuickPayDetails] = useState();
    const [currencyBalance, setCurrencyBalance] = useState({});
    const [quickPayLimitDetails, setQuickPayLimitDetails] = useState();
    const [isOpenModel, setIsOpenModel] = useState(false);
    const [paylimitDetails, setPaylimitDetails] = useState();

    const navigate = useNavigate("");

    const [formData, setFormData] = useState({
        signId: "",
        amount: "",
        message: "",
    });

    const handleInput = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const result = await AuthService.getRecieverDetails(formData?.signId);
            if (result?.success) {
                setLoading(false);
                setStep("step2");
                setReciverDetails(result?.data)
                setResponseResult("");
                handleCurrencyList();
            } else {
                setResponseResult(result || "Internal Server Error.");
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            setResponseResult(error?.message || "An unexpected error occurred.");
        }

    };

    const handleSubmitStep2 = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const result = await AuthService.quickPayFeesDetails(formData?.amount, currency?.label);
            if (result?.success) {
                setLoading(false);
                setStep("step3");
                setQuickPayDetails(result?.data)
                setResponseResult("");
                handleCurrencyList();
            } else {
                setResponseResult(result || "Internal Server Error.");
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            setResponseResult(error?.message || "An unexpected error occurred.");
        }
    };

    const handleCurrencyList = async () => {
        const result = await AuthService.getCurrencyList();
        if (result?.success) {
            setCurrencryList(result?.data);
            const balances = {};
            await Promise.all(
                result.data.map(async (item) => {
                    const balanceResult = await AuthService.walletBalance(item.short_name, "Funding");
                    if (balanceResult?.success) {
                        balances[item.short_name] = balanceResult.data.balance.$numberDecimal;
                    }
                })
            );
            setWalletBalance(balances);
        }
    };

    const optionsCurrency = currencyList?.map(item => ({
        label: item.short_name,
        value: item.short_name,
        image: item.icon_path,
        id: item._id,
    }));

    const handleCurrencyChange = (selectedOption) => {
        setCurrency(selectedOption);
        const selectedCurrencyBalance = walletBalance[selectedOption.label];
        setCurrencyBalance(
            {
                currencyName: selectedOption.label,
                balance: selectedCurrencyBalance || "0"
            }
        );
    };

    const handleQuickPayLimit = async () => {
        setLoading(true);
        try {
            const result = await AuthService.quickPayLimit(reciverDetails?.userId, quickPayDetails?.finalAmount, currencyBalance?.currencyName);
            if (result?.success) {
                setLoading(false);
                setStep("step4");
                setResponseResult("");
                setQuickPayLimitDetails(result?.data);
            } else {
                setResponseResult(result || "Internal Server Error.");
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            setResponseResult(error?.message || "An unexpected error occurred.");
        }
    };

    const handleReset = () => {
        setStep("step1");
        setResponseResult("")
        setReciverDetails("");
        setQuickPayDetails("");
        setCurrency("");
        setFormData({
            signId: "",
            amount: "",
            message: "",
        });
    }

    useEffect(() => {
        handlePayLimit(setPaylimitDetails);
    }, []);

    return (
        <main className=" qp_wrapper qp_wrapper_send" >
            <div className="page_wrapper d-block" >

                <div className="qp_body" >
                    <div className="container" >
                        <div className="qp_sub_head" >
                            <div className="row align-items-center" >
                                <div className="col-lg-6" >
                                    <div className="sub_title" >
                                        <h3>
                                            <Link to="/quick_pay" className=" current-color" >
                                                <small className="cursor-pointer" >
                                                    <i class="ri-arrow-left-long-line me-2"></i></small>
                                            </Link>
                                            Send
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-lg-6" >
                                    <div className="cl_action" >
                                        <div className="sub_info" >
                                            <span className="text_link current-color cursor-pointer" onClick={() => setIsOpenModel(true)}> Pay Limit </span>
                                            <hr />
                                            <Link to="/pay_history" className="text_link current-color">Transaction History</Link>
                                            <Link to="/recive_quick_pay" className="btn btn-muted">Recive</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sub_body" >
                            {
                                step !== "step4" &&
                                <ul id="progressbar">
                                    <li class={`cursor-pointer ${step === "step1" ? 'active' : null}`} id="step1">
                                        <strong> Reciver Details </strong>
                                    </li>
                                    <li class={`cursor-pointer ${step === "step2" ? 'active' : null}`} id="step2">
                                        <strong>Enter Amount</strong>
                                    </li>
                                    <li class={`cursor-pointer ${step === "step3" ? 'active' : null}`} id="step3">
                                        <strong> Confirm Payment </strong>
                                    </li>
                                </ul>
                            }


                            <div class="row justify-content-center">
                                <div class="col-xl-8 multistep_form ">

                                    {
                                        step === "step1" &&
                                        <fieldset class="current">
                                            <div class="form-card  ">
                                                <div class="row justify-content-center ">
                                                    <div class="col-xl-6">
                                                        <form onSubmit={handleSubmit}>
                                                            <div class="field-box text-center">
                                                                <div class="mb-3">
                                                                    <h6> Send To</h6>
                                                                    <p> <small>Send crypto to Crypto India users via email, phone number, or Crypto India ID</small> </p>
                                                                </div>
                                                            </div>
                                                            {responseResult && (
                                                                <div className="col-md-12">
                                                                    <div className="alert alert-danger">{responseResult}</div>
                                                                </div>
                                                            )}
                                                            <div class="card-body">
                                                                <div class="row gx-2 gy-2">
                                                                    <div class="col-12">
                                                                        <label class="form-label" for="signId">  Email/Phone/User ID</label>
                                                                        <div class=" field-otp-box">
                                                                            <input
                                                                                type="text"
                                                                                name="signId"
                                                                                id="signId"
                                                                                className="form-control mb-1"
                                                                                value={formData.signId}
                                                                                onChange={handleInput}
                                                                                required />
                                                                            <button type="button" class="btn btn-icon btn_view_pass btn-white" fdprocessedid="2clrj"><i class="ri-qr-scan-2-line"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-12 mx-auto">
                                                                    <button type="submit" class="btn btn-warning btn-lg btn-block py-3 w-100 mt-3">
                                                                        {loading ? <SpinLoader /> : <span>Continue</span>}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    }

                                    {
                                        step === "step2" &&
                                        <fieldset class="current">
                                            <div class="form-card  ">
                                                <div class="row justify-content-center ">
                                                    <div class="col-xl-6">
                                                        <form onSubmit={handleSubmitStep2}>
                                                            <div class="field-box text-center">
                                                                <div class="mb-3">
                                                                    <h6> Enter Amount</h6>
                                                                </div>
                                                            </div>
                                                            {responseResult && (
                                                                <div className="col-md-12">
                                                                    <div className="alert alert-danger">{responseResult}</div>
                                                                </div>
                                                            )}
                                                            <div class="card-body">
                                                                <div className="st_tab" >
                                                                    <h6>  Receiver's Info </h6>
                                                                    <div className="rc_profile" >
                                                                        <i> <img src="assets/images/user_icon.svg" className="img-fluid" alt="" /> </i>
                                                                        <div class="ud">
                                                                            <h5>{reciverDetails?.name}</h5>
                                                                            <span>{formData?.signId}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row gx-2 gy-2">
                                                                    <div class="col-12">
                                                                        <label class="form-label" for="password">  Currency </label>
                                                                        <div class=" field-otp-box  cursor-pointer">
                                                                            <Select
                                                                                options={Array.isArray(optionsCurrency) ? optionsCurrency : []}
                                                                                value={currency}
                                                                                required
                                                                                onChange={handleCurrencyChange}
                                                                                getOptionLabel={(option) => (
                                                                                    <div className="cc_row d-flex align-items-center  justify-content-between cursor-pointer"  >
                                                                                        <span className="d-flex align-items-center me-2" >
                                                                                            <img
                                                                                                src={option.image || "/assets/img/icon/nocoin.png"}
                                                                                                alt={option.label}
                                                                                                className="ms_select"
                                                                                                onError={(e) => { e.target.src = "/assets/img/icon/nocoin.png"; }}
                                                                                                style={{ width: "20px", height: "20px", MaxWidth: "20px", marginRight: "8px" }}
                                                                                            />
                                                                                            {option.label}
                                                                                        </span>
                                                                                        <small className="d-flex align-items-center me-2 cursor-pointer">
                                                                                            Aval Bal: {walletBalance[option.label] || "0"}
                                                                                        </small>
                                                                                    </div>
                                                                                )}
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <label class="form-label" for="amount">  Amount </label>
                                                                        <div class=" field-otp-box">
                                                                            <input type="number" name="amount" id="amount" className={`form-control mb-1 ${responseResult ? "is-invalid" : ""}`} placeholder="Enter Amount" value={formData.amount} onChange={handleInput} required />
                                                                            <button type="button" class="btn btn-icon btn_view_pass btn-white me-2"  > {currency?.label} </button>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-12">
                                                                        <label class="form-label" for="message">  Note (Optional) </label>
                                                                        <div class=" field-otp-box">
                                                                            <input type="text" name="message" id="message" class="form-control mb-1" placeholder="Enter Note" value={formData.message} onChange={handleInput} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 mx-auto">
                                                                    <div className="d-flex gap-3 mt-4">
                                                                        <button type="button" class="btn btn-muted btn-lg btn-block py-3 w-50" onClick={() => { setStep("step1"); setResponseResult(""); }}>
                                                                            <span> Prev Step</span>
                                                                        </button>
                                                                        <button type="submit" class="btn btn-warning btn-lg btn-block py-3 w-50 ">
                                                                            {loading ? <SpinLoader /> : <span>Continue</span>}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    }

                                    {
                                        step === "step3" &&
                                        <fieldset class="current">
                                            <div class="form-card  ">
                                                <div class="row justify-content-center ">
                                                    <div class="col-xl-6">
                                                        <form>
                                                            <div class="field-box text-center">
                                                                <div class="mb-3">
                                                                    <h6>   Confirm </h6>
                                                                </div>
                                                            </div>
                                                            {responseResult && (
                                                                <div className="col-md-12">
                                                                    <div className="alert alert-danger">{responseResult}</div>
                                                                </div>
                                                            )}
                                                            <div class="card-body">
                                                                <div className="st_tab " >
                                                                    <h6>  Receiver's Info </h6>
                                                                    <div className="rc_profile" >
                                                                        <i> <img src="assets/images/user_icon.svg" className="img-fluid" alt="" /> </i>
                                                                        <div class="ud">
                                                                            <h5>{reciverDetails?.name}</h5>
                                                                            <span>{formData?.signId}</span>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        formData.message &&
                                                                        <div className="note" >
                                                                            <span> <b>Note: </b> {formData.message}  </span>
                                                                        </div>
                                                                    }

                                                                </div>



                                                                <div className=" st_tab st_tab_amount " >
                                                                    <h6> Amount </h6>
                                                                    <div className="am_row rc_profile" >
                                                                        <div className="d-flex align-items-start justify-content-bnetween w-100" >
                                                                            <span>  Total to Pay  </span>
                                                                            <em>{quickPayDetails?.finalAmount} {currencyBalance?.currencyName}</em>
                                                                        </div>
                                                                        <div className="d-flex align-items-start justify-content-bnetween" >
                                                                            <span>Fee </span>
                                                                            <em>
                                                                                {quickPayDetails?.fee} {currencyBalance?.currencyName}
                                                                            </em>
                                                                        </div>
                                                                        <div className="d-flex align-items-start justify-content-bnetween" >
                                                                            <span>Payee Receives </span>
                                                                            <em>
                                                                                {formData?.amount} {currencyBalance?.currencyName}
                                                                            </em>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className=" st_tab st_tab_wallet " >
                                                                    <h6> Payment Method </h6>
                                                                    <div className="wallet_row rc_profile" >
                                                                        <div>
                                                                            <p>Funding Wallet</p>
                                                                            <span>Available Balance: <b>{currencyBalance?.balance} {currencyBalance?.currencyName}</b></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    currencyBalance?.balance < quickPayDetails?.finalAmount &&
                                                                    <div class="row gx-2 gy-2 mb-2">
                                                                        <div class="col-12">
                                                                            <label class="form-label" for="password">  Payment Currency </label>
                                                                            <div class=" field-otp-box">
                                                                                <select className=" form-control" disabled >
                                                                                    <option>Insufficient {currencyBalance?.currencyName}</option>
                                                                                </select>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                <div class="col-12 mx-auto">
                                                                    {currencyBalance?.balance < quickPayDetails?.finalAmount && (
                                                                        <p className="mb-2 mt-4">
                                                                            <small>
                                                                                Please deposit {(quickPayDetails?.finalAmount - currencyBalance?.balance)} {currencyBalance?.currencyName} before checkout.
                                                                            </small>
                                                                        </p>
                                                                    )}


                                                                    <small className="ext_bal text-start mt-4 mb-2 fw-light" > By continuing, I accept the <a href="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/terms_and_conditions.pdf" target="_blank" > Terms of Use </a> </small>

                                                                    <div className="d-flex gap-3 mt-0">
                                                                        <button type="button" class="btn btn-muted btn-lg btn-block py-3 w-50" onClick={() => { setStep("step2"); setResponseResult(""); }}>
                                                                            <span> Prev Step</span>
                                                                        </button>
                                                                        {
                                                                            currencyBalance?.balance < quickPayDetails?.finalAmount ?
                                                                                <button type="button" class="btn btn-warning btn-lg btn-block py-3 w-50 "
                                                                                    onClick={() => navigate("/deposit_funds")}>
                                                                                    <span> Deposit Fund</span>
                                                                                </button>
                                                                                : <button type="button" class="btn btn-warning btn-lg btn-block py-3 w-50" onClick={handleQuickPayLimit}>
                                                                                    {loading ? <SpinLoader /> : <span>Continue</span>}
                                                                                </button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    }

                                    {
                                        step === "step4" &&
                                        <fieldset class="current">
                                            <div class="form-card  ">
                                                <div class="row justify-content-center ">
                                                    <div class="col-xl-6">
                                                        <form className="pp_success" >
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="svg-success" viewBox="0 0 24 24">
                                                                <g stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10">
                                                                    <circle class="success-circle-outline" cx="12" cy="12" r="11.5" />
                                                                    <circle class="success-circle-fill" cx="12" cy="12" r="11.5" />
                                                                    <polyline class="success-tick" points="17,8.5 9.5,15.5 7,13" />
                                                                </g>
                                                            </svg>
                                                            <p class="mt-3 text-gray-400 dark:text-gray-400" data-id="9">Payment Successful</p>
                                                            <h5 data-id="8"> {quickPayLimitDetails?.amount} <sub>{quickPayLimitDetails?.currency}</sub> </h5>
                                                            <div class="tt_data ">
                                                                <div class="tt_card  ">
                                                                    <div class="tt_item no-border">
                                                                        <span class="text-muted"> To </span>
                                                                        <span class="tt_normal"> {formData?.signId}  <br /> <small className="fw-normal" > username: {reciverDetails?.name} </small> </span>
                                                                    </div>
                                                                    <div class="tt_item">
                                                                        <span class="text-muted"> Note </span>
                                                                        <span class="tt_normal">{formData?.message || "-----"}</span>
                                                                    </div>
                                                                    <div class="tt_item  ">
                                                                        <span class="text-muted"> Order No</span>
                                                                        <span class="tt_normal">{quickPayLimitDetails?._id}</span>
                                                                    </div>
                                                                    <div class="tt_item">
                                                                        <span class="text-muted"> Date </span>
                                                                        <span class="tt_normal"> {moment(quickPayLimitDetails.createdAt).format("DD-MM-YYYY : h:mm:ss a")} </span>
                                                                    </div>
                                                                    <div class="tt_item">
                                                                        <span class="text-muted"> Amount </span>
                                                                        <span class="tt_normal"> {quickPayLimitDetails?.amount}</span>
                                                                    </div>
                                                                    <div class="tt_item">
                                                                        <span class="text-muted"> Fee </span>
                                                                        <span class="tt_normal">{quickPayLimitDetails?.fee}</span>
                                                                    </div>
                                                                    <div class="tt_item no-border">
                                                                        <span class="text-muted"> Total :</span>
                                                                        <span class="tt_normal">{quickPayLimitDetails?.amount + quickPayLimitDetails?.fee}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 mx-auto">
                                                                <div className="d-flex gap-3 mt-4">
                                                                    <button type="button" class="btn btn-muted btn-lg btn-block py-3 w-50" onClick={handleReset}>
                                                                        <span>  Send Again  </span>
                                                                    </button>
                                                                    <button type="button" class="btn btn-warning btn-lg btn-block py-3 w-50" onClick={() => navigate("/dashboard")}>
                                                                        <span> Back to Home </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <PayLimitModel isOpenModel={isOpenModel} setIsOpenModel={setIsOpenModel} paylimitDetails={paylimitDetails} />
        </main>
    )
}

export default SendCrypto