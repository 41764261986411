import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthService from "../../../Services/ApiServices/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../Services/CustomAlertModel";
import { ProfileContext } from "../../../Services/ProfileProvider";
import SpinLoader from "../SpinLoader";

const ChangePhoneNumber = () => {
    const [searchParams] = useSearchParams();
    const phoneToken = searchParams.get('token');
    const [tokenDetails, setTokenDetails] = useState('');
    const [isActiveOtpField, setIsActiveOtpField] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [phoneOtp, setPhoneOtp] = useState('');
    const navigate = useNavigate();
    const { setLoginDetails } = useContext(ProfileContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);


    const handleUserToken = async () => {
        setLoading(true);
        const result = await AuthService.getToeknDetails(phoneToken);
        if (result?.success) {
            setTokenDetails(result.data);
            setErrorMessage(null);
        } else {
            setErrorMessage(result);
            setTokenDetails(null);
        }
        setLoading(false);
    };

    const handlegetOtp = async () => {
        setIsLoading(true);
        try {
            const result = await AuthService.sendChangeEmailPhoneOtp(phoneToken);
            if (result?.success) {
                alertSuccessMessage(result.message);
                setIsActiveOtpField(true);
                setIsOtpSent(true);
                setCountdown(60);
                setIsLoading(false);
            } else {
                alertErrorMessage(result);
                setIsOtpSent(false);
                setCountdown(0);
                setIsLoading(false);
            }
        } catch (error) {
            alertErrorMessage(error);
            setIsLoading(false);
        }
    };


    const handleSubmitData = async (phoneOtp) => {
        setIsLoadingSubmit(true);
        try {
            const result = await AuthService.addNewMobileNumber(phoneOtp, phoneToken);
            if (result?.success) {
                setIsLoadingSubmit(false);
                setPhoneOtp("");
                alertSuccessMessage(result.message);
                tokenExpire();
            } else {
                setIsLoadingSubmit(false);
                alertErrorMessage(result);

            }
        } catch (error) {
            setIsLoadingSubmit(false);
        }
    };

    const tokenExpire = () => {
        localStorage.clear();
        navigate("/login");
        setLoginDetails({})
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        let timer;
        if (isOtpSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsOtpSent(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSent, countdown]);

    useEffect(() => {
        handleUserToken();
    }, []);

    return (
        <>
            {loading ? (
                <div className="loading-spinner">
                    loading........
                </div>
            ) : errorMessage ? (
                <section className="market_update_sec market_update_table pt-0 full-screen-center">
                    <div className="container">
                        <div className="section-title xxl_title text-center">
                            <h2 className="text-gradient text-uppercase">
                                <span className="text-warning">{errorMessage}</span>
                            </h2>
                        </div>
                    </div>
                </section>
            ) : (
                <div className="container mt-4" >
                    <div className="row justify-content-center" >
                        <div className="col-xxl-6 col-xl-10" >
                            <h6> Change Phone Number  </h6>
                            <div className="g_start " >
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12">
                                        <label htmlFor="firstName" className="form-label">Phone Number</label>
                                        <div className="field-otp-box">
                                            <input type="number" name="new_phone" id="new_phone" className="form-control" value={tokenDetails?.new_phone} disabled />
                                            <button
                                                type="button"
                                                className="btn btn-xs custom-btn"
                                                onClick={handlegetOtp}
                                                disabled={isOtpSent}
                                            >
                                                {
                                                    isLoading ? <SpinLoader />
                                                        : <span>{isOtpSent ? `Resend in ${countdown}s` : 'GET OTP'}</span>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                    {
                                        isActiveOtpField &&
                                        <div className="col-md-12 mb-3 mb-md-4 mt-2">
                                            <div className="field-box">
                                                <label htmlFor="phoneOtp" className="form-label">Email OTP</label>
                                                <input
                                                    type="text"
                                                    name="phoneOtp"
                                                    className="form-control"
                                                    value={phoneOtp}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d{0,6}$/.test(value)) {
                                                            setPhoneOtp(value);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }

                                    {
                                        phoneOtp?.length === 6 &&
                                        <div className="col-md-12 mb-3 mt-md-3 mt-2 ">
                                            <div className="field-box">
                                                <button className="btn custom-btn btn-block w-100 btn-xl" type="button" onClick={() => handleSubmitData(phoneOtp)}>
                                                    {isLoadingSubmit ? <SpinLoader /> : <span>Submit</span>}
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>

    )
}

export default ChangePhoneNumber