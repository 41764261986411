import React, { useContext, useState } from "react";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import { alertErrorMessage, alertSuccessMessage } from "../../../../Services/CustomAlertModel";
import AuthService from "../../../../Services/ApiServices/AuthService";
import { useNavigate } from "react-router-dom";
import { ProfileContext } from "../../../../Services/ProfileProvider";

const AddNominee = () => {

    const navigate = useNavigate();
    const { handleUserProfile } = useContext(ProfileContext);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        relation: '',
        aadhar_number: '',
        date: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;
        /*  if (name === 'aadhar_number') {
             formattedValue = formattedValue.replace(/\s/g, '');
             formattedValue = formattedValue.replace(/(.{4})(?!$)/g, '$1 ');
         } */
        if (name === 'aadhar_number') {
            formattedValue = formattedValue.replace(/\D/g, '');
            formattedValue = formattedValue.slice(0, 14);
            formattedValue = formattedValue.replace(/(.{4})(?!$)/g, '$1 ');
        }
        setFormData(prevState => ({
            ...prevState,
            [name]: formattedValue
        }));
    };

    const handleSubmit = async () => {
        const aadharNumberRegex = /^\d{4}\s\d{4}\s\d{4}$/;
        if (!formData.firstName) {
            alertErrorMessage("Enter first name");
            return;
        } if (!formData.lastName) {
            alertErrorMessage("Enter Last name");
            return;
        } if (!formData.relation) {
            alertErrorMessage("Enter Relation");
            return;
        } if (!formData.date) {
            alertErrorMessage("Enter Date of Birth");
            return;
        } if (!formData.aadhar_number) {
            alertErrorMessage("Enter Aadhaar number");
            return;
        }
        if (!aadharNumberRegex.test(formData.aadhar_number)) {
            alertErrorMessage("Invalid Aadhaar Number Format");
            return;
        }
        try {
            const result = await AuthService.addNomini(formData);
            if (result?.success) {
                alertSuccessMessage(result.message);
                handleUserProfile()
                navigate("/personal_information");
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };


    return (

        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper" >
                    <section className="d_page m_account  history_page">
                        <div className="container" >
                            <div className="row justify-content-center" >
                                <div className="col-xxl-8 col-xl-10" >
                                    <h6> Add Nominee   </h6>
                                    <div className="g_start " >
                                        <div className="row">
                                            <div className="col-md-12 mb-3 mb-md-4">
                                                <div className="field-box">
                                                    <label htmlFor="firstName" className="form-label">First Name</label>
                                                    <input
                                                        type="text"
                                                        name="firstName"
                                                        className="form-control"
                                                        value={formData.firstName}
                                                        onChange={handleChange}
                                                        aria-describedby="First Name"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3 mb-md-4">
                                                <div className="field-box">
                                                    <label htmlFor="lastName" className="form-label">Last Name</label>
                                                    <input
                                                        type="text"
                                                        name="lastName"
                                                        className="form-control"
                                                        value={formData.lastName}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3 mb-md-4">
                                                <div className="field-box">
                                                    <label htmlFor="relation" className="form-label">Relation</label>
                                                    <input
                                                        type="text"
                                                        name="relation"
                                                        className="form-control"
                                                        value={formData.relation}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3 mb-md-4">
                                                <div className="field-box">
                                                    <label htmlFor="aadhar_number" className="form-label">Aadhaar No. ID</label>
                                                    <input
                                                        type="text"
                                                        name="aadhar_number"
                                                        className="form-control"
                                                        value={formData.aadhar_number}
                                                        onChange={handleChange}
                                                        maxLength="14"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-12 mb-3 mb-md-4">
                                                <div className="field-box">
                                                    <label htmlFor="date" className="form-label">Date Of Birth</label>
                                                    <input
                                                        type="date"
                                                        name="date"
                                                        className="form-control"
                                                        value={formData.date}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3 mt-md-3 mt-2">
                                                <div className="field-box">
                                                    <button
                                                        className="btn custom-btn btn-block w-100 btn-xl"
                                                        type="button"
                                                        onClick={handleSubmit}
                                                    >
                                                        <span>Submit</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>

    )
}

export default AddNominee