import React from 'react';
import { Navigate } from 'react-router-dom';

const NotProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token');

  return !token ? children : <Navigate to="/markets" />;
};

export default NotProtectedRoute;
