import React, { useEffect, createContext, useState } from 'react';
import { alertErrorMessage } from '../CustomAlertModel';
import AuthService from '../ApiServices/AuthService';

export const ProfileContext = createContext();

export function ProfileProvider(props) {

  const [loginDetails, setLoginDetails] = useState({});
  const [userDetails, setUserDetails] = useState([]);
  const token = localStorage.getItem("token");


  useEffect(() => {
    if (token) {
      handleUserProfile();
    }
  }, [token]);

  const handleUserProfile = async () => {
    const result = await AuthService.getDetails();
    if (result?.success) {
      setUserDetails(result?.data);
    } else {
      alertErrorMessage(result?.message);
    }
  };

  function maskEmailOrPhone(input, type) {
    if (!input) return '';
    if (type === 'email' && input.includes('@')) {
      const [username, domain] = input.split('@');
      const maskedUsername = username.slice(0, Math.max(username.length - 2, 0)).padEnd(username.length, '*');
      return `${maskedUsername}@${domain}`;
    }
    else {
      return input;
    }
  }

  const maskedEmail = maskEmailOrPhone(userDetails?.user?.email, 'email');

  return (
    <ProfileContext.Provider value={{ setLoginDetails, loginDetails, userDetails, handleUserProfile, maskedEmail }}>
      {props.children}
    </ProfileContext.Provider>
  );
}
