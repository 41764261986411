import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../Footer";


const CompanyManagement = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])
    return (
        <>
        <main>
             <section className="tm_sec" >
                <div className="container" >
                    <div className="map_card" >
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4135.352480165531!2d75.79123373264491!3d26.902798200110396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5b05eb8dc5b%3A0xb8acb3b79eb57697!2sCrypto%20India%20Exchange!5e0!3m2!1sen!2sin!4v1723545810829!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        <div className="con_card" >
                            <div className="sec_title"><h2 className="text-dark text-center "> Head Office </h2> </div>
                            <p> Crypto India 207-208 Second Floor, Crystal Palm, 22 Godam, C Scheme, Jaipur, Rajasthan 302004 </p>
                            {/* <a href="mailto:contact@cryptoindia.in"> contact@cryptoindia.in </a> */}
                        </div>
                        <div className="row team_row g-3  justify-content-center" >
                            <div className="col-lg-2 col-md-6" >
                                <div className="how_card card_why" >
                                    <img src="assets/images/blank_img.png" className="img-fluid" />
                                    <div className="card_contant" >
                                        <h5>
                                        Pradeep kumar yadav 
                                        <small>
                                        CEO
                                        </small>
                                        </h5>
                                        {/* <a  href="mailto:ceo@cryptoindia.in" >ceo@cryptoindia.in </a>   */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6" >
                                
                            </div>
                            <div className="col-lg-2 col-md-6" >
                                <div className="how_card card_why" >
                                    <img src="assets/images/blank_img.png" className="img-fluid" />
                                    <div className="card_contant" >
                                        <h5>
                                        Rahul kumar yadav 
                                        <small>
                                        DIRECTOR
                                        </small>
                                        </h5>
                                        {/* <a  href="mailto:director@cryptoindia.in" >director@cryptoindia.in </a>   */}
                                    </div>
                                </div>
                            </div> 
                        </div>

                        <div className="row team_row g-3 justify-content-center" >
                            <div className="col-lg-12" >
                                <div className="title" >
                                    <div className="sec_title"><h2 className="text-dark text-center "> Nodal Officer   </h2> </div> 
                                </div>   
                            </div>  
                            <div className="col-lg-2 col-md-6" >
                                <div className="how_card card_why" >
                                    <img src="assets/images/blank_img.png" className="img-fluid" />
                                    <div className="card_contant" >
                                        <h5>
                                        Pradeep kumar yadav
                                        <small>
                                        Nodal Officer
                                        </small>
                                        </h5>
                                        {/* <a  href="mailto:nodal@cryptoindia.in" > nodal@cryptoindia.in  </a>   */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row team_row g-3 justify-content-center" >
                            <div className="col-lg-12" >
                                <div className="title" >
                                    <div className="sec_title"><h2 className="text-dark text-center ">   Legal/Compliance Officer   </h2> </div> 
                                </div>   
                            </div>    
                            <div className="col-lg-2 col-md-6" >
                                <div className="how_card card_why" >
                                    <img src="assets/images/blank_img.png" className="img-fluid" />
                                    <div className="card_contant" >
                                        <h5>
                                        Ashok Tiwari
                                        <small>
                                        Compliance Officer 
                                        </small>
                                        </h5>
                                        {/* <a  href="mailto:compliance@cryptoindia.in" > compliance@cryptoindia.in  </a>   */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-4" ></div>
                            <div className="col-lg-2 col-md-6" >
                                <div className="how_card card_why" >
                                    <img src="assets/images/blank_img.png" className="img-fluid" />
                                    <div className="card_contant" >
                                        <h5>
                                        Ravikant Dabi
                                        <small>
                                        Legal Officer 
                                        </small>
                                        </h5>
                                        {/* <a  href="mailto:legal@cryptoindia.in" > legal@cryptoindia.in  </a>   */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row team_row g-3 justify-content-center" >
                            <div className="col-lg-12" >
                                <div className="title" >
                                    <div className="sec_title"><h2 className="text-dark text-center ">  Human resources  </h2> </div> 
                                </div>   
                            </div>    
                            <div className="col-lg-2 col-md-6" >
                                <div className="how_card card_why" >
                                    <img src="assets/images/blank_img.png" className="img-fluid" />
                                    <div className="card_contant" >
                                        <h5>
                                        Ankita Kumari  
                                        <small>
                                        Hr 
                                        </small>
                                        </h5>
                                        {/* <a  href="mailto:hr@cryptoindia.in" > hr@cryptoindia.in  </a>   */}
                                    </div>
                                </div>
                            </div> 
                        </div>
                         
                        <div className="row team_row g-3 justify-content-center" >
                            <div className="col-lg-12" >
                                <div className="title" >
                                    <div className="sec_title"><h2 className="text-dark text-center "> Tech Team   </h2> </div> 
                                </div>   
                            </div>  
                            <div className="col-lg-2 col-md-6" >
                                <div className="how_card card_why" >
                                    <img src="assets/images/blank_img.png" className="img-fluid" />
                                    <div className="card_contant" >
                                        <h5>
                                         Ashok Tiwari  
                                        <small>
                                        Data Protection Officer   
                                        </small>
                                        </h5>  
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6" >
                                <div className="how_card card_why" >
                                    <img src="assets/images/blank_img.png" className="img-fluid" />
                                    <div className="card_contant" >
                                        <h5>
                                        Manish Sharma  
                                        <small>
                                        Accountent
                                        </small>
                                        </h5> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6" >
                                <div className="how_card card_why" >
                                    <img src="assets/images/blank_img.png" className="img-fluid" />
                                    <div className="card_contant" >
                                        <h5>
                                         Sudesh Yadav
                                        <small>
                                        Data Analytics    
                                        </small>
                                        </h5> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6" >
                                <div className="how_card card_why" >
                                    <img src="assets/images/blank_img.png" className="img-fluid" />
                                    <div className="card_contant" >
                                        <h5>
                                         Sanjay Yadav  
                                        <small>
                                        DVU Manager     
                                        </small>
                                        </h5> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6" >
                                <div className="how_card card_why" >
                                    <img src="assets/images/blank_img.png" className="img-fluid" />
                                    <div className="card_contant" >
                                        <h5>
                                        Samayak Rastogi  
                                        <small>
                                         Backend Developer       
                                        </small>
                                        </h5> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6" >
                                <div className="how_card card_why" >
                                    <img src="assets/images/blank_img.png" className="img-fluid" />
                                    <div className="card_contant" >
                                        <h5>
                                        Sachin Fuloria  
                                        <small>
                                        Backend Developer       
                                        </small>
                                        </h5> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6" >
                                <div className="how_card card_why" >
                                    <img src="assets/images/blank_img.png" className="img-fluid" />
                                    <div className="card_contant" >
                                        <h5>
                                        Rajendra Jodha    
                                        <small>
                                        Sr. Frontend Developer       
                                        </small>
                                        </h5> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6" >
                                <div className="how_card card_why" >
                                    <img src="assets/images/blank_img.png" className="img-fluid" />
                                    <div className="card_contant" >
                                        <h5>
                                        Ajay Jangid     
                                        <small>
                                         Creative Head
                                        </small>
                                        </h5> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             </section>
        </main>
        
        <Footer />
        </>

    )
}

export default CompanyManagement