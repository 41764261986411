import React, { useRef, useState } from "react";
import Footer from "../../../Footer";
import AuthService from "../../../../../Services/ApiServices/AuthService";
import copy from 'copy-to-clipboard';
import { Link } from "react-router-dom";
import SpinLoader from "../../../SpinLoader";


const UserSupport = () => {

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phoneNumber: "",
        subject: "",
        description: "",
        attachment: null,
    });

    const [formErrors, setFormErrors] = useState({});
    const [responseResult, setResponseResult] = useState("");
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isActiveCopy, setIsActiveCopy] = useState(null);

    const fieldRefs = useRef({});
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const mobileRegex = /^\d{10}$/;

    const handleInputChange = (e) => {
        const { name, type, files, value } = e.target;

        if (name === "phoneNumber" && !/^\d*$/.test(value)) {
            return;
        }

        setFormData({
            ...formData,
            [name]: type === "file" ? files[0] : value,
        });

        if (formErrors && name === getFirstInvalidField()) {
            setFormErrors("");
        }
        setFormErrors({});
    };

    const getFirstInvalidField = () => {
        if (!formData.fullName) return "fullName";
        if (!formData?.email || !emailRegex.test(formData.email)) return 'email';
        if (!formData?.phoneNumber || !mobileRegex.test(formData.phoneNumber)) return 'phoneNumber';
        if (!formData.subject) return "subject";
        if (!formData.description) return "description";
        if (!formData.attachment) return "attachment";
        return null;
    };


    const validateForm = (name, value) => {
        switch (name) {
            case "fullName":
                return value ? "" : "Full name is required.";
            case "email":
                return emailRegex.test(value) ? "" : "Enter Valid Email Address.";
            case "phoneNumber":
                return mobileRegex.test(value) ? "" : "Enter Valid 10-digit Phone Number is required.";
            case "subject":
                return value ? "" : "subject is required.";
            case "description":
                return value ? "" : "description Type is required.";
            case "attachment":
                return value ? "" : "attachment is required.";
            default:
                return "";
        }
    };

    const handleSubmit = async () => {
        setResponseResult("")
        const firstInvalidField = getFirstInvalidField();
        if (firstInvalidField) {
            const error = validateForm(firstInvalidField, formData[firstInvalidField]);
            setFormErrors({ [firstInvalidField]: error });
            if (fieldRefs.current[firstInvalidField]) {
                fieldRefs.current[firstInvalidField].scrollIntoView({ behavior: "smooth", block: "center" });
                fieldRefs.current[firstInvalidField].focus();
            }
            return;
        }
        const formValue = new FormData();
        formValue.append("full_name", formData.fullName);
        formValue.append("email", formData.email);
        formValue.append("phone", formData.phoneNumber);
        formValue.append("subject", formData.subject);
        formValue.append("description", formData.description);
        formValue.append("support_ticket_attachment", formData.attachment);
        setLoading(true);
        try {
            const result = await AuthService.contactConnectTeam(formValue);
            if (result?.success) {
                setData(result?.data?.support_ticket_id);
                setIsActive(true);
                setLoading(false);
            } else {
                setLoading(false);
                setResponseResult(result || "An error occurred.");
            }
        } catch (error) {
            setLoading(false);
            setResponseResult(error?.message || "An unexpected error occurred.");
        }
    };

    const handleupper = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    return (
        <>
            <main className="use_support_sec" >
                <section className="tm_sec  cm_sec us_bg" >
                    <div className="container" >
                        <div className="row align-items-center justify-content-evenly" >
                            <div className="col-lg-6 col-xl-6" >
                                <h2>
                                    Connect with Our Team of Experts
                                </h2>
                                <p>
                                    Not sure what you need? The team at
                                    Square Events will be happy to listen to
                                    you and suggest event ideas you
                                    hadn't considered
                                </p>
                            </div>
                            <div className="col-lg-6 text-center col-xl-4 " >
                                <div className="company_img " >
                                    <img src="assets/images/user_support_img.svg" alt="" className="img-fluid mx-auto" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="tm_sec p-0 d-block">
                    <div className={`container-fluid ${!isActive ? '' : "d-none"}`}>
                        <div className="row gx-0 justify-content-center" >
                            <div className="col-lg-8 col-xl-6" >
                                <div className="ccc_box">
                                    <div className="ccc_form" >
                                        <div className="overflow-hidden p-0 ">
                                            <h3> Contact us </h3>
                                            <p> Our friendly team would love to hear from you! </p>
                                            <br />
                                            <div className="row">
                                                <div className="col-md-6 mb-4">
                                                    <div className="field-box">
                                                        <label for="text" className="form-label">Full Name</label>
                                                        <div className="field-otp-box">
                                                            <input
                                                                type="text"
                                                                ref={(el) => (fieldRefs.current.fullName = el)}
                                                                className={`form-control ${formErrors.fullName ? "is-invalid" : ""}`}
                                                                name="fullName"
                                                                value={formData.fullName}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.fullName && (
                                                                <div className="invalid-feedback">{formErrors.fullName}</div>
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-4">
                                                    <div className="field-box">
                                                        <label for="text" className="form-label">Email Address</label>
                                                        <div className="field-otp-box">
                                                            <input
                                                                type="email"
                                                                ref={(el) => (fieldRefs.current.email = el)}
                                                                className={`form-control ${formErrors.email ? "is-invalid" : ""}`}
                                                                name="email"
                                                                value={formData.email}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.email && (
                                                                <div className="invalid-feedback">{formErrors.email}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-4">
                                                    <div className="field-box">
                                                        <label for="text" className="form-label"> Mobile Number </label>
                                                        <div className="field-otp-box">
                                                            <input
                                                                type="tel"
                                                                ref={(el) => (fieldRefs.current.phoneNumber = el)}
                                                                className={`form-control ${formErrors.phoneNumber ? "is-invalid" : ""}`}
                                                                name="phoneNumber"
                                                                value={formData.phoneNumber}
                                                                onChange={handleInputChange}
                                                                maxLength="10"
                                                            />
                                                            {formErrors.phoneNumber && (
                                                                <div className="invalid-feedback">{formErrors.phoneNumber}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-4">
                                                    <div className="field-box">
                                                        <label for="text" className="form-label">Subject</label>
                                                        <div className="field-otp-box">
                                                            <select className={`form-control form-select ${formErrors.subject ? "is-invalid" : ""}`}
                                                                name="subject"
                                                                ref={(el) => (fieldRefs.current.subject = el)}
                                                                onChange={handleInputChange} >
                                                                <option value="Please Select a Subject" disabled selected>
                                                                    Select Subject
                                                                </option>
                                                                <option value="Unable to Sign up"> Unable to Sign up </option>
                                                                <option value="Unable to Download Mobile App"> Unable to Download Mobile App  </option>
                                                                <option value="Unable to Login Account."> Unable to Login Account. </option>
                                                                <option value="Unable to Forgot Password."> Unable to Forgot Password.   </option>
                                                                <option value="Frozen Account."> Frozen Account. </option>
                                                                <option value="Other"> Other </option>
                                                            </select>
                                                            {formErrors.subject && (
                                                                <div className="invalid-feedback">{formErrors.subject}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12 mb-4">
                                                    <label className="form-label mb-1">Description</label>
                                                    <div className=" field-otp-box">
                                                        <textarea rows="4" placeholder="Type message here" name="description"
                                                            ref={(el) => (fieldRefs.current.description = el)}
                                                            className={`form-control ${formErrors.description ? "is-invalid" : ""}`}
                                                            value={formData.description}
                                                            onChange={handleInputChange}
                                                        ></textarea>
                                                        {formErrors.description && (
                                                            <div className="invalid-feedback">{formErrors.description}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-4">
                                                    <div className="field-box">
                                                        <label for="text" className="form-label"> Attachments </label>
                                                        <div className="field-otp-box">
                                                            <input type="file"
                                                                name="attachment"
                                                                ref={(el) => (fieldRefs.current.attachment = el)}
                                                                className={`form-control ${formErrors.attachment ? "is-invalid" : ""}`}
                                                                onChange={handleInputChange}
                                                            />
                                                            {formErrors.attachment && (
                                                                <div className="invalid-feedback">{formErrors.attachment}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {responseResult && (
                                                    <div className="col-md-12">
                                                        <div className="alert alert-danger">
                                                            {responseResult} {data}</div>
                                                    </div>
                                                )}
                                                <div className="col-md-12 mt-2">
                                                    <button className="btn w-auto  px-5 btn-xl  btn-warning" type="button" onClick={handleSubmit}>
                                                        {loading ? <SpinLoader /> : <span>Submit</span>}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`container-fluid ${isActive ? '' : "d-none"}`}>
                        <div className=" row gx-0 justify-content-center" >
                            <div className="col-lg-8 col-xl-6" >
                                <div className="ccc_box thankyou_box">
                                    <div className="ccc_form" >
                                        <div className="  overflow-hidden p-0 text-center ">
                                            <img src="assets/images/check.png" alt="" className="img-fluid mx-auto mb-4" />
                                            <h3 className="text-success" > Thank You {formData?.fullName} ! </h3>
                                            <p className="mb-2" > Ticket raised successfully. Email confirmation will follow shortly </p>
                                            <p className="mb-4" > Your ticket id is - <b>{data}</b>
                                                <i className={`text-black cursor-pointer ${isActiveCopy === data ? "ri-check-line" : "ri-file-copy-line"}`} onClick={() => { copy(data); setIsActiveCopy(data) }} ></i> </p>
                                            <Link className="btn btn-dark" to="/" onClick={handleupper}> Back to Home </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </main>

            <Footer />
        </>

    )
}

export default UserSupport