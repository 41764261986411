import React from 'react';



const DownloadApp = () => {

    return (
        <section className="how_it_sec h_app_sec">
            <div className="container">
                <div className="row  align-items-center ">
                    <div className="  col-lg-5 ">
                        <div className="section-title  text-start">
                            <h2>Trade on the go. <span className="text-warning text-underline"> Anywhere, anytime.</span></h2>
                            <p> Maximize Your Crypto Trading Potential with Spot and Futures Markets </p>
                            <div className="nc_body nc_qr p-0">
                                <div className="qr_body cookieCard">
                                    <img src="/assets/images/qr_code.png" className="img-fluid" />
                                    <div className="qr_add">
                                        <div>
                                            <span className="text-muted"> Scan to Download the App</span>
                                            <p className='m-0 h5' > iOS and Android  </p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-7">
                        <img src="/assets/images/mob_mockup.png" className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DownloadApp