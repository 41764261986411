import React, { useContext, useEffect, useState } from "react";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import AuthService from "../../../../Services/ApiServices/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../../Services/CustomAlertModel";
import { ProfileContext } from "../../../../Services/ProfileProvider";

const ManagingFeatures = () => {

    const { handleUserProfile, userDetails } = useContext(ProfileContext);

    const [featureStates, setFeatureStates] = useState({
        "buy_sell": true,
        "spot": true,
        "p2p": true,
        "deposit": true,
        "withdrawal": true,
    });

    useEffect(() => {
        if (userDetails?.permissions) {
            setFeatureStates({
                "buy_sell": userDetails.permissions.buy_sell ,
                "spot": userDetails.permissions.spot ,
                "p2p": userDetails.permissions.p2p ,
                "deposit": userDetails.permissions.deposit ,
                "withdrawal": userDetails.permissions.withdrawal ,
            });
        }
    }, [userDetails]);

    const handleSwitchChange = (e, featureName) => {
        const isChecked = e.target.checked;
        setFeatureStates({
            ...featureStates,
            [featureName]: isChecked
        });
        updateFeatureState(featureName, isChecked);
    };

    const updateFeatureState = async (featureName, isEnabled) => {
        try {
            const result = await AuthService.manageFeature(featureName, isEnabled);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserProfile();
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    return (
        <main>
            <div className="page_wrapper">
                <div className="sidebar">
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper">
                    <section className="d_page m_account">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xxl-8 col-xl-10">
                                    <div className="g_start">
                                        <div className="mt-4">
                                            <h6 className="mb-4">Managing Features</h6>
                                            {[
                                                { name: "buy_sell" },
                                                { name: "spot" },
                                                { name: "p2p" },
                                                { name: "deposit" },
                                                { name: "withdrawal" }
                                            ].map((feature, index) => (
                                                <div className="d_u_p align-items-center" key={index}>
                                                    <div className="dup_left">
                                                        <div className="ud">
                                                            <small>{feature.name}</small>
                                                        </div>
                                                    </div>
                                                    <div className="twofa_list d-flex align-items-center justify-space-between">
                                                        <div className="form-check switch_btns form-switch d-flex align-items-center">
                                                            <input
                                                                className="form-check-input cursor-pointer"
                                                                type="checkbox"
                                                                name={feature.name}
                                                                id={`switch-${index}`}
                                                                onChange={(e) => handleSwitchChange(e, feature.name)}
                                                                checked={featureStates[feature.name]}
                                                            />
                                                            <label className="form-check-label ms-2" htmlFor={`switch-${index}`}>
                                                                {featureStates[feature.name] ? "Enabled" : "Disabled"}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
};

export default ManagingFeatures;
