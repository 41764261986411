import React, { useEffect, useState } from "react";
import DashboardSidebar from "../../CoustamComponent/DashboardSidebar";
import AuthService from "../../../Services/ApiServices/AuthService";
import { alertErrorMessage } from "../../../Services/CustomAlertModel";
import moment from "moment";

const NotificationsPage = () => {

    const [notificationData, setNotificationData] = useState([]);

    const handleNotification = async () => {
        try {
            const result = await AuthService.notificationList();
            if (result?.success) {
                setNotificationData(result?.data);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        handleNotification();
    }, []);


    return (
        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper" >
                    <section className="d_page m_account  history_page">
                        <div className="container" >
                            <div className="row justify-content-center" >
                                <div className="col-xxl-12 col-xl-12" >
                                    <h6> All Notifications  </h6>
                                    <div className="g_start " >
                                        <div className="notifications__list">
                                            {
                                                notificationData?.length > 0 ?
                                                    notificationData.map((item, index) => (
                                                        <div className="notifications__item" key={index}>
                                                            <div className="notifications__details">
                                                                <div className="notifications__line">
                                                                    <div className="notifications__subtitle">{item?.title}</div>
                                                                    <div className="notifications__date"> {moment(item.createdAt).format("lll")}</div>
                                                                </div>
                                                                <div className="notifications__content">{item?.description}</div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    <div className="favouriteData">
                                                        <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    )
}

export default NotificationsPage