import React, { useRef, useState } from "react";
import Footer from "../../../Footer";
import AuthService from "../../../../../Services/ApiServices/AuthService";
import Swal from "sweetalert2";
import SpinLoader from "../../../SpinLoader";


const PartnershipInquiry = () => {

    const [formData, setFormData] = useState({
        fullName: "",
        companyName: "",
        email: "",
        phoneNumber: "",
        website: "",
        inquiryType: "",
        subject: "",
        description: "",
        attachment: null,
    });

    const [formErrors, setFormErrors] = useState({});
    const [responseResult, setResponseResult] = useState("");
    const [loading, setLoading] = useState(false);
    const fieldRefs = useRef({});
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const mobileRegex = /^\d{10}$/;

    const handleInputChange = (e) => {
        const { name, type, files, value } = e.target;

        if (name === "phoneNumber" && !/^\d*$/.test(value)) {
            return;
        }
        setFormData({
            ...formData,
            [name]: type === "file" ? files[0] : value,
        });
        if (formErrors && name === getFirstInvalidField()) {
            setFormErrors("");
        }
        setResponseResult("");
    };

    const getFirstInvalidField = () => {
        if (!formData.fullName) return "fullName";
        if (!formData.companyName) return "companyName";
        if (!formData?.email || !emailRegex.test(formData.email)) return 'email';
        if (!formData?.phoneNumber || !mobileRegex.test(formData.phoneNumber)) return 'phoneNumber';
        if (!formData.website) return "website";
        if (!formData.inquiryType) return "inquiryType";
        if (!formData.subject) return "subject";
        if (!formData.description) return "description";
        return null;
    };


    const validateForm = (name, value) => {
        switch (name) {
            case "fullName":
                return value ? "" : "Full name is required.";
            case "companyName":
                return value ? "" : "company Name is required.";
            case "email":
                return emailRegex.test(value) ? "" : "Enter Valid Email Address.";
            case "phoneNumber":
                return mobileRegex.test(value) ? "" : "Enter Valid 10-digit Phone Number is required.";
            case "website":
                return value ? "" : "website Name is required.";
            case "inquiryType":
                return value ? "" : "inquiry Type is required.";
            case "subject":
                return value ? "" : "subject is required.";
            case "description":
                return value ? "" : "description is required.";
            default:
                return "";
        }
    };

    const showAlert = (jobTitle) => {
        Swal.fire({
            title: `Thank You ${formData?.name}`,
            text: "Your Request Submited Successfully",
            icon: "success",
        })
    };

    const handleSubmit = async () => {
        setResponseResult("")
        const firstInvalidField = getFirstInvalidField();
        if (firstInvalidField) {
            const error = validateForm(firstInvalidField, formData[firstInvalidField]);
            setFormErrors({ [firstInvalidField]: error });
            if (fieldRefs.current[firstInvalidField]) {
                fieldRefs.current[firstInvalidField].scrollIntoView({ behavior: "smooth", block: "center" });
                fieldRefs.current[firstInvalidField].focus();
            }
            return;
        }       
        const formValue = new FormData();
        formValue.append("full_name", formData.fullName);
        formValue.append("company_name", formData.companyName);
        formValue.append("email", formData.email);
        formValue.append("phone", formData.phoneNumber);
        formValue.append("website", formData.website);
        formValue.append("enquiry_type", formData.inquiryType);
        formValue.append("subject", formData.subject);
        formValue.append("description", formData.description);
        formValue.append("attachment", formData.attachment);
        setLoading(true);
        try {
            const result = await AuthService.partnershipProgram(formValue);
            if (result?.success) {
                showAlert(result?.message);
                setLoading(false);
                setFormData({
                    fullName: "",
                    companyName: "",
                    email: "",
                    phoneNumber: "",
                    website: "",
                    inquiryType: "Please select",
                    subject: "",
                    description: "",
                    attachment: null,
                });
            } else {
                setLoading(false);
                setResponseResult(result || "An error occurred.");
            }
        } catch (error) {
            setLoading(false);
            setResponseResult(error?.message || "An unexpected error occurred.");
        }
    };


    return (
        <>
            <main>
                <section className="tm_sec d-block">
                    <div className="container">
                        <div className="row   mt-md-5 justify-content-center">
                            <div className="col-lg-12">
                                <div className="section-title  partner_title_sect text-start ">
                                    <div className="row align-items-center g-4" >
                                        <div className="col-lg-6" >
                                            <h2 className="text-warning  "> Crypto India Partnership Program   </h2>
                                            <div className="ddd text-start"  >
                                                <p>
                                                    We’re excited to explore new opportunities for collaboration! Whether you’re interested in partnering with Crypto India, inquiring about our services, or seeking media-related information, we’d love to hear from you.
                                                </p>
                                                <p>
                                                    Please fill out the form below with the details of your inquiry or partnership proposal. Our team will review your submission and get back to you as soon as possible. We value every opportunity and look forward to building something great together!
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6" >
                                            <div className="sec_img" >
                                                <img src="assets/images/partner_img.svg" className="img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container" >
                        <div className="g_start m_card overflow-hidden p-0 ">
                            <div className="p_card p-md-5">
                                <div className="why_card"  >
                                    <h6>Let’s Connect!</h6>
                                    <p>Complete the form, and we’ll be in touch shortly</p>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-md-6 mb-4">
                                        <div className="field-box">
                                            <label for="text" className="form-label"> Full Name</label>
                                            <div className="field-otp-box">
                                                <input
                                                    type="text"
                                                    ref={(el) => (fieldRefs.current.fullName = el)}
                                                    className={`form-control ${formErrors.fullName ? "is-invalid" : ""}`}
                                                    name="fullName"
                                                    value={formData.fullName}
                                                    onChange={handleInputChange}
                                                />
                                                {formErrors.fullName && (
                                                    <div className="invalid-feedback">{formErrors.fullName}</div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="field-box">
                                            <label for="text" className="form-label">  Company/Organization Name </label>
                                            <div className="field-otp-box">
                                                <input
                                                    type="text"
                                                    ref={(el) => (fieldRefs.current.companyName = el)}
                                                    className={`form-control ${formErrors.companyName ? "is-invalid" : ""}`}
                                                    name="companyName"
                                                    value={formData.companyName}
                                                    onChange={handleInputChange}
                                                />
                                                {formErrors.companyName && (
                                                    <div className="invalid-feedback">{formErrors.companyName}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="field-box">
                                            <label for="text" className="form-label">  Email Address</label>
                                            <div className="field-otp-box">
                                                <input
                                                    type="email"
                                                    ref={(el) => (fieldRefs.current.email = el)}
                                                    className={`form-control ${formErrors.email ? "is-invalid" : ""}`}
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                />
                                                {formErrors.email && (
                                                    <div className="invalid-feedback">{formErrors.email}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="field-box">
                                            <label for="text" className="form-label"> Phone Number </label>
                                            <div className="field-otp-box">
                                                <input
                                                    type="tel"
                                                    ref={(el) => (fieldRefs.current.phoneNumber = el)}
                                                    className={`form-control ${formErrors.phoneNumber ? "is-invalid" : ""}`}
                                                    name="phoneNumber"
                                                    value={formData.phoneNumber}
                                                    onChange={handleInputChange}
                                                    maxLength={10}
                                                />
                                                {formErrors.phoneNumber && (
                                                    <div className="invalid-feedback">{formErrors.phoneNumber}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="field-box">
                                            <label for="text" className="form-label">  Website (if applicable) </label>
                                            <div className="field-otp-box">
                                                <input
                                                    type="text"
                                                    ref={(el) => (fieldRefs.current.website = el)}
                                                    className={`form-control ${formErrors.website ? "is-invalid" : ""}`}
                                                    name="website"
                                                    value={formData.website}
                                                    onChange={handleInputChange}
                                                />
                                                {formErrors.website && (
                                                    <div className="invalid-feedback">{formErrors.website}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="field-box">
                                            <label for="text" className="form-label">Inquiry Type</label>
                                            <div className="field-otp-box">

                                                <select
                                                    className={`form-control form-select ${formErrors.inquiryType ? "is-invalid" : ""}`}
                                                    name="inquiryType"
                                                    ref={(el) => (fieldRefs.current.inquiryType = el)}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="Please select" disabled selected>
                                                        Please select
                                                    </option>
                                                    <option value="Partnership Opportunity"> Partnership Opportunity </option>
                                                    <option value="Business Collaboration"> Business Collaboration  </option>
                                                    <option value="Product/Service Inquiry"> Product/Service Inquiry </option>
                                                    <option value="Media/PR"> Media/PR </option>
                                                    <option value="Other"> Other </option>
                                                </select>
                                                {formErrors.inquiryType && (
                                                    <div className="invalid-feedback">{formErrors.inquiryType}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="field-box">
                                            <label for="text" className="form-label"> Subject  </label>
                                            <div className="field-otp-box">
                                                <input
                                                    type="text"
                                                    ref={(el) => (fieldRefs.current.subject = el)}
                                                    className={`form-control ${formErrors.subject ? "is-invalid" : ""}`}
                                                    name="subject"
                                                    value={formData.subject}
                                                    onChange={handleInputChange}
                                                />
                                                {formErrors.subject && (
                                                    <div className="invalid-feedback">{formErrors.subject}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="field-box mb-3">
                                            <label for="text" className="form-label">Please attach any relevant documents.(Optional)</label>
                                            <div className="field-otp-box mb-2">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    name="attachment"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-12 mb-4">
                                        <label className="form-label mb-1">Message/Details (Required)
                                            <small className="d-block mb-2" >Please provide details about your inquiry or partnership opportunity</small>
                                        </label>
                                        <div className=" field-otp-box">
                                            <textarea rows="4" placeholder="Type message here" name="description"
                                                ref={(el) => (fieldRefs.current.description = el)}
                                                className={`form-control ${formErrors.description ? "is-invalid" : ""}`}
                                                value={formData.description}
                                                onChange={handleInputChange}
                                            ></textarea>
                                            {formErrors.description && (
                                                <div className="invalid-feedback">{formErrors.description}</div>
                                            )}
                                        </div>
                                    </div>
                                    {responseResult && (
                                        <div className="col-md-12">
                                            <div className="alert alert-danger">
                                                {responseResult}</div>
                                        </div>
                                    )}
                                    <div className="col-md-12 mt-2">
                                        <button className="btn w-auto px-5 btn-xl  btn-warning" type="button" onClick={handleSubmit}>
                                            {loading ? <SpinLoader /> : <span>Submit</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main >

            <Footer />
        </>

    )
}

export default PartnershipInquiry