import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../Footer";


const UserSupport = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])
    return (
        <>
        <main>
            <section class="tm_sec p-0">
                <div class="container-fluid">

                    <div className="ccc_box" >
                        <div className="row gx-0" >
                            <div className="col-lg-4 " >
                                <img src="assets/images/user_support.png" alt="" className="img-fluid d-none d-lg-block h-100 object-cover object-center" />
                            </div>
                            <div className="col-lg-8" >
                                <div className="ccc_form" > 
                                    <div class="  overflow-hidden p-0 "> 
                                            <h3> Contact us </h3>
                                            <p> Our friendly team would love to hear from you! </p>
                                            <br/>
                                            <div class="row">
                                                <div class="col-md-6 mb-4">
                                                    <div class="field-box">
                                                    <label for="text" class="form-label">Full Name</label>
                                                    <div class="field-otp-box">
                                                        <input type="text" class="form-control" name="subject"  />
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mb-4">
                                                    <div class="field-box">
                                                    <label for="text" class="form-label">Email Address</label>
                                                    <div class="field-otp-box">
                                                        <input type="text" class="form-control" name="subject"  />
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mb-4">
                                                    <div class="field-box">
                                                    <label for="text" class="form-label"> Mobile  Number </label>
                                                    <div class="field-otp-box">
                                                        <input type="text" class="form-control" name="subject"  />
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mb-4">
                                                    <div class="field-box">
                                                        <label for="text" class="form-label">Subject</label>
                                                        <div class="field-otp-box">
                                                            <select className="form-control form-select " >
                                                                <option> Select Option </option>
                                                                <option> Unable to Sign up </option>
                                                                <option> Unable to Download Mobile App  </option>
                                                                <option> Unable to Login Account. </option>
                                                                <option> Unable to Forgot Password.   </option>
                                                                <option> Frozen Account. </option>  
                                                                <option> Other </option> 
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mb-4">
                                                    <div class="field-box">
                                                    <label for="text" class="form-label"> Attachments (optional)  </label>
                                                    <div class="field-otp-box">
                                                        <input type="file" class="form-control" name="imagePath" multiple />
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-12 mb-4">
                                                    <label class="form-label mb-1">Description</label>
                                                    <div class=" field-otp-box">
                                                    <textarea class="form-control" rows="4" placeholder="Type message here" name="description"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <button class="   btn-dark form-control btn-block w-100 w-lg-auto" type="button">
                                                     Submit
                                                    </button>
                                                </div>
                                            </div> 
                                    </div>   
                                </div>
                            </div>
                        </div>
                    </div>
                     
                </div>
            </section>
        </main>
        
        <Footer />
        </>

    )
}

export default UserSupport