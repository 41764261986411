export const PasswordModal = ({ isOpen, conditions }) => {
    if (!isOpen) return null;

    return (
        <div className="password-modal">  
                <h5>Password Requirements:</h5>
                <ul>
                    <li className={conditions.hasUpperCase ? 'text-success' : 'text-danger'}>
                        {conditions.hasUpperCase ? '✔' : '✖'} At least 1 uppercase letter
                    </li>
                    <li className={conditions.hasLowerCase ? 'text-success' : 'text-danger'}>
                        {conditions.hasLowerCase ? '✔' : '✖'} At least 1 lowercase letter
                    </li>
                    <li className={conditions.hasNumber ? 'text-success' : 'text-danger'}>
                        {conditions.hasNumber ? '✔' : '✖'} At least 1 number
                    </li>
                    <li className={conditions.hasSpecialChar ? 'text-success' : 'text-danger'}>
                        {conditions.hasSpecialChar ? '✔' : '✖'} At least 1 special character
                    </li>
                    <li className={conditions.passLength ? 'text-success' : 'text-danger'}>
                        {conditions.passLength ? '✔' : '✖'} Minimum 8 characters
                    </li>
                </ul> 
        </div>
    );
};
