import React, { useContext, useEffect, useState } from "react";
import { alertErrorMessage, alertSuccessMessage } from "../../../../Services/CustomAlertModel";
import LoaderHelper from "../../../../Services/Loading/LoaderHelper";
import AuthService from "../../../../Services/ApiServices/AuthService";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import { ProfileContext } from "../../../../Services/ProfileProvider";
const ChangePassword = () => {

    const [currentPassword, setCurrentPassword] = useState(false);
    const [newPassword, setNewPassword] = useState(false);
    const [cnfNewPassword, setCnfNewPassword] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const { userDetails } = useContext(ProfileContext);

    const [formData, setFormData] = useState(
        {
            signId: userDetails?.user?.email,
            verificationCode: "",
            currentPassword: "",
            confirmPassword: "",
            newPassword: ""
        }
    );



    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            signId: userDetails?.user?.email,
        }));
    }, [userDetails]);


    const handleInput = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleGetOtp = async (signId, type) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(signId)) {
            alertErrorMessage('Email is invalid');
            return;
        }
        if (!signId) {
            alertErrorMessage('Please Enter Email or Mobile Number..!!');
            return;
        }
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.getOtp(signId, type);
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                setIsOtpSent(true);
                setCountdown(60);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };


    useEffect(() => {
        let timer;
        if (isOtpSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsOtpSent(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSent, countdown]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { signId, verificationCode, currentPassword, confirmPassword, newPassword } = formData;
        if (!signId || !verificationCode || !currentPassword || !confirmPassword || !newPassword) {
            alertErrorMessage('All Fields are required.');
            return;
        }
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.changePassword(formData);
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage(result.message);
                setFormData({
                    ...formData,
                    verificationCode: "",
                    currentPassword: "",
                    confirmPassword: "",
                    newPassword: ""
                })
                setCountdown(0);
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };


    return (

        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper" >
                    <section className="d_page m_account">
                        <div className="container" >
                            <div className="row justify-content-center" >
                                <div className="col-xxl-8 col-xl-10" >
                                    <div className="g_start " >
                                        <div className="d_u_p align-items-start-md m-0" >
                                            <div className="dup_left d-block " >
                                                <h6 className="mb-0"> Change Password </h6>
                                                <p className="d-block mb-0 mt-1" > Fill the form below to change your password </p>
                                            </div>
                                        </div>
                                        <div className="mt-4 mt-4 p_card p-md-5" >
                                            <div className="row">
                                                <div className="col-md-12 mb-4">
                                                    <div className="field-box">
                                                        <label htmlFor="text" className="form-label">Current Password
                                                            <em className="text-danger">*</em>
                                                        </label>
                                                        <div className="field-otp-box">
                                                            <input type={currentPassword ? 'text' : "password"} className="form-control" placeholder="Enter Your Password.." name="currentPassword" value={formData.currentPassword} onChange={handleInput} />
                                                            <button type="button" className="btn btn-icon btn_view_pass btn-white" onClick={() => { setCurrentPassword(!currentPassword) }}>
                                                                <i className={`ri-eye${currentPassword ? "-line" : "-off-line"}`} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mb-4">
                                                    <div className="field-box">
                                                        <label htmlFor="text" className="form-label">New Password
                                                            <em className="text-danger">*</em>
                                                        </label>
                                                        <div className="field-otp-box">
                                                            <input type={newPassword ? 'text' : "password"} className="form-control" placeholder="Enter New Password" name="newPassword" value={formData.newPassword} onChange={handleInput} />
                                                            <button type="button" className="btn btn-icon btn_view_pass btn-white" onClick={() => { setNewPassword(!newPassword) }}>
                                                                <i className={`ri-eye${newPassword ? "-line" : "-off-line"}`} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-4">
                                                    <div className="field-box">
                                                        <label htmlFor="text" className="form-label">Confirm New Password
                                                            <em className="text-danger">*</em>
                                                        </label>
                                                        <div className="field-otp-box">
                                                            <input type={cnfNewPassword ? 'text' : "password"} className="form-control" placeholder="Enter Confirm Password" name="confirmPassword" value={formData.confirmPassword} onChange={handleInput} />
                                                            <button type="button" className="btn btn-icon btn_view_pass btn-white" onClick={() => { setCnfNewPassword(!cnfNewPassword) }}>
                                                                <i className={`ri-eye${cnfNewPassword ? "-line" : "-off-line"}`} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {formData.confirmPassword && formData.newPassword !== formData.confirmPassword && <span className="text-danger mb-0 mt-0 mb-2">Passwords does not match.</span>}

                                                <div className="col-lg-12 col-md-12 col-12 mb-4">
                                                    <label className="form-label mb-1">Verification Code
                                                        <em className="text-danger">*</em>
                                                    </label>

                                                    <div className=" field-otp-box">
                                                        <input type="number" name="verificationCode" className="form-control" placeholder="Enter Verification Code" value={formData.verificationCode} onChange={handleInput} />

                                                        <button type="button" className="btn btn-xs custom-btn" onClick={() => handleGetOtp(formData.signId, 'changepassword')} disabled={isOtpSent || !formData.currentPassword || !formData.confirmPassword}>
                                                            <span>{isOtpSent ? `Resend OTP in ${countdown}s` : 'GET OTP'}</span>
                                                        </button>

                                                    </div>
                                                </div>

                                                <div className="col-md-12 mt-2">
                                                    <div className="field-box text-end pt-2">
                                                        <button className="form-control btn-warning" type="button" onClick={handleSubmit}>
                                                            <span>Submit</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>

    )
}

export default ChangePassword