
const MenuItems = {
    "/dashboard": { name: "Dashboard", icon: "ri-home-6-line" },
    "/wallet": { name: "Spot", icon: "ri-wallet-line" },
    "/Peer_to_peer": { name: "P2P", icon: "ri-exchange-line" },
    "/personal_information": { name: "Personal Information", icon: "ri-user-6-line" },
    "/add_nominee": { name: "Add Nominee", icon: "ri-parent-line" },
    "/verify_kyc": { name: "KYC Management", icon: "ri-shield-check-line" },
    "/payments": { name: "Payments", icon: "ri-bank-card-line" },
    "/security": { name: "Security", icon: "ri-shield-line" },
    "/currency_preference": { name: "Currency Preference", icon: "ri-money-dollar-circle-line" },
    "/managing_features": { name: "Managing Features", icon: "ri-account-circle-fill" },

    "/spot_order": { name: "Spot Orders", icon: "ri-file-list-3-line" },
    "/buy_sell_transactions": { name: "Buy/Sell Orders", icon: "ri-exchange-dollar-line" },
    "/buy_sell_transactions": { name: "Buy Sell Transactions History", icon: "ri-download-2-line" },

    "/deposit_funds": { name: "Deposit Funds", icon: "ri-luggage-deposit-line" },
    "/withdraw_funds": { name: "Withdraw Funds", icon: "ri-refund-line" },


    "/activities": { name: "Activities", icon: "ri-computer-line" },
    "/recent_devices": { name: "Recent Device", icon: "ri-computer-line" },
    
    
    "/account_summary": { name: "Fee Tds Summary", icon: "ri-history-line" },
    "/account_statement": { name: "Account Statements", icon: "ri-account-box-line" },

 
    "/referral": { name: "Refer & Earn", icon: "ri-user-shared-line" },
    "/all_notificatios": { name: "Notifications", icon: "ri-notification-line" },
    "/support": { name: "Notifications", icon: "ri-message-2-fill" },
    "/buy_sell": { name: "INR/USDT", icon: "ri-exchange-line" },

    "/": { name: "Dashboard", icon: "ri-home-6-line" }
};

export default MenuItems;
