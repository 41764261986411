import { useContext, useEffect, useRef, useState } from 'react';
import './index.css';
import { widget } from '../charting_library';
import Datafeed from './datafeed';
import { weburl } from '../../ApiServices/AuthAction/ApiConfig';
import favicon from './ccl_logo.png';
import { ProfileContext } from '../../ProfileProvider';

export default function TVChartContainer({ symbol }) {


    const [tvWidget, setTvWidget] = useState();
    const [activeInterval, setActiveInterval] = useState('1');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedDropdownInterval, setSelectedDropdownInterval] = useState(null);
    const { tradeTheme } = useContext(ProfileContext);

    const currentTheme = document.documentElement.getAttribute('data-theme');
    console.log(currentTheme, 'currentTheme');


    const intervals = [
        { value: '1', label: '1m' },
        { value: '5', label: '5m' },
        { value: '15', label: '15m' },
        { value: '60', label: '1H' },
        { value: '240', label: '4H' },
        { value: 'D', label: '1D' },
    ];

    const dropdownIntervals = [
        { value: '30', label: '30m' },
        { value: '120', label: '2H' },
        { value: '360', label: '6H' },
        { value: '480', label: '8H' },
        { value: '720', label: '12H' },
        { value: 'D', label: '3D' },
        { value: 'W', label: '1W' },
        { value: 'M', label: '1M' }
    ];

    const getChart = (symbol) => {
        setTvWidget(new widget({
            symbol: `${symbol}`,
            load_last_chart: true,
            interval: activeInterval,
            fullscreen: false,
            timezone: 'Asia/Kolkata',
            container: 'TVChartContainer',
            datafeed: Datafeed,
            has_intraday: true,
            library_path: '/charting_library/',
            custom_css_url: 'css/style.css',
            pricescale: 100000000,
            intraday_multipliers: ['1', '60'],
            height: '415px',
            logo: {
                image: favicon,
                link: `${weburl}exchange/trade`,
            },
            time_frames: [],
            theme: currentTheme === 'light' ? 'light' : 'dark',
            overrides: {
                "symbolInfo.priceFormatter": {
                    precision: 4
                },
                "paneProperties.background": currentTheme === 'light' ? '#ffffff' : "#232627ed",
                "paneProperties.backgroundType": "solid",
            },
            loading_screen: {
                backgroundColor: currentTheme === 'light' ? '#ffffff' : "#232627ed",
            },
            disabled_features: ["adaptive_logo", "border_around_the_chart", 'header_symbol_search', 'header_interval_dialog_button', 'header_compare', 'header_undo_redo', 'header_resolutions',],
        }));
    };

    const functCheckRef = useRef(true);
    useEffect(() => {
        if (symbol.split('/')[0] !== 'undefined' && functCheckRef.current) {
            getChart(symbol);
            functCheckRef.current = false;
        }
    }, [symbol]);

    useEffect(() => {
        if (tvWidget) {
            tvWidget.onChartReady(() => {
                const chart = tvWidget.chart();
                if (chart) chart.setSymbol(symbol, () => null);
            });
        }
    }, [symbol]);

    useEffect(() => {
        if (tvWidget) {
            const activeTheme = document.documentElement.getAttribute('data-theme');
            if (activeTheme === 'light') {
                tvWidget.changeTheme("light");
            } else {
                tvWidget.changeTheme("dark");
            }
        }
    }, [tradeTheme]);


    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleIntervalClick = (interval) => {
        setActiveInterval(interval.value);
        tvWidget.chart().setResolution(interval.value);
        setIsDropdownOpen(false);
        setSelectedDropdownInterval(interval);
    };

    return (
        <>
            <div className="interval-buttons-container">
                <div className='tm_row'>
                    <div className='tm_inner'>
                        <span className='tm x55lqy'>Time</span>
                        {intervals.map(interval => (
                            <button
                                key={interval.value}
                                className={`interval-button x55lqy ${interval.value === activeInterval ? 'active' : ''}`}
                                onClick={() => handleIntervalClick(interval)}
                            >
                                {interval.label}
                            </button>
                        ))}
                        <div className='dropdown-menus'>
                            <button
                                onClick={toggleDropdown}
                                className={`x55lqy ${selectedDropdownInterval ? 'active' : ''}`}
                                onMouseEnter={() => setIsDropdownOpen(true)}
                                onMouseLeave={() => setIsDropdownOpen(false)}
                            >
                                {
                                    selectedDropdownInterval?.label == "1m" || selectedDropdownInterval?.label == "5m" || selectedDropdownInterval?.label == "15m" || selectedDropdownInterval?.label == "1H" || selectedDropdownInterval?.label == "4H" || selectedDropdownInterval?.label == "1D" ? "More" :
                                        selectedDropdownInterval ? selectedDropdownInterval.label : "More"
                                }
                                <span className="arrow">{isDropdownOpen ? '▲' : '▼'}</span>
                            </button>

                            {isDropdownOpen && (
                                <div className="dropdown-content" onMouseEnter={() => setIsDropdownOpen(true)} onMouseLeave={() => setIsDropdownOpen(false)}>
                                    <div className="jknra4">
                                        <div className="i711p4">Select Intervals</div>
                                    </div>
                                    <div className="interval-grid">
                                        {dropdownIntervals.map(interval => (
                                            <button
                                                key={interval.value}
                                                className={`x55lqy dropdownbg ${interval.value === activeInterval ? 'active' : ''}`}
                                                onClick={() => handleIntervalClick(interval)}
                                            >
                                                {interval.label}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div id='TVChartContainer' />
        </>
    );
}
