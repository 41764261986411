import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
const PartnerSection = () => {

    return (
        <section className="partner_sec selection">
            <div className="container">
                <div className="row align-items-center">
                    <div className="section-p_title  col-md-3 col-lg-2  ">
                        <h4 className="text-gradient mb-0">Our Partners </h4>
                    </div>
                    <div className="parter_row  col-md-9 col-lg-10 cursor_pointer">
                        <Swiper 
                        slidesPerView={2} 
                        className="swiper-wrapper" 
                        loop={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                          }}
                        breakpoints={{
                            640: {
                                slidesPerView: 2, 
                            },
                            768: {
                                slidesPerView: 4, 
                            },
                            1024: {
                                slidesPerView: 5, 
                            },
                            }} 
                            modules={[Autoplay]}
                        >
                            <SwiperSlide><img src="/assets/images/ps_1.png" /></SwiperSlide>
                            <SwiperSlide><img src="/assets/images/ps_2.png" /></SwiperSlide>
                            <SwiperSlide><img src="/assets/images/ps_3.png" /></SwiperSlide>
                            <SwiperSlide><img src="/assets/images/ps_4.png" /></SwiperSlide>
                            <SwiperSlide><img src="/assets/images/ps_5.png" /></SwiperSlide>
                            <SwiperSlide><img src="/assets/images/ps_6.png" /></SwiperSlide>
                            <SwiperSlide><img src="/assets/images/ps_7.png" /></SwiperSlide> 
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PartnerSection