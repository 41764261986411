import React, { useContext, useEffect, useRef, useState } from "react";
import { ProfileContext } from "../../../../../Services/ProfileProvider";
import AuthService from "../../../../../Services/ApiServices/AuthService";
import { format } from "date-fns";
import SpinLoader from "../../../../CoustamComponent/SpinLoader";
import { Link, useNavigate } from "react-router-dom";

const LevelTwoKyc = () => {

    const { userDetails, userKycDetails, handleUserProfile } = useContext(ProfileContext);

    const navigate = useNavigate();
    const [checkButton, setCheckButton] = useState(false);
    const [upgradeStep, setUpgradeStep] = useState("step1");
    const [upgradeUserDetails, setUpgradeUserDetails] = useState([]);
    const [responseResult, setResponseResult] = useState("");
    const [decelaration, setDecelaration] = useState(false);
    const [itrError, setItrError] = useState(null);
    const [bankStatementError, setBankStatementError] = useState(null);
    const [salarySlipError, setSalarySlipError] = useState(null);
    const [videoError, setVideoError] = useState(null);

    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const formattedDateTime = format(currentDateTime, "do MMMM yyyy, h:mm:ss a");
    const kycLevelStatusType = userDetails?.user?.upgrade_status

    const [isLoading, setIsLoading] = useState(false);
    const [videoBlob, setVideoBlob] = useState(null);
    const [isVideoRecorded, setIsVideoRecorded] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [timer, setTimer] = useState(10);
    const [showVideo, setShowVideo] = useState(false);
    const mediaRecorderRef = useRef(null);
    const videoStreamRef = useRef(null);
    const [videoURL, setVideoURL] = useState(null);
    const [itrDocument, setItrDocument] = useState(null);
    const [bankStatement, setBankStatement] = useState(null);
    const [salarySlip, setSalarySlip] = useState(null);
    const [otherDocument, setOtherDocument] = useState(null);

    const [showcoustamButton, setShowcoustamButton] = useState(true);
    const [totalLimitError, seTotalLimitError] = useState("");
    const [dailyLimitError, setDailyLimitError] = useState("");
    const [qbLimitError, setqbLimitError] = useState("");
    const [reasonError, setReasonError] = useState("");
    const [stepRejectedRemark, setStepRejectedRemark] = useState([]);
    const [isOpenInstVideo, setIsOpenInstVideo] = useState(false);

    const [formData, setFormData] = useState({
        totalLimit: "",
        dailyLimit: "",
        qbLimit: "",
        reason: "",
    });



    useEffect(() => {
        if (upgradeUserDetails) {
            setFormData(prevState => ({
                ...prevState,
                totalLimit: upgradeUserDetails?.total_account_withdrawal_limit || '',
                dailyLimit: upgradeUserDetails?.daily_desired_limit || '',
                qbLimit: upgradeUserDetails?.daily_qb_limit || '',
                reason: upgradeUserDetails?.reason || '',
            }));
            setItrDocument(upgradeUserDetails?.itrData?.documentLink || null);
            setOtherDocument(upgradeUserDetails?.otherData?.documentLink || null);
            setBankStatement(upgradeUserDetails?.bankStatementData?.documentLink || null);
            setSalarySlip(upgradeUserDetails?.salarySlipData?.documentLink || null);
            setVideoBlob(upgradeUserDetails?.videoKYCData?.documentLink || null);
        }
    }, [upgradeUserDetails]);



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        seTotalLimitError("");
        setDailyLimitError("");
        setqbLimitError("");
        setReasonError("");
    };

    const handleuserStep = async () => {
        const result = await AuthService.getUpgradeuserStep();
        if (result?.success) {
            setUpgradeStep(result.data?.step || "step1");
            handleGetUpgradeKycDetails(result.data?.step || "step1")
            handleStepRejectedRemark(result.data?.step || "step1")
        }
    };
    const handleGetUpgradeKycDetails = async (step) => {
        const result = await AuthService.getUpgradeuserDetails(step);
        if (result?.success) {
            setUpgradeUserDetails(result.data);
        }
    };

    const changeItrDocumnet = (event) => handleImageChange(event, setItrDocument);
    const changeotherDocumnet = (event) => handleImageChange(event, setOtherDocument);
    const changeBankStatement = (event) => handleImageChange(event, setBankStatement);
    const changeSalarySlip = (event) => handleImageChange(event, setSalarySlip);

    const handleImageChange = async (event, setImage) => {
        event.preventDefault();
        const file = event.target.files[0];
        if (file) {
            setImage(file)
        };
        setItrError(null);
        setBankStatementError(null);
        setSalarySlipError(null);
    };

    const handleVideoRecord = async () => {
        setVideoError(null);
        setIsOpenInstVideo(false);
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            videoStreamRef.current.srcObject = stream;
            videoStreamRef.current.play();
            setIsRecording(true);

            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorderRef.current = mediaRecorder;

            const chunks = [];
            mediaRecorder.ondataavailable = (e) => chunks.push(e.data);

            mediaRecorder.start();

            let remainingTime = 10;
            setTimer(remainingTime);
            setShowVideo(true);
            const timerInterval = setInterval(() => {
                remainingTime -= 1;
                setTimer(remainingTime);
                setShowVideo(true);
                if (remainingTime <= 0) {
                    clearInterval(timerInterval);
                }
            }, 1000);

            setTimeout(() => {
                mediaRecorder.stop();
                stream.getTracks().forEach((track) => track.stop());
                setIsRecording(false);
                setTimer(null);
                setShowVideo(false);
            }, 10000);

            mediaRecorder.onstop = () => {
                const videoBlob = new Blob(chunks, { type: "video/webm" });
                setVideoBlob(videoBlob);
                setIsVideoRecorded(true);
                setIsRecording(false);
            };
        } catch (error) {
            console.error("Camera access error:", error);
        }
    };


    const handleSubmitEntityDocument = async () => {
        if (!itrDocument) {
            setItrError("upload ITR Declaration");
            return;
        }
        if (!bankStatement) {
            setBankStatementError("upload Bank Statement (6 Months)");
            return;
        }
        if (!salarySlip) {
            setSalarySlipError("upload Salary Slip (6 Months)");
            return;
        }
        if (!videoBlob) {
            setVideoError("Click to record live video with PAN Card");
            return;
        }
        const formValue = new FormData();
        formValue.append("itrDocument", itrDocument);
        formValue.append("bankDocument", bankStatement);
        formValue.append("salarySlip", salarySlip);
        // formValue.append("videoKyc", videoBlob, "recorded-video.webm");
        formValue.append("videoKyc", videoBlob);
        formValue.append("otherDoc", otherDocument);

        setIsLoading(true);
        setShowcoustamButton(false);

        try {
            const result = await AuthService.submitUpgradeDocument(formValue);
            if (result?.success) {
                setResponseResult("")
                handleUpdateKycStep("step2")
                handleUserProfile();
            } else {
                setResponseResult(result);
            }
        } catch (error) {
            setResponseResult("Failed to submit documents");
        }
    };


    const handleUpgradeUserLimit = async (e) => {
        e.preventDefault();
        if (!formData.totalLimit) {
            seTotalLimitError("Total withdrawal limit is required.");
            return;
        }
        if (!formData.dailyLimit) {
            setDailyLimitError("Daily withdrawal limit is required.");
            return;
        } if (!formData.qbLimit) {
            setqbLimitError("Quick Buy limit is required.");
            return;
        }
        if (!formData.reason?.trim()) {
            setReasonError("Reason for limit enhancement is required.");
            return;
        }
        setIsLoading(true);
        try {
            const result = await AuthService.getUpgradeUserLimit(formData);
            if (result?.success) {
                setResponseResult("")
                handleUpdateKycStep("step3");
            } else {
                setResponseResult(result || "An error occurred.");
            }
        } catch (error) {
            setResponseResult(error?.message || "An unexpected error occurred.");
        }
    };


    const handleUpdateKycStep = async (step) => {
        try {
            const result = await AuthService.upgradeStepStatus(step);
            if (result?.success) {
                setIsLoading(false);
                handleuserStep();
                handleGetUpgradeKycDetails(step);
            } else {
                setIsLoading(false);
                setResponseResult(result);
            }
        } catch (error) {
            setIsLoading(false);
            setResponseResult(error);
        }
    };

    const handleDeclarationSubmission = async () => {
        if (!checkButton) {
            setDecelaration(true);
            return;
        }
        try {
            const result = await AuthService.upgradeDeclaration();
            if (result?.success) {
                handleUserProfile();
                navigate("/verify_kyc");
            } else {
                setResponseResult(result);
            }
        } catch (error) {
            setResponseResult(error);
        }
    }

    useEffect(() => {
        if (upgradeStep === "step1") {
            if (upgradeUserDetails?.videoKYCData?.documentLink) {
                setVideoURL(null);
            } else if (videoBlob) {
                const url = URL?.createObjectURL(videoBlob);
                setVideoURL(url);
                return () => {
                    URL?.revokeObjectURL(url);
                };
            }
        }
    }, [videoBlob, upgradeUserDetails?.videoKYCData?.documentLink]);



    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        handleuserStep();
    }, [])

    const handleStepRejectedRemark = async (step) => {
        await AuthService.getRejectedRemarkUpgrade(step).then(async (result) => {
            if (result?.success) {
                setStepRejectedRemark(result.data);
            } else {
                setStepRejectedRemark([]);
            }
        });
    };

    return (
        <main>
            {
                kycLevelStatusType === 3 || kycLevelStatusType === 2 ?
                    <div className="pager_wrapper feature_bg overflow-hidden">
                        <div className="kycp_wrapper">
                            <section className="d_page ">
                                <div className="container">
                                    <div className="dd_title d-block text-center">
                                        <h3 className="text-warning">Account upgrade</h3>
                                    </div>
                                    <div className="mt_form">
                                        <ul id="progressbar">
                                            <ul id="progressbar">
                                                <li

                                                    className={`cursor-pointer ${upgradeStep === "step1" ? "active" : ""}  ${upgradeStep === "step2" || upgradeStep === "step3" ? "success" : "current"}`}


                                                    id="step1"
                                                >
                                                    <strong>Proof of Identification</strong>
                                                </li>
                                                <li

                                                    className={`cursor-pointer ${upgradeStep === "step2" ? "active" : ""}  ${upgradeStep === "step3" ? "success" : "current"}`}

                                                    id="step2"
                                                >
                                                    <strong> Account Limits </strong>
                                                </li>
                                                <li
                                                    className={`cursor-pointer ${upgradeStep === "step3" ? "active" : ""}`}
                                                    id="step3"
                                                >
                                                    <strong>Declaration and Submission</strong>
                                                </li>
                                            </ul>
                                        </ul>
                                        <div className="row justify-content-center" >
                                            <div className="col-xl-8 multistep_form ">
                                                {
                                                    upgradeStep === "step1" &&
                                                    <fieldset className="current">
                                                        {
                                                            stepRejectedRemark && Object.values(stepRejectedRemark).some(value => value !== null) && (
                                                                <div className="fl_card" >
                                                                    <div className="fl_row" >
                                                                        <i class="ri-close-circle-fill"></i>
                                                                        <div>
                                                                            <h6> Verification Failed </h6>
                                                                            <div className="vf_reasion">
                                                                                {
                                                                                    stepRejectedRemark?.bankStatementRemark?.remark &&

                                                                                    <div className="text-dark "><b>Bank Statement  </b>
                                                                                        <small>{stepRejectedRemark?.bankStatementRemark?.remark}</small>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    stepRejectedRemark?.itrDocRemark?.remark &&
                                                                                    <div className="text-dark  "><b> ITR Declaration </b>
                                                                                        <small>{stepRejectedRemark?.itrDocRemark?.remark}</small>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    stepRejectedRemark?.salarySlipRemark?.remark &&
                                                                                    <div className="text-dark "><b>Salary Slip  </b>
                                                                                        <small>{stepRejectedRemark?.salarySlipRemark?.remark}</small>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    stepRejectedRemark?.videoKYCRemark?.remark &&
                                                                                    <div className="text-dark "><b>Video KYC</b>
                                                                                        <small>{stepRejectedRemark?.videoKYCRemark?.remark}</small>
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        <div className="form-card mt-0">
                                                            <div className="row justify-content-center ">
                                                                <div className="col-xl-12 ">
                                                                    <div className="step_title" >
                                                                        <h4>
                                                                            Need to Upload Some Documents to Upgrade your account
                                                                        </h4>
                                                                        <p>
                                                                            Crypto India Required some valid documents to upgrade your account limits
                                                                        </p>
                                                                    </div>
                                                                    {responseResult && (
                                                                        <div className="fl_card mb-2 mtm_error " >
                                                                            <div className="fl_row gap-2" >
                                                                                <i class="ri-error-warning-fill"></i>
                                                                                <p className="mt-0">
                                                                                    {responseResult}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                    <div className="field-box mb-3">
                                                                        <div className="dov_card">
                                                                            <div className="row g-3">
                                                                                <div className="col-lg-6">
                                                                                    <div className="doc_row">
                                                                                        <label htmlFor="ItrDocumnet">ITR Declaration</label>
                                                                                        <p>Format: PDF</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    {
                                                                                        upgradeUserDetails?.itrData?.documentLink ? (
                                                                                            <div className="dc_ver" >
                                                                                                <span className="btn btn-md btn-outline-success">
                                                                                                    <i class="ri-verified-badge-fill me-2 fw-normal text-success"></i>
                                                                                                    Verified
                                                                                                </span>
                                                                                                {/* <a
                                                                                                    href={upgradeUserDetails?.itrData?.documentLink}
                                                                                                    download
                                                                                                    target="_blank"
                                                                                                    className="btn btn-md btn-dark"
                                                                                                >
                                                                                                    <i class=" me-2 fw-normal ri-download-2-line"></i>
                                                                                                    Download
                                                                                                </a> */}
                                                                                            </div>

                                                                                        ) : (
                                                                                            <input
                                                                                                type="file"
                                                                                                name="ItrDocumnet"
                                                                                                className={`form-control ${itrError ? "is-invalid" : ""}`}
                                                                                                id="ItrDocumnet"
                                                                                                accept=".pdf"
                                                                                                onChange={changeItrDocumnet}
                                                                                            />
                                                                                        )
                                                                                    }


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {itrError && <div className="invalid-feedback d-block">{itrError}</div>}

                                                                        <div className="dov_card">
                                                                            <div className="row g-3">
                                                                                <div className="col-lg-6">
                                                                                    <div className="doc_row">
                                                                                        <label htmlFor="BankStatement">Bank Statement (6 Months)</label>
                                                                                        <p>Format: PDF</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">


                                                                                    {
                                                                                        upgradeUserDetails?.bankStatementData?.documentLink ? (
                                                                                            <div className="dc_ver" >
                                                                                                <span className="btn btn-md btn-outline-success">
                                                                                                    <i class="ri-verified-badge-fill me-2 fw-normal text-success"></i>
                                                                                                    Verified
                                                                                                </span>
                                                                                                {/*  <a
                                                                                                    href={upgradeUserDetails?.bankStatementData?.documentLink}
                                                                                                    download
                                                                                                    target="_blank"
                                                                                                    className="btn btn-md btn-dark"
                                                                                                >
                                                                                                    <i class=" me-2 fw-normal ri-download-2-line"></i>
                                                                                                    Download
                                                                                                </a> */}
                                                                                            </div>
                                                                                        ) : (
                                                                                            <input type="file" name="BankStatement" className={`form-control ${bankStatementError ? "is-invalid" : ""}`} id="BankStatement" accept=".pdf" onChange={changeBankStatement} />
                                                                                        )
                                                                                    }


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {bankStatementError && <div className="invalid-feedback d-block">{bankStatementError}</div>}

                                                                        <div className="dov_card">
                                                                            <div className="row g-3">
                                                                                <div className="col-lg-6">
                                                                                    <div className="doc_row">
                                                                                        <label htmlFor="SalarySlip">Salary Slip (6 Months)</label>
                                                                                        <p>Format: PDF</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">

                                                                                    {
                                                                                        upgradeUserDetails?.salarySlipData?.documentLink ? (
                                                                                            <div className="dc_ver" >
                                                                                                <span className="btn btn-md btn-outline-success">
                                                                                                    <i class="ri-verified-badge-fill me-2 fw-normal text-success"></i>
                                                                                                    Verified
                                                                                                </span>
                                                                                                {/*   <a
                                                                                                    href={upgradeUserDetails?.salarySlipData?.documentLink}
                                                                                                    download
                                                                                                    target="_blank"
                                                                                                    className="btn btn-md btn-dark"
                                                                                                >
                                                                                                    <i class=" me-2 fw-normal ri-download-2-line"></i>
                                                                                                    Download
                                                                                                </a> */}
                                                                                            </div>
                                                                                        ) : (
                                                                                            <input type="file" name="SalarySlip" className={`form-control ${salarySlipError ? "is-invalid" : ""}`} id="SalarySlip" accept=".pdf" onChange={changeSalarySlip} />
                                                                                        )
                                                                                    }


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {salarySlipError && <div className="invalid-feedback d-block">{salarySlipError}</div>}

                                                                        {/* Video Recording */}
                                                                        <div className="dov_card">
                                                                            <div className="row g-3 align-items-center">
                                                                                <div className="col-lg-6">
                                                                                    <div className="doc_row">
                                                                                        <label>Video KYC with PAN</label>
                                                                                        <p>Click to record live video with PAN Card</p>
                                                                                    </div>
                                                                                </div>

                                                                                {
                                                                                    upgradeUserDetails?.videoKYCData?.documentLink ? (
                                                                                        <div className="col-lg-6">
                                                                                            <div className="dc_ver" >
                                                                                                <span className="btn btn-md btn-outline-success">
                                                                                                    <i class="ri-verified-badge-fill me-2 fw-normal text-success"></i>
                                                                                                    Verified
                                                                                                </span>
                                                                                                {/*  <a
                                                                                                    href={upgradeUserDetails?.videoKYCData?.documentLink}
                                                                                                    download
                                                                                                    target="_blank"
                                                                                                    className="btn btn-md btn-dark"
                                                                                                >
                                                                                                    <i class=" me-2 fw-normal ri-download-2-line"></i>
                                                                                                    Download
                                                                                                </a> */}
                                                                                            </div>

                                                                                        </div>
                                                                                    ) :
                                                                                        !isVideoRecorded ? (
                                                                                            <div className="col-lg-6 text-end">
                                                                                                {
                                                                                                    !isRecording ?
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            onClick={() => setIsOpenInstVideo(true)}
                                                                                                            className="btn btn-dark"
                                                                                                        >
                                                                                                            Start Recording
                                                                                                        </button>
                                                                                                        :
                                                                                                        <div className="vc_rc" >
                                                                                                            <span className="rec_text  " > <i class="ri-circle-fill blink "></i>  REC </span>
                                                                                                            <span className="rec_timer" >
                                                                                                                00:00:{timer}
                                                                                                            </span>
                                                                                                        </div>

                                                                                                }

                                                                                            </div>
                                                                                        ) : (
                                                                                            showcoustamButton && (
                                                                                                <div className="col-lg-6 text-end">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        // onClick={() => {
                                                                                                        //     handleVideoRecord();
                                                                                                        //     setIsVideoRecorded(false);
                                                                                                        // }}
                                                                                                        onClick={() => { setIsOpenInstVideo(true); setIsVideoRecorded(false) }}
                                                                                                        disabled={isRecording}
                                                                                                        className="btn btn-dark px-3"
                                                                                                    ><i class="ri-reset-left-fill me-2"></i>
                                                                                                        Retake
                                                                                                    </button>
                                                                                                </div>
                                                                                            )
                                                                                        )}
                                                                            </div>

                                                                            {videoError && <div className="invalid-feedback d-block">{videoError}</div>}

                                                                            <>

                                                                                {/* condition 1 */}
                                                                                <div className={`dov_card vc_card_upl text-center ${isOpenInstVideo ? "" : "d-none"}`} >
                                                                                    <div className="lc_img" >
                                                                                        <img src="/assets/images/v_kyc.svg" className="img-fluid" alt="" />
                                                                                    </div>
                                                                                    <h3>
                                                                                        Start Video KYC
                                                                                    </h3>
                                                                                    <p>
                                                                                        Ensures your pancard and face visible properly, streamlining the process for seamless access to services.
                                                                                    </p>
                                                                                    <button className="btn btn-dark px-5 mt-4"
                                                                                        onClick={handleVideoRecord}
                                                                                        disabled={isRecording}
                                                                                    >
                                                                                        Start Video Verification
                                                                                    </button>
                                                                                </div>

                                                                                {/* condition 2 */}

                                                                                <div className={` dov_card vc_card_upl vcc_video  ${showVideo ? "" : "d-none"}`}>
                                                                                    {/* Live Preview */}

                                                                                    <video
                                                                                        ref={videoStreamRef}
                                                                                        style={{ width: "100%" }}
                                                                                        muted
                                                                                    />
                                                                                </div>

                                                                                {isVideoRecorded && (
                                                                                    <>
                                                                                        <div className="dov_card vc_card_upl vcc_video" >
                                                                                            <video
                                                                                                style={{ width: "100%" }}
                                                                                                src={videoURL}
                                                                                                controls
                                                                                            />
                                                                                        </div>

                                                                                    </>
                                                                                )}
                                                                            </>

                                                                        </div>
                                                                        {/*  <button type="button" className="form-control mt-4 no-border bg-warning btn-block w-100" onClick={handleSubmitEntityDocument} disabled={isRecording}>
                                                                            {isLoading ? <SpinLoader /> : <span>Continue</span>}
                                                                        </button> */}

                                                                        <div className="dov_card">
                                                                            <div className="row g-3">
                                                                                <div className="col-lg-6">
                                                                                    <div className="doc_row">
                                                                                        <label htmlFor="otherDocument">Other Document (Optional)</label>
                                                                                        <p>Format: PDF</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">

                                                                                    {
                                                                                        upgradeUserDetails?.otherDocument?.documentLink ? (
                                                                                            <div className="dc_ver" >
                                                                                                <span className="btn btn-md btn-outline-success">
                                                                                                    <i class="ri-verified-badge-fill me-2 fw-normal text-success"></i>
                                                                                                    Verified
                                                                                                </span>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <input type="file" name="otherDocument" className="form-control" id="otherDocument" onChange={changeotherDocumnet} />
                                                                                        )
                                                                                    }


                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {upgradeUserDetails && Object.values(upgradeUserDetails).every(value => value !== null)
                                                                            ? (
                                                                                <button
                                                                                    type="button"
                                                                                    className="form-control mt-4 no-border bg-warning btn-block w-100"
                                                                                    onClick={() => handleUpdateKycStep("step2")}
                                                                                >
                                                                                    Continue
                                                                                </button>
                                                                            ) : (
                                                                                <button
                                                                                    type="button"
                                                                                    className="form-control mt-4 no-border bg-warning btn-block w-100"
                                                                                    onClick={handleSubmitEntityDocument}
                                                                                    disabled={isRecording}
                                                                                >
                                                                                    {isLoading ? <SpinLoader /> : <span>Continue</span>}
                                                                                </button>
                                                                            )
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                }

                                                {
                                                    upgradeStep === "step2" &&
                                                    <fieldset className="current">

                                                        {
                                                            stepRejectedRemark?.remark && (
                                                                <div className="fl_card" >
                                                                    <div className="fl_row" >
                                                                        <i class="ri-close-circle-fill"></i>
                                                                        <div>
                                                                            <h6> Verification Failed </h6>
                                                                            <div className="vf_reasion">
                                                                                <small>{stepRejectedRemark?.remark}</small>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        <div className="form-card mt-0">
                                                            <div className="row justify-content-center ">
                                                                <div className="col-xl-12 ">
                                                                    <div className="step_title" >
                                                                        <h4>
                                                                            Set Your Account New Limits
                                                                        </h4>

                                                                    </div>
                                                                    {responseResult && (
                                                                        <div className="fl_card mb-2 mtm_error " >
                                                                            <div className="fl_row gap-2" >
                                                                                <i class="ri-error-warning-fill"></i>
                                                                                <p className="mt-0">
                                                                                    {responseResult}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    <div className="field-box mb-3">
                                                                        <form className="row justify-content-center" onSubmit={handleUpgradeUserLimit}>
                                                                            <div className="col-lg-8 mb-3">
                                                                                <div className="field-box">
                                                                                    <label htmlFor="totalLimit" className="form-label">
                                                                                        Desired Total (FY) Account Withdrawal Limit (INR)
                                                                                    </label>
                                                                                    <input
                                                                                        type="number"
                                                                                        className={`form-control ${totalLimitError && "is-invalid"}`}
                                                                                        placeholder="Enter Amount"
                                                                                        name="totalLimit"
                                                                                        value={formData.totalLimit}
                                                                                        onChange={handleInputChange}
                                                                                        disabled={upgradeUserDetails?.total_account_withdrawal_limit}
                                                                                    />
                                                                                </div>
                                                                                {totalLimitError && <div className="invalid-feedback d-block">{totalLimitError}</div>}
                                                                            </div>


                                                                            <div className="col-lg-8 mb-3">
                                                                                <div className="field-box">
                                                                                    <label htmlFor="dailyLimit" className="form-label">
                                                                                        Desired Daily Withdrawal Limit (INR)
                                                                                    </label>
                                                                                    <input
                                                                                        type="number"
                                                                                        className={`form-control ${dailyLimitError && "is-invalid"}`}
                                                                                        placeholder="Enter Amount"
                                                                                        name="dailyLimit"
                                                                                        value={formData.dailyLimit}
                                                                                        onChange={handleInputChange}
                                                                                        disabled={upgradeUserDetails?.daily_desired_limit}
                                                                                    />
                                                                                </div>
                                                                                {dailyLimitError && <div className="invalid-feedback d-block">{dailyLimitError}</div>}

                                                                            </div>

                                                                            <div className="col-lg-8 mb-3">
                                                                                <div className="field-box">
                                                                                    <label htmlFor="qbLimit" className="form-label">
                                                                                        Quick Buy Limit (INR)
                                                                                    </label>
                                                                                    <input
                                                                                        type="number"
                                                                                        className={`form-control ${qbLimitError && "is-invalid"}`}
                                                                                        placeholder="Enter Amount"
                                                                                        name="qbLimit"
                                                                                        value={formData.qbLimit}
                                                                                        onChange={handleInputChange}
                                                                                        disabled={upgradeUserDetails?.daily_qb_limit}
                                                                                    />
                                                                                </div>
                                                                                {qbLimitError && <div className="invalid-feedback d-block">{qbLimitError}</div>}

                                                                            </div>

                                                                            <div className="col-lg-8 mb-3">
                                                                                <div className="field-box">
                                                                                    <label htmlFor="reason" className="form-label">
                                                                                        Reason for Limit Enhancement
                                                                                    </label>
                                                                                    <textarea
                                                                                        className={`form-control ${reasonError && "is-invalid"}`}
                                                                                        placeholder="Enter reason here"
                                                                                        name="reason"
                                                                                        rows="2"
                                                                                        value={formData.reason}
                                                                                        onChange={handleInputChange}
                                                                                        disabled={upgradeUserDetails?.reason}
                                                                                    ></textarea>
                                                                                </div>
                                                                                {reasonError && <div className="invalid-feedback d-block">{reasonError}</div>}
                                                                            </div>


                                                                            <div className="col-lg-8">
                                                                                <button type="submit" className="form-control mt-4 no-border bg-warning btn-block w-100">
                                                                                    {isLoading ? <SpinLoader /> : <span>Continue</span>}
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </fieldset>
                                                }

                                                {
                                                    upgradeStep === "step3" &&
                                                    <fieldset className="current">
                                                        <form className="form-card"
                                                            onSubmit={(e) => {
                                                                e.preventDefault();
                                                                handleDeclarationSubmission();
                                                            }}
                                                        >
                                                            <div className="row">
                                                                <div className="p_card mt-2 p-3">
                                                                    <h5>DECLARATION OF SOURCE OF FUNDS</h5>
                                                                    <ul className="desc mb-0">
                                                                        <p>Date: &nbsp; <b> {formattedDateTime} </b></p>
                                                                        <li className="mb-2 d-block">
                                                                            <b>[{userKycDetails?.pandetails?.name}]</b>, a citizen of India, holding Aadhaar Number <b>[{userKycDetails?.pandetails?.maskedAadhaarNumber}]</b> and PAN Card number <b>
                                                                                [{userKycDetails?.pandetails?.pan}]</b>, hereby declare and affirm that all funds used in my financial transactions, with CRYPTO INDIA (Incryp Blockchain Private Limited) in INR and any other crypto currency, are obtained from legitimate and legal sources. The funds are fully compliant with all applicable laws, regulations, and guidelines, including Anti-Money Laundering (AML) and Counter-Terrorist Financing (CTF) regulations.
                                                                        </li>
                                                                        <li className="mb-2">We further declare that:</li>

                                                                        <li className="mb-2 d-block">
                                                                            1. Legitimate Source: All funds used in our company’s financial operations, including the acquisition of crypto assets, are free from any association with illegal activities such as money laundering, tax evasion, or terrorist financing.
                                                                        </li>

                                                                        <li className="mb-2 d-block">
                                                                            2. Compliance with AML Regulations: Our company follows stringent internal controls and compliance measures, ensuring full adherence to AML, CTF, and Know Your Customer (KYC) guidelines as stipulated by regulatory authorities. We have systems in place to detect and prevent suspicious or unlawful financial activities
                                                                        </li>

                                                                        <li className="mb-2 d-block">
                                                                            3. Due Diligence: Our company undertakes regular due diligence on all counterparties and customers to verify the legitimacy of funds, consistent with legal requirements, before entering any transaction involving crypto assets or traditional financial services.

                                                                        </li>

                                                                        <li className="mb-2 d-block">
                                                                            4. Ethical Standards: Our company upholds the highest standards of ethical conduct in all financial dealings, ensuring that funds used in all transactions are derived from legitimate business activities and are compliant with domestic and international regulations.
                                                                        </li>

                                                                        <li className="mb-2 d-block">
                                                                            5. Cooperation with Authorities: In the event of any suspicion, inquiry, or investigation into the source of our company’s funds, we commit to full cooperation with law enforcement agencies, financial institutions, and regulatory authorities. We are prepared to provide all necessary documentation and information to verify the source of funds when required.
                                                                        </li>
                                                                    </ul>
                                                                    <div className="avil_bal my-2 mb-1 " >
                                                                        <div className="form-check cursor-pointer  d-block" >
                                                                            <input className={`form-check-input cursor-pointer ${decelaration ? "is-invalid" : ""}`} type="checkbox" id="flexCheckDefault" defaultChecked={checkButton} value={checkButton} onChange={() => {
                                                                                setCheckButton((prev) => !prev);
                                                                                setDecelaration(false);
                                                                            }} required />

                                                                            <label className="form-check-label" label="flexCheckDefault">
                                                                                <b>   I understand and agree to the above declaration,   <a href="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/terms_and_conditions.pdf" className="text-dark text-underline" target="_blank"> terms of use  </a>
                                                                                    &nbsp;  and <a href="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/refund_policy.pdf" className="text-dark text-underline" target="_blank"> refund policy.  </a>
                                                                                    &nbsp;  </b>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row" >
                                                                <div className="col-md-6" >
                                                                    <div className="form-group mt-4 " >
                                                                        <button className="btn form-control btn-warning  btn-block w-100 " type="submit" > Submit </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        {responseResult && (
                                                            <div className="fl_card " >
                                                                <div className="fl_row" >
                                                                    <i class="ri-close-circle-fill"></i>
                                                                    <p className="mt-0">
                                                                        {responseResult}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </fieldset>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    :
                    <div className="pager_wrapper feature_bg overflow-hidden" >
                        <main>
                            <section className="error_page login_bg login_sec">
                                {/* <img src="/assets/images/404.svg" className="img-fluid" alt="" /> */}
                                <Link to="/markets" className="btn btn-warning custom-btn" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                    <i className="ri-home-2-line me-2"></i> Back to Home
                                </Link>
                            </section>
                        </main>
                    </div>
            }





        </main >
    );
};

export default LevelTwoKyc;
