import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../Footer";


const PartnershipInquiry = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])
    return (
        <>
        <main>
            <section class="tm_sec d-block">
                <div class="container">
                    <div class="row   mt-md-5 justify-content-center">
                    <div class="col-lg-12">
                    <div class="section-title  partner_title_sect text-start ">

                        <div className="row align-items-center g-4" >
                            <div className="col-lg-6" >
                                <h2 class="text-warning  "> Crypto India Partnership Program   </h2> 
                                <div className="ddd text-start"  > 
                                <p>
                                We’re excited to explore new opportunities for collaboration! Whether you’re interested in partnering with Crypto India, inquiring about our services, or seeking media-related information, we’d love to hear from you.
                                </p>
                                
                                <p>
                                Please fill out the form below with the details of your inquiry or partnership proposal. Our team will review your submission and get back to you as soon as possible. We value every opportunity and look forward to building something great together!
                                    
                                </p> 
                                </div>
                            </div>
                            <div className="col-lg-6" >
                                <div className="sec_img" >
                                    <img src="assets/images/partner_img.svg" className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>     
                    </div>
                     
                     
                    </div>
 

                </div>

                </div> 



                <div className="container" >
                    <div class="g_start m_card overflow-hidden p-0 ">
                        <div class="p_card p-md-5">
                            <div className="why_card"  > 
                                <h6>Let’s Connect!</h6>
                                <p>
                                Complete the form, and we’ll be in touch shortly
                                </p> 
                            </div>
                            <br/>


                            <div class="row">
                                <div class="col-md-6 mb-4">
                                    <div class="field-box">
                                    <label for="text" class="form-label">  Full Name</label>
                                    <div class="field-otp-box">
                                        <input type="text" class="form-control" name="subject"  />
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="field-box">
                                    <label for="text" class="form-label">  Company/Organization Name </label>
                                    <div class="field-otp-box">
                                        <input type="text" class="form-control" name="subject"  />
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="field-box">
                                    <label for="text" class="form-label">  Email Address</label>
                                    <div class="field-otp-box">
                                        <input type="text" class="form-control" name="subject"  />
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="field-box">
                                    <label for="text" class="form-label"> Phone Number </label>
                                    <div class="field-otp-box">
                                        <input type="text" class="form-control" name="subject"  />
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="field-box">
                                    <label for="text" class="form-label">  Website (if applicable) </label>
                                    <div class="field-otp-box">
                                        <input type="text" class="form-control" name="subject"  />
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="field-box">
                                        <label for="text" class="form-label">Inquiry Type</label>
                                        <div class="field-otp-box">
                                            <select className="form-control form-select " >
                                                <option> Select Option </option>
                                                <option> Partnership Opportunity </option>
                                                <option> Business Collaboration  </option>
                                                <option> Product/Service Inquiry </option>
                                                <option> Media/PR </option>
                                                <option> Other </option> 
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="field-box">
                                    <label for="text" class="form-label"> Subject  </label>
                                    <div class="field-otp-box">
                                        <input type="text" class="form-control"   />
                                    </div>
                                    </div>
                                </div> 
                                <div class="col-lg-12 col-md-12 col-12 mb-4">
                                    <label class="form-label mb-1">Message/Details (Required) 
                                    <small className="d-block mb-2" >Please provide details about your inquiry or partnership opportunity.</small>
                                    </label>
                                    <div class=" field-otp-box">
                                    <textarea class="form-control" rows="4" placeholder="Type message here" name="description"></textarea>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-2">
                                    <button class="btn w-auto px-5 btn-xl  btn-warning" type="button">
                                    Submit 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





            </section>
        </main>
        
        <Footer />
        </>

    )
}

export default PartnershipInquiry