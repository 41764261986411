import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { alertErrorMessage } from "../../../../Services/CustomAlertModel";
import AuthService from "../../../../Services/ApiServices/AuthService";
import LoaderHelper from "../../../../Services/Loading/LoaderHelper";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import { saveAs } from 'file-saver';
import { addDays } from 'date-fns';
import copy from 'copy-to-clipboard';


const TransactionHistory = () => {

    const [activeTab, setActiveTab] = useState("DEPOSIT");
    const [transactionData, setTransactionData] = useState([]);
    const [search, setSearch] = useState("");
    const [filterTransaction, setFilterTransaction] = useState([]);
    const [totalDataLength, setTotalDataLength] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isActiveCopy, setIsActiveCopy] = useState(null);
    const [isActiveCopytx, setIsActiveCopytx] = useState(null);
    const [selectedTab, setSelectedTab] = useState('all');
    const [activeExport, setActiveExport] = useState(false);
    const [skip, setSkip] = useState(0);
    const limit = 20;

    const handleTranactionList = async (skip, limit, startDate, endDate, activeTab) => {
        const validStartDate = startDate || "";
        const validEndDate = endDate || "";
        try {
            const result = await AuthService.depositDatawithdrawalData(skip, limit, validStartDate, validEndDate, activeTab);
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                setTransactionData(result.data?.reverse());
                setTotalDataLength(result?.count);
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        handleTranactionList(skip, limit, startDate, endDate, activeTab);
    }, [skip, limit, activeTab]);


    useEffect(() => {
        const filteredData = transactionData.filter(item => {
            if (selectedTab !== 'all') {
                if (selectedTab === 'COMPLETED' && item.status !== 'COMPLETED') return false;
                if (selectedTab === 'PENDING' && item.status !== 'PENDING') return false;
                if (selectedTab === 'REJECTED' && item.status !== 'REJECTED') return false;
                if (selectedTab === 'CANCELLED' && item.status !== 'CANCELLED') return false;
            }

            return (
                item?.from?.toLowerCase().includes(search?.toLowerCase()) ||
                item?.status?.toLowerCase().includes(search?.toLowerCase()) ||
                item?.short_name?.toLowerCase().includes(search?.toLowerCase()) ||
                item?.to_address?.toLowerCase().includes(search?.toLowerCase()) ||
                item?.transaction_type?.toLowerCase().includes(search?.toLowerCase()) ||
                item?.transaction_hash?.toLowerCase().includes(search?.toLowerCase()) ||
                item?._id?.toLowerCase().includes(search?.toLowerCase())
            );
        });

        setFilterTransaction(filteredData);
    }, [selectedTab, search, transactionData]);


    const columns = [

        {
            name: 'Order No.',
            selector: row => row._id,
            sortable: true,
            wrap: true,

            format: row =>
                <div className="c_view">{row._id} </div>,
        },

        {
            name: "Date/Time",
            cell: (row) => <div className="justify-content-start" > <span>{moment(row.createdAt).format("DD/MM/YYYY")} <br /> <small>{moment(row.createdAt).format("HH:mm:ss")}</small> </span> </div>,
            sortable: false,
        },

        {
            name: 'Currency',
            selector: row => row.from,
            sortable: true,
            format: row => row.currency || '----'
        },

        {
            name: 'Amount',
            selector: row => row.amount?.toFixed(4),
            wrap: true,
            sortable: true,
        },

        ...(activeTab !== 'DEPOSIT' ? [{
            name: 'Fee',
            selector: row => row.fee || '-----',
            wrap: true,
            sortable: true,
        }] : []),

        {
            name: 'Chain',
            selector: row => row.chain || '-----',
            wrap: true,
            sortable: true,
        },

        {
            name: 'Address',
            selector: row => row.to_address || '-----',
            wrap: true,
            sortable: true,
            cell: row => (
                <>
                    <span className="cursor-pointer" title={row.to_address}>
                        {row.to_address.slice(0, 4)}****{row.to_address.slice(-4)}
                    </span>&nbsp;
                    <i className={`text-black cursor-pointer ${isActiveCopy === row?._id ? "ri-check-line" : "ri-file-copy-line"}`} onClick={() => { copy(row.to_address); setIsActiveCopy(row?._id) }} ></i>&nbsp;
                    <a href={`https://bscscan.com/address/${row.to_address}`} target="_blank"> <i className="ri-links-line text-black cursor-pointer"></i> </a>
                </>
            )
        },

        {
            name: 'Tx.Id',
            selector: row => row.transaction_hash || '-----',
            wrap: true,
            sortable: true,
            cell: row => (
                <>

                    {row.transaction_hash ?

                        <>
                            <span className="cursor-pointer" title={row.transaction_hash}>
                                {row.transaction_hash.slice(0, 4)}****{row.transaction_hash.slice(-4)}
                            </span>&nbsp;

                            <i className={`text-black cursor-pointer ${isActiveCopytx === row?._id ? "ri-check-line" : "ri-file-copy-line"}`} onClick={() => { copy(row.transaction_hash); setIsActiveCopytx(row?._id) }}></i>

                            <a href={`https://bscscan.com/tx/${row.transaction_hash}`} target="_blank"> <i className="ri-links-line text-black cursor-pointer"></i> </a>
                        </>
                        :
                        ""
                    }

                </>
            )
        },



        ...(activeTab !== 'DEPOSIT' ? [{
            name: 'Completed Time',
            selector: row => row.complete_time || '-----',
            wrap: true,
            sortable: true,
        }] : []),



        {

            name: 'Status',
            selector: row => row.status,
            wrap: true,
            sortable: true,
            format: row =>
                <span className={row.status === 'SUCCESS' || row.status === 'COMPLETED' ? " text-success" : row.status === 'PENDING' ? " text-warning" : "text-danger"}>
                    {row?.status}
                </span>,
        },

        ...(activeTab !== 'DEPOSIT' ? [{
            name: 'Action',
            selector: row => '-----',
            wrap: true,
            sortable: true,
        }] : []),


    ];

    const handlePagination = (action) => {
        if (action === 'prev') {
            if (skip - limit >= 0) {
                setSkip(skip - limit);
            }
        } else if (action === 'next') {
            if (skip + limit < totalDataLength) {
                setSkip(skip + limit);
            }
        } else if (action === 'first') {
            setSkip(0);
        } else if (action === 'last') {
            const lastPageSkip = Math.floor(totalDataLength);
            const data = lastPageSkip - limit
            setSkip(data);
        }
    };

    const handleClear = () => {
        handleTranactionList(skip, limit, startDate, endDate, activeTab);
        setSearch('');
        setStartDate('');
        setEndDate('');
    };

    const handleCleartab = () => {
        setSearch('');
        setStartDate('');
        setEndDate('');
        handleTranactionList(skip, limit, "", "", activeTab);
    };



    const initialStartDate = addDays(new Date(), -29);
    const initialEndDate = new Date();
    const [state, setState] = useState([
        {
            startDate: initialStartDate,
            endDate: initialEndDate,
            key: 'selection'
        }
    ]);

    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]).filter(key => !['_id', 'user_id', 'order_id', 'updatedAt', '_v'].includes(key));
        let csv = headers.join(',') + '\n';
        data.forEach(item => {
            const row = headers.map(header => {
                if (header === 'createdAt') {
                    return moment(item[header]).format('DD-MM-YYYY HH:mm:ss');
                }
                return item[header];
            });
            csv += row.join(',') + '\n';
        });
        return csv;
    };

    const handleExportData = async (startDate, endDate) => {
        try {
            const result = await AuthService.exportDataDepositWithdrawal(startDate, endDate, activeTab);
            if (result?.success) {
                if (result.data && result.data.length > 0) {
                    const csvData = convertToCSV(result.data);
                    saveAs(new Blob([csvData], { type: 'text/csv' }), 'Buy_sell.csv');
                } else {
                    alertErrorMessage('No Data Available...!')
                }
                LoaderHelper.loaderStatus(false);
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };

    const handleTabChange = (event) => {
        setSelectedTab(event.target.value);
    };

    return (
        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper" >
                    <section className="d_page history_page">
                        <div className="container" >
                            <div className="row justify-content-center">
                                <div className="col-lg-12" >
                                    <div className="pg_header" >
                                        <div className="hd_innre" >
                                            <h6 className="mb-0" >Transaction History</h6>
                                        </div>
                                    </div>
                                    <div className="spot_tabs my-2 my-md-3" >
                                        <ul className="nav custom-tabs">
                                            <li className={`${activeTab === "DEPOSIT" ? 'active' : ''}`}
                                                onClick={() => { setActiveTab('DEPOSIT'); handleTranactionList(skip, limit, startDate, endDate, 'DEPOSIT'); setSkip(0); handleClear() }}> <span> Deposit History </span></li>
                                            <li className={`tab_item ${activeTab === "WITHDRAWAL" ? 'active' : ''}`}
                                                onClick={() => { setActiveTab('WITHDRAWAL'); handleTranactionList(skip, limit, startDate, endDate, 'WITHDRAWAL'); setSkip(0); handleClear() }}><span>  Withdrawal History</span></li>
                                        </ul>
                                    </div>

                                    <div className="filter-card mb-3 mb-md-4">
                                        <div className="row g-2 align-items-end" >
                                            <div className="col-lg-10" >
                                                <div className="row g-2 align-items-end" >
                                                    <div className="col-lg-2" >
                                                        <div className="form-group" >
                                                            <label> Search </label>
                                                            <div className="searchBar custom-tabs mt-0">
                                                                <input type="search" className="custom_search" placeholder="Search Crypto" value={search} onChange={(e) => { setSearch(e.target.value); }} />
                                                                <i className="ri-search-2-line"></i>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-12 col-lg-2">
                                                        <div className="form-group">
                                                            <label> Status </label>
                                                            <select className="form-control form-select cursor-pointer" onChange={handleTabChange}>
                                                                <option value="all">All</option>
                                                                <option value="COMPLETED">Completed </option>
                                                                <option value="PENDING">Pending </option>
                                                                <option value="REJECTED">Rejected </option>
                                                                <option value="CANCELLED">Cancelled </option>
                                                            </select>
                                                        </div>
                                                    </div>


                                                    <div className="col-6  col-lg-2">
                                                        <div className="form-group">
                                                            <label>Start Date</label>
                                                            <input
                                                                type="date"
                                                                className="form-control cursor-pointer"
                                                                value={startDate}
                                                                onChange={(e) => setStartDate(e.target.value)}
                                                                max={new Date().toISOString().split("T")[0]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6  col-lg-2">
                                                        <div className="form-group">
                                                            <label>End Date</label>
                                                            <input
                                                                type="date"
                                                                className="form-control cursor-pointer"
                                                                value={endDate}
                                                                onChange={(e) => setEndDate(e.target.value)}
                                                                max={new Date().toISOString().split("T")[0]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <div className="d-flex gap-2 mt-2 mt-md-0">
                                                            <button className="btn btn-muted w-50" onClick={handleCleartab} disabled={!startDate || !endDate}>Clear</button>
                                                            <button className="btn btn-dark w-50" onClick={() => { handleTranactionList(skip, limit, startDate, endDate, activeTab); setActiveExport(true) }} disabled={!startDate || !endDate}>Apply</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2" >
                                                <div className="ph_right" >
                                                    <button className="btn btn-dark px-4" type="button" onClick={() => handleExportData(startDate, endDate)} disabled={!startDate || !endDate || !activeExport}>
                                                        Export <i className="ms-2 ri-download-2-line"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Deposit history */}
                                    <div className={`g_start m_card overflow-hidden p-0 ${activeTab != "DEPOSIT" ? 'd-none' : ''}`}>
                                        <div className="m_table" >
                                            <div className="table-responsive h-auto ">
                                                <DataTable
                                                    className="table table-hover"
                                                    columns={columns}
                                                    data={filterTransaction}
                                                    noDataComponent={
                                                        <p> <img src="/assets/images/not_found.svg" width="300" className='img-fluid mt-5' alt='' /> </p>
                                                    }
                                                />
                                                {
                                                    transactionData?.length > 0 &&
                                                    <div className="hVPalX gap-2" >
                                                        <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                                        <div className="sc-eAKtBH gVtWSU">
                                                            <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                                </svg>
                                                            </button>
                                                            <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                </svg>
                                                            </button>

                                                            <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                </svg>
                                                            </button>
                                                            <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                    {/* withdrawal history */}
                                    <div className={`g_start m_card overflow-hidden p-0 ${activeTab != "WITHDRAWAL" ? 'd-none' : ''}`}>
                                        <div className="m_table">
                                            <div className="table-responsive h-auto ">
                                                <DataTable className="table table-hover"
                                                    columns={columns}
                                                    data={filterTransaction}
                                                    noDataComponent={
                                                        <p> <img src="/assets/images/not_found.svg" width="300" className='img-fluid mt-5' alt='' /> </p>
                                                    }
                                                />

                                                {
                                                    transactionData?.length > 0 &&
                                                    <div className="hVPalX gap-2" >
                                                        <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                                        <div className="sc-eAKtBH gVtWSU">
                                                            <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                                </svg>
                                                            </button>
                                                            <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                </svg>
                                                            </button>

                                                            <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                </svg>
                                                            </button>
                                                            <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>

    )
}

export default TransactionHistory