import React from 'react';
import HeroSection from '../HeroSection';
import PartnerSection from '../PartnerSection';
import MarketUpdates from '../MarketUpdates';
import DecentralizedDis from '../DecentralizedDis';
import RoadMap from '../RoadMap';
import Whyus from '../Whyus';
import Aboutus from '../Aboutus';
import DownloadApp from '../DownloadApp';
import Footer from '../../Footer';

const MainPage = () => {

    return (
        <main>
            <div className="home_section">
                <HeroSection />
                <MarketUpdates />
                <PartnerSection />
                <DownloadApp />
                <DecentralizedDis />
                <RoadMap />
                <Whyus />
                <Aboutus />
            </div>
            <Footer />
        </main>
    )
}

export default MainPage