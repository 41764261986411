import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ProfileContext } from "../../../Services/ProfileProvider";
import MenuItems from "../../../Context/MenuItems";
import Swal from 'sweetalert2';
import AuthService from "../../../Services/ApiServices/AuthService";

const DashboardSidebar = () => {
    const location = useLocation();
    const [isActive, setIsActive] = useState(location.pathname);
    const [activeDropdown, setActiveDropdown] = useState("");
    const [activeMenu, setActiveMenu] = useState(false);
    const [menuName, setMenuName] = useState('Dashboard');
    const [menuIcon, setMenuIcon] = useState('ri-home-6-line');
    const [totalDataLength, setTotalDataLength] = useState([]);

    const { setLoginDetails } = useContext(ProfileContext);
    const navigate = useNavigate();



    useEffect(() => {
        const currentMenu = MenuItems[location.pathname] || MenuItems["/"];
        setMenuName(currentMenu.name);
        setMenuIcon(currentMenu.icon);
    }, [location.pathname]);

    const handleSetActive = (path) => {
        setIsActive(path);
    };

    const handleToggle = (dropdown) => {
        setActiveDropdown(prevDropdown => prevDropdown === dropdown ? '' : dropdown);
    };

    const handleMenuToggle = () => {
        setActiveMenu(prevState => !prevState);
    };

    const showAlert = () => {
        Swal.fire({
            title: "Are you sure?",
            text: `You are about to log out`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#e64942",
            cancelButtonColor: "#e7e7e7",
            confirmButtonText: "Yes Logout!",
        }).then((result) => {
            if (result.isConfirmed) {
                tokenExpire()
            }
        });
    };

    const tokenExpire = () => {
        localStorage.clear();
        navigate("/");
        setLoginDetails({});
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    /*     const handleSupportList = async () => {
            const result = await AuthService.supportTicketList(0, 10);
            const pendingTickets = result?.data?.filter(ticket => ticket.status === "Pending");
            setTotalDataLength(pendingTickets);
        };
    
        useEffect(() => {
            handleSupportList();
        }, []); */




    return (
        <div className="sub_header d_sub_header">
            <div className="sub_header_row">
                <div className="sub_header_l">
                    <span className="side_dropdown-item main_menu cursor-pointer" onClick={handleMenuToggle}>
                        <span><i className={menuIcon}></i> {menuName}</span>
                    </span>

                    <nav className={`navbar-nav navbar side_nav ${activeMenu && 'active'}`} id="sub_nav">
                        <li>
                            <Link
                                className={`nav-link ${isActive === "/dashboard" ? 'active' : ''}`}
                                to="/dashboard"
                                onClick={() => handleSetActive('/dashboard')}
                            >
                                <i className="ri-home-6-line"></i> Dashboard
                            </Link>
                        </li>

                        <li className={`side_dropdown ${activeDropdown === 'Assets' || isActive === "/wallet" || isActive === "/Peer_to_peer" ? 'active' : ''}`} onClick={() => handleToggle('Assets')}>
                            <Link className="side_dropdown-toggle nav-link" to="#" role="button">
                                <span>
                                    <i className="ri-wallet-line"></i> Assets
                                </span>
                            </Link>

                            <ul className="side_dropdown-menu">
                                <li>
                                    <Link
                                        className={`side_dropdown-item ${isActive === "/wallet" ? 'active' : ''}`}
                                        to="/wallet"
                                        onClick={() => handleSetActive('/wallet')}
                                    >
                                        Spot
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`side_dropdown-item ${isActive === "/Peer_to_peer" ? 'active' : ''}`}
                                        to="/Peer_to_peer"
                                        onClick={() => handleSetActive('/Peer_to_peer')}
                                    >
                                        P2P
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className={`side_dropdown ${activeDropdown === 'Account' || isActive === "/personal_information" || isActive === "/verify_kyc" || isActive === "/payments" || isActive === "/security" || isActive === "/currency_preference" || isActive === "/add_nominee" || isActive === "/managing_features" ? 'active' : ''}`} onClick={() => handleToggle('Account')} >
                            <Link className="side_dropdown-toggle nav-link" to="#" role="button">
                                <span>
                                    <i className="ri-user-6-line"></i> Account
                                </span>
                            </Link>

                            <ul className="side_dropdown-menu">
                                <li>
                                    <Link
                                        className={`side_dropdown-item ${isActive === "/personal_information" || isActive === "/add_nominee" ? 'active' : ''}`}
                                        to="/personal_information"
                                        onClick={() => handleSetActive('/personal_information')}
                                    >
                                        Personal Information
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`side_dropdown-item ${isActive === "/verify_kyc" ? 'active' : ''}`}
                                        to="/verify_kyc"
                                        onClick={() => handleSetActive('/verify_kyc')}
                                    >
                                        KYC Management
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`side_dropdown-item ${isActive === "/payments" ? 'active' : ''}`}
                                        to="/payments"
                                        onClick={() => handleSetActive('/payments')}
                                    >
                                        Payments
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`side_dropdown-item ${isActive === "/security" ? 'active' : ''}`}
                                        to="/security"
                                        onClick={() => handleSetActive('/security')}
                                    >
                                        Security
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`side_dropdown-item ${isActive === "/currency_preference" ? 'active' : ''}`}
                                        to="/currency_preference"
                                        onClick={() => handleSetActive('/currency_preference')}
                                    >
                                        Currency Preference
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`side_dropdown-item ${isActive === "/managing_features" ? 'active' : ''}`}
                                        to="/managing_features"
                                        onClick={() => handleSetActive('/managing_features')}
                                    >
                                        Managing Features
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className={`side_dropdown ${activeDropdown === 'Orders' || isActive === "/spot_order"
                            || isActive === "/buy_sell_transactions" || isActive === "/transaction_history"
                            ? 'active' : ''}`} onClick={() => handleToggle('Orders')} >
                            <Link className="side_dropdown-toggle nav-link" to="#" role="button">
                                <span>
                                    <i className="ri-file-list-3-line"></i> Orders
                                </span>
                            </Link>

                            <ul className="side_dropdown-menu">
                                <li>
                                    <Link
                                        className={`side_dropdown-item ${isActive === "/spot_order" ? 'active' : ''}`}
                                        to="/spot_order"
                                        onClick={() => handleSetActive('/spot_order')}
                                    >
                                        Spot Orders
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`side_dropdown-item ${isActive === "/buy_sell_transactions" ? 'active' : ''}`}
                                        to="/buy_sell_transactions"
                                        onClick={() => handleSetActive('/buy_sell_transactions')}
                                    >
                                        Buy/Sell Orders
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`side_dropdown-item ${isActive === "/transaction_history" ? 'active' : ''}`}
                                        to="/transaction_history"
                                        onClick={() => handleSetActive('/transaction_history')}
                                    >
                                        Transaction History
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link
                                        className={`side_dropdown-item ${isActive === "/withdraw_orders" ? 'active' : ''}`}
                                        to="/withdraw_orders"
                                        onClick={() => handleSetActive('/withdraw_orders')}
                                    >
                                        Withdraw History
                                    </Link>
                                </li> */}
                            </ul>
                        </li>



                        <li className={`side_dropdown ${activeDropdown === 'Activity' || isActive === "/activities"
                            || isActive === "/recent_devices"
                            ? 'active' : ''}`} onClick={() => handleToggle('Activity')} >
                            <Link className="side_dropdown-toggle nav-link" to="#" role="button">
                                <span>
                                    <i className="ri-device-line"></i> Account Activity
                                </span>
                            </Link>
                            <ul className="side_dropdown-menu">
                                <li>
                                    <Link
                                        className={`side_dropdown-item ${isActive === '/activities' ? 'active' : ''}`}
                                        to="/activities"
                                        onClick={() => handleSetActive('/activities')}
                                    >
                                        Activities
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`side_dropdown-item ${isActive === "/recent_devices" ? 'active' : ''}`}
                                        to="/recent_devices"
                                        onClick={() => handleSetActive('/recent_devices')}
                                    >
                                        Recent Devices
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className={`side_dropdown ${activeDropdown === 'reports' || isActive === "/account_summary" || isActive === "/account_statement" ? 'active' : ''}`} onClick={() => handleToggle('reports')} >
                            <Link className="side_dropdown-toggle nav-link" to="#" role="button">
                                <span>
                                    <i className="ri-file-chart-line"></i> Your Reports
                                </span>
                            </Link>

                            <ul className="side_dropdown-menu">

                                <li>
                                    <Link
                                        className={`side_dropdown-item ${isActive === "/account_summary" ? 'active' : ''}`}
                                        to="/account_summary"
                                        onClick={() => handleSetActive('/account_summary')}
                                    >
                                       Fee & Tds Summary
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`side_dropdown-item ${isActive === "/account_statement" ? 'active' : ''}`}
                                        to="/account_statement"
                                        onClick={() => handleSetActive('/account_statement')}
                                    >
                                        Account Statment
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        
                        <li>
                            <Link
                                className={`nav-link ${isActive === "/referral" ? 'active' : ''}`}
                                to="/referral"
                                onClick={() => handleSetActive('/referral')}
                            >
                                <i className="ri-user-shared-line"></i> Refer & Earn
                            </Link>
                        </li>



                        <li>
                            <Link
                                className={`nav-link ${isActive === "/support" ? 'active' : ''}`}
                                to="/support"
                                onClick={() => handleSetActive('/support')}
                            >
                                <i className="ri-message-2-line"></i>
                                <em> Support  </em>
                            </Link>
                        </li>


                        <li>
                            <Link
                                className={`nav-link ${isActive === "/all_notificatios" ? 'active' : ''}`}
                                to="/all_notificatios"
                                onClick={() => handleSetActive('/all_notificatios')}
                            >
                                <i className="ri-notification-line"></i>  <em> Notifications </em>
                            </Link>
                        </li>


                        <li>
                            <span className="nav-link cursor-pointer" onClick={showAlert}>
                                <i className="ri-logout-circle-r-line"></i> Logout
                            </span>
                        </li>
                    </nav>

                </div>
            </div>
        </div>
    );
};

export default DashboardSidebar;
