import React, { useEffect, useRef, useState } from "react";
import DashboardSidebar from "../../CoustamComponent/DashboardSidebar";
import { Link, useLocation } from "react-router-dom";
import copy from 'copy-to-clipboard';
import AuthService from "../../../Services/ApiServices/AuthService";
import { alertErrorMessage } from "../../../Services/CustomAlertModel";
import moment from "moment";

const SupportMessage = () => {
    const location = useLocation();
    const rowData = location.state?.rowData;
    const [sendMessage, setSendMessage] = useState("");
    const [message, setMessages] = useState([]);
    const chatEndRef = useRef(null);

    const scrollToBottom = () => {
        // chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
        handleRefresh();

        const intervalId = setInterval(() => {
            handleRefresh();
        }, 3000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [message]);

    const handleMessageReply = async () => {
        try {
            const result = await AuthService.sendUserMessage(rowData?._id, sendMessage);
            if (result?.success) {
                setSendMessage("");
                handleRefresh();
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleMessageReply();
    };

    const handleRefresh = async () => {
        try {
            const refreshedData = await AuthService.getMessageById(rowData?._id);
            if (refreshedData?.success) {
                setMessages(refreshedData.data?.chat);
            } else {
                alertErrorMessage(refreshedData);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    return (
        <main>
            <div className="page_wrapper">
                <div className="sidebar">
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper">
                    <section className="d_page history_page">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-12 col-xl-8">
                                    <div className="pg_header mb-0">
                                        <div className="hd_innre">
                                            <h6 className="mb-0 d-flex align-items-center">
                                                <Link className="btn btn-icon p-0 no-border me-3" to="/support">
                                                    <i className="ri-arrow-left-line"></i>
                                                </Link>
                                                Ticket ID: #{rowData?.id?.slice(0, 4)}
                                                &nbsp;
                                                <i className="ri-file-copy-line cursor-pointer text-success" onClick={() => copy(rowData?.id)}></i>
                                            </h6>
                                        </div>
                                    </div>

                                    <div className="chat_box g_start p-0">
                                        <div className="chat-header">
                                            <div className="avatar-box">
                                                <span className="nickName d-block">{rowData?.email}</span>
                                                <span className=" text-white chat_close_icon cursor-pointer" onClick={handleRefresh}><i className="ri-refresh-line"></i></span>
                                            </div>
                                            <hr />
                                            <div>
                                                <b>Description :</b> <p className="mb-0">{rowData?.description}</p>
                                            </div>
                                        </div>
                                        <div className="chat_body scroll_y">
                                            <div className="msg_history scroll_bar">
                                                {message && message.map((item, index) => (
                                                    item.message_by === 1 ? (
                                                        <div className="outgoing_msg" key={index}>
                                                            <div className="sent_msg">
                                                                <p>{item?.message}</p>
                                                            </div>
                                                            <div className="msg_time">
                                                                <small>
                                                                    {moment().diff(moment(item.createdAt), 'seconds') < 60
                                                                        ? `${moment().diff(moment(item.createdAt), 'seconds')} seconds ago`
                                                                        : moment().diff(moment(item.createdAt), 'minutes') < 60
                                                                            ? `${moment().diff(moment(item.createdAt), 'minutes')} minutes ago`
                                                                            : moment().diff(moment(item.createdAt), 'hours') < 24
                                                                                ? `${moment().diff(moment(item.createdAt), 'hours')} hours ago`
                                                                                : `${moment().diff(moment(item.createdAt), 'days')} days ago`}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="incoming_msg" key={index}>
                                                            <div className="received_msg">
                                                                <div className="received_withd_msg">
                                                                    <p>{item?.message}</p>
                                                                </div>
                                                                <div className="msg_time">
                                                                    <small>
                                                                        {moment().diff(moment(item.createdAt), 'seconds') < 60
                                                                            ? `${moment().diff(moment(item.createdAt), 'seconds')} seconds ago`
                                                                            : moment().diff(moment(item.createdAt), 'minutes') < 60
                                                                                ? `${moment().diff(moment(item.createdAt), 'minutes')} minutes ago`
                                                                                : moment().diff(moment(item.createdAt), 'hours') < 24
                                                                                    ? `${moment().diff(moment(item.createdAt), 'hours')} hours ago`
                                                                                    : `${moment().diff(moment(item.createdAt), 'days')} days ago`}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                ))}

                                                <div ref={chatEndRef} />
                                            </div>
                                        </div>
                                        <div className="chat-footer send-message">
                                            <form onSubmit={handleSubmit}>
                                                <input
                                                    type="text"
                                                    cols="10"
                                                    rows="1"
                                                    className="form-control"
                                                    placeholder="Type your message here ..."
                                                    value={sendMessage}
                                                    onChange={(e) => setSendMessage(e.target.value)}
                                                />
                                                <span className="chat_action">
                                                    <button type="submit" className="btn btn-warning px-4">
                                                        Send
                                                    </button>
                                                </span>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
};

export default SupportMessage;
