import React from 'react';
import Footer from '../../../Footer';
import Whyus from '../../../HomePage/Whyus';

const DownloadApp = () => {

    return (
        <main className='dn_wrapper' >
            <div className="home_section  pb-0">
                <section className="how_it_sec h_app_sec">
                    <div className="container">
                        <div className="row  align-items-center mt-5">
                            <div className="col-lg-5 ">
                                <div className="section-title  text-start">
                                    <h2> Trade Safely and Securely
                                    Anytime. Anywhere. with Crypto India App </h2>
                                    <p> Trade at home and on the go with our app. Available via the App Store and Google Play Store </p>
                                    <div className="nc_body nc_qr p-0">
                                        <div className="qr_body">
                                            {/* <span className="text-muted"> Scan to Download the App</span>
                                            <p className='m-0 h5' > iOS and Android  </p> */}
                                            <div class="nc_btns">
                                                <a href="#" target="_blank">
                                                <img src="assets/images/play_store.webp" class="img-fluid" alt="" /></a>
                                                <a href="#" target="_blank">
                                                <img src="assets/images/app_store.webp" class="img-fluid" alt="" /></a>
                                            </div> 
                                            <img src="/assets/images/qr_code.png" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <img src="/assets/images/mob_mockup.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Whyus />
            
            <Footer />
        </main >
    )
}

export default DownloadApp