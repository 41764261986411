import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../Footer";


const MarketMakerProgram = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])


    return (
        <>
        <main>
            <section class="tm_sec d-block">
                <div class="container">
                    <div class="row   mt-md-5 justify-content-center">
                    <div class="col-lg-12">
                    <div class="section-title  partner_title_sect text-start ">

                        <div className="row align-items-center g-4" >
                            <div className="col-lg-5" >
                                <h2 class="text-dark  "> Market Maker Program <br/> Application Form   </h2> 
                                <div className="ddd text-start"  > 
                                <p>
                                Thank you for your interest in the Crypto India Market Maker Program for our OTC Desk. As a market maker, you play a crucial role in enhancing liquidity on our platform, ensuring seamless large-scale trades for all participants. 
                                </p> 
                                </div>
                            </div>
                            <div className="col-lg-7" >
                                <div className="sec_img" >
                                    <img src="assets/images/mm_program.svg" className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>     
                    </div>
                     
                     
                    </div>
 

                </div>

                </div> 



                <div className="container" >
                    <div class="g_start m_card overflow-hidden p-0 ">
                        <div class="p_card p-md-5">
                            <div className="why_card"  >  
                                <p>
                                Please fill out the form below to apply for the program:
                                </p> 
                            </div>
                            <br/>


                            <div class="row">
                                <div class="col-md-6 mb-4">
                                    <div class="field-box">
                                        <label for="text" class="form-label"> Account Type</label>
                                        <div class="field-otp-box">
                                            <select className="form-control form-select " >
                                                <option> Select Type </option>
                                                <option> Company Account  </option> 
                                                <option> Individual Account  </option> 
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="field-box">
                                    <label for="text" class="form-label">  Name   </label>
                                    <div class="field-otp-box">
                                        <input type="text" class="form-control" name="subject"  />
                                    </div>
                                    </div>
                                </div> 
                                <div class="col-md-6 mb-4">
                                    <div class="field-box">
                                    <label for="text" class="form-label">  Email Address</label>
                                    <div class="field-otp-box">
                                        <input type="text" class="form-control" name="subject"  />
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="field-box">
                                    <label for="text" class="form-label"> Phone Number </label>
                                    <div class="field-otp-box">
                                        <input type="text" class="form-control" name="subject"  />
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="field-box">
                                    <label for="text" class="form-label">  Company Registration Number (Optional)</label>
                                    <div class="field-otp-box">
                                        <input type="text" class="form-control" name="subject"  />
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="field-box">
                                    <label for="text" class="form-label"> Country of Operation </label>
                                    <div class="field-otp-box">
                                        <input type="text" class="form-control" name="subject"  />
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="field-box">
                                    <label for="text" class="form-label"> Website(Optional): </label>
                                    <div class="field-otp-box">
                                        <input type="text" class="form-control" name="subject"  />
                                    </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 mt-4" >
                                    <h6 class="mb-3"> Trading Information </h6>
                                </div>
                                <div class="col-md-12 mb-4">
                                    <div class="field-box">
                                        <label for="text" class="form-label">  Preferred Trading Pairs:</label>
                                        <div class="field-otp-box">
                                            <select className="form-control form-select " >
                                                <option> Select Pair </option>
                                                <option> Bitcoin  </option>
                                                <option> Ethereum   </option>
                                                <option> USDT  </option> 
                                            </select>
                                        </div>
                                    </div>
                                </div>
 


                                <div class="col-md-12 mb-4">
                                    <div class="field-box">
                                    <label for="text" class="form-label"> Do you have experience providing liquidity on other exchanges (Yes/No if yes please provide name of exchange)   </label>
                                    <div class="field-otp-box">
                                        <input type="text" class="form-control" name="subject"  />
                                    </div>
                                    </div>
                                </div> 
                                <div class="col-md-12 mb-4">
                                    <div class="field-box">
                                    <label for="text" class="form-label"> Have you been involved in any legal or regulatory disputes related to trading?  </label>
                                        <div class="field-otp-box">
                                            <select className="form-control form-select " >
                                                <option> Select Option   </option>
                                                <option> Yes  </option>
                                                <option> No   </option> 
                                            </select>
                                        </div>
                                    </div>
                                </div>  
                                
                                <div className="col-md-12">
                                    <div className="form-check mb-4">
                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" required />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                        <small>By submitting this form, I agree to the terms and conditions of the Crypto India Market Maker Program and will adhere to all regulatory and compliance requirements.</small>
                                        </label>
                                    </div>
                                </div>
                                 



                                <div class="col-md-12 mt-2">
                                    <button class="btn w-auto px-5 btn-xl  btn-warning" type="button">
                                    Submit 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





            </section>
        </main>
        
        <Footer />
        </>

    )
}

export default MarketMakerProgram